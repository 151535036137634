import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

export const ConfirmationModal = ({
  show,
  confirmMessage,
  onHide,
  onConfirm,
  onCancel,
  confirmText,
  submitVariant,
}) => {
  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    onHide();
  };
  const handleCancel = () => {
    if (onCancel) onCancel();
    onHide();
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Confirm</Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: 'center' }}>{confirmMessage}</div>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={handleCancel}
          onSubmit={handleConfirm}
          submitText={confirmText ? confirmText : 'Delete'}
          submitVariant={submitVariant}
        />
      </Modal.Footer>
    </Modal>
  );
};

export const useConfirm = () => {
  const [show, setShow] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState();
  const [onCancel, setOnCancel] = useState();
  const [confirmText, setConfirmText] = useState(null);

  const onHide = () => setShow(false);

  const withConfirm = useCallback(
    (message, onConfirm, onCancel, confirmText = null) => {
      setConfirmMessage(message);
      setShow(true);
      setOnConfirm(() => onConfirm);
      setOnCancel(() => onCancel);
      setConfirmText(confirmText);
    },
    []
  );

  return [
    {
      show,
      confirmMessage,
      onConfirm,
      onCancel,
      onHide,
      confirmText,
    },
    withConfirm,
  ];
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  confirmMessage: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  submitVariant: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  submitVariant: 'danger',
};

export default ConfirmationModal;
