import React from 'react';
import { Form, Col } from 'react-bootstrap';

import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarEdit,
  faCalendarStar,
} from '@fortawesome/pro-solid-svg-icons';

import PopoverTrigger from '../../components/PopoverTrigger';
import DashboardDateSelect from './DashboardDateSelect';
import CHARTCOLORS from '../../helpers/chartColors';
import { DateOptions } from '../Reports/ReportHelpers';

const getStyles = (isLoading) => ({
  label: {
    display: 'flex',
  },
  inputStyle: {
    textAlign: 'center',
    marginRight: '10px',
    backgroundColor: '#e9ecef',
    width: '200px',
  },
  buttonStyle: {
    fontSize: '22px',
    color: !isLoading ? CHARTCOLORS[1] : CHARTCOLORS[2],
  },
  buttonStyle2: {
    fontSize: '22px',
    color: !isLoading ? CHARTCOLORS[0] : CHARTCOLORS[2],
  },
  buttonContainerStyle: {
    cursor: 'pointer',
    pointerEvents: !isLoading ? 'auto' : 'none',
    position: 'absolute',
    top: '14%',
    right: '14%',
  },
  buttonContainerStyle2: {
    cursor: 'pointer',
    pointerEvents: !isLoading ? 'auto' : 'none',
    position: 'absolute',
    top: '14%',
    right: '7%',
  },
  labelStyle: {
    marginRight: '0.5em',
    marginBottom: 0,
    marginTop: '0.2em',
  },
});

export default function DashboardDateSelectContainer(props) {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dateOption,
    setDateOption,
    isLoading,
  } = props;

  const styles = getStyles(isLoading);
  return (
    <Form.Group as={Col}>
      <Form.Row>
        <Form.Label style={styles.labelStyle}>Timeframe:</Form.Label>
        <Form.Control as={Col} style={styles.inputStyle} size='sm' disabled>
          {startDate && endDate
            ? dayjs.utc(startDate).format('MMM YYYY') +
              ' - ' +
              dayjs.utc(endDate).format('MMM YYYY')
            : ''}
        </Form.Control>
        <div id='dashboard-popover-button' style={styles.buttonContainerStyle}>
          <PopoverTrigger
            rootClose={true}
            popoverTitle={'End Date'}
            popoverContent={
              <DashboardDateSelect
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            }
            trigger='click'
            placement='bottom'
          >
            <FontAwesomeIcon
              style={styles.buttonStyle}
              icon={faCalendarEdit}
              id={'dashboard-date-popover'}
            />
          </PopoverTrigger>
        </div>
        <div
          id='dashboard-popover-button2'
          style={styles.buttonContainerStyle2}
        >
          <PopoverTrigger
            rootClose={true}
            popoverTitle={'Date Option'}
            popoverContent={
              <Form.Group controlId={'dashboard-date-option-select'}>
                <Form.Control
                  as='select'
                  onChange={(e) => setDateOption(e.target.value)}
                  value={dateOption}
                >
                  {Object.keys(DateOptions).map((option) => (
                    <option key={`unit-opt-${option}`} value={option}>
                      {DateOptions[option]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            }
            trigger='click'
            placement='bottom'
          >
            <FontAwesomeIcon
              style={styles.buttonStyle2}
              icon={faCalendarStar}
              id={'dashboard-date-popover2'}
            />
          </PopoverTrigger>
        </div>
      </Form.Row>
    </Form.Group>
  );
}
