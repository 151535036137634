import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { DateOptions, UtilityTypesLookup } from '../ReportHelpers';
import ReportMonthRange from '../../../components/reports/ReportMonthRange';
import ReportCheckBoxPicker from '../../../components/reports/ReportCheckboxPicker';
import ReportUnits from '../ReportUnits';
import ReportDropdownObject from '../../../components/reports/ReportDropdownObject';

const report_options = ['benchmark', 'building_charts'];
const report_options_names = {
  benchmark: 'Benchmark Table',
  building_charts: 'Buildings Charts',
};

export default function ProjectFeasibilityReport(props) {
  const { organizationData, updatePayload, pdf } = props;

  const [requiredUtilities, setRequiredUtilities] = useState([]);

  useEffect(() => {
    let local_utilities = [];
    organizationData['utility_types'].forEach((utility) => {
      if (utility === 'electric' || utility === 'gas') {
        local_utilities.push(utility);
      }
    });
    setRequiredUtilities(local_utilities);
  }, [organizationData]);

  return (
    <>
      <Row>
        <ReportMonthRange
          major_label={'Date Range'}
          label='End Month'
          default_date={organizationData['offset_max_date']}
          min_date={organizationData['transcript_min_date']}
          max_date={organizationData['max_bill_date']}
          payload_key={'end'}
          updatePayload={updatePayload}
          showPicker={true}
        />
      </Row>
      <Row>
        <Col>
          <ReportCheckBoxPicker
            label={'Utility Types'}
            checkboxOptions={organizationData['utility_types']}
            checkboxLookups={UtilityTypesLookup}
            defaults={organizationData['utility_types']}
            requireds={requiredUtilities}
            payload_key={'utility_types'}
            updatePayload={updatePayload}
          />
        </Col>
      </Row>

      {pdf && (
        <Row>
          <Col>
            <ReportCheckBoxPicker
              label={'Report Options'}
              checkboxOptions={report_options}
              checkboxLookups={report_options_names}
              defaults={report_options}
              requireds={[]}
              payload_key={'report_options'}
              updatePayload={updatePayload}
            />
          </Col>
        </Row>
      )}
      <ReportDropdownObject
        updatePayload={updatePayload}
        payload_key={'date_option'}
        values={DateOptions}
        defaultValue={0}
        label={'Date Option'}
        key_option={'index'}
      />
      <ReportUnits
        organizationData={organizationData}
        updatePayload={updatePayload}
      />
    </>
  );
}
