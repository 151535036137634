import React, { useEffect, useState } from 'react';

import { Form, Row, Col, Modal } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

export default function EditCustomRateCollectionModal(props) {
  const { show, onHide, showToast, organization_id, collection, updateList } =
    props;

  const [name, setName] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setName(collection.name);
  }, [collection]);

  const saveRateCollection = () => {
    setIsLoading(true);
    ApiServiceServerless.post(
      `/custom_rates/collection/${collection.id}`,
      {
        name: name,
      },
      { authorization_id: organization_id }
    )
      .then((res) => {
        showToast('success', 'Success', 'Rate Collection Updated');
        updateList(res.data, 'rate_collections');
      })
      .catch(() => {
        showToast('danger', 'Error', 'Update Error');
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  const saveDisabled = name === null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div as='h5'>Edit Rate Collection</div>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>
                Name
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                required
                placeholder=''
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          onSubmit={saveRateCollection}
          submitDisabled={saveDisabled}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
