import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Tabs, Tab, Row, Alert, Col } from 'react-bootstrap';
import { orderBy } from 'lodash';
import dayjs from 'dayjs';

import { ApiServiceServerless } from '../../../xhr_libs';
import SubAccountSettings from './SubAccountSettings';
import ManageBills from './ManageBills';

import { useOrganizationRoles } from '../../../helpers/checkRoles';
import AccountDetails from './AccountDetails';
import EditUtilityAccountModal from '../../../components/modals/UtilityAccountModals/EditUtilityAccountModal';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import AddBills from './AddBills';
import { handleAggregateBills } from './billHelpers';
import ResourceActionButtons from '../ResourceActionButton';
import AppContext from '../../../contexts/app-context';
import Loader from '../../../components/Loader';
import ResourceNav from '../ResourceNav';

const compare_keys = [
  'consumption1_value',
  'consumption2_value',
  'cost1_value',
  'cost2_value',
  'cost3_value',
  'demand1_value',
  'demand2_value',
];

export default function UtilityAccount(props) {
  const {
    userSelectedOrganization,
    userSelectedOrganizationDetails,
    selectedResource,
    setSelectedResource,
    buildingInfo,
    setBuildingInfo,
    utilityAccountInfo,
    setUtilityAccountInfo,
    utilityAccount,
    setUtilityAccount,
    resetBuilding,
    resetUtilityAccount,
    handleNewMaxDate,
    handleUpdateTables,
    isLoading,
    setIsLoading,
    isSaving,
    setIsSaving,
    isDeleting,
    setIsDeleting,
    showToast,
  } = props;
  const { setDisableOrganizationSelect } = useContext(AppContext);
  const [confirmationModalProps, withConfirm] = useConfirm();

  const [accountId, setAccountId] = useState(null);
  const [accountType, setAccountType] = useState(null);
  const [accountHasParentMask, setAccountHasParentMask] = useState(null);

  const [isLoadingBills, setIsLoadingBills] = useState(true);
  const [loadedPages, setLoadedPages] = useState(0);

  const [showEditAccountModal, setShowEditAccountModal] = useState(false);

  const [activeTab, setActiveTab] = useState('add-bills');
  const [tabSet, setTabSet] = useState(false);

  const [rawBills, setRawBills] = useState([]);
  const [allBills, setAllBills] = useState([]);
  const [aggregateBills, setAggregateBills] = useState([]);
  const [resetBillFlag, setResetFlag] = useState(0);

  const updateUtilityAccountAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'utility_account',
    'update'
  );
  const deleteUtilityAccountAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'utility_account',
    'delete'
  );
  const createBillAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'bill',
    'create'
  );
  const updateBillAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'bill',
    'update'
  );
  const deleteBillAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'bill',
    'delete'
  );

  useEffect(() => {
    setAccountId(utilityAccount.id);
  }, [utilityAccount.id]);

  useEffect(() => {
    setAccountType(utilityAccount.account_type);
  }, [utilityAccount.account_type]);

  useEffect(() => {
    setAccountHasParentMask(utilityAccount.is_masked_child);
  }, [utilityAccount.is_masked_child]);

  useEffect(() => {
    if (!tabSet && utilityAccount.has_provider_account !== undefined) {
      if (
        utilityAccount.has_provider_account ||
        utilityAccount.is_masked_child ||
        !createBillAccess
      ) {
        setActiveTab('manage-bills');
      } else {
        setActiveTab('add-bills');
      }
      setTabSet(true);
    }
  }, [utilityAccount, setActiveTab, tabSet, createBillAccess]);

  const getAccount = useCallback(() => {
    setIsLoading(true);
    ApiServiceServerless.get(`utility_accounts/${utilityAccountInfo.id}`, {
      authorization_id: utilityAccountInfo.id,
    }).then((res) => {
      const accountInfo = res.data;
      setUtilityAccount({ ...accountInfo });
      setIsLoading(false);
    });
  }, [utilityAccountInfo.id, setIsLoading, setUtilityAccount]);

  useEffect(() => {
    getAccount();
  }, [getAccount]);

  const handleSetAccount = (data) => {
    setUtilityAccount({ ...data });
  };

  const deleteAccount = () => {
    ApiServiceServerless.delete(`utility_accounts/${accountId}`, {
      authorization_id: accountId,
    })
      .then(() => {
        setSelectedResource('organization');
        resetBuilding();
        handleUpdateTables(0);
        showToast(
          'success',
          'Success',
          `Account "${utilityAccount.account_number}" was deleted.`
        );
        setIsDeleting(false);
      })
      .catch((error) => {
        const message = 'An error occurred while deleting the account.';
        showToast('danger', 'Error', message);
        setIsDeleting(false);
        throw error;
      });
  };

  const disableProviderAccounts = () => {
    ApiServiceServerless.post(
      `/providers/disable/account/${accountId}`,
      {},
      {
        authorization_id: accountId,
        alternate_authorization_id: utilityAccount.organization.id,
      }
    ).catch((error) => {
      showToast('danger', 'Error', error);
      setIsDeleting(false);
      return false;
    });
  };

  const onDeleteAccountClick = () => {
    let msg = `Are you sure you want to delete the account "${utilityAccount.account_number}"?`;
    const has_provider_account = utilityAccount['has_provider_account'];
    if (has_provider_account) {
      msg +=
        ' Additionally, any Automated Billing accounts associated with this account will be disabled';
    }
    if (accountType === 'production') {
      msg +=
        ' Warning! Deleting this production account may cause analysis to fail if it has been used in a model. ';
    }
    const beginAccountDelete = () => {
      setIsDeleting(true);
      if (has_provider_account) {
        disableProviderAccounts();
      }
      deleteAccount();
    };
    withConfirm(msg, beginAccountDelete);
  };

  const formatBills = useCallback((bills) => {
    const max_index = bills.length;

    bills.forEach((bill, index) => {
      let closest_bill = null;
      let prev_diff = 365;
      for (let i = index; i < max_index; i++) {
        if (bill.provider_meter_id === bills[i].provider_meter_id) {
          const day_diff =
            dayjs(bill.read_date).diff(bills[i].read_date, 'day') - 365;

          if (day_diff > 60) {
            break;
          }

          if (Math.abs(day_diff) < 30 && Math.abs(day_diff) < prev_diff) {
            closest_bill = bills[i];
            prev_diff = Math.abs(day_diff);
          }
        }
      }

      if (closest_bill) {
        bill = getClosestBill(bill, closest_bill);
      }
    });

    return bills;
  }, []);

  const getClosestBill = (bill, closest_bill) => {
    compare_keys.forEach((key) => {
      if (bill[key] + closest_bill[key] !== 0) {
        bill[key + '_old'] = closest_bill[key];
      }
    });
    return bill;
  };

  const isValidDate = (date) => {
    const min_date = dayjs(new Date(2000, 1, 1));
    const max_date = dayjs().year(dayjs().year() + 5);

    if (!date.isValid() || date < min_date || date > max_date) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!isLoadingBills) {
      let local_bills = orderBy(rawBills, ['read_date'], ['desc']);
      setAllBills(formatBills(local_bills));
      setAggregateBills(
        handleAggregateBills(
          local_bills.filter((bill) => bill.bill_type === accountType)
        )
      );
    }
  }, [accountType, rawBills, isLoadingBills, loadedPages, formatBills]);

  const queryPagedBills = useCallback(
    async (accountId, page) => {
      if (accountId) {
        ApiServiceServerless.get(`/bills/${accountId}/${page}`, {
          authorization_id: accountId,
        })
          .then((res) => {
            setRawBills((prev) => [...prev, ...res.data.bills]);
            if (parseInt(res.data.next_page) > 0) {
              queryPagedBills(accountId, res.data.next_page);
            } else {
              setTimeout(() => {
                setIsLoadingBills(false);
              }, 500);

              setLoadedPages((prev) => prev + 1);
            }
          })
          .catch(() => setIsLoadingBills(false));
      }
    },
    [setIsLoadingBills]
  );

  const queryMaskedBills = useCallback(
    (accountId) => {
      if (accountId) {
        ApiServiceServerless.get(`/bills/${accountId}`, {
          authorization_id: accountId,
        })
          .then((res) => {
            setRawBills(res.data);
          })
          .finally(() => {
            setIsLoadingBills(false);
          });
      }
    },
    [setIsLoadingBills]
  );

  const handleRefreshBills = useCallback(() => {
    setIsLoadingBills(true);
    setRawBills([]);
    setAllBills([]);
    setAggregateBills([]);
    if (accountId !== null && accountHasParentMask !== null) {
      if (accountHasParentMask) {
        queryMaskedBills(accountId);
      } else {
        queryPagedBills(accountId, 0);
      }
    }
  }, [accountId, accountHasParentMask, queryPagedBills, queryMaskedBills]);

  useEffect(() => {
    handleRefreshBills();
  }, [handleRefreshBills]);

  useEffect(() => {
    setDisableOrganizationSelect(isLoadingBills);
  }, [isLoadingBills, setDisableOrganizationSelect]);

  const handleAddNewBill = (bill) => {
    setRawBills((prev) => [...prev, bill]);
  };

  const handleRefreshLocalBills = (bills) => {
    setRawBills((prev) => {
      let ret = [...prev];
      bills.forEach((bill) => {
        ret = ret.map((old_bill) => {
          if (old_bill.id !== bill.id) return old_bill;
          return bill;
        });
      });
      return ret;
    });
  };

  const handleDeleteFromLocalBills = (bill_id) => {
    setRawBills((prev) => prev.filter((old_bill) => old_bill.id !== bill_id));
  };

  const handleResetBills = () => {
    setResetFlag((prev) => prev + 1);
  };

  const validateBill = useCallback(
    (data) => {
      const start_date = dayjs(data.start_date);
      const read_date = dayjs(data.read_date);
      const bill_date = dayjs(data.bill_date);

      const error_prefix =
        'Error in bill with start date ' +
        start_date.format('YYYY-MM-DD') +
        '. ';

      if (!isValidDate(start_date)) {
        showToast('danger', 'Error', error_prefix + 'Invalid Start Date');
        return false;
      }
      if (!isValidDate(read_date)) {
        showToast('danger', 'Error', error_prefix + 'Invalid Read Date');
        return false;
      }
      if (!isValidDate(bill_date)) {
        showToast('danger', 'Error', error_prefix + 'Invalid Bill Date');
        return false;
      }
      if (dayjs(data.start_date) > dayjs(data.read_date)) {
        showToast(
          'danger',
          'Error',
          error_prefix + 'Start Date must be before read date'
        );
        return false;
      }
      return true;
    },
    [showToast]
  );

  return (
    <div
      style={{ display: selectedResource === 'utilityAccount' ? '' : 'none' }}
    >
      {utilityAccount.id !== null && (
        <div>
          <Row>
            <Col>
              <ResourceNav
                selectedResource={selectedResource}
                setSelectedResource={setSelectedResource}
                organizationInfo={userSelectedOrganization}
                buildingInfo={buildingInfo}
                utilityAccountInfo={utilityAccountInfo}
                resetBuilding={resetBuilding}
                resetUtilityAccount={resetUtilityAccount}
              />
            </Col>
            <Col md={8}>
              <AccountDetails account={utilityAccount} />
            </Col>
            <ResourceActionButtons
              resource_name='Account'
              updateAccess={updateUtilityAccountAccess}
              deleteAccess={deleteUtilityAccountAccess}
              showEditModal={() => setShowEditAccountModal(true)}
              handleDelete={() => onDeleteAccountClick()}
              disabled={isSaving || isDeleting}
              refreshButton={true}
              refreshFunction={handleRefreshBills}
              refreshDisabled={isLoadingBills}
            />
          </Row>
          <div style={{ height: '1em' }}></div>

          {utilityAccount.child_masks.length === 0 &&
            utilityAccount.buildings.length > 1 &&
            utilityAccount.buildings.length !==
              utilityAccount.physical_buildings.length && (
              <Row style={{ justifyContent: 'center' }}>
                <Alert style={{ width: '90%' }} variant='danger'>
                  Warning: This account is shared between buildings with area
                  and non-physical locations. This may result in incorrect
                  spliting of utility data to these buildings. Please contact us
                  if you have any questions.
                </Alert>
              </Row>
            )}

          {utilityAccount.child_masks.length > 0 &&
            utilityAccount.child_masks.length <
              utilityAccount.sub_accounts.length && (
              <Row style={{ justifyContent: 'center' }}>
                <Alert style={{ width: '90%' }} variant='danger'>
                  Warning: Not all sub accounts are masked. Data from non-masked
                  subaccounts will not appear on the dashboard or in reports
                </Alert>
              </Row>
            )}
          <Tabs
            id='account-settings-tabs'
            activeKey={activeTab}
            onSelect={setActiveTab}
            className='et-main-tabs'
          >
            {createBillAccess && (
              <Tab eventKey='add-bills' title='Add Bills'>
                {(isLoading || isLoadingBills) && <Loader />}
                {!isLoading && !isLoadingBills && (
                  <AddBills
                    type={accountType}
                    defaultConsumptionUnit={
                      utilityAccount.default_consumption_unit
                    }
                    account={utilityAccount}
                    allBills={allBills}
                    handleRefreshBills={handleRefreshBills}
                    handleAddNewBill={handleAddNewBill}
                    validateBill={validateBill}
                    setSelectedResource={setSelectedResource}
                    resetBuilding={resetBuilding}
                    handleNewMaxDate={handleNewMaxDate}
                    isLoading={isLoading}
                    setIsSaving={setIsSaving}
                    isSaving={isSaving}
                    showToast={showToast}
                  />
                )}
              </Tab>
            )}

            <Tab eventKey='manage-bills' title='Manage Bills'>
              <ManageBills
                type={accountType}
                account={utilityAccount}
                allBills={allBills}
                aggregateBills={aggregateBills}
                resetBillFlag={resetBillFlag}
                handleResetBills={handleResetBills}
                handleRefreshLocalBills={handleRefreshLocalBills}
                handleDeleteFromLocalBills={handleDeleteFromLocalBills}
                validateBill={validateBill}
                handleNewMaxDate={handleNewMaxDate}
                isLoadingBills={isLoadingBills}
                isLoading={isLoading}
                showToast={showToast}
                createAccess={createBillAccess}
                updateAccess={updateBillAccess}
                deleteAccess={deleteBillAccess}
                setIsSaving={setIsSaving}
                isSaving={isSaving}
              />
            </Tab>
            {utilityAccount.sub_accounts.length ? (
              <Tab eventKey='advanced-settings' title='Advanced Settings'>
                <SubAccountSettings
                  account={utilityAccount}
                  showToast={showToast}
                  setAccount={setUtilityAccount}
                  setTabSet={setTabSet}
                  setSelectedResource={setSelectedResource}
                  setBuildingInfo={setBuildingInfo}
                  setUtilityAccountInfo={setUtilityAccountInfo}
                  isLoading={isLoading}
                  isLoadingBills={isLoadingBills}
                  updateAccess={updateBillAccess}
                />
              </Tab>
            ) : (
              ''
            )}
          </Tabs>
        </div>
      )}
      <EditUtilityAccountModal
        accountDetails={utilityAccount}
        organization={utilityAccount.organization}
        buildings={utilityAccount.organization.buildings}
        defaultBuildings={utilityAccount.building_ids}
        show={showEditAccountModal}
        onHide={() => {
          setShowEditAccountModal(false);
        }}
        handleSetAccount={handleSetAccount}
        handleUpdateTables={handleUpdateTables}
        showToast={showToast}
      />
      <ConfirmationModal {...confirmationModalProps} />
    </div>
  );
}
