import React from 'react';
import { capitalize } from 'lodash';
import PopoverTrigger from '../../PopoverTrigger';

// From Plug API: https://docs.arcadia.com/docs/status-and-status-detail-definitions
// Simple statuses returned to frontend unlike urjanet
const CredentialStatusLookup = Object.freeze({
  CONNECTION_SUCCESS: 'Success',
  CONNECTION_IN_PROGRESS: 'Pending',
  CONNECTION_FAILURE: 'Failure',
  CONNECTION_MANUAL: 'Manual',
});

const StatusClassNames = Object.freeze({
  Success: 'text-success',
  Pending: 'text-warning',
  Failure: 'text-danger',
  Manual: 'text-success',
});

const CredentialStatusDetailLookup = Object.freeze({
  LOGIN_AND_DATA_DISCOVERY_SUCCESS: 'Sucess',
  DATA_EXTRACTION_IN_PROGRESS: 'Data Extraction in Progesss',
  PROVIDER_WEBSITE_INACCESSIBLE: 'Proviver Website Inaccessible',
  ISSUE_UNDER_REVIEW: 'Issue Under Review',
  PROVIDER_WEBSITE_NAVIGATION_FAILURE: 'Provider Website Navigation Failure',
  INVALID_CREDENTIALS: 'Invalid Credentials',
  OTHER_ACCESS_LIMITATION: 'Access Limitation',
  UNSUPPORTED_CAPTCHA: 'Unsupported Captcha',
  UNSUPPORTED_MULTI_FACTOR_AUTHENTICATION: 'Unsuppored MFA',
  CONNECTION_MANUAL: 'Accounts from bill upload feature',
});

export function fmtPlugCredentialStatus({
  credentialStatus,
  credentialStatusDetail,
}) {
  const eventDetail = _getCredentialStatus(
    credentialStatus,
    credentialStatusDetail
  );
  return (
    <PopoverTrigger
      popoverContent={eventDetail[1]}
      trigger={['hover', 'focus']}
    >
      {_formatStatus(eventDetail[0])}
    </PopoverTrigger>
  );
}

export function fmtPlugCredentialisActive(isActive) {
  return isActive ? 'True' : 'False';
}

function _getCredentialStatus(status, statusDetail) {
  const statusTxt =
    status in CredentialStatusLookup ? CredentialStatusLookup[status] : status;
  const statusDetailTxt =
    statusDetail in CredentialStatusDetailLookup
      ? CredentialStatusDetailLookup[statusDetail]
      : statusDetail;
  return [statusTxt, statusDetailTxt];
}

const _formatStatus = (status) => (
  <div
    className={StatusClassNames[status]}
    style={{
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    }}
  >
    {status
      .split('_')
      .map((word) => capitalize(word))
      .join('\t \t')}
  </div>
);
