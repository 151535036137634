import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { sortBy } from 'lodash';

import { Form, Row, Col, Modal, InputGroup } from 'react-bootstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import NumberFormat from 'react-number-format';
import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';
import BillNumberInput from '../bills/BillFormInputs/BillNumberInput';

dayjs.extend(utc);

const GHGDataDefaults = {
  name: '',
  year: dayjs.utc(new Date()).year(),
  CO2: 0,
  N2O: 0,
  CH4: 0,
  CO2_savings: 0,
  N2O_savings: 0,
  CH4_savings: 0,
};

function AddCustomGHGModal(props) {
  const {
    show,
    onHide,
    showToast,
    organization_id,
    addToList,
    updateList,
    GHGCollections,
    defaultCollection,
  } = props;

  const [GHGData, setGHGData] = useState(GHGDataDefaults);
  const [collectionIds, setCollectionIds] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (defaultCollection !== null) {
      setCollectionIds([defaultCollection.id]);
    } else {
      setCollectionIds([]);
    }
  }, [defaultCollection]);

  const onFieldChange = (fieldName, value) => {
    setGHGData((data) => {
      data[fieldName] = value;
      return { ...data };
    });
  };

  const onNumberChange = (feildName, value) => {
    value = Number(value.replace(/,/g, ''));
    onFieldChange(feildName, value);
  };

  const updateSet = (values) => {
    let arr = Array.prototype.slice.call(values);
    arr = arr.map((item) => item.value);
    setCollectionIds(arr);
  };

  const saveGHG = () => {
    let local_GHGData = { ...GHGData };
    local_GHGData['organization_id'] = organization_id;
    local_GHGData['collection_ids'] = collectionIds;

    setIsLoading(true);
    ApiServiceServerless.post(`/custom_ghg_conversions`, local_GHGData, {
      authorization_id: organization_id,
    })
      .then((res) => {
        setGHGData(GHGDataDefaults);
        showToast('success', 'Success', 'New  GHG Conversion Created');
        addToList(res.data[0], 'ghg_conversions');
        res.data[1].forEach((collection) => {
          updateList(collection, 'ghg_collections');
        });
      })
      .catch(() => {
        showToast('danger', 'Error', 'Creation Error');
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  const saveDisabled = !GHGData.name || !GHGData.year;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div as='h5'>Create a Custom GHG Conversions</div>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>
                Name
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                required
                placeholder=''
                onChange={(e) =>
                  setGHGData({
                    ...GHGData,
                    name: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Year</Form.Label>
              <InputGroup>
                <NumberFormat
                  customInput={Form.Control}
                  isNumericString
                  decimalScale={0}
                  fixedDecimalScale
                  format={'####'}
                  defaultValue={GHGData.year}
                  onChange={(e) => onNumberChange('year', e.target.value)}
                />
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>CO2 (lb/MWh)</Form.Label>

                  <InputGroup>
                    <BillNumberInput
                      customInput={Form.Control}
                      onChange={(e) => onNumberChange('CO2', e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>CO2 Savings(lb/MWh)</Form.Label>

                  <InputGroup>
                    <BillNumberInput
                      customInput={Form.Control}
                      onChange={(e) =>
                        onNumberChange('CO2_savings', e.target.value)
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
            </Form.Group>

            <Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>N2O (lb/MWh)</Form.Label>

                  <InputGroup>
                    <BillNumberInput
                      customInput={Form.Control}
                      onChange={(e) => onNumberChange('N2O', e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>N2O Savings(lb/MWh)</Form.Label>

                  <InputGroup>
                    <BillNumberInput
                      customInput={Form.Control}
                      onChange={(e) =>
                        onNumberChange('N2O_savings', e.target.value)
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
            </Form.Group>

            <Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>CH4 (lb/MWh)</Form.Label>

                  <InputGroup>
                    <BillNumberInput
                      customInput={Form.Control}
                      onChange={(e) => onNumberChange('CH4', e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>CH4 Savings(lb/MWh)</Form.Label>

                  <InputGroup>
                    <BillNumberInput
                      customInput={Form.Control}
                      onChange={(e) =>
                        onNumberChange('CH4_savings', e.target.value)
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
            </Form.Group>

            <Form.Group>
              <Form.Label>Collection(s)</Form.Label>
              <Form.Control
                as='select'
                multiple
                disabled={defaultCollection !== null}
                defaultValue={
                  defaultCollection !== null ? [defaultCollection.id] : []
                }
                onChange={(e) => updateSet(e.target.selectedOptions)}
              >
                {sortBy(Object.values(GHGCollections), ['name']).map((item) => (
                  <option key={`collection-opt-${item.id}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          onSubmit={saveGHG}
          submitDisabled={saveDisabled}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

AddCustomGHGModal.propTypes = {
  defaultCollection: PropTypes.object,
};

AddCustomGHGModal.defaultProps = {
  defaultCollection: null,
};

export default AddCustomGHGModal;
