import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import {
  ScatterChart,
  Scatter,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
} from 'recharts';

import { REGRESSORS, REGRESSORS_UNITS, UTILITY_TYPE_UNITS } from './regressors';
import CHARTCOLORS from '../../helpers/chartColors';
import MONTHNAMES from '../../helpers/monthsAbbreviated';
import RegressionInfoTable from './SubComponents/RegressionInfoTable';
import DownloadChartData from '../DownloadChartData';

const styles = {
  axisSelectLabel: {
    marginRight: '10px',
    marginTop: '4px',
  },
  chartRegressorInputStyle: {
    width: '200px',
    textAlign: 'center',
  },
  stats: {
    marginTop: '20px',
    marginBottom: '10px',
  },
  coefficients: { marginLeft: '10px' },
  statItem: {
    marginLeft: '20px',
  },
  rowStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  unitHeader: { marginBottom: '0.5em', width: '100px' },
};

export default function PerformanceModelAnalysis(props) {
  const {
    organization_id,
    utilityType,
    regressionType,
    regressionEvaluation,
    regressionEvaluationData,
    regressionLine,
    regressionEquation,
    regressionStats,
    regressionCoefficients,
    regressionPieces,
    availableRegressors,
    productionNames,
    chartRegressor,
    setChartRegressor,
    performanceData,
    newRegressionEvaluation,
    newRegressionEvaluationData,
    newRegressionLine,
    newRegressionEquation,
    newRegressionStats,
    newRegressionCoeffs,
    newModelList,
    newRegressionPieces,
  } = props;

  const [showTotal, setShowTotal] = useState(false);
  const [formattedPerformance, setFormattedPerformance] = useState([
    { x: 0, y: 0 },
  ]);
  const [formattedEvaluation, setFormattedEvaluation] = useState([
    { x: 0, y: 0 },
  ]);
  const [formattedLine, setFormattedLine] = useState([]);

  const [newFormattedEvaluation, setNewFormattedEvaluation] = useState([
    { x: 0, y: 0 },
  ]);
  const [newFormattedLine, setNewFormattedLine] = useState([]);
  const [chartDebounce, setChartDebounce] = useState(0);

  const [chartDownloadData, setChartDownloadData] = useState([]);
  const [chartDownloadHeaders, setChartDownloadHeaders] = useState([]);
  const [chartDownloadKeys, setChartDownloadKeys] = useState([]);

  useEffect(() => {
    setChartDebounce((prev) => prev + 1);
  }, [formattedEvaluation, newFormattedEvaluation]);

  const formatPerformanceData = useCallback(
    (performanceData, chartRegressor) => {
      let temp_data = [];
      if (
        Object.keys(performanceData).length &&
        chartRegressor &&
        Object.keys(performanceData).includes(chartRegressor)
      ) {
        for (let i = 0; i < performanceData['energy'].length; i++) {
          let temp_obj = {};
          temp_obj['x'] = parseFloat(
            performanceData[chartRegressor][i].toFixed(2)
          );
          temp_obj['y'] = parseFloat(performanceData['energy'][i].toFixed(2));
          if (showTotal) {
            temp_obj['y'] *= performanceData['days'][i];
          }
          temp_obj['z'] =
            MONTHNAMES[performanceData['months'][i]] +
            ' ' +
            performanceData['years'][i];
          temp_data[i] = temp_obj;
        }
        setFormattedPerformance(temp_data);
      } else {
        setFormattedPerformance([{ x: 0, y: 0, z: 0 }]);
      }
    },
    [showTotal]
  );

  useEffect(() => {
    formatPerformanceData(performanceData, chartRegressor);
  }, [performanceData, chartRegressor, formatPerformanceData]);

  const formatEvaluation = useCallback(
    (baselineData, regressionEvaluation, chartRegressor, setEvaluation) => {
      let temp_data = [];

      if (
        regressionEvaluation.length &&
        Object.keys(baselineData).length &&
        chartRegressor &&
        baselineData['energy'].length === regressionEvaluation.length
      ) {
        for (let i = 0; i < regressionEvaluation.length; i++) {
          let temp_obj = {};
          temp_obj['x'] = parseFloat(
            baselineData[chartRegressor][i].toFixed(2)
          );
          temp_obj['y'] = parseFloat(regressionEvaluation[i].toFixed(2));

          if (showTotal) {
            temp_obj['y'] *= baselineData['days'][i];
          }
          temp_obj['z'] =
            MONTHNAMES[baselineData['months'][i]] +
            ' ' +
            baselineData['years'][i];
          temp_data[i] = temp_obj;
        }
        setEvaluation(temp_data);
      } else {
        setEvaluation([]);
      }
    },
    [showTotal]
  );

  useEffect(() => {
    formatEvaluation(
      regressionEvaluationData,
      regressionEvaluation,
      chartRegressor,
      setFormattedEvaluation
    );
  }, [
    regressionEvaluationData,
    regressionEvaluation,
    chartRegressor,
    formatEvaluation,
  ]);

  useEffect(() => {
    if (newRegressionEvaluation.length > 0) {
      formatEvaluation(
        newRegressionEvaluationData,
        newRegressionEvaluation,
        chartRegressor,
        setNewFormattedEvaluation
      );
    }
  }, [
    newRegressionEvaluationData,
    newRegressionEvaluation,
    chartRegressor,
    formatEvaluation,
  ]);

  useEffect(() => {
    if (
      regressionLine &&
      regressionLine.length > 0 &&
      chartRegressor &&
      regressionPieces[0]['regressor'] === chartRegressor &&
      !showTotal
    ) {
      setFormattedLine(regressionLine);
    } else {
      setFormattedLine([]);
    }
  }, [regressionLine, chartRegressor, regressionPieces, showTotal]);

  useEffect(() => {
    if (
      newRegressionLine &&
      newRegressionLine.length > 0 &&
      chartRegressor &&
      newModelList.length > 0 &&
      newModelList[1][0] === chartRegressor &&
      !showTotal
    ) {
      setNewFormattedLine(newRegressionLine);
    } else {
      setNewFormattedLine([]);
    }
  }, [newRegressionLine, chartRegressor, newModelList, showTotal]);

  useEffect(() => {
    let data = [formattedEvaluation];
    let headers = ['Baseline Date', 'Baseline x', 'Baseline y'];
    let keys = [['z', 'x', 'y']];
    if (newFormattedEvaluation.length > 0) {
      data.push(newFormattedEvaluation);
      headers = [
        ...headers,
        ...['Performance Date', 'Performance x', 'Performance y'],
      ];
      keys.push(['z', 'x', 'y']);
    }
    setChartDownloadData(data);
    setChartDownloadHeaders(headers);
    setChartDownloadKeys(keys);
  }, [formattedEvaluation, newFormattedEvaluation]);

  return (
    <>
      <Row style={{ ...styles.rowStyle, paddingTop: '2em' }}>
        <Row style={{ width: '500px' }}>
          <Form.Control
            style={styles.unitHeader}
            as='select'
            size='sm'
            onChange={(e) =>
              setShowTotal(e.target.value === 'true' ? true : false)
            }
          >
            <option key={'usage-per-day'} value={false}>
              {UTILITY_TYPE_UNITS[regressionType][utilityType] + '/day'}
            </option>
            <option key={'usage-total'} value={true}>
              {UTILITY_TYPE_UNITS[regressionType][utilityType]}
            </option>
          </Form.Control>
          <DownloadChartData
            organization_id={organization_id}
            data={chartDownloadData}
            headers={chartDownloadHeaders}
            keys={chartDownloadKeys}
            chart_type={'scatter'}
          />
          <ResponsiveContainer height={350} debounce={chartDebounce}>
            <ScatterChart height={350} width={500}>
              <CartesianGrid />
              <XAxis
                type='number'
                dataKey='x'
                name={
                  chartRegressor
                    ? Object.keys(REGRESSORS).includes(chartRegressor)
                      ? REGRESSORS[chartRegressor]
                      : productionNames[chartRegressor]
                    : ''
                }
              >
                <Label
                  value={
                    chartRegressor
                      ? Object.keys(REGRESSORS_UNITS).includes(chartRegressor)
                        ? REGRESSORS_UNITS[chartRegressor]
                        : 'units/day'
                      : ''
                  }
                  position='right'
                  offset={10}
                />
              </XAxis>
              <YAxis
                type='number'
                dataKey='y'
                tickFormatter={(tick) => {
                  return tick.toLocaleString();
                }}
                name={
                  utilityType.charAt(0).toUpperCase() +
                  utilityType.substr(1).toLowerCase()
                }
              />

              <ZAxis type='category' dataKey='z' name='Date' />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
                formatter={(value) =>
                  !isNaN(Number(value)) ? Number(value).toLocaleString() : value
                }
              />
              <Legend />
              <Scatter
                name='Baseline Model'
                data={formattedEvaluation}
                fill={CHARTCOLORS[1]}
                shape={'triangle'}
                hide={formattedEvaluation.length <= 1}
              />
              {formattedLine.length > 0 && (
                <Scatter
                  name='Baseline Trend Line'
                  data={formattedLine}
                  fill={CHARTCOLORS[2]}
                  shape={() => {
                    return null;
                  }}
                  line
                />
              )}
              {Object.keys(performanceData).length > 0 && (
                <Scatter
                  name='Performance'
                  data={formattedPerformance}
                  fill={CHARTCOLORS[0]}
                />
              )}
              {newRegressionEvaluation.length > 0 && (
                <Scatter
                  name='Performance Model'
                  data={newFormattedEvaluation}
                  fill={CHARTCOLORS[3]}
                  shape={'triangle'}
                />
              )}
              {newFormattedLine.length > 0 && (
                <Scatter
                  name='Performance Trend Line'
                  data={newFormattedLine}
                  fill={CHARTCOLORS[2]}
                  shape={() => {
                    return null;
                  }}
                  line
                />
              )}
            </ScatterChart>
          </ResponsiveContainer>
        </Row>
      </Row>
      <Row style={styles.rowStyle}>
        <Row style={{ marginTop: '25px' }}>
          <Col>
            <Form.Label style={styles.axisSelectLabel}>x-axis:</Form.Label>
          </Col>
          <Col>
            <Form.Control
              style={styles.chartRegressorInputStyle}
              as='select'
              size='sm'
              onChange={(e) => setChartRegressor(e.target.value)}
              value={chartRegressor}
            >
              <option
                key={'regressor-opt-null'}
                value={''}
                hidden={regressionEvaluation.length}
              >
                -
              </option>
              {availableRegressors.map((regressor) => (
                <option key={`regressor-opt-${regressor}`} value={regressor}>
                  {regressor in REGRESSORS
                    ? REGRESSORS[regressor]
                    : productionNames[regressor]}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </Row>
      <Row style={styles.rowStyle}>
        <>
          {regressionEvaluation.length > 0 && (
            <Col>
              <Row style={styles.rowStyle}>
                <h6>Baseline Regression</h6>
              </Row>
              <Row style={styles.rowStyle}>
                <RegressionInfoTable
                  coefficients={regressionCoefficients}
                  equation={regressionEquation}
                  stats={regressionStats}
                  chartData={regressionEvaluationData}
                  regressionPieces={regressionPieces}
                  productionNames={productionNames}
                />
              </Row>
            </Col>
          )}
          {newRegressionEvaluation.length > 0 && (
            <Col>
              <Row style={styles.rowStyle}>
                <h6>Performance Regression</h6>
              </Row>
              <Row style={styles.rowStyle}>
                <RegressionInfoTable
                  coefficients={newRegressionCoeffs}
                  equation={newRegressionEquation}
                  stats={newRegressionStats}
                  chartData={newRegressionEvaluationData}
                  regressionPieces={newRegressionPieces}
                  productionNames={productionNames}
                />
              </Row>
            </Col>
          )}
        </>
      </Row>
    </>
  );
}
