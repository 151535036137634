const ROLES = Object.freeze(['user', 'admin', 'owner']);
const ROLE_NAMES = { owner: 'owner', admin: 'admin', user: 'viewer', '': '' };
const ROLE_NAMES_UPPER = {
  owner: 'Owner',
  admin: 'Admin',
  user: 'Viewer',
  '': '',
};
const ROLE_WEIGHTS = { owner: 3, admin: 2, user: 1, '': 0 };
const REVERSE_ROLE_WEIGHTS = { 3: 'owner', 2: 'admin', 1: 'user', 0: '' };

export {
  ROLES,
  ROLE_NAMES,
  ROLE_NAMES_UPPER,
  ROLE_WEIGHTS,
  REVERSE_ROLE_WEIGHTS,
};
