import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import { REGRESSORS, UTILITY_TYPE_UNITS } from './regressors';
import CHARTCOLORS from '../../helpers/chartColors';
import MONTHNAMES from '../../helpers/monthsAbbreviated';
import formatValue from '../../helpers/chartFormatter';

import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import DownloadChartData from '../DownloadChartData';

const styles = { unitHeader: { marginBottom: '0.5em', width: '100px' } };

function LeanEnergyAnalysis(props) {
  const {
    organization_id,
    baselineData,
    regressionEvaluation,
    regressionEvaluationObj,
    utilityType,
    regressionType,
  } = props;

  const [showTotal, setShowTotal] = useState(false);
  const [formattedLineData, setFormattedLineData] = useState([{ x: 0, y: 0 }]);
  const [pieData, setPieData] = useState([]);
  const [pieTotal, setPieTotal] = useState(1);

  const [chartDownloadHeaders, setChartDownloadHeaders] = useState([]);
  const [chartDownloadKeys, setChartDownloadKeys] = useState([]);

  const [chartDebounce, setChartDebounce] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setChartDebounce((prev) => prev + 1);
    }, 0);
  }, [baselineData, regressionEvaluation]);

  const formatLineData = useCallback(() => {
    let temp_data = [];
    let temp_pie_data = {};
    let temp_pie_total = 0;
    for (let i = 0; i < regressionEvaluation.length; i++) {
      let temp_obj = {};
      let temp_total = 0;
      temp_obj['evaluation'] = parseFloat(regressionEvaluation[i].toFixed(2));
      if (showTotal) {
        temp_obj['evaluation'] *= baselineData['days'][i];
      }
      temp_obj['name'] =
        MONTHNAMES[baselineData['months'][i]] + ' ' + baselineData['years'][i];
      Object.keys(regressionEvaluationObj).forEach(function (key) {
        temp_obj[key] = parseFloat(regressionEvaluationObj[key][i].toFixed(2));
        if (showTotal) {
          temp_obj[key] *= baselineData['days'][i];
        }
        //set pie data
        let val =
          Math.abs(parseFloat(regressionEvaluationObj[key][i].toFixed(2))) *
          baselineData['days'][i];
        temp_total += val;
        if (temp_pie_data[key]) {
          temp_pie_data[key] += val;
        } else {
          temp_pie_data[key] = val;
        }
      });
      temp_data[i] = temp_obj;
      temp_pie_total += temp_total;
    }
    setFormattedLineData(temp_data);
    setPieData(
      Object.keys(temp_pie_data).map((key) => ({
        name: key === 'constant' ? 'Independent' : REGRESSORS[key],
        value: temp_pie_data[key],
      }))
    );
    if (temp_pie_total !== 0) {
      setPieTotal(temp_pie_total);
    }
  }, [regressionEvaluation, regressionEvaluationObj, baselineData, showTotal]);

  useEffect(() => {
    formatLineData();
  }, [formatLineData]);

  useEffect(() => {
    let headers = ['Date', 'Model Total'];
    let keys = ['name', 'evaluation'];

    Object.keys(regressionEvaluationObj).forEach((key) => {
      keys.push(key);
      headers.push(key === 'constant' ? 'Independent' : REGRESSORS[key]);
    });

    setChartDownloadHeaders(headers);
    setChartDownloadKeys(keys);
  }, [regressionEvaluationObj]);

  return (
    <Row
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '-5em',
      }}
    >
      <Col md={12} lg={6}>
        <Row>
          <Form.Control
            style={styles.unitHeader}
            as='select'
            size='sm'
            onChange={(e) =>
              setShowTotal(e.target.value === 'true' ? true : false)
            }
          >
            <option key={'usage-per-day'} value={false}>
              {UTILITY_TYPE_UNITS[regressionType][utilityType] + '/day'}
            </option>
            <option key={'usage-total'} value={true}>
              {UTILITY_TYPE_UNITS[regressionType][utilityType]}
            </option>
          </Form.Control>
          <DownloadChartData
            organization_id={organization_id}
            data={formattedLineData}
            headers={chartDownloadHeaders}
            keys={chartDownloadKeys}
            chart_type={'line'}
          />
          <ResponsiveContainer height={350} debounce={chartDebounce}>
            <LineChart
              data={formattedLineData}
              height={350}
              width={700}
              margin={{ right: 25 }}
            >
              <CartesianGrid />
              <XAxis dataKey='name' interval='preserveEnd' tickCount={6} />
              <YAxis>
                {' '}
                <Label position='top' offset={10} />{' '}
              </YAxis>
              <Tooltip
                formatter={(value) =>
                  !isNaN(Number(value)) ? Number(value).toLocaleString() : value
                }
              />
              <Legend />
              <Line
                dataKey='evaluation'
                name='Model Total'
                stroke={CHARTCOLORS[0]}
              />
              {Object.keys(regressionEvaluationObj).map((key, idx) => (
                <Line
                  dataKey={key}
                  key={key}
                  name={key === 'constant' ? 'Independent' : REGRESSORS[key]}
                  stroke={CHARTCOLORS[idx + 1]}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </Row>
      </Col>
      <Col lg={1}></Col>
      <Col md={12} lg={3}>
        <ResponsiveContainer height={275} debounce={chartDebounce}>
          <PieChart>
            <Pie
              data={pieData}
              dataKey='value'
              nameKey='name'
              fill='#8884d8'
              label={({ value }) =>
                formatValue(value) +
                ' ' +
                UTILITY_TYPE_UNITS[regressionType][utilityType] +
                ' (' +
                formatValue((value / pieTotal) * 100) +
                '%)'
              }
              legendType='circle'
              minAngle={4}
              width={275}
              height={275}
            >
              {pieData.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={CHARTCOLORS[(index + 1) % CHARTCOLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Col>
      <Col lg={1}></Col>
    </Row>
  );
}

export default LeanEnergyAnalysis;
