import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import version from '../package.json';

if (['staging', 'production', 'prod'].includes(process.env.REACT_APP_ENV)) {
  Sentry.init({
    dsn: 'https://64cb7ecf9e9445d1b37cbf2b5a50053f@sentry.io/5172043',
    environment: process.env.REACT_APP_ENV,
    release: version,
    beforeSend(event, hint) {
      if (
        hint.originalException &&
        hint.originalException.response &&
        hint.originalException.response.sentry === false
      ) {
        console.log('caught');
      } else {
        return event;
      }
    },
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
