import React, { useEffect, useState } from 'react';
import { Button, Modal, Col, Row } from 'react-bootstrap';
import { Checkbox } from 'pretty-checkbox-react';

import 'pretty-checkbox';

import { sortBy } from 'lodash';
import { setUserOrganizationPreference } from '../../helpers/user-preferences';

const styles = {
  check: {
    fontSize: '1.25em',
    marginBottom: '0.5em',
  },
  checkLabel: {
    fontSize: '1rem',
    position: 'relative',
    top: '-0.25em',
  },
  row: {
    paddingLeft: '2em',
  },
};

function BuildingSelectModal(props) {
  const {
    show,
    onHide,
    buildings,
    buildingIds,
    selectedBuildings,
    setSelectedBuildings,
    setNonSelectedBuildings,
    organization_id,
    preference_key,
  } = props;

  const [localSelected, setLocalSelected] = useState([...selectedBuildings]);
  const [sortedBuildings, setSortedBuilding] = useState([]);

  useEffect(() => {
    setSortedBuilding(
      sortBy(Object.values(buildings), [
        function (build) {
          return build.id !== 'total';
        },
        'name',
      ])
    );
  }, [buildings]);

  useEffect(() => {
    //reset on open
    if (show) {
      setLocalSelected([...selectedBuildings]);
    }
  }, [buildingIds, selectedBuildings, organization_id, preference_key, show]);

  const updateLocalSelected = (id) => {
    let temp_selected = [...localSelected];

    const found = temp_selected.indexOf(id);

    if (found !== -1) {
      temp_selected.splice(found, 1);
    } else {
      temp_selected.push(id);
    }
    setLocalSelected(temp_selected);
  };

  const updateBuildings = () => {
    setSelectedBuildings(localSelected);
    let tempNonSelectedBuildings = [];
    buildingIds.forEach((building) => {
      if (!localSelected.includes(building)) {
        tempNonSelectedBuildings.push(building);
      }
    });
    setNonSelectedBuildings(tempNonSelectedBuildings);
    if (preference_key) {
      if (buildingIds.length === localSelected.length) {
        setUserOrganizationPreference('dbs', organization_id, null, true);
      } else {
        setUserOrganizationPreference(
          'dbs',
          organization_id,
          localSelected,
          false
        );
      }
    }
    onHide();
  };

  const makeBuildingColumn = (buildings) => {
    return buildings.map((building) => (
      <Row key={building.id}>
        <Col md={1}>
          <Checkbox
            style={styles.check}
            color={'primary-o'}
            onChange={() => {
              updateLocalSelected(building.id);
            }}
            checked={localSelected.includes(building.id)}
            key={building.id}
          >
            {''}
          </Checkbox>
        </Col>
        <Col md={11}>
          <div style={styles.checkLabel}>{building.name}</div>
        </Col>
      </Row>
    ));
  };

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>Select Buildings</Modal.Header>
      <Modal.Body style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <Row style={styles.row}>
          <Col lg={6} xs={12}>
            {makeBuildingColumn(
              sortedBuildings.slice(0, Math.ceil(sortedBuildings.length / 2))
            )}
          </Col>
          <Col lg={6} xs={12}>
            {makeBuildingColumn(
              sortedBuildings.slice(
                Math.ceil(sortedBuildings.length / 2, sortedBuildings.length)
              )
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ width: '100%' }}>
          <Button
            className='float-left ml-3'
            onClick={() => setLocalSelected([])}
          >
            {' '}
            Deselect All
          </Button>
          <Button
            className='float-left ml-3'
            onClick={() => setLocalSelected([...buildingIds])}
          >
            {' '}
            Select All
          </Button>

          <Button
            className='float-right ml-3'
            onClick={() => updateBuildings()}
            disabled={localSelected.length === 0}
            title={
              localSelected.length === 0 ? 'One selection required.' : null
            }
          >
            {' '}
            Confirm
          </Button>
          <Button
            className='float-right ml-3'
            type='button'
            variant='outline-danger'
            onClick={() => onHide()}
          >
            Cancel
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

BuildingSelectModal.defaultProps = {
  organization_id: '',
  preference_key: '',
};

export default BuildingSelectModal;
