import ReportDefault from '../../../img/Reports/Report-Default.png';
import TrendReport from '../../../img/Reports/Trend-Report.png';
import TranscriptReport from '../../../img/Reports/Transcript-Report.PNG';
import PerformanceReport from '../../../img/Reports/Performance-Report.PNG';
import AnalysisReport from '../../../img/Reports/Analysis-Report.PNG';
import FeasibilityReport from '../../../img/Reports/Feasibility-Report.PNG';
import GHGSummary from '../../../img/Reports/GHG-Summary.PNG';
import CarbonTranscript from '../../../img/Reports/Carbon-Transcript.PNG';
import GHGGlidePath from '../../../img/Reports/GHG-Glide-Path.PNG';

export const report_img_lookup = {
  default: ReportDefault,
  trend: TrendReport,
  trend_excel: TrendReport,
  trend_building: TrendReport,
  transcript: TranscriptReport,
  performance: PerformanceReport,
  performance_excel: PerformanceReport,
  performance_building: PerformanceReport,
  analysis: AnalysisReport,
  analysis_excel: AnalysisReport,
  feasibility: FeasibilityReport,
  feasibility_excel: FeasibilityReport,
  ghg_summary: GHGSummary,
  carbon_transcript: CarbonTranscript,
  ghg_baseline: GHGSummary,
  ghg_glide_path: GHGGlidePath,
};

const trend_style = {
  height: '300px',
  marginTop: '20%',
};

const transcript_style = {
  height: '375px',
  marginTop: '12.5%',
};

const performance_style = {
  height: '400px',
  marginTop: '10%',
};

const analysis_style = {
  height: '350px',
  marginTop: '8%',
};

const feasibility_style = {
  height: '250px',
  marginTop: '20%',
};

const ghg_summary_style = {
  height: '425px',
  marginTop: '8%',
};

const carbon_transcript_style = {
  height: '375px',
  marginTop: '0%',
};

export const report_img_styles = {
  default: {
    height: '250px',
    marginTop: '20%',
  },
  trend: trend_style,
  trend_excel: trend_style,
  trend_building: trend_style,
  transcript: transcript_style,
  performance: performance_style,
  performance_building: performance_style,
  performance_excel: performance_style,
  analysis: analysis_style,
  analysis_excel: analysis_style,
  feasibility: feasibility_style,
  feasibility_excel: feasibility_style,
  ghg_summary: ghg_summary_style,
  carbon_transcript: carbon_transcript_style,
  ghg_baseline: carbon_transcript_style,
  ghg_glide_path: carbon_transcript_style,
};
