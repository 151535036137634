import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../../components/forms/AddEntityActionButtons';
import InvalidFeedback from '../../components/forms/InvalidFeedback';
import RequestCredentialModal from '../../components/modals/ProviderModals/RequestCredentialModal';
import {
  updateCredentialState,
  handleUpdateUsername,
  handleUpdatePassword,
} from './helpers/provider';

const styles = {
  button: {
    marginTop: '-2px',
    paddingTop: '0px',
  },
  iconStyle: {
    fontSize: '15px',
    color: 'var(--et_tab_grey)',
  },
};

const CredentialChangeAlert = (props) => {
  const { username } = props;
  return (
    <>
      <Alert variant={'danger'} style={{ marginBottom: '45px' }}>
        <Row style={{ fontSize: '.95rem' }}>
          <Col>This change will affect all users of this credential.</Col>
        </Row>
      </Alert>
      <div
        style={{
          textAlign: 'center',
          height: '60px',
          marginTop: '-15px',
          marginBottom: '20px',
        }}
      >
        {'This change will be applied to the following account:'}
        <div
          style={{ fontSize: 'larger', fontWeight: 'bold', marginTop: '8px' }}
        >
          {username}
        </div>
      </div>
    </>
  );
};

const ChangeCredentialModal = (props) => {
  const { displayField, errors, register, Alert } = props;
  const [newValue, setNewValue] = useState('');
  const [hidePass, setHidePass] = useState(true);

  const isPassword = displayField === 'Password';

  const HidePasswordToggle = () => (
    <InputGroup.Append>
      <Button
        variant='outline-secondary'
        className='et-password-input-group-button'
        onClick={() => setHidePass((prev) => !prev)}
      >
        {hidePass ? (
          <FontAwesomeIcon style={styles.iconStyle} icon={faEyeSlash} />
        ) : (
          <FontAwesomeIcon style={styles.iconStyle} icon={faEye} />
        )}
      </Button>
    </InputGroup.Append>
  );

  return (
    <>
      <Card.Header as='h5'>{`Edit ${displayField}`}</Card.Header>
      <Card.Body style={{ paddingBottom: '0px' }}>
        {Alert}
        <Form.Group>
          <InputGroup>
            <Form.Control
              type={isPassword && hidePass ? 'password' : 'text'}
              name='value'
              placeholder={`New ${displayField}`}
              isInvalid={errors.value}
              ref={register({ required: true })}
              onChange={(e) => setNewValue(e.target.value)}
            />
            {isPassword && <HidePasswordToggle />}
          </InputGroup>
          <InvalidFeedback
            errors={errors}
            name='value'
            message='Field is required'
          />
        </Form.Group>
        <Form.Group>
          <InputGroup>
            <Form.Control
              type={isPassword && hidePass ? 'password' : 'text'}
              name='repeatValue'
              placeholder={`Repeat New ${displayField}`}
              isInvalid={errors.repeatValue}
              ref={register({
                required: true,
                validate: { match: (value) => value === newValue },
              })}
            />
            {isPassword && <HidePasswordToggle />}
          </InputGroup>
          <InvalidFeedback
            errors={errors}
            name='repeatValue'
            message={`Must match first ${displayField.toLowerCase()}`}
          />
        </Form.Group>
      </Card.Body>
    </>
  );
};

const HideCredentialModal = (props) => {
  const { hidden, Alert } = props;
  const message = hidden
    ? 'Are you sure you wish to unhide this credential?'
    : 'Are you sure you wish to hide this credential?';
  return (
    <>
      <Card.Header as='h5'>Hide Credentials</Card.Header>
      <Card.Body>
        {Alert}
        <div style={{ textAlign: 'center' }}>{message}</div>
      </Card.Body>
    </>
  );
};

function EditCredential(props) {
  const {
    organizationId,
    credential,
    setAllCredentials,
    showToast,
    shared,
    provider_type,
  } = props;

  const [modalType, setModalType] = useState('hidden');
  const [modalContent, setModalContent] = useState(null);
  const [showRequestCredentialModal, setShowRequestCredentialModal] =
    useState(false);

  const { errors, register, handleSubmit } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const updateUsername = (data) => {
    const params = {
      org_id: credential.correlationId,
      credential_id: credential.credentialId,
      new_username: data.value,
    };
    handleUpdateUsername(
      provider_type,
      params,
      credential,
      setAllCredentials,
      showToast
    );
  };

  const updatePassword = (data) => {
    const params = {
      org_id: credential.correlationId,
      credential_id: credential.credentialId,
      new_password: data.value,
    };
    handleUpdatePassword(provider_type, params, showToast);
  };

  const hideCredentials = () => {
    setIsLoading(true);
    const message = credential.hidden
      ? 'Credential is no longer hidden'
      : 'Credential was marked as hidden';
    const params = {
      org_id: organizationId,
      credential_id: credential.credentialId,
      provider_type: provider_type,
    };
    ApiServiceServerless.put('/providers/hide-credentials', params, {
      authorization_id: organizationId,
    })
      .then(() => {
        updateCredentialState(
          setAllCredentials,
          credential.credentialId,
          'hidden',
          !credential.hidden
        );
        showToast('success', 'Success', message);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  const onSubmit = (data) => {
    switch (modalType) {
      case 'username':
        updateUsername(data);
        break;
      case 'password':
        updatePassword(data);
        break;
      case 'hide':
        hideCredentials(data);
        break;
      default:
        onHide();
        break;
    }
  };

  useEffect(() => {
    switch (modalType) {
      case 'username':
        setModalContent(
          <ChangeCredentialModal
            displayField='Username'
            errors={errors}
            register={register}
            Alert={<CredentialChangeAlert username={credential.username} />}
          />
        );
        break;
      case 'password':
        setModalContent(
          <ChangeCredentialModal
            displayField='Password'
            errors={errors}
            register={register}
            Alert={<CredentialChangeAlert username={credential.username} />}
          />
        );
        break;
      case 'hide':
        setModalContent(
          <HideCredentialModal
            hidden={credential.hidden}
            Alert={<CredentialChangeAlert username={credential.username} />}
          />
        );
        break;
      default:
        onHide();
        break;
    }
  }, [modalType, credential.hidden, credential.username, errors, register]);

  const onHide = () => {
    setModalType('hidden');
  };

  return (
    <>
      <RequestCredentialModal
        show={showRequestCredentialModal}
        onHide={() => setShowRequestCredentialModal(false)}
        credentialId={credential.credentialId}
        utilityProvider={credential.utilityProvider}
        organizationId={credential.correlationId}
        variant='update'
        provider_type={provider_type}
      />
      <Modal show={modalType !== 'hidden'} onHide={onHide} centered>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {modalContent}
            <AddEntityActionButtons
              onCancel={onHide}
              submitText={'Confirm'}
              isSubmitting={isLoading}
            />
          </Form>
        </Modal.Body>
      </Modal>

      <div style={{ textAlign: 'right' }}>
        <>
          {shared ? (
            <div style={{ paddingBottom: '5px' }}>
              This credential is shared with you, to edit it you must be on the
              owner organization's page
            </div>
          ) : (
            <>
              <Button
                size='sm'
                variant='link'
                style={styles.button}
                onClick={() => setShowRequestCredentialModal(true)}
              >
                Request Updated Credentials
              </Button>
              <Button
                size='sm'
                variant='link'
                style={styles.button}
                onClick={() => setModalType('username')}
              >
                Edit Username
              </Button>
              <Button
                size='sm'
                variant='link'
                style={styles.button}
                onClick={() => setModalType('password')}
              >
                Edit Password
              </Button>
            </>
          )}
          <Button
            size='sm'
            variant='link'
            style={styles.button}
            onClick={() => setModalType('hide')}
          >
            {credential.hidden ? 'Unhide' : 'Hide'}
          </Button>
        </>
      </div>
    </>
  );
}

export default EditCredential;
