import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';

import dayjs from 'dayjs';
import { ApiServiceServerless } from '../../xhr_libs';
import LeanEnergyAnalysis from './LeanEnergyAnalysis';
import PerformanceModelAnalysis from './PerformanceModelAnalysis';
import Loader from '../Loader';
import PerformanceCalenderAnalysis from './PerformanceCalenderAnalysis';
import AdditionalAnalysisModal from '../modals/AdditionalAnalysisModal';
import CHARTCOLORS from '../../helpers/chartColors';

const styles = {
  boxStyle: { padding: '1em 0em 1em 3em' },
  boxHeader: { marginBottom: '1em' },
};
export default function AdditionalAnalysis(props) {
  const {
    organization_id,
    regressionEvaluation,
    regressionEvaluationObj,
    regressionEvaluationData,
    regressionLine,
    baselineData,
    utilityType,
    regressionType,
    building,
    showToast,
    regressionEquation,
    regressionStats,
    regressionCoefficients,
    filterRegressionPieces,
    regressionName,
    regressionId,
    regressionOptions,
    existingModels,
    availableRegressors,
    productionNames,
    chartRegressor,
    setChartRegressor,
    ranRegressionPieces,
    ranRegressionEventCollection,
    ranRegressionStartDate,
  } = props;

  const [tabKey, setTabKey] = useState('lea');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [performanceData, setPerformanceData] = useState({});
  const [simpleBaseline, setSimpleBaseline] = useState([]);
  const [baselinePerformanceEvaluation, setBaselinePerformanceEvaluation] =
    useState([]);
  const [
    baselinePerformanceEvaluationObj,
    setBaselinePerformanceEvaluationObj,
  ] = useState({});
  const [performanceLoading, setPerformanceLoading] = useState(false);

  const [showAdditionalAnalysisModal, setShowAdditionalAnalysisModal] =
    useState(false);

  const [newRegressionId, setNewRegressionId] = useState('-1');
  const [newRegressionName, setNewRegressionName] = useState('');

  const [newRegressionEvaluation, setNewRegressionEvaluation] = useState([]);
  const [newRegressionEvaluationObj, setNewRegressionEvaluationObj] = useState(
    {}
  );
  const [newRegressionEvaluationData, setNewRegressionEvaluationData] =
    useState({});
  const [newRegressionLine, setNewRegressionLine] = useState([]);
  const [newRegressionEquation, setNewRegressionEquation] = useState('');
  const [newRegressionStats, setNewRegressionStats] = useState({});
  const [newRegressionCoeffs, setNewRegressionCoeffs] = useState([]);
  const [newModelList, setNewModelList] = useState([]);
  const [newRegressionPieces, setNewRegressionPieces] = useState([]);

  const clearPerformance = () => {
    setPerformanceData({});
    setBaselinePerformanceEvaluation([]);
    setBaselinePerformanceEvaluationObj({});

    setSimpleBaseline([]);
    setNewRegressionEvaluation([]);
    setNewRegressionEvaluationObj({});
    setNewRegressionEvaluationData({});
    setNewRegressionLine([]);
    setNewRegressionStats({});
    setNewRegressionEquation('');
    setNewRegressionCoeffs([]);
    setNewModelList([]);
    setNewRegressionPieces([]);
  };

  useEffect(() => {
    clearPerformance();
    setNewRegressionId('-1');
    setNewRegressionName('');
  }, [baselineData, regressionEvaluation]);

  const hasData = (
    regressionEvaluation,
    regressionEvaluationObj,
    baselineData
  ) => {
    return (
      regressionEvaluation.length > 0 &&
      Object.keys(regressionEvaluationObj).length > 0 &&
      regressionEvaluationObj[Object.keys(regressionEvaluationObj)[0]]
        .length === regressionEvaluation.length &&
      Object.keys(baselineData).length > 0
    );
  };

  const getPerformanceData = (
    utility_type,
    regressionType,
    start_date,
    end_date,
    new_model_list,
    new_model_options,
    regression_event_collection
  ) => {
    setPerformanceLoading(true);
    const diff = dayjs.utc(end_date).diff(dayjs.utc(start_date), 'month');
    if (diff < 10) {
      showToast(
        'danger',
        'Error',
        'Performance Range must contain at least 11 months of data'
      );
      setPerformanceLoading(false);
    } else {
      const payload = {
        start_date: dayjs.utc(start_date).format('YYYY-MM-DD'),
        end_date: dayjs.utc(end_date).format('YYYY-MM-DD'),
        model_list: [['constant', 'linear']].concat(
          filterRegressionPieces().map((piece) => [
            piece.regressor,
            piece.model,
          ])
        ),
        coeffs: regressionCoefficients,
        new_model_list: new_model_list,
        new_model_options: new_model_options,
        regression_event_collection: regression_event_collection,
        baseline_start_date: dayjs
          .utc(ranRegressionStartDate)
          .format('YYYY-MM-DD'),
      };
      clearPerformance();
      ApiServiceServerless.post(
        `/analysis/${building}/${utility_type}/${regressionType}/performance`,
        payload,
        {
          authorization_id: organization_id,
        }
      )
        .then((res) => {
          setPerformanceData(res.data.performance);
          setBaselinePerformanceEvaluation(res.data.evaluation);
          setBaselinePerformanceEvaluationObj(res.data.evaluation_obj);
          setSimpleBaseline(res.data.simple_baseline);
          if (res.data.new_evaluation) {
            setNewRegressionEvaluation(res.data.new_evaluation.evaluation);
            setNewRegressionEvaluationObj(
              res.data.new_evaluation.evaluation_obj
            );
            setNewRegressionEvaluationData(res.data.new_evaluation.model_data);
            setNewRegressionLine(res.data.new_evaluation.regression_line);
            setNewRegressionStats(res.data.new_evaluation.stats);
            setNewRegressionEquation(res.data.new_evaluation.equation);
            setNewRegressionCoeffs(res.data.new_evaluation.coeffs);
            setNewModelList(new_model_list);
            setNewRegressionPieces(
              new_model_list
                .map((piece, index) => ({
                  id: index,
                  regressor: piece[0],
                  model: piece[1],
                }))
                .filter((piece) => piece.id !== 0)
            );
          }
          setPerformanceLoading(false);
        })
        .catch((error) => {
          setPerformanceLoading(false);
          showToast('danger', 'Error', error);
          throw error;
        });
    }
  };

  return (
    <>
      {!hasData(regressionEvaluation, regressionEvaluationObj, baselineData) ? (
        'Run or load a regression to view Additional Analysis'
      ) : (
        <>
          <Row style={{ marginBottom: '0.5em' }}>
            <Col md={3}>
              <h4>Additional Analysis</h4>
              <div>
                Select Performance Data:
                <FontAwesomeIcon
                  style={{
                    fontSize: '20px',
                    marginLeft: '0.5em',
                    color: CHARTCOLORS[1],
                    cursor: 'pointer',
                  }}
                  icon={faEdit}
                  onClick={() => setShowAdditionalAnalysisModal(true)}
                />
              </div>
            </Col>
            <Col>
              {newRegressionName && <>Performance Model: {newRegressionName}</>}
            </Col>
          </Row>

          <div className='et-sub-tab-content' tabkey={tabKey}>
            <Tabs
              id='additional-analysis-tabs'
              activeKey={tabKey}
              onSelect={setTabKey}
              style={{ marginTop: '0' }}
            >
              <Tab eventKey='lea' title={'Lean Energy Analysis'}>
                {performanceLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div style={styles.boxStyle}>
                      <h4 style={styles.boxHeader}>
                        Baseline Lean Energy Analysis
                      </h4>
                      <LeanEnergyAnalysis
                        organization_id={organization_id}
                        baselineData={baselineData}
                        regressionEvaluation={regressionEvaluation}
                        regressionEvaluationObj={regressionEvaluationObj}
                        utilityType={utilityType}
                        regressionType={regressionType}
                      />
                    </div>
                    {baselinePerformanceEvaluation.length > 0 && (
                      <div style={styles.boxStyle}>
                        <h4 style={styles.boxHeader}>
                          Current Lean Energy Analysis - Without Performance
                          Upgrades
                        </h4>
                        <LeanEnergyAnalysis
                          organization_id={organization_id}
                          baselineData={performanceData}
                          regressionEvaluation={baselinePerformanceEvaluation}
                          regressionEvaluationObj={
                            baselinePerformanceEvaluationObj
                          }
                          utilityType={utilityType}
                          regressionType={regressionType}
                        />
                      </div>
                    )}
                    {newRegressionEvaluation.length > 0 && (
                      <div style={styles.boxStyle}>
                        <h4 style={styles.boxHeader}>
                          Current Lean Energy Analysis - With Performance
                          Upgrades
                        </h4>
                        <LeanEnergyAnalysis
                          organization_id={organization_id}
                          baselineData={performanceData}
                          regressionEvaluation={newRegressionEvaluation}
                          regressionEvaluationObj={newRegressionEvaluationObj}
                          utilityType={utilityType}
                          regressionType={regressionType}
                        />
                      </div>
                    )}
                  </>
                )}
              </Tab>
              <Tab eventKey='calender' title={'Performance Analysis'}>
                {performanceLoading ? (
                  <Loader />
                ) : (
                  <div style={{ padding: '1em' }}>
                    <PerformanceCalenderAnalysis
                      organization_id={organization_id}
                      performanceData={performanceData}
                      baselineData={baselinePerformanceEvaluation}
                      simpleBaseline={simpleBaseline}
                      performanceModel={newRegressionEvaluation}
                      utilityType={utilityType}
                      regressionType={regressionType}
                    />
                  </div>
                )}
              </Tab>
              <Tab eventKey='model' title={'Model Analysis'}>
                {performanceLoading ? (
                  <Loader />
                ) : (
                  <PerformanceModelAnalysis
                    organization_id={organization_id}
                    utilityType={utilityType}
                    regressionType={regressionType}
                    regressionEvaluation={regressionEvaluation}
                    regressionEvaluationData={regressionEvaluationData}
                    regressionLine={regressionLine}
                    regressionEquation={regressionEquation}
                    regressionStats={regressionStats}
                    regressionCoefficients={regressionCoefficients}
                    availableRegressors={availableRegressors}
                    productionNames={productionNames}
                    chartRegressor={chartRegressor}
                    setChartRegressor={setChartRegressor}
                    regressionPieces={ranRegressionPieces}
                    performanceData={performanceData}
                    newRegressionEvaluation={newRegressionEvaluation}
                    newRegressionEvaluationData={newRegressionEvaluationData}
                    newRegressionLine={newRegressionLine}
                    newRegressionEquation={newRegressionEquation}
                    newRegressionStats={newRegressionStats}
                    newRegressionCoeffs={newRegressionCoeffs}
                    newModelList={newModelList}
                    newRegressionPieces={newRegressionPieces}
                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </>
      )}

      <AdditionalAnalysisModal
        show={showAdditionalAnalysisModal}
        onHide={() => setShowAdditionalAnalysisModal(false)}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        utilityType={utilityType}
        regressionType={regressionType}
        getPerformanceData={getPerformanceData}
        existingModels={existingModels}
        regressionName={regressionName}
        regressionId={regressionId}
        regressionOptions={regressionOptions}
        filterRegressionPieces={filterRegressionPieces}
        newRegressionId={newRegressionId}
        setNewRegressionId={setNewRegressionId}
        setNewRegressionName={setNewRegressionName}
        ranRegressionEventCollection={ranRegressionEventCollection}
      />
    </>
  );
}
