import React from 'react';
import { Modal } from 'react-bootstrap';
import EnergyTracerTable from '../tables';

const styles = {
  column: {
    paddingTop: '11px',
  },
};

export default function ViewGHGConversionModal(props) {
  const { show, onHide, rows } = props;

  const tableColumns = [
    { dataField: 'name', text: 'Name', style: styles.column },
    { dataField: 'year', text: 'Year', style: styles.column },
    {
      dataField: 'CO2',
      text: 'CO2 (CO2 Savings) lbs/MWh',
      style: styles.column,
      formatter: (cell, row) => (
        <>{row['CO2'] + ' (' + row['CO2_savings'] + ')'}</>
      ),
    },
    {
      dataField: 'N2O',
      text: 'N2O (N2O Savings) lbs/MWh',
      style: styles.column,
      formatter: (cell, row) => (
        <>{row['N2O'] + ' (' + row['N2O_savings'] + ')'}</>
      ),
    },
    {
      dataField: 'CH4',
      text: 'CH4 (CH4 Savings) lbs/MWh',
      style: styles.column,
      formatter: (cell, row) => (
        <>{row['CH4'] + ' (' + row['CH4_savings'] + ')'}</>
      ),
    },
  ];

  return (
    <Modal show={show} onHide={onHide} size='xl'>
      <Modal.Header closeButton>View GHG Conversion</Modal.Header>
      <Modal.Body>
        <div style={{ padding: '0.5em' }}>
          <EnergyTracerTable data={rows} columns={tableColumns} keyField='id' />
        </div>
      </Modal.Body>
    </Modal>
  );
}
