import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt,
  faBuildingMemo,
  faMemoCircleInfo,
} from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless, forceAuthRefreshAsync } from '../../../xhr_libs';
import EnergyTracerTable from '../../../components/tables';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import OrganizationPortfolioTokenModal from '../../../components/modals/ResourceModals/OrganizationPortfolioTokenModal';
import OrganizationPortfolioIdModal from '../../../components/modals/ResourceModals/OrganizationPortfolioIdModal';
import {
  ROLE_WEIGHTS,
  ROLES,
  ROLE_NAMES_UPPER,
} from '../../../helpers/RolesData';
import {
  useOrganizationRoles,
  useOrganizationRolesWeight,
} from '../../../helpers/checkRoles';
import AppContext from '../../../contexts/app-context';

const styles = {
  column: {
    padding: '22px 15px 0px 15px',
  },
  actionColumn: {
    padding: '16px 15px 0px 15px',
  },
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
};

function OrganizationPortfolios(props) {
  const { organization, userSelectedOrganizationDetails, showToast } = props;

  const { userPortfolios } = useContext(AppContext);

  const [portfolioOrganizations, setPortfolioOrganizations] = useState([]);
  const [availablePortfolios, setAvailablePortfolios] = useState([]);
  const [showPortfolioTokenModal, setShowPortfolioTokenModal] = useState(false);
  const [showPortfolioIdModal, setShowPortfolioIdModal] = useState(false);

  const organizationRoleWeight = useOrganizationRolesWeight(
    userSelectedOrganizationDetails
  );

  const [confirmationModalProps, withConfirm] = useConfirm();

  const createAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'portfolio_organization',
    'create'
  );
  const updateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'portfolio_organization',
    'update'
  );
  const deleteAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'portfolio_organization',
    'delete'
  );

  const organizationColumns = [
    { dataField: 'name', text: 'Name', style: styles.column },
    {
      dataField: 'role',
      text: 'Role',
      style: styles.actionColumn,
      formatter: (cell, row) => (
        <Form.Group>
          <Form.Control
            as='select'
            value={row.role}
            disabled={
              !updateAccess || organizationRoleWeight < ROLE_WEIGHTS[row.role]
            }
            onChange={(e) => {
              changeUserRole(row.id, e.target.value);
            }}
          >
            {ROLES.map(
              (role) =>
                (organizationRoleWeight >= ROLE_WEIGHTS[role] ||
                  role === row.role) && (
                  <option key={'org-role-key-' + role} value={role}>
                    {ROLE_NAMES_UPPER[role]}
                  </option>
                )
            )}
          </Form.Control>
        </Form.Group>
      ),
    },
  ];

  const actionColumns = [
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell, row) => (
        <>
          {organizationRoleWeight >= ROLE_WEIGHTS[row.role] ? (
            <button
              className='btn btn-danger'
              onClick={() => {
                handleDeletePortfolioOrganization(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '21px',
                  lineHeight: 'inherit',
                  paddingTop: '2px',
                }}
                icon={faTrashAlt}
              />
            </button>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  let tableColumns = [].concat(
    organizationColumns,
    deleteAccess ? actionColumns : []
  );

  const changeUserRole = (portfolioId, userRole) => {
    // Allows immediate UI feedback onChange. Value is corrected if an error occurs.
    setPortfolioOrganizations(
      portfolioOrganizations.map((po) => {
        if (po.id !== portfolioId) return po;
        else {
          po.role = userRole;
          return po;
        }
      })
    );
    saveUserRole(portfolioId, userRole);
  };

  const saveUserRole = (portfolioId, userRole) => {
    ApiServiceServerless.post(
      `portfolios/portfolio_organizations/${portfolioId}/${organization.id}/${userRole}`,
      {},
      { authorization_id: organization.id }
    )
      .then(async () => {
        showToast('success', 'Success', 'Updated Portfolio Role');
        await forceAuthRefreshAsync();
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        getPortfolioOrganizations(organization);
      });
  };

  const handleDeletePortfolioOrganization = (portfolioId) => {
    const deletePortfolioOrganization = () => {
      ApiServiceServerless.delete(
        `portfolios/portfolio_organizations/organization/${portfolioId}/${organization.id}`,
        { authorization_id: organization.id }
      )
        .then(async () => {
          await forceAuthRefreshAsync();
          setPortfolioOrganizations((prev) =>
            prev.filter((portfolio) => portfolio.id !== portfolioId)
          );
          showToast('success', 'Success', 'Portfolio Deleted');
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
        });
    };
    withConfirm(
      "Are you sure you want to remove this portfolio's access to your organizaion?",
      deletePortfolioOrganization
    );
  };

  const getPortfolioOrganizations = useCallback((org) => {
    const portfolios = org.portfolios;
    const roles = Object.keys(org.portfolios).map((key) => {
      return {
        id: parseInt(key),
        name: portfolios[key].name,
        role: portfolios[key].role,
      };
    });
    setPortfolioOrganizations(roles);
  }, []);

  useEffect(() => {
    if (organization) {
      getPortfolioOrganizations(organization);
    }
  }, [organization, getPortfolioOrganizations]);

  useEffect(() => {
    const active_portfolio_ids = portfolioOrganizations.map((portfolio) => {
      return portfolio.id;
    });
    let temp_portfolios = userPortfolios.filter(
      (portfolio) => !active_portfolio_ids.includes(portfolio.id)
    );
    setAvailablePortfolios(sortBy(temp_portfolios, ['name']));
  }, [portfolioOrganizations, userPortfolios]);

  return (
    <>
      <div style={{ marginBottom: '16px', textAlign: 'right' }}>
        {createAccess && (
          <>
            <div
              style={{
                marginTop: '1em',
                marginBottom: '1em',
                textAlign: 'right',
              }}
            >
              <FontAwesomeIcon
                style={styles.iconStyle}
                icon={faBuildingMemo}
                title={' New Portfolio from List'}
                onClick={() => setShowPortfolioIdModal(true)}
              />
              <FontAwesomeIcon
                style={styles.iconStyle}
                icon={faMemoCircleInfo}
                title={' New Portfolio with Token'}
                onClick={() => setShowPortfolioTokenModal(true)}
              />
            </div>
          </>
        )}
      </div>
      {portfolioOrganizations.length === 0 ? (
        <div>This Organization has not been shared with any portfolios.</div>
      ) : (
        <EnergyTracerTable
          data={portfolioOrganizations}
          columns={tableColumns}
          keyField={'id'}
        />
      )}
      <ConfirmationModal {...confirmationModalProps} />
      <OrganizationPortfolioTokenModal
        show={showPortfolioTokenModal}
        onHide={() => setShowPortfolioTokenModal(false)}
        organizationId={organization.id}
        setPortfolioOrganizations={setPortfolioOrganizations}
        showToast={showToast}
        roleWeight={organizationRoleWeight}
      />
      <OrganizationPortfolioIdModal
        show={showPortfolioIdModal}
        onHide={() => setShowPortfolioIdModal(false)}
        organizationId={organization.id}
        setPortfolioOrganizations={setPortfolioOrganizations}
        portfolios={availablePortfolios}
        showToast={showToast}
        roleWeight={organizationRoleWeight}
      />
    </>
  );
}

export default OrganizationPortfolios;
