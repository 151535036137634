import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ResourceDetails from '../../../components/ResourceDetails';

export default function AccountDetails(props) {
  const { account } = props;

  return (
    <>
      <Row>
        <Col md={2}>
          <ResourceDetails
            header={'Reference ID'}
            value={account.reference_id}
          />
        </Col>
        <Col md={2}>
          <ResourceDetails
            header={'Utility Provider'}
            value={account.utility_provider}
          />
        </Col>
        <Col md={2}>
          <ResourceDetails
            header={'Unit of Measure'}
            value={account.default_consumption_unit}
          />
        </Col>
        <Col md={2}>
          <ResourceDetails
            header={'Building(s)'}
            value={account.buildings.length}
          />
        </Col>
        <Col md={2}>
          <ResourceDetails
            header={'In Reports'}
            value={!account.exclude_from_reports ? 'True' : 'False'}
          />
        </Col>
      </Row>
    </>
  );
}
