import React, { useState, useContext, useEffect } from 'react';

import { ApiServiceServerless } from '../xhr_libs';
import AppContext from '../contexts/app-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import CHARTCOLORS from '../helpers/chartColors';

const link_name = 'report-chart-data-link';

export default function DownloadChartData(props) {
  const { organization_id, data, headers, keys, chart_type } = props;

  const { showToast } = useContext(AppContext);

  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [chartData, setChartData] = useState([]);

  const stlyes = {
    iconStyle: {
      fontSize: '21px',
      color: !isGeneratingReport ? CHARTCOLORS[1] : CHARTCOLORS[2],
      cursor: !isGeneratingReport ? 'pointer' : 'none',
      pointerEvents: !isGeneratingReport ? 'all' : 'none',
      marginRight: '0.5em',
      marginLeft: '0.5em',
      marginTop: '0.25em',
    },
  };

  useEffect(() => {
    let chart_data = [];
    if (chart_type === 'line' || chart_type === 'bar' || chart_type === 'pie') {
      chart_data = formatLineChartData(data, headers, keys);
    } else if (chart_type === 'scatter') {
      chart_data = formatScatterChartData(data, headers, keys);
    }
    setChartData(chart_data);
  }, [data, headers, keys, chart_type]);

  const getChartData = (organization_id, chartData) => {
    setIsGeneratingReport(true);
    const fileName = 'Chart Data.xlsx';

    ApiServiceServerless.post(
      `/chart_data`,
      { chartData },
      {
        authorization_id: organization_id,
        headers: {
          Accept: 'application/vnd.openxmlformats-',
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
      }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const old_link = document.getElementById(link_name);
        if (old_link) {
          old_link.remove();
        }
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.id = link_name;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
      })
      .then(() => {
        const link = document.getElementById(link_name);
        link.click();
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsGeneratingReport(false);
      });
  };

  return (
    <FontAwesomeIcon
      style={stlyes.iconStyle}
      icon={faDownload}
      title='Download Chart Data'
      onClick={() => getChartData(organization_id, chartData)}
    />
  );
}

export function formatLineChartData(data, headers, keys) {
  let chart_data = [];

  chart_data.push(headers);

  data.forEach((row) => {
    let temp_arr = [];
    keys.forEach((key) => {
      temp_arr.push(row[key]);
    });
    chart_data.push(temp_arr);
  });
  return chart_data;
}

export function formatScatterChartData(data, headers, keys) {
  let chart_data = [];

  chart_data.push(headers);

  let scatter_obj = {};
  data.forEach((scatter, idx1) => {
    let idx2 = 0; //idx2 manually set to avoid empty row
    scatter.forEach((row) => {
      if (!Object.keys(scatter_obj).includes(idx2.toString())) {
        //inititalize
        scatter_obj[idx2] = [];
        //fill offset if current scatter is longer than prev
        let col_offset = 0;
        for (let i = 0; i < idx1; i++) {
          col_offset += keys[i].length;
        }
        for (let j = 0; j < col_offset; j++) {
          scatter_obj[idx2].push('');
        }
      }
      keys[idx1].forEach((key) => {
        scatter_obj[idx2].push(row[key]);
      });
      if (row) {
        idx2++; //only increment for row with data (see analysis chart ignore)
      }
    });
  });
  chart_data = [...chart_data, ...Object.values(scatter_obj)];

  return chart_data;
}
