import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from 'recharts';

import CHARTCOLORS from '../helpers/chartColors';
import formatValue from '../helpers/chartFormatter';

const SELECTION_KEY = 'Selection Total';
const SELECTION_BASELINE_KEY = 'Selection Baseline Total';

const EnergyTracerMultiLineChart = function (props) {
  var { data, unit, keys, baseline, showSummary } = props;

  const [summaryData, setSummaryData] = useState([]);

  const [chartDebounce, setChartDebounce] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setChartDebounce((prev) => prev + 1);
    }, 500);
  }, [data]);

  useEffect(() => {
    let summary = [];

    data.forEach((month) => {
      let temp_month = {
        name: month.name,
        month: month.month,
      };
      temp_month[SELECTION_KEY] = 0;
      if (baseline) {
        temp_month[SELECTION_BASELINE_KEY] = 0;
      }
      Object.keys(month).forEach((key) => {
        if (key !== 'name' && key !== 'month' && !key.includes(' Baseline')) {
          temp_month[SELECTION_KEY] += month[key];
        }
        if (
          key !== 'name' &&
          key !== 'month' &&
          baseline &&
          key.includes(' Baseline')
        ) {
          temp_month[SELECTION_BASELINE_KEY] += month[key];
        }
      });
      summary.push(temp_month);
    });
    setSummaryData(summary);
  }, [data, baseline]);

  return (
    <ResponsiveContainer width={'100%'} height={300} debounce={chartDebounce}>
      <LineChart data={showSummary ? summaryData : data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey='name'
          type='category'
          tick={{ fontSize: 16 }}
          interval={0}
        />
        <YAxis type='number' tickFormatter={(tick) => formatValue(tick, '')}>
          <Label value={''} position='top' offset={10} />
        </YAxis>
        <Tooltip wrapperStyle={{ zIndex: '200' }} />
        <Legend
          //wrapperStyle={{ bottom: '0px', position: 'relative' }}
          iconType='plainline'
        />
        {!showSummary &&
          keys.map((key, index) => (
            <Line
              type='monotone'
              dataKey={key}
              key={key}
              formatter={(value) => formatValue(value, unit)}
              stroke={CHARTCOLORS[index % CHARTCOLORS.length]}
            />
          ))}
        {!showSummary &&
          baseline &&
          keys.map((key, index) => (
            <Line
              type='monotone'
              dataKey={key + ' Baseline'}
              key={key + ' Baseline'}
              formatter={(value) => formatValue(value, unit)}
              stroke={CHARTCOLORS[index % CHARTCOLORS.length]}
              strokeDasharray='5 5'
            />
          ))}
        {showSummary && (
          <Line
            type='monotone'
            dataKey={SELECTION_KEY}
            key={SELECTION_KEY}
            formatter={(value) => formatValue(value, unit)}
            stroke={CHARTCOLORS[0]}
          />
        )}
        {showSummary && baseline && (
          <Line
            type='monotone'
            dataKey={SELECTION_BASELINE_KEY}
            key={SELECTION_BASELINE_KEY}
            formatter={(value) => formatValue(value, unit)}
            stroke={CHARTCOLORS[0]}
            strokeDasharray='5 5'
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

EnergyTracerMultiLineChart.propTypes = {
  baseline: PropTypes.bool,
  showSummary: PropTypes.bool,
};

EnergyTracerMultiLineChart.defaultProps = {
  baseline: false,
  showSummary: false,
};

export default EnergyTracerMultiLineChart;
