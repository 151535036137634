import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import SmallerLoader from '../SmallerLoader';

export default function AddEntityActionButtons(props) {
  return (
    <Form.Group className='d-flex justify-content-end'>
      <Button
        data-id='btn_cancel'
        variant={props.cancelVariant || 'outline-danger'}
        onClick={props.onCancel}
        hidden={!props.onCancel}
      >
        {props.cancelText || 'Cancel'}
      </Button>
      <Button
        data-id='btn_submit'
        type='submit'
        className={!!props.onCancel ? 'ml-3' : ''}
        variant={props.submitVariant || 'success'}
        onClick={props.onSubmit}
        disabled={props.submitDisabled || props.isSubmitting}
      >
        {!props.isSubmitting && <>{props.submitText}</>}
        {props.isSubmitting && <SmallerLoader text={props.submittingText} />}
      </Button>
    </Form.Group>
  );
}

AddEntityActionButtons.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitDisabled: PropTypes.bool,
  submitText: PropTypes.string,
  submittingText: PropTypes.string,
  cancelText: PropTypes.string,
  cancelVariant: PropTypes.string,
  submitVariant: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

AddEntityActionButtons.defaultProps = {
  submitText: 'Save',
  submittingText: 'Saving...',
  isSubmitting: false,
};
