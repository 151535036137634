import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import ArcadiaIFrame from '../../../components/UtilityImportSettings/ArcadiaIFrame';

const urjanet_iframe_key =
  'h9PCGEYwX64Ly0SyF3qs-1ea74e94-b6d0-d802-aa5b-0a4a5971b149';
const plug_iframe_key = 'h9PCGEYwX64Ly0SyF3qs';

function NewProviderModal(props) {
  const { show, onHide, organizationId, provider_type } = props;

  const [localIframeKey, setLocalIframeKey] = useState(null);

  useEffect(() => {
    if (parseInt(provider_type) === 1) {
      setLocalIframeKey(urjanet_iframe_key);
    }
    if (parseInt(provider_type) === 2) {
      setLocalIframeKey(plug_iframe_key);
    }
  }, [provider_type]);

  return (
    <Modal size='lg' show={show} onHide={onHide} centered>
      {(localIframeKey && parseInt(provider_type) === 1) ||
      parseInt(provider_type) === 2 ? (
        <ArcadiaIFrame
          organizationId={organizationId}
          iframe_key={localIframeKey}
          provider_type={provider_type}
        />
      ) : (
        <></>
      )}
    </Modal>
  );
}

export default NewProviderModal;
