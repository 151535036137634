import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  containerStyle: {
    width: '100%',
    borderBottom: 'solid 1px var(--et_border_grey)',
    height: '28px',
    marginLeft: '2em',
  },
  buttonContainerStyle: {
    float: 'right',
  },
};

function GraphActionContainer(props) {
  return (
    <div style={{ ...styles.containerStyle, ...props.customStyle }}>
      <div style={styles.buttonContainerStyle}>{props.children}</div>
    </div>
  );
}

GraphActionContainer.propTypes = {
  customStyle: PropTypes.object,
};

GraphActionContainer.defaultProps = {
  customStyle: {},
};

export default GraphActionContainer;
