import React, { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';

const formStyle = {
  marginRight: '20px',
  fontSize: 14,
};

export default function AnalysisDateSelect(props) {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    utilityType,
    regressionType,
    getBaselineData,
    clearRegression,
    startDateSelect,
    endDateSelect,
  } = props;

  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  const handleLocalDate = (newDate, type) => {
    if (type === 'start') {
      setLocalStartDate(dayjs.utc(newDate).startOf('month'));
    } else {
      setLocalEndDate(dayjs.utc(newDate).endOf('month'));
    }
  };

  const updateDateSelection = () => {
    let start_date = '';
    let end_date = '';
    if (dayjs(localStartDate).isValid() && dayjs(localEndDate).isValid()) {
      start_date = localStartDate;
      end_date = localEndDate;
    } else {
      start_date = startDate;
      end_date = endDate;
    }
    setStartDate(start_date);
    setEndDate(end_date);

    clearRegression();
    getBaselineData(utilityType, regressionType, start_date, end_date);
    document.getElementById('analysis-popover-button').click();
  };

  return (
    <>
      <Form style={formStyle}>
        {startDateSelect && (
          <Col>
            <Form.Row>
              <Form.Group>
                <Form.Label>Start date</Form.Label>
                <InputGroup size='sm'>
                  <DatePicker
                    //plus one on start date since it is set to first of month
                    selected={
                      localStartDate
                        ? new Date(localStartDate).setDate(
                            new Date(localStartDate).getDate() + 1
                          )
                        : new Date()
                    }
                    dateFormat='MM/yyyy'
                    onChange={(date) => handleLocalDate(date, 'start')}
                    showMonthYearPicker
                    showTwoColumnMonthYearPicker
                  />
                </InputGroup>
              </Form.Group>
            </Form.Row>
          </Col>
        )}
        {endDateSelect && (
          <Col>
            <Form.Row>
              <Form.Group>
                <Form.Label>End date</Form.Label>
                <InputGroup size='sm'>
                  <DatePicker
                    selected={
                      localEndDate ? new Date(localEndDate) : new Date()
                    }
                    dateFormat='MM/yyyy'
                    onChange={(date) => handleLocalDate(date, 'end')}
                    showMonthYearPicker
                    showTwoColumnMonthYearPicker
                  />
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Button onClick={() => updateDateSelection()}> Confirm</Button>
          </Col>
        )}
      </Form>
    </>
  );
}

AnalysisDateSelect.propTypes = {
  startDateSelect: PropTypes.bool,
  endDateSelect: PropTypes.bool,
};

AnalysisDateSelect.defaultProps = {
  startDateSelect: true,
  endDateSelect: true,
};
