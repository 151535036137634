import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { cloneDeep } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrashAlt, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import EnergyTracerTable from '../../../components/tables';
import AddUtilityAccountMaskModal from '../../../components/modals/AddUtilityAccountMaskModal';
import Loader from '../../../components/Loader';
import { setUserOrganizationPreference } from '../../../helpers/user-preferences';
import SmallerLoader from '../../../components/SmallerLoader';

const styles = {
  buttonRow: { textAlign: 'center' },
  icon: {
    fontSize: '15px',
    lineHeight: 'inherit',
    paddingTop: '2px',
  },
};

const PAYLOAD_KEYS = [
  'name',
  'exclude_from_reports',
  'is_solar',
  'ignore_cost',
  'ignore_consumption',
  'ignore_demand',
];

export default function SubAccountSettings(props) {
  const {
    account,
    showToast,
    setAccount,
    setTabSet,
    setSelectedResource,
    setBuildingInfo,
    setUtilityAccountInfo,
    isLoadingAccount,
    isLoadingBills,
    updateAccess,
  } = props;

  const [localSubAccounts, setLocalSubAccounts] = useState([]);
  const [showAddMaskModal, setShowAddMaskModal] = useState(false);
  const [addedSubAccount, setAddedSubAccount] = useState(null);

  const [isSaving, setIsSaving] = useState('-1');
  const [isDeleting, setIsDeleting] = useState('-1');

  useEffect(() => {
    updateSubAccountStatus('isDeleting', isDeleting);
  }, [isDeleting]);

  useEffect(() => {
    setLocalSubAccounts(
      account.sub_accounts.map((sub_account) => {
        return {
          ...sub_account,
        };
      })
    );
  }, [account]);

  const bool_keys = [
    'exclude_from_reports',
    'is_solar',
    'ignore_cost',
    'ignore_consumption',
    'ignore_demand',
  ];

  const updateSubAccountStatus = (key, value) => {
    setLocalSubAccounts((prev) =>
      prev.map((sub) => {
        sub[key] = value;
        return sub;
      })
    );
  };

  const updateSubAccount = (id, key, value) => {
    setLocalSubAccounts((prev) =>
      prev.map((sub) => {
        if (sub['id'] !== id) return sub;
        let newSub = cloneDeep(sub);
        if (bool_keys.includes(key)) {
          newSub[key] = !newSub[key];
        } else {
          newSub[key] = value;
        }

        return newSub;
      })
    );
  };

  const formatPayload = (id, old_sub_accounts, new_sub_acccounts) => {
    let payload = {};

    const old_sub = old_sub_accounts.find((sub) => sub.id === id);
    const new_sub = new_sub_acccounts.find((sub) => sub.id === id);

    PAYLOAD_KEYS.forEach((key) => {
      if (old_sub[key] !== new_sub[key]) {
        payload[key] = new_sub[key];
      }
    });
    return payload;
  };

  const saveSubAccount = (id) => {
    setIsSaving(id);
    const payload = formatPayload(id, account.sub_accounts, localSubAccounts);
    ApiServiceServerless.post(
      `utility_accounts/sub_utility_accounts/${id}`,
      payload,
      { authorization_id: account.id }
    )
      .then((res) => {
        const message = 'Your changes were saved.';
        showToast('success', 'Success', message);
        setAccount((prev) => ({
          ...prev,
          sub_accounts: prev.sub_accounts.map((sub) => {
            if (sub.id !== res.data.id) return sub;
            else return res.data;
          }),
        }));
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsSaving('-1');
      });
  };

  const deleteMask = (parent_account_id, sub_account_id, mask_account_id) => {
    setIsDeleting(sub_account_id);
    ApiServiceServerless.delete(
      `utility_accounts/utility_account_masks/${parent_account_id}/${sub_account_id}/${mask_account_id}`,
      { authorization_id: account.id }
    )
      .then((res) => {
        setAccount((prev) => ({
          ...prev,
          sub_accounts: prev.sub_accounts.map((sub) => {
            if (sub.id !== sub_account_id) return sub;
            return { ...sub, masked_account: null };
          }),
          available_masks: { ...prev.available_masks, [res.data.id]: res.data },
        }));
      })
      .catch(() => showToast('danger', 'Error', 'Error deleting mask'))
      .finally(() => {
        setIsDeleting('-1');
      });
  };

  const refreshAddMask = (data) => {
    setAddedSubAccount(null);
    setAccount((prev) => {
      let new_acc = { ...prev };
      new_acc.sub_accounts = prev.sub_accounts.map((sub) => {
        if (sub.id !== data.id) return sub;
        else return data;
      });

      return new_acc;
    });
  };

  useEffect(() => {
    if (addedSubAccount != null) {
      setShowAddMaskModal(true);
    }
  }, [addedSubAccount]);

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (cell, row) => (
        <Form style={{ width: '80%' }}>
          <Form.Control
            defaultValue={row.name}
            disabled={!updateAccess}
            onBlur={(e) => updateSubAccount(row.id, 'name', e.target.value)}
          />
        </Form>
      ),
    },
    {
      dataField: 'exclude_from_reports',
      text: 'Exclude From Reports',
      style: styles.buttonRow,
      formatter: (cell, row) => (
        <Form>
          <Form.Check.Input
            style={{ height: '1.1rem', width: '1.1rem' }}
            disabled={!updateAccess}
            checked={row.exclude_from_reports}
            onChange={(e) =>
              updateSubAccount(row.id, 'exclude_from_reports', e.target.value)
            }
          />
        </Form>
      ),
    },
    {
      dataField: 'is_solar',
      text: 'Is Solar Account',
      style: styles.buttonRow,
      formatter: (cell, row) => (
        <Form>
          <Form.Check.Input
            style={{ height: '1.1rem', width: '1.1rem' }}
            disabled={!updateAccess}
            checked={row.is_solar}
            onChange={(e) =>
              updateSubAccount(row.id, 'is_solar', e.target.value)
            }
          />
        </Form>
      ),
    },
    {
      dataField: 'ignore_cost',
      text: 'Ignore Cost',
      style: styles.buttonRow,
      formatter: (cell, row) => (
        <Form>
          <Form.Check.Input
            style={{ height: '1.1rem', width: '1.1rem' }}
            disabled={!updateAccess}
            checked={row.ignore_cost}
            onChange={(e) =>
              updateSubAccount(row.id, 'ignore_cost', e.target.value)
            }
          />
        </Form>
      ),
    },
    {
      dataField: 'ignore_consumption',
      text: 'Ignore Consumption',
      style: styles.buttonRow,
      formatter: (cell, row) => (
        <Form>
          <Form.Check.Input
            style={{ height: '1.1rem', width: '1.1rem' }}
            disabled={!updateAccess}
            checked={row.ignore_consumption}
            onChange={(e) =>
              updateSubAccount(row.id, 'ignore_consumption', e.target.value)
            }
          />
        </Form>
      ),
    },
    {
      dataField: 'ignore_demand',
      text: 'Ignore Demand',
      style: styles.buttonRow,
      formatter: (cell, row) => (
        <Form>
          <Form.Check.Input
            style={{ height: '1.1rem', width: '1.1rem' }}
            disabled={!updateAccess}
            checked={row.ignore_demand}
            onChange={(e) =>
              updateSubAccount(row.id, 'ignore_demand', e.target.value)
            }
          />
        </Form>
      ),
    },
    updateAccess
      ? {
          dataField: 'id',
          text: 'Actions',
          style: { width: '75px', textAlign: 'center' },
          formatExtraData: {
            isSaving: isSaving,
            isDeleting: isDeleting,
          },
          formatter: (cell, row, rowIndex, formatExtraData) => (
            <Button
              type='submit'
              variant='primary'
              onClick={() => saveSubAccount(row.id)}
              disabled={
                formatExtraData.isSaving !== '-1' ||
                formatExtraData.isDeleting !== '-1'
              }
            >
              {formatExtraData.isSaving === '-1' ? (
                <FontAwesomeIcon style={styles.icon} icon={faSave} />
              ) : formatExtraData.isSaving === row.id ? (
                <SmallerLoader />
              ) : (
                <FontAwesomeIcon style={styles.icon} icon={faSave} />
              )}
            </Button>
          ),
        }
      : {},
  ];

  const subColumns = [
    {
      dataField: 'masked_account',
      text: 'Account Mask',
      style: { textAlign: 'right' },
      headerStyle: { textAlign: 'center' },
      formatExtraData: {
        isSaving: isSaving,
        isDeleting: isDeleting,
      },
      formatter: (cell, row, rowIndex, formatExtraData) =>
        row.masked_account == null ||
        (account.available_masks &&
          row.masked_account in account.available_masks === false) ? (
          <>
            None{' '}
            {account.available_masks ? (
              <Button
                type='submit'
                variant='primary'
                className='float-right ml-3'
                onClick={() => {
                  setAddedSubAccount(row);
                }}
                disabled={
                  formatExtraData.isSaving !== '-1' ||
                  formatExtraData.isDeleting !== '-1'
                }
              >
                <FontAwesomeIcon style={styles.icon} icon={faPlus} />
              </Button>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <div
              style={{ display: 'inline-block' }}
              className='et-link'
              onClick={() => {
                setSelectedResource('utilityAccount');
                setUtilityAccountInfo({
                  id: row.masked_account,
                  name: account['available_masks'][row.masked_account]
                    .account_number,
                });
                setBuildingInfo({
                  id: account.buildings[0].id,
                  name: account.buildings[0].name,
                });
                setTabSet(false);
                setUserOrganizationPreference(
                  'lsa',
                  account.organization.id,
                  row.masked_account
                );
              }}
            >
              {account['available_masks'][row.masked_account].account_number}
            </div>
            <Button
              variant='danger'
              size='sm'
              style={{ marginLeft: '10px' }}
              onClick={() => {
                deleteMask(account.id, row.id, row.masked_account);
              }}
              disabled={
                formatExtraData.isSaving !== '-1' ||
                formatExtraData.isDeleting !== '-1'
              }
            >
              {formatExtraData.isDeleting === '-1' ? (
                <FontAwesomeIcon style={styles.icon} icon={faTrashAlt} />
              ) : formatExtraData.isDeleting === row.id ? (
                <SmallerLoader />
              ) : (
                <FontAwesomeIcon style={styles.icon} icon={faTrashAlt} />
              )}
            </Button>
          </>
        ),
    },
  ];

  let allColumns = [].concat(tableColumns, subColumns);

  return isLoadingAccount || isLoadingBills ? (
    <Row>
      <Col
        style={{
          paddingBottom: '8vh',
          paddingTop: '8vh',
          paddingLeft: '49%',
        }}
      >
        <Loader />
      </Col>
    </Row>
  ) : (
    <>
      <EnergyTracerTable
        data={Object.values(localSubAccounts)}
        columns={allColumns}
      />
      {account.available_masks ? (
        <AddUtilityAccountMaskModal
          show={showAddMaskModal}
          onHide={() => {
            setShowAddMaskModal(false);
            setAddedSubAccount(null);
          }}
          account={account}
          setAccount={setAccount}
          buildings={account.organization.buildings}
          sub_account={addedSubAccount}
          refreshAddMask={refreshAddMask}
          showToast={showToast}
          defaultType={account.account_type}
        />
      ) : (
        <></>
      )}
    </>
  );
}
