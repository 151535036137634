import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import ConfirmationModal, { useConfirm } from './ConfirmationModal';
import EditEntityActionButtons from '../forms/EditEntityActionButtons';

const styles = {
  formStyle: {
    textAlign: 'left',
  },
};

export default function RegressionModal(props) {
  const {
    show,
    onHide,
    saveRegression,
    variant,
    regression,
    utilityType,
    building_id,
    projects,
    deleteRegression,
    deleteAccess,
    updateAccess,
    isDeleting,
  } = props;
  const [name, setName] = useState('');
  const [availableProjects, setAvailableProjects] = useState([]);
  const [projectIds, setProjectIds] = useState([]);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [isSaving, setIsSaving] = useState(false);

  const updateSet = (values) => {
    const arr = Array.prototype.slice.call(values);
    setProjectIds(arr.map((item) => item.value));
  };

  useEffect(() => {
    if (regression) {
      setName(regression.name);
    }
  }, [regression]);

  useEffect(() => {
    if (utilityType && building_id && projects) {
      setAvailableProjects(
        sortBy(
          projects.filter(
            (project) =>
              project.utility_types.includes(utilityType) &&
              !project.ignore_building_ids.includes(parseInt(building_id))
          ),
          ['name']
        )
      );
    } else {
      setAvailableProjects([]);
    }
  }, [projects, utilityType, building_id]);

  const clearForm = () => {
    setName('');
    setProjectIds([]);
  };

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();
    regression
      ? saveRegression(regression.id, name, handleSuccess, handleError)
      : saveRegression(name, projectIds, handleSuccess, handleError);
  };

  const handleSuccess = () => {
    clearForm();
    onHide();
    setIsSaving(false);
  };

  const handleError = () => {
    onHide();
    setIsSaving(false);
  };

  const handleCancel = () => {
    clearForm();
    onHide();
  };

  const handleDelete = () => {
    onHide();
    const msg = `Are you sure you want to delete the model "${regression.name}"?`;
    const deleteModel = () => {
      deleteRegression(regression.id);
      onHide();
      clearForm();
    };
    withConfirm(msg, deleteModel);
  };

  return (
    <>
      <ConfirmationModal {...confirmationModalProps} />
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          {variant === 'save' ? 'Save A Regression' : 'Edit Regression'}
        </Modal.Header>
        <Form onSubmit={handleSubmit} style={styles.formStyle}>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>
                    Name
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    required
                    value={name || ''}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                {variant === 'save' && (
                  <Form.Group controlId='accountForm.BuildingSelect'>
                    <Form.Label>
                      Project(s)
                      <span className='text-warning font-weight-bold pl-1'>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      as='select'
                      multiple
                      defaultValue={[]}
                      onChange={(e) => updateSet(e.target.selectedOptions)}
                    >
                      {availableProjects.map((item) => (
                        <option key={`building-opt-${item.id}`} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <EditEntityActionButtons
              onCancel={handleCancel}
              onDelete={variant === 'edit' ? handleDelete : null}
              submitDisabled={
                name === '' || (regression && regression.name === name)
              }
              submitShow={updateAccess}
              deleteShow={deleteAccess}
              isSubmitting={isSaving}
              isDeleting={isDeleting}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

RegressionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  saveRegression: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['save', 'edit']).isRequired,
  regression: PropTypes.object,
  isDeleting: PropTypes.bool,
};

RegressionModal.defaultProps = {
  isDeleting: false,
};
