import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faFileSpreadsheet,
  faSave,
} from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../../../xhr_libs';
import DuplicateCsvMonthlyModal from './DuplicateCsvMonthlyModal';
import CsvErrorMonthlyModal from './CsvErrorMonthlyModal';

const styles = {
  row: { marginBottom: '1em', textAlign: 'left' },
  icon: {
    fontSize: '21px',
    lineHeight: 'inherit',
    paddingTop: '2px',
    minHeight: '21px',
    minWidth: '21px',
  },
  buttonCol: { textAlign: 'right' },
};

export default function CsvMonthlyUpload(props) {
  const { account, showToast, handleRefreshBills } = props;

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [resetTrigger, setResetTrigger] = useState(0);
  const [showDuplicateModal, setShowDupicateModal] = useState(false);
  const [duplicateDates, setDuplicateDates] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadTemplate = () => {
    setIsDownloading(true);
    ApiServiceServerless.get(
      `/bill_upload_monthly/${account.organization.id}/${account.account_type}`,
      {
        headers: {
          Accept: 'application/vnd.openxmlformats-',
          'Content-Type': 'application/json',
        },
        responseType: 'arraybuffer',
        authorization_id: account.organization.id,
      }
    )
      .then((response) => {
        setIsDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', `bills_template.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(() => {
        setIsDownloading(false);
        showToast('danger', 'Error', 'Error downloading template');
      });
  };

  const getCsv = (e) => {
    let files = e.target.files;
    if (files && files.length > 0) {
      const file = new FormData();
      file.append('file', files[0]);
      file.append('fileName', files[0].name);
      setFile(file);
      setFileName(files[0].name);
    }
  };

  const uploadFile = () => {
    setIsLoading(true);
    ApiServiceServerless.post(
      `/bill_upload_monthly/${account.organization.id}/${account.account_type}`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        authorization_id: account.organization.id,
      }
    )
      .then((resp) => {
        if (resp.data.duplicate_count > 0) {
          setDuplicateDates(resp.data.duplicates);
          setShowDupicateModal(true);
        } else {
          handleSuccessToast(resp);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        resetUpload();
        showToast('danger', 'Error', error);
        setIsLoading(false);
        throw error;
      });
  };

  const handleSuccessToast = (resp) => {
    const numberOfUploads = resp.data.uploads;
    const message = numberOfUploads + ' bills have been uploaded successfully';
    showToast('success', 'Success', message);
    if (resp.data.error_count > 0) {
      setErrors(resp.data.errors);
      setShowErrorModal(true);
    }

    handleRefreshBills();
    resetUpload();
  };

  const resetUpload = () => {
    setFile(null);
    setFileName('');
    setResetTrigger((prev) => prev + 1);
  };

  return (
    <>
      <Row style={styles.row}>
        <Col md={8}>1. Download template</Col>
        <Col style={styles.buttonCol}>
          <Button
            variant='primary'
            type='submit'
            disabled={isLoading || isDownloading}
            onClick={downloadTemplate}
          >
            <FontAwesomeIcon style={styles.icon} icon={faDownload} />
          </Button>
        </Col>
      </Row>
      <Row style={styles.row}>
        <Col md={8}>
          <>{'2. '}</>
          {file && fileName
            ? fileName.length <= 20
              ? fileName
              : fileName.slice(0, 20) + '...'
            : 'Select a file'}
        </Col>
        <Col style={styles.buttonCol}>
          {' '}
          <input
            style={{ display: 'none' }}
            id='upload-csv-monthly'
            type='file'
            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel '
            label='Select a csv file'
            onChange={getCsv}
            key={resetTrigger}
          ></input>
          <Button variant='primary'>
            <label
              htmlFor='upload-csv-monthly'
              style={{
                margin: '0px',
                padding: '0px',
                cursor: 'inherit',
              }}
            >
              <FontAwesomeIcon style={styles.icon} icon={faFileSpreadsheet} />
            </label>
          </Button>
        </Col>
      </Row>

      <Row style={styles.row}>
        <Col md={8}> 3. {isLoading ? 'Uploading file...' : 'Upload file'}</Col>
        <Col style={styles.buttonCol}>
          <Button
            variant='success'
            disabled={
              (file && fileName ? false : true) || isLoading || isDownloading
            }
            onClick={() => uploadFile()}
          >
            <FontAwesomeIcon style={styles.icon} icon={faSave} />
          </Button>
        </Col>
      </Row>

      <DuplicateCsvMonthlyModal
        show={showDuplicateModal}
        onHide={() => setShowDupicateModal(false)}
        account={account}
        file={file}
        duplicateDates={duplicateDates}
        handleSuccessToast={handleSuccessToast}
        showToast={showToast}
        resetUpload={resetUpload}
        setIsLoading={setIsLoading}
      />
      <CsvErrorMonthlyModal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        errors={errors}
      />
    </>
  );
}
