import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { GoogleApiWrapper } from 'google-maps-react';
import Autocomplete from 'react-google-autocomplete';
import './google-autocomplete.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

import { formatAddress, extractGoogleAddress } from '../helpers';

const styles = {
  iconStyle: {
    fontSize: '15px',
    color: 'var(--et_tab_grey)',
  },
};

function AddressWrapper(props) {
  const { address, onAddressSelect, setValidAddress, notRequired, disabled } =
    props;

  const isDisabled = typeof disabled !== 'undefined' ? disabled : false;

  const [editAddress, setEditAddress] = useState({});
  const [isInValid, setIsInValid] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const onAddressLookup = (place) => {
    setShowValidation(false);
    checkValidity(place);
    const address = extractGoogleAddress(place);
    onAddressSelect(address);
    setEditAddress(address);
    if (setValidAddress) setValidAddress(true);
  };

  const checkValidity = (value) => {
    const isValid = !!value.place_id || value === '';
    setIsInValid(!isValid);
    if (setValidAddress) setValidAddress(isValid);
  };

  useEffect(() => {
    setEditAddress(address);
  }, [address]);

  const onChange = (value) => {
    if (value === '') {
      setShowValidation(false);
      onAddressSelect({});
    }
    checkValidity(value);
    setEditAddress({ formatted_address: value });
  };

  return (
    <Form.Group>
      <Form.Label>
        Address{' '}
        {notRequired ? (
          ''
        ) : (
          <span className='text-warning font-weight-bold'>*</span>
        )}
      </Form.Label>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <FontAwesomeIcon
              style={styles.iconStyle}
              icon={faMagnifyingGlass}
            />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          style={{
            borderTopRightRadius: '.25rem',
            borderBottomRightRadius: '.25rem',
          }}
          as={Autocomplete}
          isInvalid={isInValid && showValidation}
          value={formatAddress(editAddress) || ''}
          onPlaceSelected={(place) => onAddressLookup(place)}
          onChange={(e) => onChange(e.target.value)}
          types={['address']}
          disabled={isDisabled}
          componentRestrictions={{ country: 'us' }}
          onBlur={(e) => setTimeout(() => setShowValidation(true), 300)} // wait for autocomplete to set correct value
          onFocus={() => setShowValidation(false)}
        />
        <Form.Control.Feedback type='invalid'>
          Please select a valid address from the dropdown.
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}

AddressWrapper.propTypes = {
  address: PropTypes.object.isRequired,
  onAddressSelect: PropTypes.func.isRequired,
  setValidAddress: PropTypes.func,
  notRequired: PropTypes.bool,
};

export default GoogleApiWrapper((_props) => ({
  apiKey: 'AIzaSyDhXgQ1qffA9WawUp3LDzUqKpFNaXUmm8g',
  language: 'en-us',
}))(AddressWrapper);
