import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarEdit } from '@fortawesome/pro-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

import CHARTCOLORS from '../../../helpers/chartColors';
import PopoverTrigger from '../../../components/PopoverTrigger';
import EditUtilityAccountDateSelect from './EditUtilityAccountDateSelect';

const styles = {
  inputStyle: {
    width: '200px',
    textAlign: 'center',
    marginRight: '10px',
    display: 'inline-block',
  },
  buttonStyle: {
    fontSize: '26px',
    color: CHARTCOLORS[1],
  },
  buttonContainerStyle: {
    display: 'inline-block',
    cursor: 'pointer',
  },
  cancelButton: {
    padding: '0px',
    borderWidth: '0px',
    borderRadius: '50%',
    height: '28px',
    width: '28px',
    marginLeft: '20px',
  },
};

export default function EditUtilityAccountDates(props) {
  const { title, date, dateKey, updateAccountDate } = props;

  const [localDate, setLocalDate] = useState(null);

  useEffect(() => {
    if (date) {
      setLocalDate(dayjs.utc(date));
    }
  }, [date]);

  const resetDate = () => {
    setLocalDate(null);
    updateAccountDate(null, dateKey);
  };

  return (
    <Form.Group
      controlId={'accountForm.' + dateKey}
      style={{ marginBottom: '0.5rem' }}
    >
      <Form.Label>{title}</Form.Label>
      <div>
        <Form.Control
          style={styles.inputStyle}
          size='sm'
          disabled
          value={localDate ? dayjs.utc(localDate).format('MMM YYYY') : 'None'}
        />
        <div
          id={dateKey + '-popover-button'}
          style={styles.buttonContainerStyle}
        >
          <PopoverTrigger
            rootClose={true}
            popoverTitle={'Date'}
            popoverContent={
              <EditUtilityAccountDateSelect
                date={localDate}
                setDate={setLocalDate}
                updateDate={updateAccountDate}
                dateKey={dateKey}
              />
            }
            trigger='click'
            placement='bottom'
          >
            <FontAwesomeIcon
              style={styles.buttonStyle}
              icon={faCalendarEdit}
              id={'analysis-date-popover'}
            />
          </PopoverTrigger>
          <Button variant='outline-danger' style={styles.cancelButton}>
            <FontAwesomeIcon
              style={{ fontSize: '18px', lineHeight: 'inherit' }}
              icon={faTimesCircle}
              onClick={() => resetDate()}
            />
          </Button>
        </div>
      </div>
    </Form.Group>
  );
}
