import React, { useEffect } from 'react';
import { Redirect } from 'react-router';

function Logout({ logoutUser }) {
  useEffect(() => {
    logoutUser();
  }, [logoutUser]);

  return (
    <>
      <Redirect to='/login' />
    </>
  );
}

export default Logout;
