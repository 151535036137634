import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import dayjs from 'dayjs';

import AnalysisYears from '../analysis/AnalysisYears';

export default function AdditionalAnalysisModal(props) {
  const {
    show,
    onHide,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    utilityType,
    regressionType,
    getPerformanceData,
    existingModels,
    regressionName,
    regressionId,
    regressionOptions,
    filterRegressionPieces,
    newRegressionId,
    setNewRegressionId,
    setNewRegressionName,
    ranRegressionEventCollection,
  } = props;

  let new_end_date = dayjs.utc(new Date()).endOf('month');
  new_end_date = new_end_date.month(new_end_date.month() - 1);
  let new_start_date = dayjs.utc(new Date()).startOf('month');
  new_start_date = new_start_date.month(new_start_date.month() - 12);

  const [localStartDate, setLocalStartDate] = useState(
    startDate ? startDate : new_start_date
  );
  const [localEndDate, setLocalEndDate] = useState(
    endDate ? endDate : new_end_date
  );

  const [localModelOptions, setLocalModelOptions] = useState('');
  const [localPiecesKey, setLocalPiecesKey] = useState(newRegressionId);

  useEffect(() => {
    setLocalStartDate(localEndDate.month(localEndDate.month() - 11));
  }, [localEndDate]);

  useEffect(() => {
    let temp = {};
    temp['-1'] = { name: 'None', value: '' };
    if (regressionName === '') {
      temp['0'] = {
        name: 'Unsaved Regression (Current)',
        value: [['constant', 'linear']].concat(
          filterRegressionPieces().map((piece) => [
            piece.regressor,
            piece.model,
          ])
        ),
        options: regressionOptions,
      };
    }
    existingModels.forEach((model) => {
      temp[model.id.toString()] = {
        name: regressionId === model.id ? model.name + '(Current)' : model.name,
        value: model.model_list,
        options: model.regression_options,
      };
    });
    setLocalModelOptions(temp);
  }, [
    existingModels,
    regressionName,
    filterRegressionPieces,
    regressionId,
    regressionOptions,
  ]);

  const confirmPerformanceData = () => {
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    setNewRegressionId(localPiecesKey);
    if (localPiecesKey !== '-1') {
      setNewRegressionName(localModelOptions[localPiecesKey].name);
    } else {
      setNewRegressionName('');
    }
    getPerformanceData(
      utilityType,
      regressionType,
      localStartDate.format('YYYY-MM-DD'),
      localEndDate.format('YYYY-MM-DD'),
      localModelOptions[localPiecesKey].value,
      localModelOptions[localPiecesKey].options,
      ranRegressionEventCollection
    );
    onHide();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size='lg'
        dialogClassName='et-sub-large-modal'
      >
        <Modal.Header closeButton style={{ height: '50px' }}>
          Select Performance Data
        </Modal.Header>
        <Modal.Body
          style={{
            overflowY: 'scroll',
            maxHeight: '65vh',
            marginBottom: '10px',
          }}
        >
          <AnalysisYears
            startDate={dayjs.utc(localStartDate)}
            setStartDate={setLocalStartDate}
            endDate={localEndDate}
            setEndDate={setLocalEndDate}
            utilityType={utilityType}
            regressionType={regressionType}
            getBaselineData={() => {}}
            clearRegression={() => {}}
            title={'Performance date range'}
            startDateSelect={false}
          />
          <Form>
            <Form.Label>Performance Model</Form.Label>
            <Form.Control
              as='select'
              size='sm'
              onChange={(e) => setLocalPiecesKey(e.target.value)}
              defaultValue={newRegressionId}
            >
              {Object.keys(localModelOptions).map((key) => (
                <option key={`key-opt-${key}`} value={key}>
                  {localModelOptions[key].name}
                </option>
              ))}
            </Form.Control>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type='button'
            variant='outline-danger'
            onClick={() => onHide()}
          >
            Cancel
          </Button>
          <Button variant='primary' onClick={() => confirmPerformanceData()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
