import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faPlusSquare,
  faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';

import { ApiServiceServerless } from '../../../xhr_libs';

import {
  fmtBoolExists,
  fmtLongString,
} from '../../../components/tables/formatters';
import {
  fmtPlugCredentialStatus,
  fmtPlugCredentialisActive,
} from '../../../components/tables/formatters/plug';

const styles = {
  buttonContainer: { width: '60%', textAlign: 'left', margin: 'auto' },
  buttonIcon: { marginRight: '.3rem', padding: '0px' },
};

export const getPlugCredentials = (
  setAllCredentials,
  setCredentialIds,
  organizationId,
  orgs,
  hiddenCredentialIds,
  setIsLoading,
  showToast
) => {
  setIsLoading(true);
  const payload = { orgs: orgs };
  ApiServiceServerless.post(
    `/plug/credentials/get/${organizationId}`,
    payload,
    {
      authorization_id: organizationId,
    }
  )
    .then(({ data }) => {
      const creds = data.map((cred) => ({
        id: cred.id,
        credentialId: cred.id,
        correlationId: cred.correlationId,
        status: {
          credentialStatus: cred.status,
          credentialStatusDetail: cred.statusDetail,
        },
        utilityProvider: cred.provider.name,
        username: cred.username,
        hidden: hiddenCredentialIds.includes(cred.id),
        isActive: cred.isActive,
        shared: cred.share,
        psuedo: false,
      }));
      setAllCredentials(creds);
      setCredentialIds(creds.map((cred) => cred.credentialId));
      setIsLoading(false);
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      setIsLoading(false);
      throw error;
    });
};

export const PLUG_CREDENTIAL_COLUMNS = [
  {
    dataField: 'utilityProvider',
    text: 'Utility Provider',
    formatter: fmtLongString,
  },
  {
    dataField: 'username',
    text: 'Associated Username',
    formatter: fmtLongString,
  },
  {
    dataField: 'status',
    text: 'Credential Status',
    formatter: fmtPlugCredentialStatus,
  },
  {
    dataField: 'isActive',
    text: 'Active',
    formatter: fmtPlugCredentialisActive,
  },
];

export const getPlugAccounts = (
  setPseudoCredentials,
  setProviderAccounts,
  organizationId,
  credentialIds,
  showToast,
  setIsLoading
) => {
  setIsLoading(true);
  ApiServiceServerless.post(
    `/plug/accounts`,
    {
      credentials: credentialIds,
      correlationId: organizationId,
    },
    { authorization_id: organizationId }
  )
    .then(({ data }) => {
      setProviderAccounts(handleParseAccounts(data.accounts));
      setPseudoCredentials(data.pseudoCredentials);
      setIsLoading(false);
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      setIsLoading(false);
      throw error;
    });
};

const handleParseAccounts = (accounts) => {
  let accounts_list = [];
  accounts.forEach((account) => {
    // check created at to make sure not in 5 minute delay for account deactivation. current backend timeout 5min current frontend timeout 10min
    let createdAt = dayjs.utc(account.createdAt);
    if ((dayjs.utc(new Date()) - createdAt) / 60 / 1000 > 10) {
      accounts_list.push({ ...parseAccount(account) });
    }
  });
  return accounts_list;
};

const parseAccount = (account) => {
  return {
    accountNumber: account.accountNumber,
    id: account.id,
    credentialId: account.credentialId,
    isStatementsProductActive: account.isStatementsProductActive,
    latestStatementDate: account.latestStatementDate,
    providerClassification: parseProviderClassification(
      account.providerClassification
    ),
    utilityProvider: account.provider.name,
    status: account.statusDetail,
    statusDetail: account.statusDetail,
    accountType: account.type,
    recent_url: `https://${
      process.env.REACT_APP_ENV === 'prod'
        ? ''
        : process.env.REACT_APP_ENV + '.'
    }api.web.energytracer.com/plug/get_recent_url/${account.id}`,
  };
};

const parseProviderClassification = (providerClassification) => {
  if (providerClassification === 'PUBLISHER') {
    return 'PRIMARY';
  }
  if (providerClassification === 'PASS_THROUGH') {
    return 'SECONDARY';
  }
  return '';
};

export const joinPlugAccountsToLocal = (
  providerAccounts,
  localUtilityAccounts,
  setProviderAccountsWithLocal
) => {
  const all_accounts = providerAccounts.map((acct) => ({
    ...acct,
    localAccount: localUtilityAccounts.find(
      (local) => local.plug_account_id === acct.id
    ),
  }));
  setProviderAccountsWithLocal(all_accounts);
};

export const updatePlugUsername = (
  params,
  credential,
  updateCredentialState,
  setAllCredentials,
  showToast
) => {
  ApiServiceServerless.post('/plug/credentials/update', params, {
    authorization_id: params.org_id,
  })
    .then(() => {
      updateCredentialState(
        setAllCredentials,
        credential.credentialId,
        'username',
        params.new_username
      );
      showToast('success', 'Success', 'Username was updated');
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      throw error;
    });
};

export const updatePlugPassword = (params, showToast) => {
  ApiServiceServerless.post('/plug/credentials/update', params, {
    authorization_id: params.org_id,
  })
    .then(() => {
      showToast('success', 'Success', 'Password was updated');
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      throw error;
    });
};

export const updatePlugAccount = (
  modalData,
  organizationId,
  data,
  newAccountId,
  setProviderAccounts,
  setLocalUtilityAccounts,
  setSubmitDisabled,
  showToast,
  onHide,
  setIsSaving
) => {
  let payload = {
    plugAccount: modalData.id,
    org_id: organizationId,
    credentialId: modalData.credentialId,
  };
  const local_account_id = modalData.localAccount
    ? modalData.localAccount.id
    : '';
  if (local_account_id.toString() !== newAccountId.toString()) {
    payload.linkedAccount = newAccountId;
  }
  if (modalData.isStatementsProductActive !== data.active) {
    payload.active = data.active;
  }

  ApiServiceServerless.post(`/plug/accounts/update`, payload, {
    authorization_id: organizationId,
  })
    .then((res) => {
      handleAccountReturn(
        res,
        modalData.id,
        payload.linkedAccount,
        'active' in payload
          ? payload.active
          : modalData.isStatementsProductActive,
        setProviderAccounts,
        setLocalUtilityAccounts
      );
      showToast(
        'success',
        'Success',
        'Account ' + modalData.accountNumber + ' updated.'
      );
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      throw error;
    })
    .finally(() => {
      onHide();
      setSubmitDisabled(false);
      setIsSaving(false);
    });
};

const handleAccountReturn = (
  res,
  provider_account_id,
  linkedAccount,
  active_status,
  setProviderAccounts,
  setLocalUtilityAccounts
) => {
  if (res.data.plug_status) {
    setProviderAccounts((prev) =>
      prev.map((account) => {
        if (account.id !== provider_account_id) return account;
        let editted_account = { ...account };
        if (res.data.plug_status.active) {
          editted_account.isStatementsProductActive = active_status;
        }
        return editted_account;
      })
    );
  }
  if (res.data.utility_account) {
    //maybe set new plug account id
    setLocalUtilityAccounts((prev) =>
      prev.map((account) => {
        if (
          account.plug_account_id !== res.data.utility_account.plug_account_id
        )
          return account;
        let new_account = { ...account };
        new_account.plug_account_id = null;
        return new_account;
      })
    );
    //maybe set new account
    setLocalUtilityAccounts((prev) =>
      prev.map((account) => {
        if (account.id !== parseInt(linkedAccount)) return account;
        return res.data.utility_account;
      })
    );
  }
};

export const getPlugAccountColumns = (handleOpenLinkModal, updateAccess) => {
  let plug_account_columns = [
    {
      dataField: 'accountNumber',
      text: 'Account Number',
    },
    {
      dataField: 'latestStatementDate',
      text: 'Latest Bill Date',
      formatter: (_cell, row) => {
        return (
          <div>
            {row.latestStatementDate}
            {row.latestStatementDate &&
              row.latestStatementDate !== '' &&
              row.recent_url && (
                <div style={{ display: 'inline-block' }}>
                  <a
                    href={row.recent_url}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ paddingLeft: '0.5em' }}
                  >
                    <FontAwesomeIcon
                      style={{
                        fontSize: '18px',
                      }}
                      icon={faDownload}
                    />
                  </a>
                </div>
              )}
          </div>
        );
      },
    },
    {
      dataField: 'isStatementsProductActive',
      text: 'Active',
      formatter: fmtBoolExists,
    },
    {
      dataField: 'localAccount',
      text: 'Linked',
      formatter: fmtBoolExists,
    },
    {
      dataField: 'id',
      text: 'Settings',
      formatter: (_cell, row) => {
        return updateAccess ? (
          <Button
            size='sm'
            variant='link'
            style={{ width: '100%', margin: '0px', padding: '0px' }}
            onClick={() => handleOpenLinkModal(row, 2)}
          >
            <div style={styles.buttonContainer}>
              <FontAwesomeIcon
                icon={row.localAccount ? faEdit : faPlusSquare}
                style={styles.buttonIcon}
              />
              {'Edit'}
            </div>
          </Button>
        ) : (
          ''
        );
      },
    },
  ];
  return plug_account_columns;
};

// use show secondary as a way of also showing summary accounts
export const filterPlugAccountByCredential = (
  credential,
  account,
  showSecondary
) => {
  return (
    account.credentialId === credential.credentialId &&
    (account.accountType !== 'SUMMARY' || showSecondary)
  );
};

export const handleSetNewPlugAccount = (
  newAccount,
  setLocalUtilityAccounts
) => {
  setLocalUtilityAccounts((prev) =>
    prev.map((account) => {
      if (account.plug_account_id !== newAccount.plug_account_id) {
        return account;
      }
      let new_account = { ...account };
      new_account.plug_account_id = null;
      return new_account;
    })
  );
  setLocalUtilityAccounts((prev) => [...prev, newAccount]);
};
