import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import AddEntityActionButtons from '../forms/AddEntityActionButtons';
import { ROLES, ROLE_NAMES_UPPER, ROLE_WEIGHTS } from '../../helpers/RolesData';

function InviteUserModal(props) {
  const {
    onHide,
    show,
    onSubmit,
    onUpdate,
    roleWeight,
    isAddingUser,
    setIsAddingUser,
  } = props;

  const [user, setUser] = useState({ email: '', role: ROLES[0] });
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsAddingUser(true);
    const form = e.currentTarget;
    if (form.checkValidity()) {
      onSubmit(user);
      setValidated(false);
      clearFields();
    } else {
      setValidated(true);
      setIsAddingUser(false);
    }
  };

  const handleUpdate = (field, value) => {
    let newState = { ...user };
    newState[field] = value;
    setUser(newState);
    if (onUpdate) onUpdate(user);
  };

  const clearFields = () => {
    setUser({ email: '', role: ROLES[0] });
  };

  const handleCancel = () => {
    clearFields();
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Invite New Member</Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Row>
                <Form.Group as={Col} controlId='formEmail'>
                  <Form.Label>
                    Email
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    data-id='invite-user_email'
                    required
                    type='email'
                    placeholder='email@example.com'
                    value={user.email}
                    onChange={(e) => handleUpdate('email', e.target.value)}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please provide a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId='formRole'>
                  <Form.Label>Role</Form.Label>
                  <Form.Control
                    data-id='invite-user_role'
                    as='select'
                    onChange={(e) => handleUpdate('role', e.target.value)}
                  >
                    {ROLES.map(
                      (role) =>
                        roleWeight >= ROLE_WEIGHTS[role] && (
                          <option key={role} value={role}>
                            {ROLE_NAMES_UPPER[role]}
                          </option>
                        )
                    )}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={handleCancel}
            isSubmitting={isAddingUser}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

InviteUserModal.propTypes = {
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  show: PropTypes.bool.isRequired,
};

export default InviteUserModal;
