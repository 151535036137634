import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useTabUrl(initialActiveKey) {
  const history = useHistory();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(initialActiveKey);

  const getHash = (hash) => hash.split('#')[1];

  // Update active tab to match hash (on-mount only)
  useEffect(() => {
    const hash = getHash(location.hash);
    if (hash) setActiveTab(hash);
    // eslint-disable-next-line
  }, []);

  // Update hash to match active tab
  useEffect(() => {
    const hash = getHash(location.hash);
    if (activeTab !== hash) {
      history.replace({ ...location, hash: activeTab });
    }
  }, [activeTab, history, location]);

  return [activeTab, setActiveTab];
}
