import React, { useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import { ApiServiceServerless } from '../../../../xhr_libs';

const styles = {
  duplicateMessage: {
    textAlign: 'center',
    marginTop: '20px',
  },
  dateList: {
    margin: '0 16px',
    color: '#DB2B39',
  },
};

export default function DuplicateCsvModal(props) {
  const {
    show,
    onHide,
    account,
    file,
    duplicateDates,
    handleSuccessToast,
    showToast,
    resetUpload,
    setIsLoading,
  } = props;

  const [overwriteDuplicates, setOverwriteDuplicates] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const uploadFileWithUpdates = (e) => {
    e.preventDefault();
    ApiServiceServerless.post(
      `/bill_upload/${account.id}/${overwriteDuplicates}`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        authorization_id: account.organization.id,
      }
    )
      .then((resp) => {
        setDisabled(false);
        handleSuccessToast(resp);
        onHide();
      })
      .catch((error) => {
        setDisabled(false);
        showToast('danger', 'Error', error);
        resetUpload();
        onHide();
        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const dateList = () => {
    return duplicateDates.length > 15
      ? '(' + duplicateDates.length + ' duplicates)'
      : duplicateDates.map((date, j) =>
          j < duplicateDates.length - 1
            ? dayjs.utc(date).format('M/D/YYYY') + ', '
            : dayjs.utc(date).format('M/D/YYYY')
        );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>Duplicate Uploads</Modal.Header>
      <Form onSubmit={uploadFileWithUpdates}>
        <Modal.Body>
          <Row style={styles.duplicateMessage}>
            <Col>
              Bills have already been uploaded for the following Read Dates:
            </Col>
          </Row>

          <hr />

          <Row>
            <Col>
              <p style={styles.dateList}>{dateList()}</p>
            </Col>
          </Row>

          <hr />
          <Row style={styles.duplicateMessage}>
            <Col>Would you like to overwrite them?</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Form.Group className='d-flex justify-content-end'>
            <Button
              type='submit'
              variant='outline-primary'
              className='mx-3'
              onClick={() => {
                setOverwriteDuplicates(false);
                setIsLoading(false);
              }}
              disabled={disabled}
            >
              No
            </Button>

            <Button
              type='submit'
              variant='primary'
              onClick={() => setOverwriteDuplicates(true)}
              disabled={disabled}
            >
              Yes
            </Button>
          </Form.Group>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
