import React, { useState, useEffect } from 'react';

import { ApiServiceServerless } from '../../xhr_libs';
import NavigationAlert from '../../components/analysis/NavigationAlert';

export default function CheckResources(props) {
  const { dashboardData, organization } = props;
  const [statusState, setStatusState] = useState(0);

  const get_dashboard_status = (org_id) => {
    ApiServiceServerless.get(`/dashboard/status/${org_id}`, {
      authorization_id: org_id,
    }).then((res) => {
      const data = res.data;
      if (data.buildings === 0) {
        setStatusState(2);
      } else if (data.accounts === 0) {
        setStatusState(3);
      } else {
        setStatusState(4);
      }
    });
  };

  useEffect(() => {
    if (dashboardData['month_array'].length === 0) {
      if (organization.id) {
        get_dashboard_status(organization.id);
      } else {
        setStatusState(1);
      }
    } else {
      setStatusState(0);
    }
  }, [dashboardData, organization]);

  switch (statusState) {
    case 1:
      return (
        <NavigationAlert
          mainText={'It looks like you do not have any organizations yet.'}
          buttonText={'New Organization'}
          location={'/portfolios'}
        />
      );
    case 2:
      return (
        <NavigationAlert
          mainText={
            'It looks like this organization does not have any buildings yet.'
          }
          buttonText={'New Building'}
          location={'/resources'}
        />
      );
    case 3:
      return (
        <NavigationAlert
          mainText={
            'It looks like this organization does not have any utility accounts yet.'
          }
          buttonText={'New Account'}
          location={'/resources'}
        />
      );
    case 4:
      return (
        <NavigationAlert
          mainText={'No utility bills have been found.'}
          buttonText={'Check Bills'}
          location={'/resources'}
        />
      );
    default:
      return <></>;
  }
}
