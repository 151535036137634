import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import EditEntityActionButtons from '../forms/EditEntityActionButtons';
import { ApiServiceServerless } from '../../xhr_libs';

export default function AddConversionCollectionModal(props) {
  const {
    show,
    onHide,
    collection,
    organization_id,
    GHGConversions,
    updateList,
    showToast,
  } = props;

  const [conversionId, setConversionId] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const clearForm = () => {
    setConversionId('');
  };

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();
    ApiServiceServerless.post(
      `/custom_ghg_conversions/collection_conversion/${parseInt(
        collection.id
      )}/${conversionId}`,
      { org_id: organization_id },
      { authorization_id: organization_id }
    )
      .then((res) => {
        updateList(res.data[0], 'ghg_collections');
        updateList(res.data[1], 'ghg_conversions');
        onHide();
        clearForm();
        setIsSaving(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        onHide();
        setIsSaving(false);
        throw err;
      });
  };

  const handleCancel = () => {
    onHide();
    clearForm();
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          {'Add Conversion to Collection'}
        </Modal.Header>
        <Form
          noValidate
          onSubmit={handleSubmit}
          style={{
            textAlign: 'left',
          }}
        >
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>GHG Conversion </Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) => setConversionId(parseInt(e.target.value))}
                  >
                    <option key={'null-val'} value={''} hidden></option>
                    {GHGConversions.filter(
                      (conversion) =>
                        !collection.conversion_ids.includes(conversion.id)
                    ).length === 0 && (
                      <option key={'null-null'} value={''} disabled>
                        No available conversions
                      </option>
                    )}
                    {GHGConversions.filter(
                      (conversion) =>
                        !collection.conversion_ids.includes(conversion.id)
                    ).map((conversion) => (
                      <option key={conversion.id} value={conversion.id}>
                        {conversion.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <EditEntityActionButtons
              onCancel={handleCancel}
              submitDisabled={conversionId === ''}
              submitShow={true}
              isSubmitting={isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
