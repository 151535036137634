import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Row, Col, Card, Tab, Nav } from 'react-bootstrap';
import { cloneDeep, orderBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownAZ,
  faCalendarArrowDown,
} from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import BillList from '../../../components/bills/BillList';
import EditBillsFooter from '../../../components/bills/EditBillsFooter';

import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import BillsLoader from './BillsLoader';
import BillFilter from '../../../components/bills/BillFilter';
import CHARTCOLORS from '../../../helpers/chartColors';

const styles = { tab_style: { marginTop: 0 } };

const PAYLOAD_KEYS = [
  'bill_date',
  'consumption1_value',
  'consumption2_value',
  'cost1_value',
  'cost2_value',
  'cost3_value',
  'demand1_value',
  'demand2_value',
  'is_estimate',
  'read_date',
  'start_date',
];

export default function ManageBills(props) {
  const {
    type,
    account,
    allBills,
    aggregateBills,
    isLoadingAccount,
    isLoadingBills,
    showToast,
    createAccess,
    updateAccess,
    deleteAccess,
    setIsSaving,
    isSaving,
    resetBillFlag,
    handleResetBills,
    handleRefreshLocalBills,
    handleDeleteFromLocalBills,
    validateBill,
    handleNewMaxDate,
  } = props;

  const editedBills = useRef({});

  const [showSaveBar, setShowSaveBar] = useState(false);
  const [hasAdded, setHasAdded] = useState(false);
  const [hasSavedMultiple, setHasSavedMultiple] = useState(0);

  const [filterType, setFilterType] = useState(0);
  const [subAccountsByDate, setSubAccountsByDate] = useState([]);
  const [subAccountsByName, setSubAccountsByName] = useState([]);
  const [filteredSubAccounts, setFilteredSubAccounts] = useState([]);

  const [confirmationModalProps, withConfirm] = useConfirm();
  const [confirmText, setConfirmText] = useState('');
  const [submitVariant, setSubmitVariant] = useState('');

  useEffect(() => {
    if (filterType === 0) {
      setFilteredSubAccounts(subAccountsByDate);
    }
    if (filterType === 1) {
      setFilteredSubAccounts(subAccountsByName);
    }
  }, [subAccountsByDate, subAccountsByName, filterType]);

  const addToSaveBills = useCallback(
    (bill) => {
      if (bill.id) {
        setHasAdded(true);
        editedBills.current[bill.id] = bill;
      }
    },
    [setHasAdded]
  );

  useEffect(() => {
    if (!showSaveBar && hasAdded) setShowSaveBar(true);
  }, [hasAdded, showSaveBar]);

  const removeFromSaveBills = useCallback((id) => {
    let localSaveBills = cloneDeep(editedBills.current);
    if (id in localSaveBills) {
      delete localSaveBills[id];
      editedBills.current = localSaveBills;
      if (!Object.keys(localSaveBills).length) {
        setHasAdded(false);
        setShowSaveBar(false);
      }
    }
  }, []);

  const formatPayload = useCallback(
    (bill) => {
      let payload = {};

      const old_bill = allBills.find((old_bill) => old_bill.id === bill.id);

      PAYLOAD_KEYS.forEach((key) => {
        if (bill[key] !== old_bill[key]) {
          payload[key] = bill[key];
        }
      });
      return payload;
    },
    [allBills]
  );

  const saveBill = useCallback(
    (data, exit) => {
      setIsSaving(true);
      const payload = formatPayload(data);

      ApiServiceServerless.post(`bills/${data.id}`, payload, {
        authorization_id: account.id,
      })
        .then((res) => {
          handleRefreshLocalBills([res.data]);
          handleNewMaxDate({
            account_id: account.id,
            new_max_date: res.data.read_date,
          });
          const message = 'Bill has been updated.';
          showToast('success', 'Success', message);

          removeFromSaveBills(data.id);
          setIsSaving(false);
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          handleResetBills();
          removeFromSaveBills(data.id);
          setIsSaving(false);
          throw error;
        });
    },
    [
      account.id,
      handleRefreshLocalBills,
      showToast,
      removeFromSaveBills,
      setIsSaving,
      handleResetBills,
      handleNewMaxDate,
      formatPayload,
    ]
  );

  const saveEditedBills = () => {
    let good_bills = true;
    Object.values(editedBills.current).forEach((bill) => {
      good_bills = good_bills && validateBill(bill);
    });
    if (good_bills) {
      let payload = {};
      Object.keys(editedBills.current).forEach((bill_id) => {
        payload[bill_id] = formatPayload(editedBills.current[bill_id]);
      });

      setIsSaving(true);
      ApiServiceServerless.post(`bills/bulk_update/${account.id}`, payload, {
        authorization_id: account.id,
      })
        .then((res) => {
          const message = 'Bills have been updated.';
          showToast('success', 'Success', message);
          editedBills.current = {};
          setHasAdded(false);
          setShowSaveBar(false);
          setHasSavedMultiple(hasSavedMultiple + 1);
          setIsSaving(false);
          handleRefreshLocalBills(res.data);
          res.data.forEach((bill) => {
            handleNewMaxDate({
              account_id: account.id,
              new_max_date: bill.read_date,
            });
          });
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          setIsSaving(false);
          handleResetBills();
          editedBills.current = {};
          setHasAdded(false);
          setShowSaveBar(false);
          throw error;
        });
    }
  };

  const handleDeleteBill = useCallback(
    (id) => {
      const msg = 'Are you sure you want to delete this bill?';
      const deleteBill = () => {
        setIsSaving(true);
        ApiServiceServerless.delete(`/bills/${id}`, {
          authorization_id: account.id,
        })
          .then(() => {
            removeFromSaveBills(id);
            handleDeleteFromLocalBills(id);
            showToast('success', 'Success', 'Bill has been deleted');
            setIsSaving(false);
          })
          .catch((error) => {
            showToast('danger', 'Error', error);
            setIsSaving(false);
            throw error;
          });
      };

      setConfirmText('Delete');
      setSubmitVariant('danger');
      withConfirm(msg, deleteBill);
    },
    [
      account.id,
      showToast,
      withConfirm,
      handleDeleteFromLocalBills,
      removeFromSaveBills,
      setIsSaving,
    ]
  );

  const handlePauseAutomation = (bill) => {
    setIsSaving(true);
    ApiServiceServerless.post(
      `bills/${bill.id}/pause`,
      { pause_automation: !bill.pause_automation },
      {
        authorization_id: account.id,
      }
    )
      .then((res) => {
        handleRefreshLocalBills([res.data]);
        showToast('success', 'Success', 'Automation status updated');
        setIsSaving(false);
      })
      .catch((error) => {
        setIsSaving(false);
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  const handleSplitProviderBill = (provider_bill_id) => {
    const msg =
      'Warning! You are about to split a merged bill. This action cannot be undone and should only be done for bills with mutiple dates included on the statement. Please make sure you under this feature before using it by contacting an administrator for help.';
    setConfirmText('Continue');
    setSubmitVariant('success');
    withConfirm(msg, () => {
      setIsSaving(true);

      ApiServiceServerless.post(
        `bills/${provider_bill_id}/split`,
        {},
        {
          authorization_id: account.id,
        }
      )
        .then(() => {
          showToast(
            'success',
            'Success',
            'Splitting Bill. This will take a few minutes. Please refresh bill list to pull changes'
          );
          setIsSaving(false);
        })
        .catch((error) => {
          setIsSaving(false);
          showToast('danger', 'Error', error);
          throw error;
        });
    });
  };

  useEffect(() => {
    let sub_accounts_by_name = [];
    let sub_accounts_by_date = [];
    // has multiple sub account OR has more than one masked sub account pointing to it
    if (
      (account.sub_accounts.length > 0 ||
        account.parent_mask_sub_accounts.length > 0) &&
      allBills.length > 0
    ) {
      let subAccounts = {};
      account.sub_accounts.forEach((sub) => {
        const meter_id = sub.provider_meter_id;
        subAccounts[meter_id] = {
          ...sub,
          max_year: 0,
          max_month: 0,
        };
      });
      if (account.parent_mask_sub_accounts.length > 0) {
        account.parent_mask_sub_accounts.forEach((sub) => {
          const meter_id = sub.provider_meter_id;
          subAccounts[meter_id] = {
            ...sub,
            max_year: 0,
            max_month: 0,
          };
        });
      }
      const available_meter_ids = Object.keys(subAccounts);

      sub_accounts_by_name = Object.values(subAccounts);
      sub_accounts_by_date = Object.values(subAccounts);

      allBills.forEach((bill) => {
        if (available_meter_ids.includes(bill.provider_meter_id)) {
          let new_date = new Date(bill.read_date);
          if (
            new_date.getFullYear() >
            subAccounts[bill.provider_meter_id]['max_year']
          ) {
            subAccounts[bill.provider_meter_id]['max_year'] =
              new_date.getFullYear();
            if (
              new_date.getMonth() >
              subAccounts[bill.provider_meter_id]['max_month']
            ) {
              subAccounts[bill.provider_meter_id]['max_month'] =
                new_date.getMonth();
            }
          }
        }
      });

      sub_accounts_by_name = orderBy(subAccounts, ['name']);
      sub_accounts_by_date = orderBy(
        subAccounts,
        ['max_year', 'max_month'],
        ['desc', 'desc']
      );
    }
    setSubAccountsByDate(sub_accounts_by_date);
    setSubAccountsByName(sub_accounts_by_name);
  }, [allBills, account]);

  return (
    <>
      <ConfirmationModal
        {...confirmationModalProps}
        confirmText={confirmText}
        submitVariant={submitVariant}
      />

      <Card.Body>
        {isLoadingAccount ? (
          <BillsLoader />
        ) : (
          <>
            <>
              {filteredSubAccounts.length === 0 ? (
                <Row style={{ justifyContent: 'center', minHeight: '300px' }}>
                  <Col sm={10}>
                    <BillList
                      type={type}
                      account={account}
                      defaultConsumptionUnit={account.default_consumption_unit}
                      allBills={allBills}
                      resetBillFlag={resetBillFlag}
                      addToSaveBills={addToSaveBills}
                      removeFromSaveBills={removeFromSaveBills}
                      hasSavedMultiple={hasSavedMultiple}
                      deleteBill={handleDeleteBill}
                      saveBill={saveBill}
                      handlePauseAutomation={handlePauseAutomation}
                      handleSplitProviderBill={handleSplitProviderBill}
                      validateBill={validateBill}
                      isTotal={false}
                      createAccess={createAccess}
                      updateAccess={updateAccess}
                      deleteAccess={deleteAccess}
                      isSaving={isSaving}
                      isLoadingBills={isLoadingBills}
                    />
                  </Col>
                </Row>
              ) : (
                <Row style={{ justifyContent: 'center', minHeight: '300px' }}>
                  <Col>
                    <Tab.Container
                      defaultActiveKey={
                        filteredSubAccounts.length > 0
                          ? filteredSubAccounts[0].id
                          : 'manual'
                      }
                      id='sub-account-tabs'
                    >
                      <Row>
                        <Col
                          md={2}
                          style={{
                            maxHeight: '500px',
                            overflowY: 'auto',
                            paddingRight: '0',
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              fontSize: '20px',
                              marginLeft: '0.5em',
                              color:
                                filterType === 0
                                  ? CHARTCOLORS[1]
                                  : CHARTCOLORS[2],
                              cursor: 'pointer',
                            }}
                            icon={faCalendarArrowDown}
                            onClick={() => setFilterType(0)}
                            title='Sort By Bill Date'
                          />
                          <FontAwesomeIcon
                            style={{
                              fontSize: '20px',
                              marginLeft: '0.5em',
                              color:
                                filterType === 1
                                  ? CHARTCOLORS[1]
                                  : CHARTCOLORS[2],
                              cursor: 'pointer',
                            }}
                            icon={faArrowDownAZ}
                            onClick={() => setFilterType(1)}
                            title='Sort Alphabetically'
                          />
                          <Nav variant='pills' className='flex-column'>
                            <Nav.Item className='et-bill-tab'>
                              <Nav.Link eventKey='total'>Total</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='et-bill-tab'>
                              <Nav.Link eventKey='filter'>Filter</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='et-bill-tab'>
                              <Nav.Link eventKey='manual'>Manual</Nav.Link>
                            </Nav.Item>
                            {filteredSubAccounts.map((data) => (
                              <Nav.Item
                                key={`tab-${data.id}`}
                                className='et-bill-tab'
                              >
                                <Nav.Link eventKey={data.id}>
                                  {data.name}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                        </Col>
                        <Col md={10} style={{ paddingLeft: '2em' }}>
                          <Tab.Content style={styles.tab_style}>
                            {' '}
                            <Tab.Pane eventKey={'manual'} key={'tab-manual'}>
                              <BillList
                                type={type}
                                account={account}
                                defaultConsumptionUnit={
                                  account.default_consumption_unit
                                }
                                allBills={allBills.filter(
                                  (bill) => bill.provider_meter_id === 'none'
                                )}
                                resetBillFlag={resetBillFlag}
                                addToSaveBills={addToSaveBills}
                                removeFromSaveBills={removeFromSaveBills}
                                hasSavedMultiple={hasSavedMultiple}
                                deleteBill={handleDeleteBill}
                                saveBill={saveBill}
                                handlePauseAutomation={handlePauseAutomation}
                                handleSplitProviderBill={
                                  handleSplitProviderBill
                                }
                                validateBill={validateBill}
                                isTotal={false}
                                createAccess={createAccess}
                                updateAccess={updateAccess}
                                deleteAccess={deleteAccess}
                                isSaving={isSaving}
                                isLoadingBills={isLoadingBills}
                              />
                            </Tab.Pane>
                            {filteredSubAccounts.map(
                              (data) =>
                                allBills.filter(
                                  (bill) =>
                                    bill.provider_meter_id ===
                                    data.provider_meter_id
                                ).length > 0 && (
                                  <Tab.Pane
                                    eventKey={data.id}
                                    key={`tab-${data.id}`}
                                  >
                                    <BillList
                                      type={type}
                                      account={account}
                                      allBills={allBills.filter(
                                        (bill) =>
                                          bill.provider_meter_id ===
                                          data.provider_meter_id
                                      )}
                                      resetBillFlag={resetBillFlag}
                                      addToSaveBills={addToSaveBills}
                                      removeFromSaveBills={removeFromSaveBills}
                                      hasSavedMultiple={hasSavedMultiple}
                                      deleteBill={handleDeleteBill}
                                      saveBill={saveBill}
                                      handlePauseAutomation={
                                        handlePauseAutomation
                                      }
                                      handleSplitProviderBill={
                                        handleSplitProviderBill
                                      }
                                      validateBill={validateBill}
                                      isTotal={false}
                                      createAccess={createAccess}
                                      updateAccess={updateAccess}
                                      deleteAccess={deleteAccess}
                                      isSaving={isSaving}
                                      isLoadingBills={isLoadingBills}
                                    />
                                  </Tab.Pane>
                                )
                            )}
                            <Tab.Pane eventKey={'filter'} key={'tab-filter'}>
                              <BillFilter
                                type={type}
                                allBills={allBills}
                                addToSaveBills={addToSaveBills}
                                removeFromSaveBills={removeFromSaveBills}
                                hasSavedMultiple={hasSavedMultiple}
                                deleteBill={handleDeleteBill}
                                saveBill={saveBill}
                                handlePauseAutomation={handlePauseAutomation}
                                handleSplitProviderBill={
                                  handleSplitProviderBill
                                }
                                validateBill={validateBill}
                                isTotal={false}
                                createAccess={createAccess}
                                updateAccess={updateAccess}
                                deleteAccess={deleteAccess}
                                isSaving={isSaving}
                                isLoadingBills={isLoadingBills}
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey={'total'} key={'tab-total'}>
                              <BillList
                                type={type}
                                account={account}
                                allBills={aggregateBills}
                                resetBillFlag={resetBillFlag}
                                addToSaveBills={addToSaveBills}
                                removeFromSaveBills={removeFromSaveBills}
                                hasSavedMultiple={hasSavedMultiple}
                                deleteBill={handleDeleteBill}
                                saveBill={saveBill}
                                handlePauseAutomation={handlePauseAutomation}
                                handleSplitProviderBill={
                                  handleSplitProviderBill
                                }
                                validateBill={validateBill}
                                isTotal={true}
                                createAccess={createAccess}
                                updateAccess={updateAccess}
                                deleteAccess={deleteAccess}
                                isSaving={isSaving}
                                isLoadingBills={isLoadingBills}
                              />
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Col>
                </Row>
              )}
            </>
          </>
        )}
      </Card.Body>

      {showSaveBar && (
        <EditBillsFooter
          saveEditedBills={saveEditedBills}
          isSaving={isSaving}
        />
      )}
    </>
  );
}
