import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';

const styles = {
  inputStyle: {
    width: '240px',
  },
  colStyle: {
    maxWidth: '300px',
  },
};

export default function DataSetSelection(props) {
  const {
    organization,
    buildings,
    availableUtilities,
    defaultPerformanceProject,
    building,
    utilityType,
    regressionType,
    setBuilding,
    setUtilityType,
    setRegressionType,
    getBaselineData,
    setBaselineData,
    resetPage,
    setStartDate,
    setEndDate,
    setDefaultStartDate,
    defaultStartDate,
    setDefaultEndDate,
    defaultEndDate,
    localExistingModels,
    regressionEvaluation,
    regressionName,
  } = props;

  const [utilityTypes, setUtilityTypes] = useState([]);

  useEffect(() => {
    if (availableUtilities) {
      let utilities = availableUtilities.filter((type) =>
        ['electric', 'gas'].includes(type)
      );
      utilities = utilities.map(
        (utility) =>
          utility.charAt(0).toUpperCase() + utility.substr(1).toLowerCase()
      );
      setUtilityTypes(utilities);
    }
  }, [availableUtilities]);

  const setDates = useCallback(
    (project) => {
      let startDate = '';
      let endDate = '';
      if (project && project.savings_baseline_start_date) {
        startDate = dayjs.utc(project.savings_baseline_start_date);
        endDate = startDate;
        endDate = endDate.year(endDate.year() + 1);
        endDate = endDate.day(endDate.day() - 1);
      }

      setDefaultStartDate(startDate);
      setDefaultEndDate(endDate);
      setStartDate(startDate);
      setEndDate(endDate);
    },
    [setDefaultStartDate, setDefaultEndDate, setStartDate, setEndDate]
  );

  useEffect(() => {
    setDates(defaultPerformanceProject);
  }, [
    setDates,
    defaultPerformanceProject,
    organization,
    building,
    utilityType,
    regressionType,
  ]);

  return (
    <Row>
      <Col style={styles.colStyle}>
        <Form.Group>
          <Form.Control
            style={styles.inputStyle}
            as='select'
            onChange={(e) => {
              resetPage('building');
              setBuilding(e.target.value);
            }}
            value={building}
          >
            <option key={'building-opt-select'} value={''} hidden>
              Select Building
            </option>
            <option
              key={'building-opt-null'}
              value={''}
              hidden={buildings.length}
              disabled
            >
              No Buildings
            </option>
            {sortBy(buildings, ['name']).map((building) => (
              <option key={`building-opt-${building.id}`} value={building.id}>
                {building.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      <Col style={styles.colStyle}>
        <Form.Group>
          <Form.Control
            style={styles.inputStyle}
            as='select'
            onChange={(e) => {
              resetPage('utility');
              setUtilityType(e.target.value.split(',')[0]);
              setRegressionType(e.target.value.split(',')[1]);
              e.target.value === ''
                ? setBaselineData({})
                : getBaselineData(
                    e.target.value.split(',')[0],
                    e.target.value.split(',')[1],
                    defaultStartDate,
                    defaultEndDate
                  );
            }}
            value={utilityType + ',' + regressionType}
            disabled={!building}
          >
            <option key={'utilityType-opt-select'} value={''} hidden>
              Select Utility Type
            </option>
            <option
              key={'utilityType-opt-null'}
              value={''}
              hidden={utilityTypes.length}
              disabled
            >
              No Utility Accounts
            </option>
            {utilityTypes.map((utilityType) => (
              <option
                key={`utilityType-opt-${utilityType.toLowerCase()}-consumption`}
                value={utilityType.toLowerCase() + ',consumption'}
              >
                {utilityType}
              </option>
            ))}
            {utilityTypes.map((utilityType) => (
              <option
                hidden={utilityType !== 'Electric'}
                key={`utilityType-opt-${utilityType.toLowerCase()}-demand`}
                value={utilityType.toLowerCase() + ',demand'}
              >
                {utilityType + ' Demand'}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Col>
      {localExistingModels.length > 0 && (
        <Col>
          {regressionEvaluation.length > 0 ? (
            <>
              {' '}
              <>Curent Model:</>{' '}
              <>{regressionName ? regressionName : 'Unsaved Model'}</>
            </>
          ) : (
            ''
          )}
        </Col>
      )}
    </Row>
  );
}
