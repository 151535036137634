import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import EnergyTracerTable from '../../tables';
import CHARTCOLORS from '../../../helpers/chartColors';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
    textAlign: 'center',
  },
};

export default function BillDiscrepancyModal(props) {
  const { show, onHide, alert } = props;

  const [formattedAlert, setFormattedAlert] = useState([]);

  useEffect(() => {
    let temp_alert = [];
    const keys = ['1', '2', '3'];
    keys.forEach((key) => {
      temp_alert.push({
        name: 'Method ' + key,
        alert: alert['method' + key],
        peer: !alert['method' + key + '_peer'] && alert['method' + key],
        self: !alert['is_missing'] && alert['method' + key],
      });
    });

    setFormattedAlert(temp_alert);
  }, [alert]);

  const scoreFormatter = (cell, double) => {
    let val = 0;
    if (cell) {
      val += 1;
    }
    if (cell && double) {
      val += 1;
    }
    if (val > 0) {
      return <div style={{ color: 'green' }}>{val}</div>;
    } else {
      return <div style={{ color: 'red' }}>{val}</div>;
    }
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Method',
      style: styles.column,
    },
    {
      dataField: 'alert',
      text: 'Alert',
      style: styles.column,
      formatter: (cell) => scoreFormatter(cell, true),
    },
    {
      dataField: 'peer',
      text: 'Peer Integrity',
      style: styles.column,
      formatter: (cell) => scoreFormatter(cell, false),
    },
    {
      dataField: 'self',
      text: 'Self Integrity',
      style: styles.column,
      formatter: (cell) => scoreFormatter(cell, false),
    },
  ];

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        {' '}
        Bill Discrepency Alert{' '}
        <div
          style={{
            cursor: 'pointer',
            display: 'inline-block',
            marginLeft: '0.5em',
          }}
          onClick={() =>
            window.open(
              'https://support.veregy.com/article/251-alerts',
              '_blank'
            )
          }
        >
          <FontAwesomeIcon
            style={{ fontSize: '18px', color: CHARTCOLORS[1] }}
            icon={faInfoCircle}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <EnergyTracerTable
          data={formattedAlert}
          columns={tableColumns}
          keyField={'name'}
        />
        <>
          Each method compares the cost per consumption unit of the bill in
          question to a test case from other bills associated with that
          building.<br></br>
          <u>Method 1 –</u> Bill from the same time the previous year<br></br>
          <u>Method 2 –</u> Average of the previous 6 bills <br></br>
          <u>Method 3 –</u> Bill with the closest consumption value within the
          last 12 months
        </>
      </Modal.Body>
    </Modal>
  );
}
