import React, { useState } from 'react';

import { Form, Row, Col, Modal } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

export default function AddCustomGHGCollectionModal(props) {
  const {
    show,
    onHide,
    showToast,
    organization_id,
    addToList,
    setActiveCollectionId,
  } = props;

  const [name, setName] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const saveGHGCollection = () => {
    setIsLoading(true);
    ApiServiceServerless.post(
      `/custom_ghg_conversions/collection`,
      {
        name: name,
        org_id: organization_id,
      },
      { authorization_id: organization_id }
    )
      .then((res) => {
        showToast('success', 'Success', 'New  GHG Collection Created');
        addToList(res.data, 'ghg_collections');
        setActiveCollectionId(res.data.id);
      })
      .catch(() => {
        showToast('danger', 'Error', 'Creation Error');
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  const saveDisabled = name === null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div as='h5'>Create a GHG Collection</div>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>
                Name
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                required
                placeholder=''
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          onSubmit={saveGHGCollection}
          submitDisabled={saveDisabled}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
