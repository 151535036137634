import { useContext } from 'react';
import AppContext from '../contexts/app-context';
import { ROLE_WEIGHTS, REVERSE_ROLE_WEIGHTS } from './RolesData';

//organization required for get_portfolio_roles() in portfolios field
export function useOrganizationRolesWeight(organization) {
  const { userPortfolios, userSelectedPortfolio } = useContext(AppContext);

  //in case things break always return true and backend will handle auth checks
  if (!organization) {
    return 1;
  }

  const portfolioOrgnaizationRole = getOrganizationPortfolioRole(
    organization,
    userPortfolios,
    userSelectedPortfolio
  );

  return Math.max(
    ROLE_WEIGHTS[organization.role],
    ROLE_WEIGHTS[portfolioOrgnaizationRole]
  );
}

//organization required for get_portfolio_roles() in portfolios field
export function useOrganizationRoles(organization, resource, action) {
  const { rolesObject, userPortfolios, userSelectedPortfolio } =
    useContext(AppContext);

  const portfolioOrgnaizationRole = getOrganizationPortfolioRole(
    organization,
    userPortfolios,
    userSelectedPortfolio
  );

  //in case things break always return true and backend will handle auth checks
  if (!organization || !rolesObject || !rolesObject[resource]) {
    return true;
  }

  // actual auth check
  if (
    rolesObject[resource][action].includes(organization.role) ||
    rolesObject[resource][action].includes(portfolioOrgnaizationRole)
  ) {
    return true;
  } else {
    return false;
  }
}

export function usePortfolioRoles(portfolioId, resource, action) {
  const { userPortfolios, rolesObject } = useContext(AppContext);

  const portfolio = userPortfolios.find((org) => org.id === portfolioId);

  //in case things break always return true and backend will handle auth checks
  if (!portfolio || !rolesObject || !rolesObject[resource]) {
    return true;
  }
  // actual auth check
  if (rolesObject[resource][action].includes(portfolio.role)) {
    return true;
  } else {
    return false;
  }
}

export function usePortfolioRolesWeight(portfolioId) {
  const { userPortfolios } = useContext(AppContext);

  const portfolio = userPortfolios.find((org) => org.id === portfolioId);

  //in case things break always return true and backend will handle auth checks
  if (!portfolio) {
    return 1;
  }

  return ROLE_WEIGHTS[portfolio.role];
}

export function getOrganizationPortfolioRole(
  organization,
  userPortfolios,
  userSelectedPortfolio
) {
  const portfolioId = userSelectedPortfolio.id;
  let roleWeight = 0;
  if (parseInt(portfolioId) === -1) {
    userPortfolios.forEach((portfolio) => {
      if (
        organization &&
        organization.portfolios &&
        portfolio.id in organization.portfolios
      ) {
        const temp_weight = Math.min(
          ROLE_WEIGHTS[organization.portfolios[portfolio.id]],
          ROLE_WEIGHTS[portfolio.role]
        );
        if (temp_weight > roleWeight) {
          roleWeight = temp_weight;
        }
      }
    });
    return REVERSE_ROLE_WEIGHTS[roleWeight];
  } else {
    if (
      organization &&
      organization.portfolios &&
      userSelectedPortfolio.id in organization.portfolios
    ) {
      const portfolio = userPortfolios.find((org) => org.id === portfolioId);
      return REVERSE_ROLE_WEIGHTS[
        Math.min(
          ROLE_WEIGHTS[organization.portfolios[portfolioId]],
          ROLE_WEIGHTS[portfolio.role]
        )
      ];
    }
  }
}
