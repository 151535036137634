import React from 'react';

import etLogo from '../../img/et-logo-new-cropped.svg';
import { Link } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';

const styles = {
  ha: {
    padding: '0.5em',
    paddingTop: '0.75em',
    color: 'grey',
    border: 'none',
    background: 'none',
    fontSize: '16px',
    marginLeft: '1em',
    marginRight: '1em',
    display: 'inline-block',
  },
  hb: {
    margin: '0.5em 1em',
    fontSize: '16px',
    width: '100px',
    color: 'white',
  },
  hr0: {
    backgroundColor: 'rgb(248,248,248)',
    width: '100%',
    position: 'sticky',
    zIndex: '1000',
    top: '0',
    marginLeft: '0',
    borderBottom: 'solid 0.5px lightgrey',
    padding: '0.25em 1em',
  },
};

export default function HomeBar() {
  return (
    <>
      <Navbar expand='md' style={styles.hr0} collapseOnSelect>
        <Container style={{ maxWidth: '80%' }}>
          <Navbar.Brand
            className='mr-auto'
            to='/'
            as={Link}
            data-id='brand_home'
            style={{ padding: 0 }}
          >
            <img
              src={etLogo}
              alt='blockLogo'
              style={{
                height: '55px',
                paddingBottom: '5px',
                paddingLeft: '10px',
              }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ml-auto'>
              <Nav.Link
                href={'https://veregy.com/utility-monitoring-analytics/'}
                target='_blank'
                style={styles.ha}
                className='et-non-active-anchor'
              >
                About
              </Nav.Link>

              <Nav.Link
                href={'https://support.veregy.com/section/101-energytracer/'}
                target='_blank'
                style={styles.ha}
                className='et-non-active-anchor'
              >
                Documentation
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
