import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { get } from 'lodash';

import { ApiServiceServerless } from '../../../xhr_libs';

import {
  fmtUrjanetCredentialStatus,
  fmtUrjanetCredentialLastSuccess,
} from '../../../components/tables/formatters/urjanet';
import {
  fmtUSD,
  fmtBoolYesNo,
  fmtBoolExists,
  fmtLongString,
} from '../../../components/tables/formatters';

const styles = {
  buttonContainer: { width: '60%', textAlign: 'left', margin: 'auto' },
  buttonIcon: { marginRight: '.3rem', padding: '0px' },
};

export const getUrjanetCredentials = (
  setAllCredentials,
  setCredentialIds,
  organizationId,
  orgs,
  hiddenCredentialIds,
  setIsLoading,
  showToast
) => {
  setIsLoading(true);
  const payload = { orgs: orgs };
  ApiServiceServerless.post(`/urjanet/credentials/${organizationId}`, payload, {
    authorization_id: organizationId,
  })
    .then(({ data }) => {
      const creds = data.map((cred) => ({
        id: cred.id,
        credentialId: cred.extractionChannelId,
        correlationId: cred.correlationId,
        status: {
          credentialStatus: cred.credentialStatus,
          credentialStatusDetail: cred.credentialStatusDetail,
        },
        utilityProvider: cred.utilityProvider.naturalKey,
        username: cred.username,
        hidden: hiddenCredentialIds.includes(cred.extractionChannelId),
        lastSuccess: cred.lastSuccess,
        shared: cred.share,
      }));
      setAllCredentials(creds);
      setCredentialIds(creds.map((cred) => cred.credentialId));
      setIsLoading(false);
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      setIsLoading(false);
      throw error;
    });
};

export const URJANET_CREDENTIAL_COLUMNS = [
  {
    dataField: 'utilityProvider',
    text: 'Utility Provider',
    formatter: fmtLongString,
  },
  {
    dataField: 'username',
    text: 'Associated Username',
    formatter: fmtLongString,
  },
  {
    dataField: 'status',
    text: 'Credential Status',
    formatter: fmtUrjanetCredentialStatus,
  },
  {
    dataField: 'lastSuccess',
    text: 'Account Discovery',
    formatter: fmtUrjanetCredentialLastSuccess,
  },
];

export const getUrjanetAccounts = (
  setProviderAccounts,
  organizationId,
  credentialIds,
  showToast,
  setIsLoading
) => {
  setIsLoading(true);
  getDiscoveredAccounts(
    setProviderAccounts,
    organizationId,
    credentialIds,
    showToast,
    setIsLoading
  );
  getExtractedAccounts(
    setProviderAccounts,
    organizationId,
    credentialIds,
    showToast,
    setIsLoading
  );
};

const getDiscoveredAccounts = (
  setProviderAccounts,
  organizationId,
  credentialIds,
  showToast,
  setIsLoading
) => {
  ApiServiceServerless.post(
    `/urjanet/accounts/discovered`,
    {
      credentials: credentialIds,
    },
    { authorization_id: organizationId }
  )
    .then(({ data }) => {
      const discoveredAccounts = data.map((acct) => ({
        ...parseAccount(acct),
        enrolled: false,
      }));
      setProviderAccounts((prev) => [...prev, ...discoveredAccounts]);
      setIsLoading(false);
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      setIsLoading(false);
      throw error;
    });
};
const getExtractedAccounts = (
  setProviderAccounts,
  organizationId,
  credentialIds,
  showToast,
  setIsLoading
) => {
  ApiServiceServerless.post(
    `/urjanet/accounts/extracted`,
    {
      credentials: credentialIds,
    },
    { authorization_id: organizationId }
  )
    .then(({ data }) => {
      const extractedAccounts = data.map((acct) => ({
        ...parseAccount(acct),
        enrolled: true,
      }));
      setProviderAccounts((prev) => [...prev, ...extractedAccounts]);
      setIsLoading(false);
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      setIsLoading(false);
      throw error;
    });
};

const parseAccount = (account) => {
  return {
    accountNumber: account.accountNumber,
    extractionChannels: account.extractionChannels,
    lastSuccessfulExtractionChannel: account.lastSuccessfulExtractionChannel,
    id: account.id,
    latestTotalBillAmount: account.latestTotalBillAmount,
    providerClassification: account.providerClassification,
    subscribed: account.subscribed,
    pullHistory: account.pullHistory,
    utilityProvider: account.utilityProvider.naturalKey,
    verifiedExtractionChannels: account.verifiedExtractionChannels,
  };
};

export const joinUrjanetAccountsToLocal = (
  providerAccounts,
  localUtilityAccounts,
  setProviderAccountsWithLocal
) => {
  const all_accounts = providerAccounts.map((acct) => ({
    ...acct,
    localAccount: localUtilityAccounts.find(
      (local) => local.urjanet_account_id === acct.id
    ),
  }));
  setProviderAccountsWithLocal(all_accounts);
};

export const updateUrjanetUsername = (
  params,
  credential,
  updateCredentialState,
  setAllCredentials,
  showToast
) => {
  ApiServiceServerless.post('/urjanet/credentials/update/username', params, {
    authorization_id: params.org_id,
  })
    .then(({ data }) => {
      updateCredentialState(
        setAllCredentials,
        credential.credentialId,
        'username',
        data.username
      );
      showToast('success', 'Success', 'Username was updated');
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      throw error;
    });
};

export const updateUrjanetPassword = (params, showToast) => {
  ApiServiceServerless.post('/urjanet/credentials/update/password', params, {
    authorization_id: params.org_id,
  })
    .then(() => showToast('success', 'Success', 'Password was updated'))
    .catch((error) => {
      showToast('danger', 'Error', error);
      throw error;
    });
};

export const enrollUrjanetAccount = (
  modalData,
  organizationId,
  data,
  newAccountId,
  setProviderAccounts,
  setLocalUtilityAccounts,
  setSubmitDisabled,
  showToast,
  onHide,
  setIsSaving
) => {
  let payload = {
    urjanetAccount: modalData.id,
    linkAccount: newAccountId || parseInt(data.linkAccount),
    orgId: organizationId,
    shouldPullHistory: data.shouldPullHistory === 'true',
    enrollAutoBilling: data.enrollAutoBilling === 'true',
  };
  ApiServiceServerless.post('/urjanet/accounts/enroll', payload, {
    authorization_id: organizationId,
  })
    .then((res) => {
      onHide();
      handleAccountReturn(
        res,
        modalData.id,
        {
          enroll: true,
          subscribe: payload.enrollAutoBilling,
          pullHistory: payload.shouldPullHistory,
        },
        payload.linkAccount,
        setProviderAccounts,
        setLocalUtilityAccounts
      );
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      onHide();
      throw error;
    })
    .finally(() => {
      setSubmitDisabled(false);
      setIsSaving(false);
    });
};

export const updateUrjanetAccount = (
  modalData,
  organizationId,
  data,
  newAccountId,
  setProviderAccounts,
  setLocalUtilityAccounts,
  setSubmitDisabled,
  showToast,
  onHide,
  setIsSaving
) => {
  let payload = {
    orgId: organizationId,
    subscribe: null,
    pullHistory: null,
    linkNewAccount: newAccountId || parseInt(data.linkAccount) || null,
  };

  const subscribe = data.enrollAutoBilling === 'true'; // 'select' returns string

  if (subscribe !== modalData.subscribed)
    payload = { ...payload, subscribe: subscribe };

  const history = data.shouldPullHistory === 'true'; // 'select' returns string
  if (history !== modalData.pullHistory)
    payload = { ...payload, pullHistory: history };

  ApiServiceServerless.post(
    `/urjanet/accounts/update/${modalData.id}`,
    payload,
    { authorization_id: organizationId }
  )
    .then((res) => {
      handleAccountReturn(
        res,
        modalData.id,
        {
          enroll: true,
          subscribe: subscribe,
          pullHistory: history,
        },
        payload.linkNewAccount,
        setProviderAccounts,
        setLocalUtilityAccounts
      );
    })
    .catch((error) => {
      showToast('danger', 'Error', error);
      throw error;
    })
    .finally(() => {
      onHide();
      setSubmitDisabled(false);
      setIsSaving(false);
    });
};

const handleAccountReturn = (
  res,
  provider_account_id,
  accountData,
  linkedAccount,
  setProviderAccounts,
  setLocalUtilityAccounts
) => {
  if (res.data.urjanet_status) {
    setProviderAccounts((prev) =>
      prev.map((account) => {
        if (account.id !== provider_account_id) return account;
        let editted_account = { ...account };
        if (res.data.urjanet_status.enroll) {
          editted_account.enrolled = true;
        }
        if (res.data.urjanet_status.subscribe) {
          editted_account.subscribed = accountData.subscribe;
        }
        if (res.data.urjanet_status.subscribe) {
          editted_account.pullHistory = accountData.pullHistory;
        }
        return editted_account;
      })
    );
  }
  if (res.data.utility_account) {
    //maybe remove old account link
    setLocalUtilityAccounts((prev) =>
      prev.map((account) => {
        if (
          account.urjanet_account_id !==
          res.data.utility_account.urjanet_account_id
        )
          return account;
        let new_account = { ...account };
        new_account.urjanet_account_id = null;
        return new_account;
      })
    );
    //maybe set new account link
    setLocalUtilityAccounts((prev) =>
      prev.map((account) => {
        if (account.id !== linkedAccount) return account;
        return res.data.utility_account;
      })
    );
  }
};

export const getUrjanetAccountColumns = (handleOpenLinkModal, updateAccess) => {
  return [
    {
      dataField: 'accountNumber',
      text: 'Account Number',
    },
    {
      dataField: 'latestTotalBillAmount',
      text: 'Latest Total Bill Amount',
      formatter: fmtUSD,
    },
    {
      dataField: 'enrolled',
      text: 'Enrolled',
      formatter: fmtBoolYesNo,
    },
    {
      dataField: 'subscribed',
      text: 'Automatic Bills',
      formatter: fmtBoolYesNo,
    },
    {
      dataField: 'localAccount',
      text: 'Linked',
      formatter: fmtBoolExists,
    },
    {
      dataField: 'id',
      text: 'Settings',
      formatter: (_cell, row) => {
        return updateAccess ? (
          <Button
            size='sm'
            variant='link'
            style={{ width: '100%', margin: '0px', padding: '0px' }}
            onClick={() => handleOpenLinkModal(row, 1)}
          >
            <div style={styles.buttonContainer}>
              <FontAwesomeIcon
                icon={row.localAccount ? faEdit : faPlusSquare}
                style={styles.buttonIcon}
              />
              {'Edit'}
            </div>
          </Button>
        ) : (
          ''
        );
      },
    },
  ];
};

export const filterUrjanetAccountByCredential = (credential, account) => {
  const extractionChannelId = credential.credentialId;
  return (
    extractionChannelId ===
      get(account, 'verifiedExtractionChannels[0].naturalKey') ||
    extractionChannelId === get(account, 'extractionChannels[0].naturalKey') ||
    extractionChannelId ===
      get(account, 'lastSuccessfulExtractionChannel.naturalKey')
  );
};

export const handleSetNewUrjanetAccount = (
  newAccount,
  setLocalUtilityAccounts
) => {
  setLocalUtilityAccounts((prev) =>
    prev.map((account) => {
      if (account.urjanet_account_id !== newAccount.urjanet_account_id) {
        return account;
      }
      let new_account = { ...account };
      new_account.urjanet_account_id = null;
      return new_account;
    })
  );

  setLocalUtilityAccounts((prev) => [...prev, newAccount]);
};
