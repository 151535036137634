import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/pro-light-svg-icons';

import EnergyTracerTable from '../../../tables';
import { ApiServiceServerless } from '../../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../modals/ConfirmationModal';

import AddGHGProjectSavingsProjectModal from '../../../modals/AddGHGProjectSavingsProjectModal';
import EditGHGProjectSavingsProjectModal from '../../../modals/EditGHGProjectSavingsProjectModal';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

const ghg_savings_projects = {
  1: 'Energy Efficiency Projects',
  2: 'District Energy/ Central Plant Projects',
  3: 'Onsite Renewable Energy Projects',
  4: 'Green Energy Procurements',
  5: 'Carbon Credits (RECs)',
};

const null_measure = {
  id: null,
  name: null,
  value: null,
  years: null,
  offset_years: 0,
  savings_type: null,
};

export default function GHGProjectSavingsProjects(props) {
  const {
    organizationId,
    projects,
    GHGMeasures,
    addToList,
    updateList,
    removeFromList,
    showToast,
    createAccess,
    updateAccess,
    deleteAccess,
  } = props;

  const [localMeasures, setLocalMeasures] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setLocalMeasures(GHGMeasures);
  }, [GHGMeasures]);

  useEffect(() => {
    let temp_data = [];
    localMeasures.forEach((measure) => {
      let temp_obj = { ...measure };
      temp_obj['project_names'] = [];
      temp_obj['project_ids'].forEach((project_id) => {
        let project = projects.filter(
          (project) =>
            project.id === parseInt(project_id) &&
            project.project_type === 'ghg'
        );
        if (project.length > 0) {
          temp_obj['project_names'].push(project[0].name);
        }
      });
      temp_data.push(temp_obj);
    });
    setTableData(temp_data);
  }, [localMeasures, projects]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmationModalProps, withConfirm] = useConfirm();

  const [editFlag, setEditFlag] = useState(0);
  const [editMeasure, setEditMeasure] = useState(null_measure);

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
      style: styles.column,
    },
    { dataField: 'value', text: 'Value (MTCO2e/yr)', style: styles.column },
    { dataField: 'years', text: 'Duration (yrs)', style: styles.column },
    { dataField: 'offset_years', text: 'Offset (yrs)', style: styles.column },

    {
      dataField: 'savings_type',
      text: 'Project Type',
      style: styles.column,
      formatter: (cell) => ghg_savings_projects[cell],
    },
    {
      dataField: 'project_names',
      text: 'Project List',
      style: styles.column,
      formatter: (cell, row) =>
        cell.length === 0 ? (
          <>No Projects</>
        ) : (
          <>
            {cell.map((name, idx) => (
              <div key={idx}>{name}</div>
            ))}
          </>
        ),
    },
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell) => (
        <>
          {updateAccess && (
            <Button
              onClick={() => handleShowEditModal(cell)}
              style={{
                marginRight: '1em',
                maxWidth: '33px',
                maxHeight: '31px',
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                  position: 'relative',
                  left: '-1px',
                }}
              />
            </Button>
          )}
          {deleteAccess && (
            <Button
              className='btn btn-danger'
              style={{ maxWidth: '33px', maxHeight: '31px' }}
              onClick={() => {
                handleDelete(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                }}
                icon={faTrashAlt}
              />
            </Button>
          )}
        </>
      ),
    },
  ];

  const handleDelete = (savings_id) => {
    const msg = `Are you sure you want to delete this Savings Project? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteProject(savings_id);
    });
  };

  const deleteProject = (savings_id) => {
    ApiServiceServerless.delete(
      `/ghg_projects/project_ghg_savings/${parseInt(savings_id)}`,
      { authorization_id: organizationId }
    )
      .then((res) => {
        showToast('success', 'Success', 'Savings Project Deleted');
        removeFromList(savings_id, 'ghg_measures');
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  const handleShowEditModal = (measure_id) => {
    setEditFlag((prev) => prev + 1);
    setEditMeasure(localMeasures.find((measure) => measure.id === measure_id));
  };

  useEffect(() => {
    if (editMeasure.name !== null) {
      setShowEditModal(true);
    }
  }, [editMeasure, editFlag]);

  return (
    <>
      {createAccess && (
        <Button
          style={{ float: 'right', marginBottom: '1em' }}
          onClick={() => setShowAddModal(true)}
        >
          {' '}
          Add Savings Project
        </Button>
      )}

      <EnergyTracerTable
        data={tableData}
        columns={tableColumns}
        keyField={'id'}
      />
      <AddGHGProjectSavingsProjectModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        org_id={organizationId}
        projects={projects}
        addToList={addToList}
        showToast={showToast}
        ghg_savings_projects={ghg_savings_projects}
      />
      <EditGHGProjectSavingsProjectModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        org_id={organizationId}
        projects={projects}
        updateList={updateList}
        showToast={showToast}
        existingMeasure={editMeasure}
        ghg_savings_projects={ghg_savings_projects}
      />

      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
}
