import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

export default function ChartWarning(props) {
  const { onClick, title } = props;
  return (
    <FontAwesomeIcon
      style={{
        fontSize: '20px',
        color: 'var(--et_tab_grey)',
        margin: '0 0.35em',
        verticalAlign: 'text-bottom',
        cursor: 'pointer',
      }}
      icon={faExclamationTriangle}
      title={title}
      onClick={() => onClick(true)}
    />
  );
}
