import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

export default function RegressionEventsSelectModal(props) {
  const {
    show,
    onHide,
    regressionEventCollections,
    setRegressionCollection,
    loadedRegressionCollection,
    getBaselineData,
    utilityType,
    regressionType,
    startDate,
    endDate,
    clearRegression,
  } = props;

  const [localCollection, setLocalCollection] = useState(null);

  useEffect(() => {
    setLocalCollection(loadedRegressionCollection);
  }, [loadedRegressionCollection]);

  const updateCollection = () => {
    setRegressionCollection(localCollection);
    clearRegression();
    getBaselineData(
      utilityType,
      regressionType,
      startDate,
      endDate,
      localCollection
    );
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton style={{ height: '50px' }}>
        Regression Event Collection Select
      </Modal.Header>
      <Modal.Body>
        <Form inline style={{ marginBottom: '0.5em' }}>
          <Form.Label
            style={{
              display: 'block',
              marginRight: '0.5em',
              marginLeft: '0.5em',
            }}
          >
            Collection:
          </Form.Label>
          <Form.Control
            style={{ float: 'right' }}
            as='select'
            size='sm'
            value={localCollection ? localCollection : ''}
            onChange={(e) => {
              setLocalCollection(
                e.target.value !== 'None' ? parseInt(e.target.value) : null
              );
            }}
          >
            <option value={null} key={'null-opt'}>
              None
            </option>
            {regressionEventCollections.length === 0 && (
              <option disabled value={'dis'} key={'null-dis'}>
                No Collections
              </option>
            )}
            {regressionEventCollections.map((collection) => (
              <option
                key={`collection-opt-${collection.id}`}
                value={collection.id}
              >
                {collection.name}
              </option>
            ))}
          </Form.Control>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='float-right ml-3'
          type='button'
          variant='outline-danger'
          onClick={() => onHide()}
        >
          Cancel
        </Button>
        <Button className='float-right ml-3' onClick={() => updateCollection()}>
          {' '}
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
