import React, { useEffect, useState, useCallback } from 'react';
import { Row, Form } from 'react-bootstrap';
import {
  LineChart,
  Line,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import CHARTCOLORS from '../../helpers/chartColors';
import MONTHNAMES from '../../helpers/monthsAbbreviated';
import DownloadChartData from '../DownloadChartData';
import { UTILITY_TYPE_UNITS } from './regressors';

const styles = { unitHeader: { marginBottom: '0.5em', width: '100px' } };

export default function PerformanceCalenderAnalysis(props) {
  const {
    organization_id,
    utilityType,
    regressionType,
    baselineData,
    performanceData,
    simpleBaseline,
    performanceModel,
  } = props;

  const [showTotal, setShowTotal] = useState(false);
  const [calenderData, setCalenderData] = useState([]);

  const [chartDownloadHeaders, setChartDownloadHeaders] = useState([]);
  const [chartDownloadKeys, setChartDownloadKeys] = useState([]);

  const formatBaselineData = useCallback(() => {
    if (baselineData.length > 0 && simpleBaseline.length > 0) {
      let temp_data = [];
      for (let i = 0; i < baselineData.length; i++) {
        let temp_obj = {};
        temp_obj['baseline'] = parseFloat(baselineData[i].toFixed(2));
        temp_obj['performance'] = parseFloat(
          performanceData['energy'][i].toFixed(2)
        );
        temp_obj['simple_baseline'] = parseFloat(simpleBaseline[i].toFixed(2));
        if (performanceModel.length > 0) {
          temp_obj['performance_eval'] = parseFloat(
            performanceModel[i].toFixed(2)
          );
        } else {
          temp_obj['performance_eval'] = 0;
        }
        if (showTotal) {
          temp_obj['baseline'] *= performanceData['days'][i];
          temp_obj['performance'] *= performanceData['days'][i];
          temp_obj['simple_baseline'] *= performanceData['days'][i];
          temp_obj['performance_eval'] *= performanceData['days'][i];
        }

        temp_obj['name'] =
          MONTHNAMES[performanceData['months'][i]] +
          ' ' +
          performanceData['years'][i];
        temp_data[i] = temp_obj;
      }
      setCalenderData(temp_data);
    } else {
      setCalenderData([]);
    }
  }, [
    baselineData,
    performanceData,
    simpleBaseline,
    performanceModel,
    showTotal,
  ]);

  useEffect(() => {
    let headers = [
      'Date',
      'Simple Baseline',
      'Adjusted Baseline',
      'Performance',
    ];
    let keys = ['name', 'simple_baseline', 'baseline', 'performance'];
    if (performanceModel.length > 0) {
      headers.push('Performance Model');
      keys.push('performance_eval');
    }
    setChartDownloadHeaders(headers);
    setChartDownloadKeys(keys);
  }, [calenderData, performanceModel]);

  useEffect(() => {
    formatBaselineData();
  }, [formatBaselineData]);

  return (
    <>
      {baselineData.length === 0 ? (
        <div>Select Performance Period</div>
      ) : (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Row style={{ width: '700px' }}>
            <Form.Control
              style={styles.unitHeader}
              as='select'
              size='sm'
              onChange={(e) =>
                setShowTotal(e.target.value === 'true' ? true : false)
              }
            >
              <option key={'usage-per-day'} value={false}>
                {UTILITY_TYPE_UNITS[regressionType][utilityType] + '/day'}
              </option>
              <option key={'usage-total'} value={true}>
                {UTILITY_TYPE_UNITS[regressionType][utilityType]}
              </option>
            </Form.Control>
            <DownloadChartData
              organization_id={organization_id}
              data={calenderData}
              headers={chartDownloadHeaders}
              keys={chartDownloadKeys}
              chart_type={'line'}
            />
            <ResponsiveContainer height={350} debounce={calenderData.length}>
              <LineChart
                data={calenderData}
                height={350}
                width={700}
                margin={{ right: 25 }}
              >
                <CartesianGrid />
                <XAxis dataKey='name' interval={'preserveEnd'} tickCount={6} />
                <YAxis
                  tickFormatter={(tick) => {
                    return tick.toLocaleString();
                  }}
                />
                <Tooltip
                  formatter={(value) =>
                    !isNaN(Number(value))
                      ? Number(value).toLocaleString()
                      : value
                  }
                />
                <Legend />
                <Line
                  name='Simple Baseline'
                  dataKey={'simple_baseline'}
                  stroke={CHARTCOLORS[2]}
                />
                <Line
                  name='Adjusted Baseline'
                  dataKey={'baseline'}
                  stroke={CHARTCOLORS[0]}
                />
                <Line
                  name='Performance'
                  dataKey={'performance'}
                  stroke={CHARTCOLORS[1]}
                />
                {performanceModel.length > 0 && (
                  <Line
                    name='Performance Eval'
                    dataKey={'performance_eval'}
                    stroke={CHARTCOLORS[3]}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </Row>
        </Row>
      )}
    </>
  );
}
