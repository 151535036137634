import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { orderBy } from 'lodash';
import NavigationAlert from '../../../components/analysis/NavigationAlert';

const styles = {
  form: {
    textAlignLast: 'center',
    display: 'block',
  },
  label: {
    display: 'flex',
  },
};

export default function OperationalSavingsReport(props) {
  const { organizationData, updatePayload } = props;

  const [localProjects, setLocalProjects] = useState({});
  const [performanceProjectId, setPerformanceProjectId] = useState(null);

  useEffect(() => {
    const temp_projects = orderBy(
      Object.values(organizationData['performance_projects']),
      ['default_project', 'id'],
      ['desc', 'asc']
    );
    setLocalProjects(organizationData['performance_projects']);
    if (temp_projects.length > 0) {
      setPerformanceProjectId(temp_projects[0]['id']);
      updatePayload('performance_project', temp_projects[0]['id']);
    } else {
      setPerformanceProjectId(null);
    }
  }, [organizationData, updatePayload]);

  return (
    <>
      {Object.keys(localProjects).length === 0 ? (
        <div style={{ marginBottom: '1em' }}>
          <NavigationAlert
            mainText={'Performance Project required for this report'}
            buttonText={'Create Project'}
            location={'/analysis'}
          />
        </div>
      ) : (
        <>
          <Form style={styles.form}>
            <Form.Group controlId={'performanceProject'}>
              <Form.Label style={styles.label}>Performance Project</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => {
                  updatePayload(
                    'performance_project',
                    parseInt(e.target.value)
                  );
                  setPerformanceProjectId(parseInt(e.target.value));
                }}
                value={performanceProjectId}
              >
                {orderBy(
                  Object.values(localProjects),
                  ['default_project', 'id'],
                  ['desc', 'asc']
                ).map((project) => (
                  <option key={`report-opt-${project.id}`} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </>
      )}
    </>
  );
}
