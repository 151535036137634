/*
Green
Blue
Grey
Orange
Purple
Red
Light Blue
Light Grey
*/

const CHARTCOLORS = [
  '#73B043',
  '#195E88',
  '#5C5C5C',
  '#E9B44B',
  '#554F7D',
  '#DB2B39',
  '#93B7BE',
  '#B4B4B4',
];
export default CHARTCOLORS;

export const CHARTCOLORS_EXTENDED = [
  ...CHARTCOLORS,
  '#F2638C',
  '#178F64',
  '#8B6508',
  '#56A0D3',
];

export const CHARTCOLORS_RGB = [
  [115, 176, 67],
  [25, 94, 136],
  [92, 92, 92],
  [233, 180, 75],
  [85, 79, 125],
  [219, 43, 57],
  [147, 183, 190],
  [180, 180, 180],
];

/*
Green
Blue
Grey
Red
Dark Blue
Light Red
Orange
dark purple
*/

export const CHARTCOLORS_old = [
  '#00AE42',
  '#0069A7',
  '#5C5C5C',
  '#DB2B39',
  '#173753',
  '#f95d6a',
  '#ff7c43',
  '#2f4b7c',
];
