import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';

const styles = {
  duplicateMessage: {
    textAlign: 'center',
    marginTop: '20px',
  },
  dateList: {
    margin: '0 16px',
    color: '#DB2B39',
  },
};

export default function CsvErrorModal(props) {
  const { show, onHide, errors } = props;

  const [localErrors, setLocalErrors] = useState({});

  useEffect(() => {
    let temp = {};
    Object.keys(errors).forEach((acc) => {
      if (errors[acc].length > 0) {
        temp[acc] = errors[acc].join(',');
      }
    });
    setLocalErrors(temp);
  }, [errors]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Upload Error</Modal.Header>
      <Modal.Body>
        <Row style={styles.duplicateMessage}>
          <Col>
            The following bills contain errors causing their upload to fail
          </Col>
        </Row>

        <hr />
        <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
          {Object.keys(localErrors).map((acc) => (
            <div key={acc}>
              <h2>{acc}</h2>
              <Row>
                <Col>
                  <p style={styles.dateList}>{localErrors[acc]}</p>
                </Col>
              </Row>
            </div>
          ))}
        </div>

        <hr />
      </Modal.Body>
    </Modal>
  );
}
