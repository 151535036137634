import React from 'react';
import { capitalize, get } from 'lodash';
import PopoverTrigger from '../../PopoverTrigger';

// From Urjanet API:
// https://developer.urjanet.net/docs/utilitydataservice-api-documentation.html#header-credential-status
const CredentialStatusLookup = Object.freeze({
  OK: 'Success',
  PENDING: {
    WEBSITE_DOWN: 'Temporary problem in accessing the website',
    UNDER_REVIEW:
      'Urjanet is reviewing the issue and will update the status shortly',
    STATEMENT_UNAVAILABLE:
      'Temporary problem in accessing bills within this credential',
  },
  FIX: {
    LOGIN_FAILURE:
      'Login attempt failed. Update your credentials, and the system will try again.',
    NO_ACCOUNTS_FOUND: '	No accounts found within this credential',
    ACCOUNT_LOCKED:
      'Login failed due to account lockout. Please unlock the account.',
    UNSUPPORTED_CAPTCHA:
      'Login failed due to a captcha required by the utility. In some cases, captcha can be disabled in the utility’s website settings.',
    E_BILL_REQUIRED:
      'Enrollment in electronic billing is required to access online statements.',
  },
});

export function fmtUrjanetCredentialStatus({
  credentialStatus,
  credentialStatusDetail,
}) {
  const eventDetail = _getCredentialStatus(
    credentialStatus,
    credentialStatusDetail
  );
  if (typeof eventDetail === 'string') return eventDetail;
  return (
    <PopoverTrigger
      popoverContent={eventDetail[1]}
      trigger={['hover', 'focus']}
    >
      {_formatStatus(eventDetail[0])}
    </PopoverTrigger>
  );
}

export function fmtUrjanetCredentialLastSuccess(eventStatus) {
  return eventStatus ? 'Complete' : 'Pending';
}

function _getCredentialStatus(status, statusDetail) {
  switch (status) {
    case 'OK':
      return 'Submitted';
    case 'PENDING':
      return 'Submitted';
    default:
      return [
        // status,
        statusDetail,
        get(CredentialStatusLookup, [status, statusDetail], ''),
      ];
  }
}

const _formatStatus = (status) => (
  <div
    className='text-danger'
    style={{
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    }}
  >
    {status
      .split('_')
      .map((word) => capitalize(word))
      .join('\t \t')}
  </div>
);
