import React, { useEffect, useState } from 'react';

import { Form, Row, Col, Modal } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

export default function EditRegressionEventCollectionModal(props) {
  const {
    show,
    onHide,
    showToast,
    organization_id,
    collection,
    updateList,
    setRegressionEventCollectionsEditted,
  } = props;

  const [name, setName] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (collection) {
      setName(collection.name);
    } else {
      setName('');
    }
  }, [collection]);

  const saveRegressionEventCollection = () => {
    setIsLoading(true);
    ApiServiceServerless.post(
      `/regression_events/collection/${collection.id}`,
      {
        name: name,
      },
      { authorization_id: organization_id }
    )
      .then((res) => {
        showToast('success', 'Success', 'Regression Event Collection Updated');
        updateList(res.data, 'regression_event_collections');
        setRegressionEventCollectionsEditted((prev) => prev + 1);
      })
      .catch(() => {
        showToast('danger', 'Error', 'Update Error');
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  const saveDisabled = name === null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div as='h5'>Edit Regression Event Collection</div>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>
                Name
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                required
                placeholder=''
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          onSubmit={saveRegressionEventCollection}
          submitDisabled={saveDisabled}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
