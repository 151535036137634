import React, { useState, useEffect } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';

import { ApiServiceServerless, forceAuthRefresh } from '../../../xhr_libs';
import EditEntityActionButtons from '../../../components/forms/EditEntityActionButtons';
import Loader from '../../../components/Loader';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import { DEFAULT_PORTFOLIO_VALUES } from './../helpers';

function PortfolioSettings(props) {
  const {
    setSelectedPage,
    portfolio,
    showToast,
    isLoading,
    setPortfolio,
    setRefreshFlag,

    updateAccess,
    deleteAccess,
  } = props;

  const [name, setName] = useState('');
  const [validated, setValidated] = useState(false);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setName(portfolio.name);
  }, [portfolio]);

  const savePortfolio = (e) => {
    e.preventDefault();
    setIsSaving(true);
    const form = e.currentTarget;
    if (form.checkValidity()) {
      ApiServiceServerless.post(
        `portfolios/${portfolio.id}`,
        {
          name: name,
        },
        { authorization_id: portfolio.id }
      )
        .then((res) => {
          setPortfolio(res.data);
          setRefreshFlag((prev) => prev + 1);
          showToast('success', 'Success', 'Portfolio was saved.');
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          throw error;
        })
        .finally(() => {
          forceAuthRefresh();
          setValidated(false);
          setIsSaving(false);
        });
    } else {
      setValidated(true);
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setName(portfolio.name);
    setValidated(false);
  };

  const disableActions = () => name === portfolio.name;

  const handleDeletePortfolio = () => {
    const msg = `Are you sure you want to delete this Portfolio?`;
    const deletePortfolio = () => {
      setIsDeleting(true);
      ApiServiceServerless.delete(`portfolios/${portfolio.id}`, {
        authorization_id: portfolio.id,
      })
        .then(() => {
          showToast('success', 'Success', 'Portfolio was deleted.');
          forceAuthRefresh();
          setPortfolio({ ...DEFAULT_PORTFOLIO_VALUES });
          setSelectedPage('portfolio-table');
          setRefreshFlag((prev) => prev + 1);
          setIsDeleting(false);
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          setIsDeleting(false);
          throw error;
        });
    };
    withConfirm(msg, deletePortfolio);
  };

  return (
    <>
      <ConfirmationModal {...confirmationModalProps} />
      <Card.Body>
        {isLoading ? (
          <Container>
            <Row>
              <Col
                style={{
                  paddingBottom: '20vh',
                  paddingTop: '10vh',
                  paddingLeft: '49%',
                }}
              >
                <Loader />
              </Col>
            </Row>
          </Container>
        ) : (
          <Form
            noValidate
            validated={validated}
            onSubmit={savePortfolio}
            style={{ textAlign: 'left' }}
          >
            <Form.Group controlId='formGridName'>
              <Form.Label>
                Name
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                required
                disabled={!updateAccess}
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Form.Group>

            <Form.Group controlId='formGridToken'>
              <Form.Label>Share Token</Form.Label>
              <Form.Control disabled defaultValue={portfolio.share_token} />
            </Form.Group>

            <EditEntityActionButtons
              onCancel={handleCancel}
              submitDisabled={disableActions()}
              cancelDisabled={disableActions()}
              onDelete={handleDeletePortfolio}
              deleteShow={deleteAccess}
              submitShow={updateAccess}
              isSubmitting={isSaving}
              isDeleting={isDeleting}
            />
          </Form>
        )}
      </Card.Body>
    </>
  );
}

export default PortfolioSettings;
