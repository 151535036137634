import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { cloneDeep } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../../../xhr_libs';
import Loader from '../../../../components/Loader';
import EnergyTracerTable from '../../../../components/tables';
import ExpansionArrow from '../../../../components/ExpansionArrow';
import OrganizationContactModal from '../../../../components/modals/OrganizationContactModal';
import ConfirmationModal, {
  useConfirm,
} from '../../../../components/modals/ConfirmationModal';
import { fmtBoolYesNo } from '../../../../components/tables/formatters';
import OrganizationContactsForm from './OrganizationContactsForm';
import { useOrganizationRoles } from '../../../../helpers/checkRoles';

const DELETE_CONFIRM_TEXT = 'Are you sure you want to delete this contact?';

const styles = {
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
};

export default function OrganizationContacts(props) {
  const {
    organization,
    userSelectedOrganizationDetails,
    contacts,
    isLoading,
    showToast,
    setOrganization,
  } = props;

  const [originalContacts, setOriginalContacts] = useState(false);
  const [editContacts, setEditContacts] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const updateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'organization',
    'update'
  );

  useEffect(() => {
    setOriginalContacts(contacts);
    setEditContacts(contacts);
  }, [contacts]);

  const handleResetContact = (id) => {
    const originalContact = originalContacts.find(
      (contact) => contact.id === id
    );
    setEditContacts((prev) =>
      prev.map((contact) => {
        if (contact['id'] !== id) return contact;
        let newContact = cloneDeep(originalContact);
        return newContact;
      })
    );
    setFormKey(formKey + 1);
  };

  const handleDeleteContact = (id) => {
    const deleteContact = () => {
      setIsDeleting(true);
      ApiServiceServerless.delete(
        `/organizations/${organization.id}/contacts/${id}`,
        { authorization_id: organization.id }
      )
        .then(() => showToast('success', 'Success', 'Contact Deleted'))
        .catch((error) => {
          showToast('danger', 'Error', error);
        })
        .finally(() => {
          setOrganization((prev) => ({
            ...prev,
            contacts: prev.contacts.filter((contact) => contact.id !== id),
          }));
          setIsDeleting(false);
        });
    };
    withConfirm(DELETE_CONFIRM_TEXT, deleteContact);
  };

  const saveContact = (e, id) => {
    setIsSaving(true);
    e.preventDefault();
    const contact = editContacts.find((contact) => contact.id === id);
    if (contact) {
      const payload = {
        name: contact.name,
        email: contact.email,
        title: contact.title,
        address: contact.address,
        phone: contact.phone,
        admin: contact.admin,
        billing: contact.billing,
        facility: contact.facility,
        reports: contact.reports,
      };

      ApiServiceServerless.post(
        `/organizations/${organization.id}/contacts/${id}`,
        payload,
        { authorization_id: organization.id }
      )
        .then((res) => {
          setOrganization((prev) => ({
            ...prev,
            contacts: prev.contacts.map((contact) => {
              if (contact['id'] !== res.data['id']) return contact;
              return res.data;
            }),
          }));
          showToast('success', 'Success', 'Contact Saved');
          setIsSaving(false);
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          setIsSaving(false);
        });
    } else {
      setIsSaving(false);
    }
  };

  const handleChange = (id, type, value) => {
    setEditContacts((prev) =>
      prev.map((contact) => {
        if (contact['id'] !== id) return contact;
        let newContact = cloneDeep(contact);
        newContact[type] = value;
        return newContact;
      })
    );
  };

  const COLUMNS = [
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'admin',
      text: 'Admin',
      formatter: fmtBoolYesNo,
    },
    {
      dataField: 'billing',
      text: 'Billing',
      formatter: fmtBoolYesNo,
    },
    {
      dataField: 'facility',
      text: 'Facility',
      formatter: fmtBoolYesNo,
    },
    {
      dataField: 'reports',
      text: 'Reports',
      formatter: fmtBoolYesNo,
    },
  ];

  const EXPAND_ROW = {
    renderer: (row) => (
      <div
        style={{
          boxShadow: 'inset 1px 2px 3px 0px #eeeeee',
          margin: '-5px',
          paddingTop: '16px',
          paddingRight: '16px',
        }}
      >
        <Row>
          <OrganizationContactsForm
            localContact={editContacts.find((con) => con.id === row.id)}
            saveContact={saveContact}
            handleChange={handleChange}
            handleDeleteContact={handleDeleteContact}
            updateAccess={updateAccess}
            handleResetContact={handleResetContact}
            formKey={formKey}
            isSaving={isSaving}
            isDeleting={isDeleting}
          />
        </Row>
      </div>
    ),
    showExpandColumn: true,
    expandColumnPosition: 'right',
    expandHeaderColumnRenderer: ({ isAnyExpands: expanded }) => (
      <>
        <span style={{ marginRight: '10px' }}>Expand All</span>
        <span>
          <ExpansionArrow expanded={expanded} />
        </span>
      </>
    ),
    expandColumnRenderer: ({ expanded }) => (
      <>
        <span style={{ marginRight: '10px' }}>Expand</span>
        <span>
          <ExpansionArrow expanded={expanded} />
        </span>
      </>
    ),
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {updateAccess && (
            <div
              style={{
                textAlign: 'right',
                marginBottom: '1em',
              }}
            >
              <FontAwesomeIcon
                style={styles.iconStyle}
                icon={faUserPlus}
                title={'Add Contact'}
                onClick={() => setShowContactModal(true)}
              />
            </div>
          )}
          {Object.keys(contacts).length === 0 ? (
            <div style={{ margin: '30px' }}>
              {`This organization has no contacts`}
            </div>
          ) : (
            <EnergyTracerTable
              data={editContacts}
              columns={COLUMNS}
              expandRow={EXPAND_ROW}
            />
          )}
        </>
      )}
      <ConfirmationModal {...confirmationModalProps} />
      <OrganizationContactModal
        show={showContactModal}
        onHide={() => setShowContactModal(false)}
        setOrganization={setOrganization}
        showToast={showToast}
        organizationId={organization.id}
      />
    </>
  );
}
