import React, { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export default function EditUtilityAccountDateSelect(props) {
  const { date, setDate, updateDate, dateKey } = props;

  const [localDate, setLocalDate] = useState(date);

  const formStyle = {
    fontSize: 14,
  };

  const handleLocalDate = (newDate) => {
    let new_date = null;
    new_date = dayjs.utc(newDate).endOf('month');
    setLocalDate(new_date);
  };

  const updateDateSelection = () => {
    if (dayjs(localDate).isValid()) {
      setDate(localDate);
      updateDate(localDate, dateKey);
    } else {
      setDate(null);
      updateDate(null, dateKey);
    }
    document.getElementById(dateKey + '-popover-button').click();
  };

  return (
    <>
      <Form style={formStyle}>
        <Col>
          <Form.Row>
            <Form.Group>
              <InputGroup size='sm'>
                <DatePicker
                  selected={localDate ? new Date(localDate) : new Date()}
                  dateFormat='MM/yyyy'
                  onChange={(date) => handleLocalDate(date)}
                  showMonthYearPicker
                  showTwoColumnMonthYearPicker
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>

          <Button
            onClick={(e) => updateDateSelection()}
            data-id='db_date_confirm'
          >
            {' '}
            Confirm
          </Button>
        </Col>
      </Form>
    </>
  );
}
