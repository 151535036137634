import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/pro-light-svg-icons';

import AddCustomGHGModal from '../../../modals/AddCustomGHGModal';
import EnergyTracerTable from '../../../tables';
import { ApiServiceServerless } from '../../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../modals/ConfirmationModal';
import EditCustomGHGModal from '../../../modals/EditCustomGHGModal';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

const empty_conversions = {
  name: '',
  year: 2022,
  CO2: 0,
  N2O: 0,
  CH4: 0,
  CO2_savings: 0,
  N2O_savings: 0,
  CH4_savings: 0,
};

export default function CustomGHGConversions(props) {
  const {
    organization,
    showToast,
    createAccess,
    updateAccess,
    deleteAccess,
    GHGConversions,
    GHGCollections,
    addToList,
    updateList,
    removeFromList,
  } = props;

  const [tableData, setTableData] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [editFlag, setEditFlag] = useState(0);
  const [editConversion, setEditConversion] = useState(empty_conversions);

  const [confirmationModalProps, withConfirm] = useConfirm();

  useEffect(() => {
    let temp_data = [];
    GHGConversions.forEach((conversion) => {
      let temp_obj = { ...conversion };
      temp_obj['collection_names'] = [];
      temp_obj['collection_ids'].forEach((collection_id) => {
        let collection = GHGCollections.filter(
          (collection) => collection.id === collection_id
        );
        if (collection.length > 0) {
          temp_obj['collection_names'].push(collection[0].name);
        }
      });
      temp_data.push(temp_obj);
    });
    setTableData(temp_data);
  }, [GHGConversions, GHGCollections]);

  const handleDelete = (conversion_id) => {
    const msg = `Are you sure you want to delete this Conversion? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteCustomGHGConversions(conversion_id);
    });
  };

  const deleteCustomGHGConversions = (conversion_id) => {
    ApiServiceServerless.delete(`/custom_ghg_conversions/${conversion_id}`, {
      authorization_id: organization.id,
    })
      .then(() => {
        showToast('success', 'Success', 'Conversions Deleted');
        removeFromList(conversion_id, 'ghg_conversions');
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  const handleShowEditModal = (conversion_id) => {
    setEditFlag((prev) => prev + 1);
    setEditConversion(
      GHGConversions.find((conversion) => conversion.id === conversion_id)
    );
  };

  useEffect(() => {
    if (editConversion.name !== '') {
      setShowEditModal(true);
    }
  }, [editConversion, editFlag]);

  const tableColumns = [
    { dataField: 'name', text: 'Name', style: styles.column },
    { dataField: 'year', text: 'Year', style: styles.column },
    {
      dataField: 'CO2',
      text: 'CO2 (CO2 Savings) lbs/MWh',
      style: styles.column,
      formatter: (cell, row) => (
        <>{row['CO2'] + ' (' + row['CO2_savings'] + ')'}</>
      ),
    },
    {
      dataField: 'N2O',
      text: 'N2O (N2O Savings) lbs/MWh',
      style: styles.column,
      formatter: (cell, row) => (
        <>{row['N2O'] + ' (' + row['N2O_savings'] + ')'}</>
      ),
    },
    {
      dataField: 'CH4',
      text: 'CH4 (CH4 Savings) lbs/MWh',
      style: styles.column,
      formatter: (cell, row) => (
        <>{row['CH4'] + ' (' + row['CH4_savings'] + ')'}</>
      ),
    },
    {
      dataField: 'collection_names',
      text: 'Collection List',
      style: styles.column,
      formatter: (cell, row) =>
        cell.length === 0 ? (
          <>No Collections</>
        ) : (
          <>
            {cell.map((name, idx) => (
              <div key={idx}>{name}</div>
            ))}
          </>
        ),
    },
  ];
  const actionColumn = [
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell, row) => (
        <>
          {updateAccess && (
            <Button
              onClick={() => handleShowEditModal(cell)}
              style={{
                marginRight: '1em',
                maxWidth: '33px',
                maxHeight: '31px',
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                  position: 'relative',
                  left: '-1px',
                }}
              />
            </Button>
          )}
          {deleteAccess && (
            <Button
              className='btn btn-danger'
              style={{ maxWidth: '33px', maxHeight: '31px' }}
              onClick={() => {
                handleDelete(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                }}
                icon={faTrashAlt}
              />
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {createAccess && (
        <Button
          onClick={() => {
            setShowAddModal(true);
          }}
          style={{ marginBottom: '1em', float: 'right' }}
        >
          Add Custom GHG Conversion
        </Button>
      )}
      <EnergyTracerTable
        data={tableData}
        columns={[].concat(tableColumns, actionColumn)}
        keyField={'id'}
      />
      <AddCustomGHGModal
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
        }}
        showToast={showToast}
        organization_id={organization.id}
        addToList={addToList}
        updateList={updateList}
        GHGCollections={GHGCollections}
      />
      <EditCustomGHGModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        showToast={showToast}
        organization_id={organization.id}
        defaultGHGData={editConversion}
        GHGCollections={GHGCollections}
        updateList={updateList}
      />
      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
}
