const formatValue = (value, unit) => {
  let suffix = '';
  if (value.toFixed(0).length >= 9) {
    value = (value / 1000000).toPrecision(6);
    suffix = 'M';
  } else if (value.toFixed(0).length >= 7) {
    value = (value / 1000).toPrecision(4);
    suffix = 'K';
  } else if (value.toFixed(2).length >= 7) {
    value = value.toFixed(0);
  } else {
    value = value.toFixed(2);
  }
  let ret = `${parseFloat(value).toLocaleString()}${suffix}`;
  if (unit === '$') {
    ret = `$${ret}`;
  } else if (unit) {
    ret = `${ret} ${unit}`;
  }
  return ret;
};

export default formatValue;
