import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { sortBy } from 'lodash';

import EditEntityActionButtons from '../forms/EditEntityActionButtons';
import { ApiServiceServerless } from '../../xhr_libs';

export default function AddMultiProjectRateCollectionModal(props) {
  const {
    show,
    onHide,
    buildingsLookup,
    project_id,
    organization_id,
    rateCollections,
    updateList,
    showToast,
  } = props;

  const [collectionId, setCollectionId] = useState('');
  const [utility, setUtility] = useState('');
  const [localBuildings, setLocalBuildings] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  const clearForm = () => {
    setCollectionId('');
    setUtility('');
    setLocalBuildings([]);
  };

  const updateSet = (values) => {
    let arr = Array.prototype.slice.call(values);
    arr = arr.map((item) => item.value);
    setLocalBuildings(arr);
  };

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();
    ApiServiceServerless.post(
      `/performance_projects/project_rate_collection/${parseInt(
        project_id
      )}/${collectionId}`,
      { building_ids: Array.from(localBuildings), utility: utility },
      { authorization_id: organization_id }
    )
      .then((res) => {
        res.data.forEach((collection) =>
          updateList(collection, 'rate_collections')
        );
        onHide();
        clearForm();
        setIsSaving(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        onHide();
        setIsSaving(false);
        throw err;
      });
  };

  const handleCancel = () => {
    onHide();
    clearForm();
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          {'Add Project Rate Collection'}
        </Modal.Header>
        <Form
          noValidate
          onSubmit={handleSubmit}
          style={{
            textAlign: 'left',
          }}
        >
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group controlId='accountForm.BuildingSelect'>
                  <Form.Label>
                    Building(s)
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    as='select'
                    multiple
                    required
                    onChange={(e) => updateSet(e.target.selectedOptions)}
                  >
                    {sortBy(Object.values(buildingsLookup), ['name']).map(
                      (item) => (
                        <option key={`building-opt-${item.id}`} value={item.id}>
                          {item.name}
                        </option>
                      )
                    )}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Rate Collection </Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) => {
                      setCollectionId(parseInt(e.target.value.split('_')[0]));
                      setUtility(e.target.value.split('_')[1]);
                    }}
                  >
                    <option key={'null-val'} value={''} hidden></option>
                    {rateCollections.length === 0 && (
                      <option key={'null-null'} value={''} disabled>
                        No existing collections
                      </option>
                    )}
                    {rateCollections.map((collection) => (
                      <option
                        key={collection.id}
                        value={collection.id + '_' + collection.utility_type}
                      >
                        {collection.name + ' (' + collection.utility_type + ')'}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <EditEntityActionButtons
              onCancel={handleCancel}
              submitDisabled={
                collectionId === '' || localBuildings.length === 0
              }
              submitShow={true}
              isSubmitting={isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
