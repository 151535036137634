import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { isEqual } from 'lodash';
import numeral from 'numeral';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import AddressWrapper from '../../../helpers/AddressWrapper';
import EditEntityActionButtons from '../../../components/forms/EditEntityActionButtons';
import {
  BuildingTypes,
  BUILDING_BENCHMARK_OPTIONS,
} from '../../../helpers/BuildingTypes';
import CHARTCOLORS from '../../../helpers/chartColors';
import Loader from '../../../components/Loader';

const styles = {
  form: {
    textAlign: 'left',
    padding: '10px',
    marginTop: '5px',
  },
};

const DEFAULT_BUILDING_VALUES = {
  name: '',
  square_footage: '',
  address: {},
  not_physical: '',
  orgId: '',
  organization: { name: '' },
  utility_acconts: [],
  has_provider_account: false,
  building_type: null,
  benchmark_type: null,
  reference_id: null,
};

const PAYLOAD_KEYS = [
  'name',
  'square_footage',
  'not_physical',
  'address',
  'building_type',
  'benchmark_type',
  'reference_id',
];

export default function EditBuildingModal(props) {
  const {
    show,
    onHide,
    building_id,
    building,
    organization_name,
    handleSetBuilding,
    handleUpdateTables,
    isLoading,
    showToast,
    isSaving,
    setIsSaving,
  } = props;

  const [validated, setValidated] = useState(false);
  const [validAddress, setValidAddress] = useState(true);

  const [editBuilding, setEditBuilding] = useState({
    ...DEFAULT_BUILDING_VALUES,
  });

  useEffect(() => {
    setEditBuilding({ ...building });
  }, [building, show]);

  const onFieldChange = (fieldName, value) => {
    setEditBuilding((building) => {
      building[fieldName] = value;
      return { ...building };
    });
  };

  const formatPayload = (editBuilding, building, handleUpdateTables) => {
    let payload = {};

    PAYLOAD_KEYS.forEach((key) => {
      if (editBuilding[key] !== building[key]) {
        payload[key] = editBuilding[key];
      }
    });

    const local_keys = Object.keys(payload);
    //special checks
    if (local_keys.includes('square_footage')) {
      payload.square_footage = numeral(payload.square_footage).format('0');
    }
    //table update checks
    if (local_keys.includes('name')) {
      handleUpdateTables(0);
    }
    return payload;
  };

  const onSaveClick = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (validAddress && form.checkValidity()) {
      setIsSaving(true);
      const payload = formatPayload(editBuilding, building, handleUpdateTables);
      ApiServiceServerless.post(`buildings/${building_id}`, payload, {
        authorization_id: building_id,
      })
        .then((res) => {
          handleSetBuilding(res.data);
          showToast('success', 'Success', 'Building Saved.');
          onHide();
          setIsSaving(false);
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          onHide();
          setIsSaving(false);
          throw error;
        });
    } else {
      setValidated(true);
    }
  };

  const actionsDisabled = isEqual(building, editBuilding);
  const saveDisabled = !editBuilding.name || !validAddress;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Edit Building</Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div style={{ minHeight: '40vh' }}>
            <Loader />
          </div>
        ) : (
          <Form
            noValidate
            validated={validated}
            onSubmit={onSaveClick}
            style={styles.form}
          >
            <div>
              <Form.Group controlId='editBuildingForm.name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={editBuilding.name || ''}
                  onChange={(e) => onFieldChange('name', e.target.value)}
                  maxLength={100}
                />
              </Form.Group>

              <Form.Group controlId='editBuildingForm.organization'>
                <Form.Label>Organization</Form.Label>
                <Form.Control disabled={true} value={organization_name} />
              </Form.Group>

              <Form.Group controlId='editBuildingForm.reference_id'>
                <Form.Label>Reference ID</Form.Label>
                <Form.Control
                  value={editBuilding.reference_id || ''}
                  onChange={(e) =>
                    onFieldChange('reference_id', e.target.value)
                  }
                />
              </Form.Group>

              <AddressWrapper
                address={editBuilding.address}
                onAddressSelect={(value) => onFieldChange('address', value)}
                setValidAddress={setValidAddress}
                notRequired={true}
              />

              <Form.Group controlId='editBuilding.type'>
                <Form.Label>
                  Type{' '}
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://www.eia.gov/consumption/commercial/building-type-definitions.php'
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: '18px', color: CHARTCOLORS[1] }}
                      icon={faInfoCircle}
                    />
                  </a>{' '}
                </Form.Label>
                <Form.Control
                  as='select'
                  onChange={(e) =>
                    onFieldChange('building_type', e.target.value)
                  }
                  value={editBuilding.building_type || ''}
                >
                  <option disabled key={'select-type'} value={null}></option>
                  {Object.keys(BuildingTypes).map((data, index) => (
                    <option key={index} value={data}>
                      {BuildingTypes[data]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='editBuilding.usage_profile'>
                <Form.Label>Utility Usage Profile</Form.Label>
                <Form.Control
                  as='select'
                  onChange={(e) =>
                    onFieldChange('benchmark_type', e.target.value)
                  }
                  value={editBuilding.benchmark_type || ''}
                >
                  <option disabled key={'select-type'} value={null}></option>
                  {Object.keys(BUILDING_BENCHMARK_OPTIONS).map(
                    (data, index) => (
                      <option key={index} value={data}>
                        {BUILDING_BENCHMARK_OPTIONS[data]}
                      </option>
                    )
                  )}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId='editBuildingForm.areaEntry'>
                <Form.Label>Area (sq.ft.)</Form.Label>
                <Form.Control
                  value={
                    editBuilding.not_physical
                      ? ''
                      : numeral(editBuilding.square_footage).format('0,0')
                  }
                  onChange={(e) =>
                    onFieldChange('square_footage', e.target.value)
                  }
                  disabled={editBuilding.not_physical}
                />
              </Form.Group>

              <Form.Group controlId='editBuildingForm.noAssociatedArea'>
                <Form.Label>No associated area</Form.Label>
                <Form.Check inline className='ml-3 align-text-bottom'>
                  <Form.Check.Input
                    style={{ height: '1.1rem', width: '1.1rem' }}
                    checked={editBuilding.not_physical}
                    onChange={(e) =>
                      onFieldChange('not_physical', e.target.checked)
                    }
                  />
                </Form.Check>
              </Form.Group>
              <EditEntityActionButtons
                onSubmit={onSaveClick}
                submitDisabled={actionsDisabled || saveDisabled}
                onCancel={onHide}
                isSubmitting={isSaving}
              />
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
}
