import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { ghg_units } from '../../helpers/GHGUnits';

const styles = {
  label: {
    display: 'flex',
  },
};

function GHGReportUnitPicker(props) {
  const { updatePayload, payload_key } = props;

  const [localUnit, setLocalUnit] = useState('mton');

  useEffect(() => {
    updatePayload(payload_key, localUnit);
  }, [updatePayload, localUnit, payload_key]);

  return (
    <Form.Group controlId={'ghg-unit-select'}>
      <Form.Label style={styles.label}>Report Emissions Unit</Form.Label>
      <Form.Control
        as='select'
        onChange={(e) => setLocalUnit(e.target.value)}
        value={localUnit}
      >
        {Object.keys(ghg_units).map((unit) => (
          <option key={`unit-opt-${unit}`} value={unit}>
            {ghg_units[unit]}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}

GHGReportUnitPicker.propTypes = {
  payload_key: PropTypes.string,
};

GHGReportUnitPicker.defaultProps = {
  payload_key: 'ghg_report_unit',
};

export default GHGReportUnitPicker;
