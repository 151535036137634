import React, { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export default function ReportDatePicker(props) {
  const { currentDate, setDate, minDate, maxDate } = props;

  const [localDate, setLocalDate] = useState(currentDate);

  const formStyle = {
    fontSize: 14,
  };

  const updateDateSelection = () => {
    if (dayjs(localDate).isValid()) {
      setDate(localDate);
    } else {
      setDate(currentDate);
    }
    document.getElementById('report-popover-button').click();
  };

  return (
    <>
      <Form style={formStyle}>
        <Col>
          <Form.Row>
            <Form.Group>
              <InputGroup size='sm'>
                <DatePicker
                  selected={localDate ? new Date(localDate) : new Date()}
                  dateFormat='MM/yyyy'
                  onChange={(date) => setLocalDate(date)}
                  showMonthYearPicker
                  showTwoColumnMonthYearPicker
                  minDate={new Date(minDate)}
                  maxDate={new Date(maxDate)}
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>

          <Button
            onClick={(e) => updateDateSelection()}
            data-id='db_date_confirm'
          >
            {' '}
            Confirm
          </Button>
        </Col>
      </Form>
    </>
  );
}
