import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import {
  faFiles,
  faUpload,
  faSyncAlt,
  faCheck,
  faX,
} from '@fortawesome/pro-solid-svg-icons';
import ApiServiceServerless from '../../xhr_libs';

const styles = {
  icon: {
    fontSize: '21px',
    lineHeight: 'inherit',
    paddingTop: '2px',
    minHeight: '21px',
    minWidth: '21px',
  },
  cancelStyle: {
    fontSize: '18px',
    color: 'red',
    cursor: 'pointer',
  },
  cancelStyleDisabled: {
    fontSize: '18px',
    color: 'red',
    opacity: '50%',
  },
  loadingStyle: {
    fontSize: '18px',
    color: 'var(--et_blue)',
  },
  successStyle: {
    fontSize: '18px',
    color: 'var(--et_green)',
  },
  failedStyle: {
    fontSize: '18px',
    color: 'red',
  },
  warningStyle: {
    fontSize: '18px',
    color: 'var(--et_orange)',
  },
};

export default function PlugUploadModal(props) {
  const { show, onHide, organizationId } = props;

  const [files, setFiles] = useState([]);
  const [fileStates, setFileStates] = useState([]);
  const [stateCount, setStateCount] = useState(0);
  const [resetTrigger, setResetTrigger] = useState(0);

  const [isUploading, setIsUploading] = useState(false);

  const getPDF = (e) => {
    let files = Array.from(e.target.files);
    let file_states = [];
    files.forEach((file) => file_states.push({ state: 0 }));

    setFiles((prev) => [...prev, ...files]);
    setFileStates((prev) => [...prev, ...file_states]);
  };

  const removeFile = (idx) => {
    setFiles((prev) => [
      ...prev.slice(0, idx),
      ...prev.slice(idx + 1, prev.length),
    ]);
    setFileStates((prev) => [
      ...prev.slice(0, idx),
      ...prev.slice(idx + 1, prev.length),
    ]);
  };

  const uploadFiles = async () => {
    setIsUploading(true);

    for (let idx = 0; idx < files.length; idx++) {
      let ret = 0;
      if (
        fileStates[idx].state === 0 ||
        fileStates[idx].state === 3 ||
        fileStates[idx].state === 4
      ) {
        setFileState(idx, 1);
        ret = await uploadPDF(files[idx], idx, organizationId);

        //Fake upload for ui testing
        /*await new Promise((r) => setTimeout(r, 1000));
        setFileState(idx, 2);*/
      }
      if (ret === 1) {
        break;
      }
    }

    setIsUploading(false);
  };

  const uploadPDF = async (file, idx, organizationId) => {
    let local_file = new FormData();
    local_file.append('file', file);
    local_file.append('fileName', file.name);
    return ApiServiceServerless.post(
      `plug/upload/${organizationId}`,
      local_file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        authorization_id: organizationId,
      }
    )
      .then(() => {
        setFileState(idx, 2);
        return 0;
      })
      .catch((error) => {
        let state = 3;
        if (
          error.response &&
          error.response.status &&
          error.response.status === 501
        ) {
          state = 4;
        }
        setFileState(idx, state);
        return 1;
      });
  };

  const setFileState = (idx, state) => {
    setFileStates((prev) =>
      prev.map((state_item, idx2) => {
        if (idx !== idx2) return state_item;
        return { ...state_item, state: state };
      })
    );
  };

  useEffect(() => {
    let state_count = 0;

    for (let idx = 0; idx < fileStates.length; idx++) {
      if (
        fileStates[idx].state === 0 ||
        fileStates[idx].state === 3 ||
        fileStates[idx].state === 4
      ) {
        state_count = 1;
        break;
      }
    }
    setStateCount(state_count);
  }, [fileStates]);

  const resetModal = () => {
    setFiles([]);
    setFileStates([]);
    setResetTrigger();
  };

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton={!isUploading}>Upload Files</Modal.Header>
      <Modal.Body
        style={{ minHeight: '200px', maxHeight: '500px', overflowY: 'auto' }}
      >
        {files.length === 0 ? (
          <div>Select Files to Upload</div>
        ) : (
          <>
            {files.map((file, idx) => {
              return (
                <div key={idx}>
                  <FontAwesomeIcon
                    style={
                      isUploading
                        ? styles.cancelStyleDisabled
                        : styles.cancelStyle
                    }
                    icon={faTimesCircle}
                    onClick={() => removeFile(idx)}
                  />{' '}
                  {file.name}
                  {fileStates[idx].state === 1 && (
                    <>
                      {' - '}
                      <FontAwesomeIcon
                        style={styles.loadingStyle}
                        icon={faSyncAlt}
                        className='fa-spin'
                      />
                    </>
                  )}
                  {fileStates[idx].state === 2 && (
                    <>
                      {' - '}
                      <FontAwesomeIcon
                        style={styles.successStyle}
                        icon={faCheck}
                      />
                      {' Success '}
                    </>
                  )}
                  {fileStates[idx].state === 3 && (
                    <>
                      {' - '}
                      <FontAwesomeIcon style={styles.failedStyle} icon={faX} />
                      {' Upload Failed '}
                    </>
                  )}
                  {fileStates[idx].state === 4 && (
                    <>
                      {' - '}
                      <FontAwesomeIcon style={styles.warningStyle} icon={faX} />
                      {' Upload Limit Reached'}
                    </>
                  )}
                </div>
              );
            })}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' disabled={files.length === 0 || isUploading}>
          <FontAwesomeIcon
            style={styles.icon}
            icon={faTimesCircle}
            onClick={() => resetModal()}
          />
        </Button>
        <input
          style={{ display: 'none' }}
          id='upload-pdf'
          type='file'
          accept='.pdf'
          onChange={getPDF}
          key={resetTrigger}
          multiple
        ></input>

        <Button variant='primary' title={'Upload Files'} disabled={isUploading}>
          <label
            htmlFor='upload-pdf'
            style={{
              margin: '0px',
              padding: '0px',
              cursor: 'inherit',
            }}
          >
            <FontAwesomeIcon style={styles.icon} icon={faFiles} />
          </label>
        </Button>
        <Button
          variant='success'
          disabled={files.length === 0 || stateCount === 0 || isUploading}
          onClick={() => uploadFiles()}
        >
          <FontAwesomeIcon style={styles.icon} icon={faUpload} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
