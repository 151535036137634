import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import { sortBy } from 'lodash';

import { ApiServiceServerless } from '../../../xhr_libs';
import AddEntityActionButtons from '../../forms/AddEntityActionButtons';
import consumptionUnits from '../../../helpers/consumptionUnits';
import {
  handleSetAccountKey,
  handleUpdateAccount,
  handleSetNewAccount,
} from '../../../components/UtilityImportSettings/helpers/provider';
import { UTILITY_TYPES } from '../../../helpers/utility-types';
import { fmtBoolYesNo } from '../../tables/formatters';

const formStyle = {
  textAlign: 'left',
};

const default_local_data = {
  id: '',
  linkedAccount: '',
  accountNumber: '',
  utilityProvider: '',
  active: '',
  credentialId: null,
};

function PlugLinkAccountModal(props) {
  const {
    localUtilityAccounts,
    modalData,
    onHide,
    organization,
    buildings,
    provider_type,
    setProviderAccounts,
    setLocalUtilityAccounts,
    handleUpdateTables,
    show,
    showToast,
    activeLicense,
  } = props;

  const [localData, setLocalData] = useState({ ...default_local_data });

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [jointUtilityAccounts, setJointUtilityAccounts] = useState([]);
  const [accountKey, setAccountKey] = useState('');

  const [linkedAccountIsLocked, setLinkedAccountIsLocked] = useState(false);
  const [showNewAccountOpts, setShowNewAccountOpts] = useState(false);

  const handleSetLocalData = (key, val) => {
    setLocalData((prev) => {
      let data = { ...prev };
      data[key] = val;
      return data;
    });
  };

  useEffect(() => {
    if (!modalData.localAccount && localData.linkedAccount) {
      setSubmitEnabled(true);
    } else if (modalData.isStatementsProductActive !== localData.active) {
      setSubmitEnabled(true);
    } else {
      setSubmitEnabled(false);
    }
  }, [modalData, localData]);

  useEffect(() => {
    setLocalData({
      ...modalData,
      active:
        modalData.localAccount && modalData.localAccount.id
          ? modalData.isStatementsProductActive
          : true,
      linkedAccount:
        modalData.localAccount && modalData.localAccount.id
          ? modalData.localAccount.id
          : '',
    });
    if (modalData.localAccount && modalData.localAccount.id) {
      setLinkedAccountIsLocked(true);
    } else {
      setLinkedAccountIsLocked(false);
    }
  }, [modalData]);

  useEffect(() => {
    if (!localData.linkedAccount) {
      setSubmitDisabled(true);
    } else if (
      localData.linkedAccount === 'new' &&
      (!localData.account_type || !localData.buildings)
    ) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [localData]);

  useEffect(() => {
    handleSetAccountKey(provider_type, setAccountKey);
  }, [provider_type]);

  useEffect(() => {
    let jointAccounts = [];
    localUtilityAccounts.forEach((acc) => jointAccounts.push(acc));

    if (
      modalData.localAccount &&
      modalData.localAccount.id &&
      !localUtilityAccounts.find((acc) => acc.id === modalData.localAccount.id)
    ) {
      jointAccounts.push(modalData.localAccount);
    }
    setJointUtilityAccounts(jointAccounts);
  }, [localUtilityAccounts, modalData]);

  useEffect(() => {
    if (show === false) {
      setLocalData({ ...default_local_data });
    }
    setShowNewAccountOpts(false);
    setSubmitDisabled(false);
  }, [show]);

  const onSubmit = (data) => {
    setIsSaving(true);
    maybeCreateNewUtilityAccount(data).then((newAccount) => {
      if (newAccount) {
        if (data.linkedAccount === 'new') {
          handleSetNewAccount(
            provider_type,
            newAccount,
            setLocalUtilityAccounts
          );
          handleUpdateTables(1);
        }
        updateAccount(newAccount.id);
      } else {
        onHide();
      }
    });
  };

  const maybeCreateNewUtilityAccount = async (data) => {
    if (data.linkedAccount !== 'new') return { id: data.linkedAccount };
    const account_type = data.account_type;
    let building_ids = Array.prototype.slice.call(data.buildings);
    building_ids = building_ids.map((item) => item.value);

    const payload = {
      building_ids: building_ids,
      account: {
        account_number: localData.accountNumber,
        account_type: account_type,
        utility_provider: localData.utilityProvider,
        exclude_from_reports: false,
        default_consumption_unit: consumptionUnits[account_type][0],
      },
    };
    const response = await ApiServiceServerless.post(
      '/utility_accounts',
      payload,
      { authorization_id: organization.id }
    ).catch(() => {
      setIsSaving(false);
      showToast('danger', 'Error', 'Error creating new account.');
    });
    return response && response.data ? response.data : null;
  };

  const updateAccount = (newAccountId) => {
    handleUpdateAccount(
      provider_type,
      modalData,
      organization.id,
      localData,
      newAccountId,
      setProviderAccounts,
      setLocalUtilityAccounts,
      setSubmitDisabled,
      showToast,
      onHide,
      setIsSaving
    );
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>Account Import Settings</Modal.Header>
      <Form style={formStyle}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group controlId='plug.formGridNumber'>
                <Form.Label>Imported Account Number</Form.Label>
                <Form.Control
                  name='accountNumber'
                  defaultValue={localData.accountNumber}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group controlId='plug.formGridUtility'>
                <Form.Label>Utility Provider</Form.Label>
                <Form.Control
                  name='utilityProvider'
                  defaultValue={localData.utilityProvider}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group controlId='plug.providerForm.active'>
                <Form.Label>Account Active</Form.Label>
                <Form.Control
                  as='select'
                  name='active'
                  value={localData.active}
                  onChange={(e) =>
                    handleSetLocalData(
                      'active',
                      e.target.value === 'true' ? true : false
                    )
                  }
                >
                  {[true, false].map((opt) => (
                    <option key={`active-opt-${opt}`} value={opt}>
                      {fmtBoolYesNo(opt)}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId='plug.formAccountLink'>
                <Form.Label>Linked EnergyTracer Account</Form.Label>
                <Form.Row>
                  <Col>
                    <Form.Control
                      as='select'
                      name='linkedAccount'
                      value={localData.linkedAccount}
                      disabled={linkedAccountIsLocked || !activeLicense}
                      onChange={(e) => {
                        handleSetLocalData('linkedAccount', e.target.value);
                        setShowNewAccountOpts(e.target.value === 'new');
                      }}
                    >
                      <option key={'link-opt-null'} value={''} hidden>
                        -
                      </option>
                      {jointUtilityAccounts
                        .filter(
                          (acct) =>
                            !acct[accountKey] ||
                            acct[accountKey] === localData.id
                        )
                        .map((acct) => (
                          <option key={`link-opt-${acct.id}`} value={acct.id}>
                            {acct.account_number}
                          </option>
                        ))}
                      <option key={`link-opt-new`} value={'new'}>
                        Create a New Account
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Row>
              </Form.Group>
              {showNewAccountOpts && (
                <CreateNewAccountOpts
                  buildings={buildings}
                  handleSetLocalData={handleSetLocalData}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onSubmit={() => onSubmit(localData)}
            onCancel={onHide}
            submitDisabled={!submitEnabled || submitDisabled || !activeLicense}
            isSubmitting={isSaving}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default PlugLinkAccountModal;

function CreateNewAccountOpts(props) {
  const { buildings, handleSetLocalData } = props;

  return (
    <>
      <Form.Group controlId='plug.providerForm.utilityType'>
        <Form.Label>
          Utility Type
          <span className='text-warning font-weight-bold pl-1'>*</span>
        </Form.Label>
        <Form.Control
          as='select'
          name='account_type'
          defaultValue={''}
          onChange={(e) => handleSetLocalData('account_type', e.target.value)}
        >
          <option key={`type-opt-null`} value={''} hidden disabled>
            {''}
          </option>
          {Object.keys(UTILITY_TYPES).map((type) => (
            <option key={`type-opt-${type}`} value={type}>
              {UTILITY_TYPES[type]}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId='plug.providerForm.buildingsSelect'>
        <Form.Label>Select Building(s)</Form.Label>
        <Form.Control
          as='select'
          name='buildings'
          multiple
          onChange={(e) =>
            handleSetLocalData('buildings', e.target.selectedOptions)
          }
        >
          {sortBy(buildings, ['name']).map((building) => (
            <option key={`building-opt-${building.id}`} value={building.id}>
              {building.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </>
  );
}
