import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { orderBy } from 'lodash';

import MONTHSARRAY from '../../../helpers/months';
import ReportPerformanceIntegrity from '../ReportPerformanceIntegrity';
import NavigationAlert from '../../../components/analysis/NavigationAlert';

const styles = {
  form: {
    textAlignLast: 'center',
    display: 'block',
  },
  label: {
    display: 'flex',
  },
};

export default function PerformanceReport(props) {
  const {
    organizationData,
    updatePayload,
    setPerformanceWarning,
    setPerformanceDisabled,
  } = props;

  const [localProjects, setLocalProjects] = useState({});
  const [performanceProjectId, setPerformanceProjectId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [localPYear, setLocalPYear] = useState(null);
  const [localPMonth, setLocalPMonth] = useState(null);
  const [localPerformanceYears, setLocalPerformanceYears] = useState([]);
  const [localPerformanceMonths, setLocalPerformanceMonths] = useState([]);

  const [stablePMonth, setStablePMonth] = useState(0);

  const [localSelectedBuildings, setLocalSelectedBuildings] = useState([]);

  useEffect(() => {
    if (
      performanceProjectId &&
      organizationData &&
      organizationData.buildings &&
      organizationData.performance_projects
    ) {
      let temp_buildings = [];
      organizationData.buildings.forEach((building) => {
        if (
          !organizationData.performance_projects[
            performanceProjectId
          ].ignore_building_ids.includes(parseInt(building.id))
        ) {
          temp_buildings.push(parseInt(building.id));
        }
      });
      setLocalSelectedBuildings(temp_buildings);
    } else {
      setLocalSelectedBuildings([]);
    }
  }, [organizationData, performanceProjectId]);

  useEffect(() => {
    const temp_projects = orderBy(
      Object.values(organizationData['performance_projects']),
      ['default_project', 'id'],
      ['desc', 'asc']
    );
    setLocalProjects(organizationData['performance_projects']);
    if (temp_projects.length > 0) {
      setPerformanceProjectId(temp_projects[0]['id']);
      updatePayload('performance_project', temp_projects[0]['id']);
    } else {
      setPerformanceProjectId(null);
    }
  }, [organizationData, updatePayload]);

  useEffect(() => {
    let flag = false;
    if (Object.keys(localProjects).length === 0) {
      flag = true;
    }
    if (
      Object.keys(localProjects).length > 0 &&
      performanceProjectId !== null &&
      !localProjects[performanceProjectId]['project_complete']
    ) {
      flag = true;
    }
    setPerformanceDisabled(flag);
  }, [localProjects, performanceProjectId, setPerformanceDisabled]);

  useEffect(() => {
    if (
      performanceProjectId !== null &&
      performanceProjectId in organizationData.ppfi
    ) {
      const performanceYears =
        organizationData.ppfi[performanceProjectId].performance_years &&
        Object.values(
          organizationData.ppfi[performanceProjectId].performance_years
        ).length > 0
          ? Object.values(
              organizationData.ppfi[performanceProjectId].performance_years
            )
          : [];
      const perfYearsLength = performanceYears.length;
      setLocalPerformanceYears(performanceYears);
      setLocalPYear(perfYearsLength);
      updatePayload('performance_year', perfYearsLength);
      setPerformanceWarning(false);
    }
  }, [
    performanceProjectId,
    organizationData,
    updatePayload,
    setPerformanceWarning,
  ]);

  useEffect(() => {
    if (
      performanceProjectId !== null &&
      performanceProjectId in organizationData.ppfi
    ) {
      const performanceMonths = [
        ...(organizationData.ppfi[performanceProjectId].performance_months ||
          []),
      ];

      if (localPYear === localPerformanceYears.length) {
        const perfIndex =
          organizationData.ppfi[performanceProjectId]
            .current_performance_index || 0;
        const monthsAfterPerfIndex = performanceMonths.splice(0, perfIndex);
        setLocalPerformanceMonths(monthsAfterPerfIndex);
      } else {
        setLocalPerformanceMonths(performanceMonths);
      }
    }
  }, [
    localPYear,
    localPerformanceYears,
    organizationData,
    performanceProjectId,
  ]);

  useEffect(() => {
    const perfMonth = localPerformanceMonths.length;
    setLocalPMonth(perfMonth);
    setStablePMonth(perfMonth);
    updatePayload('performance_month', perfMonth);
  }, [localPerformanceMonths, updatePayload]);

  useEffect(() => {
    setLocalPMonth(stablePMonth);
    updatePayload('performance_month', stablePMonth);
  }, [stablePMonth, updatePayload]);

  useEffect(() => {
    if (localPMonth > stablePMonth) {
      setPerformanceWarning(true);
    } else {
      setPerformanceWarning(false);
    }
  }, [stablePMonth, localPMonth, setPerformanceWarning]);

  return (
    <>
      {Object.keys(localProjects).length === 0 ? (
        <div style={{ marginBottom: '1em' }}>
          <NavigationAlert
            mainText={'Performance Project required for this report'}
            buttonText={'Create Project'}
            location={'/analysis'}
          />
        </div>
      ) : (
        <>
          <Form style={styles.form}>
            <Form.Group controlId={'performanceProject'}>
              <Form.Label style={styles.label}>Performance Project</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => {
                  updatePayload(
                    'performance_project',
                    parseInt(e.target.value)
                  );
                  setPerformanceProjectId(parseInt(e.target.value));
                  setLocalPYear(null);
                  setLocalPMonth(null);
                }}
                value={performanceProjectId}
              >
                {orderBy(
                  Object.values(localProjects),
                  ['default_project', 'id'],
                  ['desc', 'asc']
                ).map((project) => (
                  <option key={`report-opt-${project.id}`} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
          <>
            {performanceProjectId !== null &&
            !localProjects[performanceProjectId]['project_complete'] ? (
              <div style={{ marginBottom: '1em' }}>
                The selected project is not complete. Please fill out all fields
                to run this project
              </div>
            ) : (
              <>
                <Form style={styles.form}>
                  <Form.Group controlId={'performanceYear'}>
                    <Form.Label style={styles.label}>
                      Performance Year
                    </Form.Label>
                    <Form.Control
                      as='select'
                      onChange={(e) => {
                        updatePayload(
                          'performance_year',
                          parseInt(e.target.value)
                        );
                        setLocalPYear(parseInt(e.target.value));
                      }}
                      value={localPYear ? localPYear : ''}
                      disabled={isLoading}
                    >
                      {localPerformanceYears.map((pYear, index) => (
                        <option
                          key={`report-opt-${index + 1}`}
                          value={index + 1}
                        >
                          {'Performance Year ' +
                            (index + 1) +
                            ' (' +
                            MONTHSARRAY[pYear[0][1] - 1] +
                            ' ' +
                            pYear[0][0] +
                            ' - ' +
                            MONTHSARRAY[pYear[1][1] - 1] +
                            ' ' +
                            pYear[1][0] +
                            ')'}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
                <Form style={styles.form}>
                  <Form.Group controlId={'performanceMonth'}>
                    <Form.Label style={styles.label}>
                      Performance Month
                    </Form.Label>
                    <Form.Control
                      as='select'
                      onChange={(e) => {
                        updatePayload(
                          'performance_month',
                          parseInt(e.target.value)
                        );
                        setLocalPMonth(parseInt(e.target.value));
                      }}
                      value={localPMonth ? localPMonth : ''}
                      disabled={isLoading}
                    >
                      {localPerformanceMonths.map((pMonth, index) => (
                        <option
                          key={`report-opt-${index + 1}`}
                          value={index + 1}
                        >
                          {MONTHSARRAY[pMonth]}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form>
                <Row>
                  <Col>
                    <ReportPerformanceIntegrity
                      organizationId={organizationData['organization_id']}
                      performanceProjectId={performanceProjectId}
                      localPYear={localPYear}
                      performanceMonths={
                        organizationData.ppfi[performanceProjectId][
                          'performance_months'
                        ]
                      }
                      utilities={
                        localProjects[performanceProjectId]['utility_types']
                      }
                      setStablePMonth={setStablePMonth}
                      selectedBuildings={localSelectedBuildings}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      setPerformanceDisabled={setPerformanceDisabled}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        </>
      )}
    </>
  );
}
