import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Toast } from 'react-bootstrap';
import dayjs from 'dayjs';

const DEFAULT_DELAY = 7000;
const DEFAULT_DELAY2 = 15000;

const AUTOHIDE_VARIANTS = ['success', 'success_button'];

const agoFormatter = (seconds) => {
  if (seconds > 60) return '>1 minute ago';
  return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
};

const countdownFormatter = (remaining) =>
  `${remaining} second${remaining === 1 ? '' : 's'}`;

function ToastMessage(props) {
  const { children, title, variant, onClose } = props;
  const location = useLocation();
  const [prevPath, setPrevPath] = useState(location.pathname);

  const autohide = AUTOHIDE_VARIANTS.includes(variant);
  const delay = variant === 'success_button' ? DEFAULT_DELAY2 : DEFAULT_DELAY;

  const [start] = useState(dayjs());
  const [show, setShow] = useState(true);
  const [ago, setAgo] = useState(
    autohide ? countdownFormatter(delay / 1000) : 'Just Now'
  );

  const handleClose = useCallback(
    (e) => {
      setShow(false);
      onClose && onClose(e);
    },
    [onClose]
  );

  useEffect(() => {
    if (variant === 'success_button' && location.pathname !== prevPath) {
      setShow(false);
    } else {
      setPrevPath(location.pathname);
    }
  }, [location, prevPath, variant]);

  const updateTime = useCallback(() => {
    const seconds = dayjs().diff(start, 'second');
    if (autohide) {
      const remaining = delay / 1000 - seconds;
      remaining > 0 ? setAgo(countdownFormatter(remaining)) : handleClose();
    } else {
      setAgo(agoFormatter(seconds));
    }
  }, [autohide, delay, start, handleClose]);

  useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [updateTime]);

  const getClasses = (variant) => {
    switch (variant) {
      case 'success':
        return 'bg-success text-white';
      case 'success_button':
        return 'bg-success text-white et-toast-buttons';
      case 'warning':
        return 'bg-warning text-dark';
      case 'info':
        return 'bg-info text-white';
      case 'primary':
        return 'bg-primary text-white';
      case 'secondary':
        return 'bg-secondary text-white';
      case 'danger':
        return 'bg-danger text-white';
      default:
        return '';
    }
  };

  return (
    <Toast
      show={show}
      animation={true}
      autohide={autohide}
      delay={autohide ? delay : undefined}
      onClose={(e) => {
        handleClose(e);
      }}
      style={{ width: '18em', backgroundColor: 'rgba(255,255,255,1)' }}
    >
      <Toast.Header className={getClasses(variant)}>
        <strong className='mr-auto'>{title}</strong>
        <small style={{ marginLeft: '1em' }}>{ago}</small>
      </Toast.Header>
      {children && <Toast.Body>{children}</Toast.Body>}
    </Toast>
  );
}

ToastMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.string.isRequired,
};

export default ToastMessage;
