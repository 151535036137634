import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { ApiServiceServerless } from '../../../xhr_libs';
import AppContext from '../../../contexts/app-context';
import AddEntityActionButtons from '../../forms/AddEntityActionButtons';
import InvalidFeedback from '../../forms/InvalidFeedback';

const formStyle = {
  textAlign: 'left',
};

const SuccessMessage = ({ requesteeEmail, handleHide }) => (
  <Modal.Body>
    <div style={{ marginTop: '25px' }}>
      <Alert variant='success'>
        Your credential request has been sent to {requesteeEmail}
      </Alert>
      <div style={{ marginTop: '45px', textAlign: 'center' }}>
        <hr />
        <Button style={{ width: '40%' }} onClick={() => handleHide()}>
          Close
        </Button>
      </div>
    </div>
  </Modal.Body>
);

function RequestCredentialModal(props) {
  const { userEmail } = useContext(AppContext);

  const {
    show,
    onHide,
    credentialId,
    utilityProvider,
    organizationId,
    variant,
    provider_type,
  } = props;

  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [requesteeEmail, setRequesteeEmail] = useState('');

  const { errors, register, handleSubmit, setValue } = useForm({
    shouldUnregister: false, // allows field to populate on useEffect
  });

  const isUpdate = variant === 'update';

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue('requesterEmail', userEmail);
  }, [setValue, userEmail]);

  const onSubmit = (data) => {
    setIsLoading(true);
    const payload = {
      credential_id: isUpdate ? credentialId : null,
      utility_provider: isUpdate ? utilityProvider : '',
      organization_id: organizationId,
      requester_email: data.requesterEmail,
      requestee_email: data.requesteeEmail,
      provider_type: provider_type,
    };
    setRequesteeEmail(data.requesteeEmail);
    setShowSuccessMsg(true);
    const url = isUpdate ? 'providers/share-update' : 'providers/share-request';
    ApiServiceServerless.post(url, payload, {
      authorization_id: organizationId,
    })
      .then(() => {
        setRequesteeEmail(data.requesteeEmail);
        setShowSuccessMsg(true);
      })
      .finally(() => {
        handleHide();
      });
  };

  const handleHide = () => {
    onHide();
    setShowSuccessMsg(false);
    setRequesteeEmail('');
    setValue('requesteeEmail', '');
    setIsLoading(false);
  };

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        {`Request ${isUpdate ? 'Updated' : ''} Credentials`}
      </Modal.Header>
      {showSuccessMsg ? (
        <SuccessMessage
          requesteeEmail={requesteeEmail}
          handleHide={handleHide}
        />
      ) : (
        <Form style={formStyle} onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group controlId='formGridNumber'>
                  <Form.Label>Your Email</Form.Label>
                  <Form.Control
                    name='requesterEmail'
                    placeholder='Enter your email'
                    isInvalid={errors.requesterEmail}
                    ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                  />
                  <Form.Text className='text-muted'>
                    Provided so that the requestee can contact you, if
                    necessary.
                  </Form.Text>
                  <InvalidFeedback
                    errors={errors}
                    name='requesterEmail'
                    message='Please enter a valid email address.'
                  />
                </Form.Group>
                <Form.Group controlId='formGridUtility'>
                  <Form.Label>Requestee Email</Form.Label>
                  <Form.Control
                    name='requesteeEmail'
                    placeholder='Enter requestee email'
                    isInvalid={errors.requesteeEmail}
                    ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='requesteeEmail'
                    message='Please enter a valid email address.'
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <AddEntityActionButtons
              onCancel={handleHide}
              isSubmitting={isLoading}
            />
          </Modal.Footer>
        </Form>
      )}
    </Modal>
  );
}

RequestCredentialModal.propTypes = {
  variant: PropTypes.oneOf(['new', 'update']),
};

export default RequestCredentialModal;
