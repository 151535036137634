export function extractGoogleAddress(googlePlaceObj) {
  if (!googlePlaceObj) return {};
  return {
    address_components: googlePlaceObj.address_components,
    formatted_address: googlePlaceObj.formatted_address,
    place_id: googlePlaceObj.place_id,
    lat: googlePlaceObj.geometry.location.lat(),
    lng: googlePlaceObj.geometry.location.lng(),
  };
}

export function extractGoogleAddressKey(key, googlePlaceObj) {
  if (!googlePlaceObj || !googlePlaceObj.address_components) return '';
  switch (key) {
    case 'city':
      for (const item of googlePlaceObj['address_components']) {
        if (item.types.includes('locality')) {
          return item.long_name;
        }
      }
      break;
    case 'state':
      for (const item of googlePlaceObj['address_components']) {
        if (item.types.includes('administrative_area_level_1')) {
          return item.long_name;
        }
      }
      break;
    default:
      return '';
  }
}

export function formatAddress(googlePlaceObj) {
  if (!googlePlaceObj) return '';
  return googlePlaceObj.formatted_address;
}
