// Create an array of years from minYear to present
const getYears = (minYear) => {
  const currentYear = new Date().getFullYear();
  const length = currentYear - minYear + 1;
  return Array.from({ length }, (_, i) => currentYear - i);
};

const YEARS = getYears(1991);

export default YEARS;
