import React, { useCallback, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { ApiServiceServerless } from '../../xhr_libs';
import Loader from '../../components/Loader';

import EnergyTracerTable from '../../components/tables';
import MONTHSABBREVIATED from '../../helpers/monthsAbbreviated';
import getIntegrityPercent from '../Alerts/BillIntergrityHelpers';
import CHARTCOLORS from '../../helpers/chartColors';

const default_found = {
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
};

const defaultPerformanceMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const styles = {
  column: {
    paddingTop: '10px',
  },
};

const cellFormatter = (val) => {
  let print_val = (val * 100).toFixed(0) + '%';
  if (val > 0.95) {
    return <div style={{ color: 'green' }}>{print_val}</div>;
  } else if (val > 0.66) {
    return <div style={{ color: 'orange' }}>{print_val}</div>;
  } else {
    return <div style={{ color: 'red' }}>{print_val}</div>;
  }
};

const textFormatter = (val) => {
  return <div>{val.charAt(0).toUpperCase() + val.substr(1).toLowerCase()}</div>;
};

const default_found_obj = { found: {}, found_count: {}, found_modes: {} };

export default function ReportPerformanceIntegrity(props) {
  const {
    organizationId,
    performanceProjectId,
    localPYear,
    utilities,
    performanceMonths,
    setStablePMonth,
    selectedBuildings,
    isLoading,
    setIsLoading,
    setPerformanceDisabled,
  } = props;

  const [localTotal, setLocalTotal] = useState({});
  const [localRows, setLocalRows] = useState([]);
  const [localPerformanceMonths, setLocalPerformanceMonths] = useState(
    defaultPerformanceMonths
  );
  const [found, setFound] = useState(default_found_obj);
  const [hasFound, setHasFound] = useState(false);

  const getData = useCallback(() => {
    if (organizationId && performanceProjectId && localPYear) {
      setFound(default_found_obj);
      setHasFound(false);
      setIsLoading(true);
      setPerformanceDisabled(true);
      ApiServiceServerless.get(
        `reports/${organizationId}/${performanceProjectId}/${localPYear}`,
        { authorization_id: organizationId }
      )
        .then((res) => {
          setFound(res.data);
          setHasFound(true);
          setIsLoading(false);
          setPerformanceDisabled(false);
        })
        .catch(() => {
          setFound(default_found_obj);
          setHasFound(false);
          setIsLoading(false);
          setPerformanceDisabled(false);
        });
    }
  }, [
    setIsLoading,
    setPerformanceDisabled,
    organizationId,
    performanceProjectId,
    localPYear,
  ]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (performanceMonths && performanceMonths.length > 0) {
      setLocalPerformanceMonths(performanceMonths);
    } else {
      setLocalPerformanceMonths(defaultPerformanceMonths);
    }
  }, [performanceMonths]);

  useEffect(() => {
    setLocalTotal({});
    let local_total_percent = {};
    let local_total_percent_count = {};
    utilities.forEach((utility) => {
      local_total_percent[utility] = { ...default_found };
      local_total_percent_count[utility] = {
        ...default_found,
      };
    });

    if (selectedBuildings.length > 0 && hasFound && localPYear) {
      Object.keys(found['found']).forEach((building) => {
        if (selectedBuildings.includes(parseInt(building))) {
          Object.keys(found['found'][building]).forEach((account) => {
            utilities.forEach((utility) => {
              if (
                utility in found['found'][building][account] &&
                localPYear.toString() in
                  found['found'][building][account][utility]
              ) {
                let months = Object.keys(
                  found['found'][building][account][utility][localPYear]
                );
                months.forEach((month) => {
                  let local_found =
                    found['found'][building][account][utility][localPYear][
                      month
                    ];
                  let local_found_count =
                    found['found_count'][building][account][utility][
                      localPYear
                    ][month];
                  let local_percent = getIntegrityPercent(
                    local_found,
                    local_found_count,
                    found['found_modes'][building][account][utility]
                  );
                  local_total_percent[utility][month] += local_percent;
                  if (local_found_count !== -1) {
                    local_total_percent_count[utility][month] += 1;
                  }
                });
              }
            });
          });
        }
      });
      setLocalTotal({
        percent: { ...local_total_percent },
        percent_count: { ...local_total_percent_count },
      });
    }
  }, [found, hasFound, localPYear, utilities, selectedBuildings]);

  useEffect(() => {
    setLocalRows([]);
    let local_rows = [];
    let local_row = {};
    const months = Object.keys(default_found);
    if (
      Object.keys(localTotal).length > 0 &&
      Object.keys(localTotal['percent']).length > 0
    ) {
      utilities.forEach((utility) => {
        local_row = {};
        if (
          Object.keys(localTotal['percent']).includes(utility) &&
          Object.keys(localTotal['percent'][utility]).length > 0
        ) {
          months.forEach((month) => {
            let local_total_percent = localTotal['percent'][utility][month];
            let local_total_percent_count =
              localTotal['percent_count'][utility][month];
            let local_percent =
              local_total_percent_count !== 0
                ? local_total_percent / local_total_percent_count
                : 0;
            local_row[month] = local_percent;
          });
          local_row['type'] = utility;
          local_rows.push(local_row);
        }
      });
      setLocalRows(local_rows);
    }
  }, [utilities, localTotal, localPYear]);

  useEffect(() => {
    let stable_p_month = 0;
    if (Object.keys(localTotal).length > 0) {
      for (let j = 0; j < 12; j++) {
        let util_count = 0;
        for (let i = 0; i < utilities.length; i++) {
          if (
            Object.keys(localTotal['percent']).includes(utilities[i]) &&
            Object.keys(localTotal['percent'][utilities[i]]).length > 0
          ) {
            let local_total_percent = localTotal['percent'][utilities[i]][j];
            let local_total_percent_count =
              localTotal['percent_count'][utilities[i]][j];
            let local_percent =
              local_total_percent_count !== 0
                ? local_total_percent / local_total_percent_count
                : 0;
            if (local_percent > 0.9) {
              util_count += 1;
            }
          }
        }
        if (util_count === utilities.length) {
          stable_p_month = j;
        } else {
          break;
        }
      }
    }
    setStablePMonth(stable_p_month + 1);
  }, [localTotal, localPYear, utilities, setStablePMonth]);

  const tableColumns = [
    {
      dataField: 'type',
      text: 'Utility',
      style: styles.column,
      formatter: (cell) => textFormatter(cell),
    },
    {
      dataField: '0',
      text: MONTHSABBREVIATED[localPerformanceMonths[0]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '1',
      text: MONTHSABBREVIATED[localPerformanceMonths[1]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '2',
      text: MONTHSABBREVIATED[localPerformanceMonths[2]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '3',
      text: MONTHSABBREVIATED[localPerformanceMonths[3]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '4',
      text: MONTHSABBREVIATED[localPerformanceMonths[4]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '5',
      text: MONTHSABBREVIATED[localPerformanceMonths[5]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '6',
      text: MONTHSABBREVIATED[localPerformanceMonths[6]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '7',
      text: MONTHSABBREVIATED[localPerformanceMonths[7]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '8',
      text: MONTHSABBREVIATED[localPerformanceMonths[8]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '9',
      text: MONTHSABBREVIATED[localPerformanceMonths[9]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '10',
      text: MONTHSABBREVIATED[localPerformanceMonths[10]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
    {
      dataField: '11',
      text: MONTHSABBREVIATED[localPerformanceMonths[11]],
      style: styles.column,
      formatter: (cell) => cellFormatter(cell),
    },
  ];

  return (
    <>
      {isLoading && (
        <Row style={{ height: '70px', paddingBottom: '30px' }}>
          <Loader />
        </Row>
      )}
      {hasFound && (
        <>
          <Form>
            <Form.Label>
              Data Coverage{' '}
              <a
                href='/alerts#data-coverage'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon
                  style={{
                    fontSize: '18px',
                    color: CHARTCOLORS[1],
                  }}
                  icon={faInfoCircle}
                />
              </a>
            </Form.Label>
          </Form>

          <EnergyTracerTable
            data={localRows}
            columns={tableColumns}
            keyField={'type'}
          />
        </>
      )}
    </>
  );
}
