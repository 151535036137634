export const ghg_units = {
  lbs: 'lbs',
  ton: 'Imperial Tons',
  kg: 'kg',
  mton: 'Metric Tons',
};

export const short_ghg_units = {
  lbs: 'lbs',
  ton: 'ton',
  kg: 'kg',
  mton: 'mton',
};

export const ghg_conversions = {
  lbs: 1,
  ton: 2240,
  kg: 2.20462,
  mton: 2204.62,
};
