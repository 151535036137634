import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const styles = {
  label: {
    display: 'flex',
  },
};

export default function ReportDropdownArray(props) {
  const { updatePayload, payload_key, values, defaultValue, label } = props;

  const [localValue, setLocalValue] = useState(values[defaultValue]);

  useEffect(() => {
    if (payload_key) {
      updatePayload(payload_key, localValue);
    }
  }, [updatePayload, localValue, payload_key]);

  return (
    <Form.Group controlId={label + '-val-select'}>
      <Form.Label style={styles.label}>{label}</Form.Label>
      <Form.Control
        as='select'
        onChange={(e) => setLocalValue(values[e.target.value])}
        defaultValue={localValue}
      >
        {Object.keys(values).map((val) => (
          <option key={`val-opt-${val}`} value={val}>
            {values[val]}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}
