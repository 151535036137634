import React from 'react';
import { Modal } from 'react-bootstrap';
import EnergyTracerTable from '../tables';

const styles = {
  column: {
    paddingTop: '11px',
  },
};

export default function ViewModelModal(props) {
  const { show, onHide, row } = props;

  const tableColumns = [
    {
      dataField: 'building_id',
      text: 'Building',
      style: styles.column,
      formatter: (cell, row) => <>{row.building_name}</>,
    },
    {
      dataField: 'utility_type',
      text: 'Utility',

      style: styles.column,
    },
    {
      dataField: 'name',
      text: 'Name',
      style: styles.column,
    },
    {
      dataField: 'date_range',
      text: 'Date Range',
      style: styles.column,
    },
    {
      dataField: 'stats',
      text: 'Stats',
      style: styles.column,
      formatter: (cell) =>
        cell
          ? `RMSE: ${Number(
              parseFloat(cell.RMSE).toFixed(2)
            ).toLocaleString()} CVRMSE: ${parseFloat(cell.CVRMSE).toFixed(
              3
            )} R2: ${parseFloat(cell.R2).toFixed(3)}`
          : '',
    },
  ];

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>View Model</Modal.Header>
      <Modal.Body>
        <div style={{ padding: '0.5em' }}>
          {row && (
            <EnergyTracerTable
              data={[row]}
              columns={tableColumns}
              keyField='id'
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
