import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

export default function AddProjectModal(props) {
  const {
    show,
    onHide,
    organization_id,
    showToast,
    setActiveProject,
    setActiveProjectId,
    setActiveProjectType,
    setProjects,
    createAccessAnalysis,
    createAccessGHG,
  } = props;

  const [projectName, setProjectName] = useState('');
  const [projectType, setProjectType] = useState('');
  const [disabled, setDisabled] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (projectName && projectType) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [projectName, projectType]);

  const addProject = (e) => {
    e.preventDefault();
    setIsLoading(true);
    ApiServiceServerless.post(
      `/${projectType}_projects/${organization_id}`,
      {
        name: projectName,
      },
      { authorization_id: organization_id }
    )
      .then((res) => {
        setProjects((prev) => [...prev, res.data]);
        setActiveProject(null);
        setActiveProjectId(res.data.id);
        setActiveProjectType(res.data.project_type);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Add Project</Modal.Header>
      <Form onSubmit={addProject}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {'Project Name'}
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Row>
              <Form.Control
                required
                placeholder=''
                onChange={(e) => setProjectName(e.target.value)}
              />
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Project Type
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>

            <Form.Control
              as='select'
              required
              onChange={(e) => setProjectType(e.target.value)}
            >
              <option key={'opt-null'} value={''} hidden>
                -
              </option>
              {createAccessAnalysis && (
                <option key={'opt-performance'} value={'performance'}>
                  Performance Project
                </option>
              )}
              {createAccessGHG && (
                <option key={'opt-ghg'} value={'ghg'}>
                  GHG Project
                </option>
              )}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={onHide}
            submitDisabled={disabled}
            isSubmitting={isLoading}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
