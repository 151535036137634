import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

const null_savings = {
  value: null,
  year: null,
};

export default function AddProjectedSavingsModal(props) {
  const {
    show,
    onHide,
    organizationId,
    project,
    handleRefreshData,
    showToast,
  } = props;

  const [disabled, setDisabled] = useState(false);
  const [savingsData, setSavingsData] = useState(null_savings);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSavingsData(null_savings);
  }, [show]);

  const parseNumber = (val) => {
    const float_val = !isNaN(parseFloat(val.replace(/,/g, '')))
      ? parseFloat(val.replace(/,/g, ''))
      : 0;
    return float_val;
  };

  useEffect(() => {
    setDisabled(
      savingsData['value'] === null ||
        savingsData['value'] === 0 ||
        savingsData['year'] === null ||
        project.projected_savings.filter(
          (saving) => saving.year === savingsData.year
        ).length > 0
    );
  }, [savingsData, project.projected_savings]);

  const addSavings = () => {
    setIsLoading(true);
    ApiServiceServerless.post(
      `/performance_projects/projected_savings/${parseInt(project.id)}`,
      {
        ...savingsData,
      },
      { authorization_id: organizationId }
    )
      .then((res) => {
        showToast('success', 'Success', 'Savings successfully added');
        handleRefreshData(res.data);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton> Add a Projected Savings</Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            {'Value ($) '}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={2}
              fixedDecimalScale
              placeholder={'0.00'}
              onChange={(e) =>
                setSavingsData({
                  ...savingsData,
                  value: parseNumber(e.target.value),
                })
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {'Performance Year (eg. 1,2,..) '}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={0}
              fixedDecimalScale
              onChange={(e) =>
                setSavingsData({
                  ...savingsData,
                  year: parseNumber(e.target.value),
                })
              }
              isInvalid={
                project.projected_savings.filter(
                  (saving) => saving.year === savingsData.year
                ).length > 0
              }
            />
            <Form.Control.Feedback type='invalid'>
              Performance Year Already Exists
            </Form.Control.Feedback>
          </Form.Row>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          onSubmit={addSavings}
          submitDisabled={disabled}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
