import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import EnergyTracerTable from '../../../components/tables';
import AddProjectMeasureModal from '../../../components/modals/AddProjectMeasureModal';
import AddGHGProjectSavingsProjectModal from '../../../components/modals/AddGHGProjectSavingsProjectModal';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

const ghg_savings_projects = {
  1: 'Energy Efficiency Projects',
  2: 'District Energy/ Central Plant Projects',
  3: 'Onsite Renewable Energy Projects',
  4: 'Green Energy Procurements',
  5: 'Carbon Credits (RECs)',
};

export default function ProjectMeasures(props) {
  const {
    organization_id,
    project,
    measures,
    updateList,
    addToList,
    showToast,
    updateAccess,
    deleteAccess,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const handleDelete = (measure_id) => {
    const msg = `Are you sure you want to delete this Project Savings? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteProjectMeasure(measure_id);
    });
  };

  const deleteProjectMeasure = (measure_id) => {
    ApiServiceServerless.delete(
      `/ghg_projects/project_measure/${parseInt(project.id)}/${measure_id}`,
      { authorization_id: organization_id }
    )
      .then((res) => {
        updateList(res.data, 'ghg_measures');
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
      style: styles.column,
    },
    { dataField: 'value', text: 'Value (MTCO2e/yr)', style: styles.column },
    { dataField: 'years', text: 'Duration (yrs)', style: styles.column },
    { dataField: 'offset_years', text: 'Offset (yrs)', style: styles.column },

    {
      dataField: 'savings_type',
      text: 'Project Type',
      style: styles.column,
      formatter: (cell) => ghg_savings_projects[cell],
    },
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell) => (
        <>
          {deleteAccess && (
            <Button
              className='btn btn-danger'
              style={{ maxWidth: '33px', maxHeight: '31px' }}
              onClick={() => {
                handleDelete(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                }}
                icon={faTrashAlt}
              />
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {updateAccess && (
        <Button
          onClick={() => {
            setShowAddNew(true);
          }}
          style={{ marginBottom: '1em', float: 'right', marginLeft: '1em' }}
        >
          Add New Project Saving
        </Button>
      )}
      {updateAccess && (
        <Button
          onClick={() => {
            setShowAdd(true);
          }}
          style={{ marginBottom: '1em', float: 'right' }}
        >
          Add Existing Project Saving
        </Button>
      )}

      <EnergyTracerTable
        data={measures.filter((measure) =>
          measure.project_ids.includes(project.id)
        )}
        columns={tableColumns}
        keyField={'id'}
      />

      <ConfirmationModal {...confirmationModalProps} />
      <AddProjectMeasureModal
        show={showAdd}
        onHide={() => setShowAdd(false)}
        project_type={'ghg'}
        project_id={project.id}
        organization_id={organization_id}
        measures={measures}
        updateList={updateList}
        showToast={showToast}
      />
      <AddGHGProjectSavingsProjectModal
        show={showAddNew}
        onHide={() => setShowAddNew(false)}
        org_id={organization_id}
        defaultProject={project}
        projects={[project]}
        addToList={addToList}
        showToast={showToast}
        ghg_savings_projects={ghg_savings_projects}
      />
    </>
  );
}
