import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarEdit } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { faSquareDashedCirclePlus } from '@fortawesome/pro-solid-svg-icons';

import AnalysisDateSelect from './AnalysisDateSelect';
import PopoverTrigger from '../../components/PopoverTrigger';
import CHARTCOLORS from '../../helpers/chartColors';
import RegressionAdvancedDatesModal from '../modals/RegressionAdvancedDatesModal';
import RegressionEventsSelectModal from '../modals/RegressionEventsSelectModal';

export default function AnalysisYears(props) {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    utilityType,
    regressionType,
    getBaselineData,
    clearRegression,
    title,
    showAdvancedSelect,
    setIgnoreDateKeys,
    setLoadedIgnoreDateKeys,
    loadedIgnoreDateKeys,
    regressionEventCollections,
    setRegressionCollection,
    loadedRegressionCollection,
    showEventSelect,
    startDateSelect,
    endDateSelect,
  } = props;

  const styles = {
    inputStyle: {
      width: '400px',
      marginRight: '5px',
      textAlign: 'center',
    },
    buttonStyle: {
      fontSize: '26px',
      color: utilityType ? CHARTCOLORS[1] : CHARTCOLORS[2],
    },
    buttonStyle2: {
      fontSize: '26px',
      color: utilityType ? CHARTCOLORS[0] : CHARTCOLORS[2],
      cursor: utilityType ? 'pointer' : 'none',
      pointerEvents: utilityType ? 'all' : 'none',
      marginLeft: '5px',
    },
    buttonContainerStyle: {
      display: 'inline-block',
      cursor: 'pointer',
      pointerEvents: utilityType ? 'auto' : 'none',
    },
  };

  const [showAdvancedModal, setShowAdvancedModal] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);

  return (
    <>
      <Col style={{ textAlign: 'center', marginBottom: '10px' }}>
        <Row>
          <Form.Label>{title ? title : 'Baseline date range'}</Form.Label>
        </Row>
        <Row>
          <Form.Control
            style={styles.inputStyle}
            size='sm'
            disabled
            value={
              startDate && endDate && utilityType
                ? dayjs.utc(startDate).format('MMMM YYYY') +
                  ' - ' +
                  dayjs.utc(endDate).format('MMMM YYYY')
                : ''
            }
          ></Form.Control>

          <div id='analysis-popover-button' style={styles.buttonContainerStyle}>
            <PopoverTrigger
              rootClose={true}
              popoverTitle={'Edit Date Selection'}
              popoverContent={
                <AnalysisDateSelect
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  utilityType={utilityType}
                  regressionType={regressionType}
                  getBaselineData={getBaselineData}
                  clearRegression={clearRegression}
                  startDateSelect={startDateSelect}
                  endDateSelect={endDateSelect}
                />
              }
              trigger='click'
              placement='bottom'
            >
              <FontAwesomeIcon
                style={styles.buttonStyle}
                icon={faCalendarEdit}
                id={'analysis-date-popover'}
                title={'Select Date Range'}
              />
            </PopoverTrigger>
          </div>
          {showAdvancedSelect && (
            <FontAwesomeIcon
              style={styles.buttonStyle2}
              icon={faCalendarTimes}
              onClick={() => setShowAdvancedModal(true)}
              title={'Exclude Dates'}
            />
          )}
          {showEventSelect && (
            <FontAwesomeIcon
              style={styles.buttonStyle2}
              icon={faSquareDashedCirclePlus}
              onClick={() => setShowEventModal(true)}
              title={'Regression Events'}
            />
          )}
        </Row>
      </Col>
      <RegressionAdvancedDatesModal
        show={showAdvancedModal}
        onHide={() => setShowAdvancedModal(false)}
        startDate={startDate}
        endDate={endDate}
        setIgnoreDateKeys={setIgnoreDateKeys}
        loadedIgnoreDateKeys={loadedIgnoreDateKeys}
        setLoadedIgnoreDateKeys={setLoadedIgnoreDateKeys}
        clearRegression={clearRegression}
      />
      <RegressionEventsSelectModal
        show={showEventModal}
        onHide={() => setShowEventModal(false)}
        regressionEventCollections={regressionEventCollections}
        setRegressionCollection={setRegressionCollection}
        loadedRegressionCollection={loadedRegressionCollection}
        getBaselineData={getBaselineData}
        utilityType={utilityType}
        regressionType={regressionType}
        startDate={startDate}
        endDate={endDate}
        clearRegression={clearRegression}
      />
    </>
  );
}

AnalysisYears.propTypes = {
  showAdvancedSelect: PropTypes.bool,
  setIgnoreDateKeys: PropTypes.func,
  loadedIgnoreDateKeys: PropTypes.array,
  setLoadedIgnoreDateKeys: PropTypes.func,
  regressionEventCollections: PropTypes.array,
  showEventSelect: PropTypes.bool,
  startDateSelect: PropTypes.bool,
  endDateSelect: PropTypes.bool,
};

AnalysisYears.defaultProps = {
  showAdvancedSelect: false,
  showEventSelect: false,
  setIgnoreDateKeys: () => {},
  setLoadedIgnoreDateKeys: () => {},
  loadedIgnoreDateKeys: [],
  regressionEventCollections: [],
  startDateSelect: true,
  endDateSelect: true,
};
