import React, { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import dayjs from 'dayjs';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export default function DashboardDateSelect(props) {
  const { startDate, setStartDate, endDate, setEndDate } = props;

  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);

  const formStyle = {
    fontSize: 14,
  };

  const handleLocalDate = (newDate) => {
    let end_date = dayjs.utc(newDate).endOf('month');
    let start_date = end_date;
    start_date = start_date.month(start_date.month() - 11).startOf('month');

    setLocalEndDate(end_date);
    setLocalStartDate(start_date);
  };

  const updateDateSelection = () => {
    if (dayjs(localEndDate).isValid()) {
      setStartDate(localStartDate);
      setEndDate(localEndDate);
    } else {
      setStartDate(startDate);
      setEndDate(endDate);
    }
    document.getElementById('dashboard-popover-button').click();
  };

  return (
    <>
      <Form style={formStyle}>
        <Col>
          <Form.Row>
            <Form.Group>
              <InputGroup size='sm'>
                <DatePicker
                  selected={localEndDate ? new Date(localEndDate) : new Date()}
                  dateFormat='MM/yyyy'
                  onChange={(date) => handleLocalDate(date)}
                  showMonthYearPicker
                  showTwoColumnMonthYearPicker
                />
              </InputGroup>
            </Form.Group>
          </Form.Row>

          <Button
            onClick={(e) => updateDateSelection()}
            data-id='db_date_confirm'
          >
            {' '}
            Confirm
          </Button>
        </Col>
      </Form>
    </>
  );
}
