import React from 'react';
import { ListGroupItem, ListGroup, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { setUserOrganizationPreference } from '../../helpers/user-preferences';

function BuildingMissingBenchmarkModal(props) {
  const { benchmarkMissing, buildingData, org_id, show, onHide } = props;

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          Missing Building Types
        </Modal.Header>
        <Modal.Body
          style={{
            overflowY: 'auto',
            maxHeight: '65vh',
            marginBottom: '10px',
          }}
        >
          <p>
            Buildings with missing types use the average for their climate zone,
            which can cause incorrect benchmarking.
          </p>
          <ListGroup>
            <ListGroupItem>
              <b>Building Name</b>
            </ListGroupItem>
            {benchmarkMissing.map((building) => (
              <ListGroupItem key={`missing-bldg-type-item-${building}`}>
                <Link
                  to='/resources'
                  target='_blank'
                  onClick={() =>
                    setUserOrganizationPreference(
                      'bnf',
                      org_id,
                      buildingData[building].name
                    )
                  }
                >
                  {buildingData[building].name}
                </Link>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BuildingMissingBenchmarkModal;
