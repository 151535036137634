import React, { useEffect, useState } from 'react';

import { ReportLookup } from '../ReportHelpers';
import ExcelReport from './ExcelReport';
import TrendReport from './TrendReport';
import UsageReport from './UsageReport';
import TranscriptReport from './TranscriptReport';
import PerformanceReport from './PerformanceReport';
import AnalysisReport from './AnalysisReport';
import CarbonTranscriptReport from './CarbonTranscriptReport';

import Loader from '../../../components/Loader';
import GHGSummaryReport from './GHGSummaryReport';
import { NoDataReports, SavingsDateReports } from '../ReportHelpers';
import GHGBaselineReport from './GHGBaselineReport';
import ProjectFeasibilityReport from './ProjectFeasibilityReport';
import OperationalSavingsReport from './OperationalSavingsReport';
import ExcelProviderReport from './ExcelProviderReport';
import ExcelPorfolioManagerReport from './ExcelPorfolioManagerReport';
import NavigationAlert from '../../../components/analysis/NavigationAlert';

export default function ReportOptions(props) {
  const {
    variant,
    reportPrimaryType,
    reportSecondaryType,
    organizationData,
    setDisabled,
    isLoading,
    setBuildingHide,
  } = props;

  const [noData, setNoData] = useState(false);

  useEffect(() => {
    let disabled = false;
    let NoData = false;
    if (
      Object.keys(organizationData).length === 0 ||
      !organizationData['min_date'] ||
      !organizationData['max_date']
    ) {
      disabled = true;
    }

    if (
      !NoDataReports.includes(reportSecondaryType) &&
      (Object.keys(organizationData).length === 0 ||
        !organizationData['min_date'] ||
        !organizationData['max_date'])
    ) {
      NoData = true;
      disabled = true;
    }
    if (
      SavingsDateReports.includes(reportSecondaryType) &&
      Object.keys(organizationData).length === 0
    ) {
      disabled = true;
    }

    if (
      (reportSecondaryType === 'ghg_baseline' ||
        reportSecondaryType === 'ghg_glide_path') &&
      (Object.keys(organizationData).length === 0 ||
        Object.keys(organizationData['ghg_projects']).length === 0)
    ) {
      disabled = true;
    }
    setDisabled(disabled);
    setNoData(NoData);
  }, [organizationData, setDisabled, setBuildingHide, reportSecondaryType]);

  useEffect(() => {
    const report_type = ReportLookup.find(
      (report) => report.type === reportPrimaryType
    ).reportInfo.find((report) => report.type === reportSecondaryType);
    setBuildingHide(report_type && report_type.building_hide);
  }, [reportPrimaryType, reportSecondaryType, setBuildingHide, variant]);

  return isLoading ? (
    <Loader />
  ) : noData ? (
    <NavigationAlert
      mainText={'No utility bills have been found.'}
      buttonText={'Check Bills'}
      location={'/resources'}
    />
  ) : (
    getReportOption(reportSecondaryType, props)
  );
}

const getReportOption = (reportSecondaryType, props) => {
  switch (reportSecondaryType) {
    case 'excel':
      return <ExcelReport {...props} />;
    case 'excel_provider':
      return <ExcelProviderReport {...props} />;
    case 'excel_portfolio_manager':
      return <ExcelPorfolioManagerReport {...props} />;
    case 'trend':
      return <TrendReport {...props} />;
    case 'trend_excel':
      return <TrendReport {...props} />;
    case 'trend_building':
      return <TrendReport {...props} />;
    case 'usage':
      return <UsageReport {...props} />;
    case 'usage_landscape':
      return <UsageReport {...props} />;
    case 'transcript':
      return <TranscriptReport {...props} />;
    case 'performance':
      return <PerformanceReport {...props} />;
    case 'performance_building':
      return <PerformanceReport {...props} />;
    case 'performance_excel':
      return <PerformanceReport {...props} />;
    case 'analysis':
      return <AnalysisReport {...props} />;
    case 'analysis_excel':
      return <AnalysisReport {...props} />;
    case 'ghg_summary':
      return <GHGSummaryReport {...props} />;
    case 'feasibility_excel':
      return <ProjectFeasibilityReport {...props} />;
    case 'feasibility':
      return <ProjectFeasibilityReport {...props} pdf={true} />;
    case 'operational_savings':
      return <OperationalSavingsReport {...props} />;
    case 'carbon_transcript':
      return <CarbonTranscriptReport {...props} />;
    case 'ghg_baseline':
      return <GHGBaselineReport {...props} with_units={true} />;
    case 'ghg_glide_path':
      return <GHGBaselineReport {...props} with_units={false} />;
    default:
      return <></>;
  }
};
