import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Helmet from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import LoginPageDecoration from '../components/LoginPageDecoration';
import InvalidFeedback from '../components/forms/InvalidFeedback';
import ReCaptcha, { withReCaptcha } from '../components/ReCaptcha';
import { ApiServicePublicServerless } from '../xhr_libs';
import { setAuthToken } from '../auth';

const PAGE_TITLE = 'Sign Up';

const SuccessMessage = () => (
  <div style={{ marginTop: '25px' }}>
    <Alert variant='success'>
      Thanks for signing up! You should receive an email to validate your email
      address. Please click the link in the email to validate, then return to{' '}
      <Link to='/login'>login</Link> to start using your Account.
      <br />
      <small>You will be redirected automatically in 10s.</small>
    </Alert>
    <Link to='/login'>Back to Login</Link>
  </div>
);

function Register({ history, location, showToast }) {
  const [token, setToken] = useState(null);
  const [emailParam, setEmailParam] = useState('');
  const [password, setPassword] = useState('');
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [sumbitting, setSubmitting] = useState(false);

  const { register, errors, handleSubmit } = useForm();
  const recaptchaRef = useRef();

  const onSubmit = (data) => {
    setSubmitting(true);
    withReCaptcha(() => {
      ApiServicePublicServerless.post('/register', {
        first_name: data.firstName,
        last_name: data.lastName,
        email: emailParam ? emailParam : data.email,
        password: data.password1,
        token: token,
      })
        .then((res) => {
          if (emailParam) {
            setAuthToken(res);
            history.push('/');
          } else {
            setShowSuccessMsg(true);
            showToast('success', 'Success', 'Registered a new account');
            setTimeout(() => history.push('/login'), 10000);
          }
        })
        .catch((err) => {
          setSubmitting(false);
          showToast('danger', 'Error', err);
          throw err;
        });
    }, recaptchaRef);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('password_token');
    const email = params.get('email');
    if (token !== '') {
      setToken(token);
      setEmailParam(email);
    }
  }, [location.search]);

  return (
    <>
      <ReCaptcha ref={recaptchaRef} />
      <LoginPageDecoration />
      <Container
        style={{ marginTop: '2em', width: '450px' }}
        className='et-unauth-container'
      >
        <Helmet>
          <title>{PAGE_TITLE}</title>
        </Helmet>
        <Card style={{ border: 'none' }}>
          <Card.Body className={'login-cardbody'}>
            <h4 className={'mb-4'}>{PAGE_TITLE}</h4>
            {showSuccessMsg ? (
              <SuccessMessage />
            ) : (
              <Form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                style={{ textAlign: 'left' }}
              >
                <Form.Group>
                  <Form.Label>
                    Email
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    type='email'
                    name='email'
                    placeholder=''
                    isInvalid={errors.email}
                    defaultValue={emailParam}
                    disabled={emailParam}
                    ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='email'
                    message='Please provide a valid email address'
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    First name
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    placeholder=''
                    name='firstName'
                    isInvalid={errors.firstName}
                    ref={register({ required: true })}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='firstName'
                    message='Field is required'
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Last name
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    placeholder=''
                    name='lastName'
                    isInvalid={errors.lastName}
                    ref={register({ required: true })}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='lastName'
                    message='Field is required'
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Password
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    type='password'
                    name='password1'
                    placeholder=''
                    isInvalid={errors.password1}
                    ref={register({ required: true, minLength: 8 })}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='password1'
                    message='Password must be at least 8 characters long'
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Confirm Password
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    type='password'
                    name='password2'
                    placeholder=''
                    isInvalid={errors.password2}
                    ref={register({
                      required: true,
                      validate: { match: (value) => value === password },
                    })}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='password2'
                    message='Must match first password'
                  />
                </Form.Group>
                <hr />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    className={'btn-success'}
                    style={{
                      width: '100%',
                      marginTop: '5px',
                      marginBottom: '15px',
                    }}
                    type='submit'
                    disabled={sumbitting}
                  >
                    {sumbitting ? (
                      <>
                        {'Loading... '}
                        <FontAwesomeIcon
                          className='fa-spin'
                          icon={faSyncAlt}
                        />{' '}
                      </>
                    ) : (
                      'Sign Up'
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

Register.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(Register);
