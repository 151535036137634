import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import EnergyTracerTable from '../tables';
import RegressionModal from '../modals/RegressionModal';
import { ApiServiceServerless } from '../../xhr_libs';
import SmallerLoader from '../SmallerLoader';

const styles = {
  column: {
    paddingTop: '11px',
  },
  buttonColumn: {
    width: '200px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  button: {
    width: '70px',
  },
  loadingButton: {
    width: '120px',
  },
};

function ExistingModels(props) {
  const {
    organization_id,
    building_id,
    utilityType,
    regressionType,
    existingModels,
    projects,
    handleLoadRegression,
    updateList,
    removeFromList,
    updateAccess,
    deleteAccess,
    showToast,
    isLoading,
  } = props;

  const [regressionToEdit, setRegressionToEdit] = useState(-1);
  const [regressionToEditObj, setRegressionToEditObj] = useState({});

  const [tableData, setTableData] = useState([]);

  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setTableData((prev) =>
      prev.map((data) => {
        return { ...data, isLoading: isLoading };
      })
    );
  }, [isLoading]);

  useEffect(() => {
    if (regressionToEdit === -1) {
      setRegressionToEditObj({});
    } else {
      setRegressionToEditObj(
        existingModels.find((model) => model.id === regressionToEdit)
      );
    }
  }, [regressionToEdit, existingModels]);

  const saveExistingRegression = (
    regressionId,
    name,
    handleSuccess,
    handleError
  ) => {
    ApiServiceServerless.post(
      `/analysis/${regressionId}/save`,
      { name: name },
      {
        authorization_id: organization_id,
      }
    )
      .then((res) => {
        updateList(res.data, 'models');
        showToast('success', 'Success', 'Your changes were saved.');
        handleSuccess();
      })
      .catch(() => {
        showToast(
          'danger',
          'Unauthorized',
          'You do not have permission to edit regressions.'
        );
        handleError();
      });
  };

  const deleteRegression = (regressionId) => {
    setIsDeleting(true);
    ApiServiceServerless.delete(`/analysis/${regressionId}`, {
      authorization_id: organization_id,
    })
      .then(() => {
        showToast('success', 'Success', 'A regression was deleted.');
        removeFromList(regressionId, 'models');
        setIsDeleting(false);
      })
      .catch(() => {
        showToast(
          'danger',
          'Unauthorized',
          'You do not have permission to delete regressions.'
        );
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    let temp_data = [];
    if (
      building_id !== null &&
      utilityType !== null &&
      regressionType !== null
    ) {
      let models = existingModels.filter(
        (model) =>
          model.building_id === parseInt(building_id) &&
          model.utility_type === utilityType &&
          model.regression_type === regressionType
      );
      models.forEach((model) => {
        let temp_obj = { ...model };
        temp_obj['project_names'] = [];
        temp_obj['project_ids'].forEach((project_id) => {
          let project = projects.filter(
            (project) =>
              project.id === parseInt(project_id) &&
              project.project_type === 'performance'
          );
          if (project.length > 0) {
            temp_obj['project_names'].push(project[0].name);
          }
        });
        temp_data.push(temp_obj);
      });
    }
    setTableData(temp_data);
  }, [existingModels, projects, building_id, utilityType, regressionType]);

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Name',
      style: styles.column,
    },
    {
      dataField: 'date_range',
      text: 'Date Range',
      style: styles.column,
    },
    {
      dataField: 'stats',
      text: 'Stats',
      style: styles.column,
      formatter: (cell) =>
        cell
          ? `RMSE: ${Number(
              parseFloat(cell.RMSE).toFixed(2)
            ).toLocaleString()} CVRMSE: ${parseFloat(cell.CVRMSE).toFixed(
              3
            )} R2: ${parseFloat(cell.R2).toFixed(3)}`
          : '',
    },
    {
      dataField: 'project_names',
      text: 'Project List',
      style: styles.column,
      formatter: (cell, row) =>
        cell.length === 0 ? (
          <>No Projects</>
        ) : (
          <>
            {cell.map((name, idx) => (
              <div key={idx}>{name}</div>
            ))}
          </>
        ),
    },
    {
      dataField: 'id',
      text: 'Actions',
      formatter: (cell, row) => (
        <div style={styles.buttonContainer}>
          <Button
            style={
              row.isLoading && row.isLoading === row.id.toString()
                ? styles.loadingButton
                : styles.button
            }
            variant='outline-primary'
            onClick={() => handleLoadRegression(cell)}
            disabled={row.isLoading && row.isLoading !== '-1'}
          >
            {row.isLoading && row.isLoading === row.id.toString() ? (
              <SmallerLoader text={'Loading...'} />
            ) : (
              <>Load</>
            )}
          </Button>

          {updateAccess && (
            <Button
              style={styles.button}
              variant='outline-primary'
              onClick={() => setRegressionToEdit(cell)}
              disabled={row.isLoading && row.isLoading !== '-1'}
            >
              Edit
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {tableData.length ? (
        <>
          <h5 style={{ textAlign: 'left' }}>
            {utilityType.charAt(0).toUpperCase() +
              utilityType.substr(1).toLowerCase()}{' '}
            {regressionType.charAt(0).toUpperCase() +
              regressionType.substr(1).toLowerCase()}{' '}
            Models
          </h5>
          <EnergyTracerTable data={tableData} columns={tableColumns} />
          <RegressionModal
            variant='edit'
            show={Object.keys(regressionToEditObj).length !== 0}
            onHide={() => setRegressionToEdit(-1)}
            saveRegression={saveExistingRegression}
            regression={regressionToEditObj}
            utilityType={utilityType}
            building_id={building_id}
            deleteRegression={deleteRegression}
            deleteAccess={deleteAccess}
            updateAccess={updateAccess}
            isDeleting={isDeleting}
          />
        </>
      ) : (
        'No Existing Models for the Building and Utility Type Selected'
      )}
    </>
  );
}

export default ExistingModels;
