import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import MONTHS from '../../../helpers/months';
import YEARS from '../../../helpers/years';

export default function BillDateSelect(props) {
  const { type, value, onChange } = props;
  const options = type === 'year' ? YEARS : MONTHS;
  const disabled = props.disabled || false;

  return (
    <Form.Control
      data-id='org_settings_date'
      aria-label={`utility-${type}`}
      as='select'
      value={value === null ? '' : value}
      onChange={onChange}
      disabled={disabled}
    >
      <option key={`${type}-opt-null`} value={null}>
        {type.charAt(0).toUpperCase() + type.slice(1, type.length)}
      </option>
      {options.map((data, index) => (
        <option
          key={`${type}-opt-${index}`}
          value={type === 'year' ? data : index}
        >
          {data}
        </option>
      ))}
    </Form.Control>
  );
}

BillDateSelect.propTypes = {
  type: PropTypes.oneOf(['year', 'month']).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
