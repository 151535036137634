import React from 'react';
import { Form, Col } from 'react-bootstrap';
import EditEntityActionButtons from '../../../../components/forms/EditEntityActionButtons';

import AddressWrapper from '../../../../helpers/AddressWrapper';

export default function OrganizationContactsForm(props) {
  const {
    localContact,
    saveContact,
    handleChange,
    handleDeleteContact,
    updateAccess,
    handleResetContact,
    formKey,
    isSaving,
    isDeleting,
  } = props;

  return (
    <Form
      style={{ width: '60%', marginLeft: '20%', paddingBottom: '15px' }}
      onSubmit={(e) => saveContact(e, localContact.id)}
      key={formKey}
    >
      <Form.Row>
        <Form.Group as={Col} controlId='editContactForm.contactName'>
          <Form.Label>Contact Name</Form.Label>
          <Form.Control
            required
            placeholder=''
            defaultValue={localContact.name}
            onChange={(e) =>
              handleChange(localContact.id, 'name', e.target.value)
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId='editContactForm.contactTitle'>
          <Form.Label>Contact Title</Form.Label>
          <Form.Control
            defaultValue={localContact.title}
            onChange={(e) =>
              handleChange(localContact.id, 'title', e.target.value)
            }
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId='editContactForm.contactEmail'>
          <Form.Label>Contact email</Form.Label>
          <Form.Control
            required
            type='email'
            defaultValue={localContact.email}
            onChange={(e) =>
              handleChange(localContact.id, 'email', e.target.value)
            }
          />
        </Form.Group>
        <Form.Group as={Col} controlId='editContactForm.contactPhone'>
          <Form.Label>Contact Phone Number</Form.Label>
          <Form.Control
            type='phone'
            defaultValue={localContact.phone}
            onChange={(e) =>
              handleChange(localContact.id, 'phone', e.target.value)
            }
          />
        </Form.Group>
      </Form.Row>
      <AddressWrapper
        notRequired={true}
        address={localContact.address}
        onAddressSelect={(value) =>
          handleChange(localContact.id, 'address', value)
        }
      />
      <Form.Row>
        <Form.Group as={Col} controlId='editContactForm.admin'>
          <Form.Label>Administrative Contact</Form.Label>
          <Form.Check inline className='ml-3 align-text-bottom'>
            <Form.Check.Input
              style={{ height: '1.1rem', width: '1.1rem' }}
              checked={localContact.admin}
              onChange={(e) =>
                handleChange(localContact.id, 'admin', !localContact.admin)
              }
            />
          </Form.Check>
        </Form.Group>

        <Form.Group as={Col} controlId='editContactForm.billing'>
          <Form.Label>Billing Contact</Form.Label>
          <Form.Check inline className='ml-3 align-text-bottom'>
            <Form.Check.Input
              style={{ height: '1.1rem', width: '1.1rem' }}
              checked={localContact.billing}
              onChange={(e) =>
                handleChange(localContact.id, 'billing', !localContact.billing)
              }
            />
          </Form.Check>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId='editContactForm.facility'>
          <Form.Label>Facility Contact</Form.Label>
          <Form.Check inline className='ml-3 align-text-bottom'>
            <Form.Check.Input
              style={{ height: '1.1rem', width: '1.1rem' }}
              checked={localContact.facility}
              onChange={(e) =>
                handleChange(
                  localContact.id,
                  'facility',
                  !localContact.facility
                )
              }
            />
          </Form.Check>
        </Form.Group>
        <Form.Group as={Col} controlId='editContactForm.reports'>
          <Form.Label>Report Recipient</Form.Label>
          <Form.Check inline className='ml-3 align-text-bottom'>
            <Form.Check.Input
              style={{ height: '1.1rem', width: '1.1rem' }}
              checked={localContact.reports}
              onChange={(e) =>
                handleChange(localContact.id, 'reports', !localContact.reports)
              }
            />
          </Form.Check>
        </Form.Group>
      </Form.Row>
      <EditEntityActionButtons
        onDelete={() => handleDeleteContact(localContact.id)}
        onCancel={() => handleResetContact(localContact.id)}
        submitShow={updateAccess}
        deleteShow={updateAccess}
        isSubmitting={isSaving}
        isDeleting={isDeleting}
      />
    </Form>
  );
}
