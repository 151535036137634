import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export default function ResourceDetails(props) {
  const { header, value, variant, prefix, suffix } = props;

  const [localValue, setLocalValue] = useState('--');

  const [addressPieces, setAddressPieces] = useState(['--']);

  useEffect(() => {
    if (value) {
      if (variant === 'date') {
        setLocalValue(dayjs.utc(value).format('MMM YYYY'));
      } else if (variant === 'address') {
        formatAddress(value);
      } else {
        setLocalValue(value);
      }
    } else {
      setLocalValue('--');
    }
  }, [value, variant]);

  const formatAddress = (value) => {
    let number = '';
    let route = '';
    let city = '';
    let state = '';
    let zip = '';

    if (value && value.address_components) {
      value.address_components.forEach((comp) => {
        if (comp.types.includes('street_number')) {
          number = comp.short_name;
        }
        if (comp.types.includes('route')) {
          route = comp.short_name;
        }
        if (
          comp.types.includes('locality') ||
          comp.types.includes('political')
        ) {
          city = comp.short_name;
        }
        if (comp.types.includes('administrative_area_level_1')) {
          state = comp.short_name;
        }
        if (comp.types.includes('postal_code')) {
          zip = comp.short_name;
        }
      });
      setAddressPieces([number + ' ' + route, city + ', ' + state + ' ' + zip]);
    }
  };

  return (
    <div style={{ textAlign: 'left' }}>
      <div style={{ fontSize: '0.8em', marginBottom: '0.5em' }}>{header}</div>
      {(variant === 'default' || variant === 'date') && <div>{localValue}</div>}
      {variant === 'address' && (
        <>
          <div>{addressPieces[0]}</div>
          <div>{addressPieces[1]}</div>
        </>
      )}
      {variant === 'number' &&
        (prefix ? prefix : '') +
          localValue.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          }) +
          (suffix ? suffix : '')}
    </div>
  );
}

ResourceDetails.defaultProps = {
  variant: 'default',
};
