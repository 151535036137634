import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import {
  ScatterChart,
  Scatter,
  CartesianGrid,
  Legend,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Label,
} from 'recharts';
import dayjs from 'dayjs';

import RegressionInfoTable from './SubComponents/RegressionInfoTable';
import { REGRESSORS, REGRESSORS_UNITS, UTILITY_TYPE_UNITS } from './regressors';
import CHARTCOLORS from '../../helpers/chartColors';
import MONTHNAMES from '../../helpers/monthsAbbreviated';
import DownloadChartData from '../DownloadChartData';
import Loader from '../Loader';

const styles = {
  axisSelectLabel: {
    marginRight: '10px',
    marginTop: '4px',
  },
  chartRegressorInputStyle: {
    width: '200px',
    textAlign: 'center',
  },
  coefficients: { marginLeft: '10px' },
  rowStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowHeader: {
    textAlign: 'center',
    width: '100%',
    marginBottom: '10px',
  },
};

function AnalysisChart(props) {
  const {
    organization_id,
    baselineData,
    utilityType,
    startDate,
    endDate,
    regressionType,
    evaluation,
    regressionEvaluationData,
    regressionLine,
    stats,
    equation,
    coefficients,
    baselineLoading,
    regressionPieces,
    availableRegressors,
    productionNames,
    chartRegressor,
    setChartRegressor,
    ranRegressionPieces,
    ignoreDateKeys,
    regressionEventCollections,
    regressionEventCollection,
  } = props;

  const [formattedBaseline, setFormattedBaseline] = useState([{ x: 0, y: 0 }]);
  const [formattedIgnore, setFormattedIgnore] = useState([{ x: 0, y: 0 }]);
  const [formattedEvaluation, setFormattedEvaluation] = useState([
    { x: 0, y: 0 },
  ]);
  const [formattedLine, setFormattedLine] = useState([]);

  const [chartDownloadData, setChartDownloadData] = useState([]);
  const [chartDownloadHeaders, setChartDownloadHeaders] = useState([]);
  const [chartDownloadKeys, setChartDownloadKeys] = useState([]);

  const [regressionEventCollectionName, setRegressionEventCollectionName] =
    useState(null);

  useEffect(() => {
    let name = null;
    if (
      regressionEventCollection &&
      regressionEventCollection !== '' &&
      regressionEventCollections.length > 0
    ) {
      const collection = regressionEventCollections.find(
        (collection) => collection.id === regressionEventCollection
      );
      if (collection) {
        name = collection.name;
      }
    }
    setRegressionEventCollectionName(name);
  }, [regressionEventCollection, regressionEventCollections]);

  const formatBaselineData = useCallback(() => {
    let temp_data = [];
    let temp_ignore = [];
    let ignore_date_key = '';
    if (
      Object.keys(baselineData).length &&
      chartRegressor &&
      Object.keys(baselineData).includes(chartRegressor)
    ) {
      for (let i = 0; i < baselineData['energy'].length; i++) {
        ignore_date_key =
          baselineData['years'][i] + '-' + (baselineData['months'][i] + 1);
        let temp_obj = {};
        temp_obj['x'] = parseFloat(baselineData[chartRegressor][i]);
        temp_obj['y'] = parseFloat(baselineData['energy'][i]);
        temp_obj['z'] =
          MONTHNAMES[baselineData['months'][i]] +
          ' ' +
          baselineData['years'][i];

        if (ignoreDateKeys.includes(ignore_date_key)) {
          temp_ignore[i] = temp_obj;
        } else {
          temp_data[i] = temp_obj;
        }
      }
      setFormattedIgnore(temp_ignore);
      setFormattedBaseline(temp_data);
    } else {
      setFormattedBaseline([{ x: 0, y: 0, z: 0 }]);
      setFormattedIgnore([{ x: 0, y: 0 }]);
    }
  }, [baselineData, chartRegressor, ignoreDateKeys]);

  const formatEvaluation = useCallback(() => {
    let temp_data = [];

    if (
      evaluation.length &&
      Object.keys(regressionEvaluationData).length &&
      chartRegressor &&
      regressionEvaluationData['energy'].length === evaluation.length
    ) {
      for (let i = 0; i < evaluation.length; i++) {
        let temp_obj = {};
        temp_obj['x'] = parseFloat(
          regressionEvaluationData[chartRegressor][i].toFixed(2)
        );
        temp_obj['y'] = parseFloat(evaluation[i].toFixed(2));
        temp_obj['z'] =
          MONTHNAMES[regressionEvaluationData['months'][i]] +
          ' ' +
          regressionEvaluationData['years'][i];
        temp_data[i] = temp_obj;
      }
      setFormattedEvaluation(temp_data);
    } else {
      setFormattedEvaluation([{ x: 0, y: 0, z: 0 }]);
    }
  }, [regressionEvaluationData, evaluation, chartRegressor]);

  useEffect(() => {
    formatBaselineData();
  }, [formatBaselineData]);

  useEffect(() => {
    formatEvaluation();
  }, [formatEvaluation]);

  useEffect(() => {
    if (
      regressionLine &&
      regressionLine.length > 0 &&
      chartRegressor &&
      regressionPieces[0]['regressor'] === chartRegressor
    ) {
      setFormattedLine(regressionLine);
    } else {
      setFormattedLine([]);
    }
  }, [regressionLine, chartRegressor, regressionPieces]);

  useEffect(() => {
    let data = [formattedBaseline];
    let headers = ['Baseline Date', 'Baseline x', 'Baseline y'];
    let keys = [['z', 'x', 'y']];
    if (formattedEvaluation.length > 1) {
      data.push(formattedEvaluation);
      headers = [
        ...headers,
        ...['Evaluation Date', 'Evaluation x', 'Evaluation y'],
      ];
      keys.push(['z', 'x', 'y']);
    }
    setChartDownloadData(data);
    setChartDownloadHeaders(headers);
    setChartDownloadKeys(keys);
  }, [formattedBaseline, formattedEvaluation]);

  return (
    <>
      <Row>
        {' '}
        <div style={styles.rowHeader}>
          Regression Data <br></br>
          {startDate && endDate && utilityType
            ? '(' +
              dayjs.utc(startDate).format('MMM YYYY') +
              ' - ' +
              dayjs.utc(endDate).format('MMM YYYY') +
              ')'
            : ''}{' '}
          {formattedBaseline.length > 1 && (
            <DownloadChartData
              organization_id={organization_id}
              data={chartDownloadData}
              headers={chartDownloadHeaders}
              keys={chartDownloadKeys}
              chart_type={'scatter'}
            />
          )}
        </div>
      </Row>
      <Row
        style={{
          display: 'grid',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '25px',
          position: 'absolute',
          left: '55%',
          top: '28%',
          zIndex: '2',
        }}
      >
        {baselineLoading ? <Loader /> : ''}
      </Row>
      <Row style={{ ...styles.rowStyle, marginTop: '-10px' }}>
        <ScatterChart height={350} width={500}>
          <CartesianGrid />
          <XAxis
            type='number'
            dataKey='x'
            name={
              chartRegressor
                ? Object.keys(REGRESSORS).includes(chartRegressor)
                  ? REGRESSORS[chartRegressor]
                  : Object.keys(productionNames).includes(chartRegressor)
                  ? productionNames[chartRegressor]
                  : ''
                : ''
            }
            style={{ opacity: Object.keys(baselineData).length }}
          >
            <Label
              value={
                chartRegressor
                  ? Object.keys(REGRESSORS_UNITS).includes(chartRegressor)
                    ? REGRESSORS_UNITS[chartRegressor]
                    : 'units/day'
                  : ''
              }
              position='right'
              offset={10}
            />
          </XAxis>
          <YAxis
            type='number'
            dataKey='y'
            name={
              utilityType.charAt(0).toUpperCase() +
              utilityType.substr(1).toLowerCase()
            }
            tickFormatter={(tick) => {
              return tick.toLocaleString();
            }}
            style={{ opacity: Object.keys(baselineData).length }}
          >
            <Label
              value={
                utilityType !== ''
                  ? UTILITY_TYPE_UNITS[regressionType][utilityType] + '/day'
                  : ''
              }
              position='top'
              offset={10}
            />
          </YAxis>
          <ZAxis type='category' dataKey='z' name='Date' />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            formatter={(value) =>
              !isNaN(Number(value)) ? Number(value).toLocaleString() : value
            }
          />
          <Legend wrapperStyle={{ position: 'relative', top: '-15px' }} />
          <Scatter
            name='Baseline'
            data={formattedBaseline}
            fill={CHARTCOLORS[0]}
            hide={!Object.keys(baselineData).length}
          />
          <Scatter
            name='Model'
            data={formattedEvaluation}
            fill={CHARTCOLORS[1]}
            shape={'triangle'}
            hide={formattedEvaluation.length <= 1}
          />
          {formattedLine.length > 0 && (
            <Scatter
              name='Trend Line'
              data={formattedLine}
              fill={CHARTCOLORS[2]}
              shape={() => {
                return null;
              }}
              line
            />
          )}
          {ignoreDateKeys.length > 0 && (
            <Scatter
              name='Ignored'
              data={formattedIgnore}
              fill={CHARTCOLORS[7]}
              hide={formattedIgnore.length <= 1}
            />
          )}
        </ScatterChart>
      </Row>
      {regressionEventCollectionName && (
        <Row style={{ ...styles.rowStyle, marginTop: '0.5em' }}>
          Selected Regression Events: {regressionEventCollectionName}
        </Row>
      )}
      <Row style={styles.rowStyle}>
        <Row style={{ marginTop: '10px' }}>
          <Col>
            <Form.Label style={styles.axisSelectLabel}>x-axis:</Form.Label>
          </Col>
          <Col>
            <Form.Control
              style={styles.chartRegressorInputStyle}
              as='select'
              size='sm'
              disabled={!Object.keys(baselineData).length}
              onChange={(e) => setChartRegressor(e.target.value)}
              value={chartRegressor}
            >
              <option
                key={'regressor-opt-null'}
                value={''}
                hidden={Object.keys(baselineData).length}
              >
                -
              </option>
              {availableRegressors.map((regressor) => (
                <option key={`regressor-opt-${regressor}`} value={regressor}>
                  {regressor in REGRESSORS
                    ? REGRESSORS[regressor]
                    : productionNames && regressor in productionNames
                    ? productionNames[regressor]
                    : ''}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </Row>
      <Row style={styles.rowStyle}>
        <RegressionInfoTable
          coefficients={coefficients}
          equation={equation}
          stats={stats}
          chartData={regressionEvaluationData}
          regressionPieces={ranRegressionPieces}
          productionNames={productionNames}
        />
      </Row>
    </>
  );
}

export default AnalysisChart;
