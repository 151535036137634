import React, { useState } from 'react';

import { Form, Row, Col, Modal } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

const utilityTypes = ['electric', 'gas', 'propane', 'water'];

export default function AddCustomRateCollectionModal(props) {
  const {
    show,
    onHide,
    showToast,
    organization_id,
    addToList,
    setActiveCollectionId,
  } = props;

  const [name, setName] = useState(null);
  const [utility, setUtility] = useState('electric');

  const [isLoading, setIsLoading] = useState(false);

  const saveRateCollection = () => {
    setIsLoading(true);
    ApiServiceServerless.post(
      `/custom_rates/collection`,
      {
        name: name,
        org_id: organization_id,
        utility_type: utility,
      },
      { authorization_id: organization_id }
    )
      .then((res) => {
        showToast('success', 'Success', 'New  Rate Collection Created');
        addToList(res.data, 'rate_collections');
        setActiveCollectionId(res.data.id);
      })
      .catch(() => {
        showToast('danger', 'Error', 'Creation Error');
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  const saveDisabled = name === null;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div as='h5'>Create a Rate Collection</div>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>
                Name
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                required
                placeholder=''
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='accountForm.utilityType'>
              <Form.Label>
                Utility type
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                as='select'
                required
                onChange={(e) => {
                  setUtility(e.target.value);
                }}
              >
                {utilityTypes.map((type) => (
                  <option key={`type-opt-${type}`} value={type}>
                    {type.charAt(0).toUpperCase() +
                      type.substr(1).toLowerCase()}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          onSubmit={saveRateCollection}
          submitDisabled={saveDisabled}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
