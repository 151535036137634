import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

const styles = {
  iconStyle: {
    fontSize: '15px',
    color: 'var(--et_tab_grey)',
  },
};

const ExpansionArrow = ({ expanded }) =>
  expanded ? (
    <FontAwesomeIcon style={styles.iconStyle} icon={faChevronUp} />
  ) : (
    <FontAwesomeIcon style={styles.iconStyle} icon={faChevronDown} />
  );

ExpansionArrow.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

export default ExpansionArrow;
