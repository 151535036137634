import React, { useEffect, useState } from 'react';

import BrandBar from './BrandBar';
import NavBar from './NavBar';

import { version, version_acticle } from '../../auth';
import VersionModal from '../modals/VersionModal';

const MAX_WIDTH = '1380px';

function Header(props) {
  const { userVersion, setUserVersion, organizationSelectRoute } = props;
  const [showVersionModal, setShowVersionModal] = useState(false);

  useEffect(() => {
    if (userVersion !== -1 && userVersion !== version) {
      if (version_acticle !== null) {
        setShowVersionModal(true);
      } else {
        setUserVersion(version);
      }
    }
  }, [userVersion, setUserVersion]);

  return (
    <div
      style={{ position: 'sticky', top: 0, zIndex: 1000 }}
      id='energytracer-header'
    >
      <BrandBar maxWidth={MAX_WIDTH} />
      <NavBar
        maxWidth={MAX_WIDTH}
        organizationSelectRoute={organizationSelectRoute}
      />
      <VersionModal
        show={showVersionModal}
        onHide={() => {
          setShowVersionModal(false);
          setUserVersion(version);
        }}
      />
    </div>
  );
}

export default Header;
