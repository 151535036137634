import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const PAGE_TITLE = '404 - Page Not Found';

class NotFound extends React.Component {
  render() {
    return (
      <Container
        style={{ marginTop: '60px', maxWidth: '40%' }}
        className='et-unauth-container'
      >
        <Helmet>
          <title>{PAGE_TITLE}</title>
        </Helmet>
        <Row>
          <Col>
            <h4 className={'mb-4'}>Sorry, page not found (404)</h4>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col>
            <p>
              We couldn't find the page you are looking for. If you believe this
              is an error,&nbsp;
              <span
                style={{ cursor: 'pointer' }}
                className='text-primary underline'
                onClick={() =>
                  window.open(
                    'https://support.veregy.com/conversation/new',
                    '_blank'
                  )
                }
              >
                please let us know
              </span>
              .
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button as={Link} to='/' variant='primary'>
              Take Me Home
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NotFound;
