import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-solid-svg-icons';

import EnergyTracerTable from '../../../components/tables';
import EditUtilityAccountModal from '../../../components/modals/UtilityAccountModals/EditUtilityAccountModal';
import FilterInput from '../../../components/FilterInput';
import FilterSelect from '../../../components/FilterSelect';
import { UTILITY_TYPES_OPTS } from '../../../helpers/utility-types';
import {
  getUserOrganizationPreference,
  setUserOrganizationPreference,
} from '../../../helpers/user-preferences';

const styles = {
  buttonDiv: { textAlign: 'right' },
  iconStyle: {
    fontSize: '16px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
  formLabelStyles: {
    fontSize: '0.9rem',
    marginBottom: '0',
  },
};

export default function BuildingAccountsTable(props) {
  const {
    building,
    updateAccess,
    setBuilding,
    setSelectedResource,
    setUtilityAccountInfo,
    handleUpdateTables,
    showToast,
  } = props;

  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showAccountModalFlag, setShowAccountModalFlag] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});

  const [tableData, setTableData] = useState([]);

  const [accountFilterValue, setAccountFilterValue] = useState('');
  const [utilityFilterValue, setUtilityFilterValue] = useState(0);

  const handleEditAccount = (id) => {
    setSelectedAccount(building.accounts.find((acc) => acc.id === id));
    setShowAccountModalFlag(true);
  };

  const handleSetAccount = (data) => {
    setBuilding((prev) => ({
      ...prev,
      accounts: prev.accounts.map((account) => {
        if (account.id !== data.id) return account;
        return data;
      }),
    }));
  };

  useEffect(() => {
    if (showAccountModalFlag) {
      setShowAccountModal(true);
    }
  }, [showAccountModalFlag]);

  const setUtilityAccount = (row) => {
    setSelectedResource('utilityAccount');
    setUtilityAccountInfo({ id: row.id, name: row.account_number });
  };

  const tableColumns = [
    {
      dataField: 'account.account_number',
      text: 'Account',
      formatter: (cell, row) => (
        <div
          className='et-link'
          onClick={() => {
            setUtilityAccount(row);
            setUserOrganizationPreference(
              'lsa',
              building.organization.id,
              row.id
            );
          }}
        >
          {row.account_number}
        </div>
      ),
      classes: (_cell, row) => columnClasses(row),
    },
    {
      dataField: 'account.account_type',
      text: 'Account Type',
      formatter: (_cell, row) => (
        <div>
          {row.account_type.charAt(0).toUpperCase() +
            row.account_type.substr(1).toLowerCase()}
        </div>
      ),

      classes: (_cell, row) => columnClasses(row),
    },
    {
      dataField: 'building.id',
      text: '',
      formatter: (cell, row) => (
        <div style={styles.buttonDiv}>
          {updateAccess && (
            <FontAwesomeIcon
              style={styles.iconStyle}
              icon={faPen}
              title={'Edit Building'}
              onClick={() => handleEditAccount(row.id)}
            />
          )}
        </div>
      ),
      classes: (_cell, row) => columnClasses(row),
    },
  ];

  const columnClasses = (row) => {
    return row.id ===
      getUserOrganizationPreference('lsa', building.organization.id)
      ? 'et-table-selected-row'
      : '';
  };

  useEffect(() => {
    setTableData(
      formatTableData(building.accounts, accountFilterValue, utilityFilterValue)
    );
  }, [building.accounts, accountFilterValue, utilityFilterValue]);

  const formatTableData = (
    accounts,
    accountFilterValue,
    utilityFilterValue
  ) => {
    let filtered_accounts = [...accounts];
    //utility filter
    if (utilityFilterValue && parseInt(utilityFilterValue) !== 0) {
      filtered_accounts = filtered_accounts.filter(
        (acc) =>
          acc.account_type &&
          acc.account_type.toLowerCase() ===
            UTILITY_TYPES_OPTS[parseInt(utilityFilterValue)].toLowerCase()
      );
    }
    // account filter
    if (accountFilterValue !== '') {
      filtered_accounts = filtered_accounts.filter(
        (acc) =>
          acc.account_number &&
          acc.account_number
            .toLowerCase()
            .includes(accountFilterValue.toLowerCase())
      );
    }
    return filtered_accounts;
  };

  return (
    <>
      <Row style={{ marginTop: '3em' }}>
        <FilterInput
          label='Filter Account'
          setFilterValue={setAccountFilterValue}
          size={3}
          organization_id={building.organization.id}
          preference_key='anf'
        />
        <FilterSelect
          label='Utility Type'
          options={UTILITY_TYPES_OPTS}
          setFilterValue={setUtilityFilterValue}
          size={2}
          organization_id={building.organization.id}
          preference_key={'atf'}
          empty_preference={'0'}
        />
      </Row>
      <EnergyTracerTable
        keyField='id'
        columns={tableColumns}
        data={tableData}
      />
      <EditUtilityAccountModal
        accountDetails={selectedAccount}
        organization={building.organization}
        buildings={building.siblings}
        defaultBuildings={selectedAccount.building_ids}
        show={showAccountModal}
        onHide={() => {
          setSelectedAccount({});
          setShowAccountModal(false);
          setShowAccountModalFlag(false);
        }}
        handleSetAccount={handleSetAccount}
        handleUpdateTables={handleUpdateTables}
        showToast={showToast}
      />
    </>
  );
}
