import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { sortBy } from 'lodash';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

let null_measure = {
  id: null,
  name: null,
  value: null,
  years: null,
  offset_years: 0,
  savings_type: null,
};

function AddGHGProjectSavingsProjectModal(props) {
  const {
    show,
    onHide,
    org_id,
    projects,
    defaultProject,
    addToList,
    showToast,
    ghg_savings_projects,
  } = props;

  const [savingsProject, setSavingsProject] = useState(null_measure);
  const [projectIds, setProjectIds] = useState([]);

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSavingsProject(null_measure);
    if (defaultProject !== null) {
      setProjectIds([defaultProject.id]);
    } else {
      setProjectIds([]);
    }
  }, [show, defaultProject]);

  useEffect(() => {
    if (
      savingsProject.name !== null &&
      savingsProject.value !== null &&
      savingsProject.years !== null &&
      savingsProject.value !== 0 &&
      savingsProject.years !== 0 &&
      savingsProject.offset_years !== null &&
      savingsProject.savings_type !== null
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [savingsProject]);

  const handleEditProject = (key, value) => {
    setSavingsProject((prev) => ({ ...prev, [key]: value }));
  };

  const addProject = () => {
    setIsLoading(true);
    ApiServiceServerless.post(
      `/ghg_projects/project_ghg_savings/${org_id}`,
      {
        project_ids: projectIds,
        ...savingsProject,
      },
      { authorization_id: org_id }
    )
      .then((res) => {
        showToast('success', 'Success', 'Savings Project Added');
        addToList(res.data, 'ghg_measures');
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  const updateSet = (values) => {
    let arr = Array.prototype.slice.call(values);
    arr = arr.map((item) => item.value);
    setProjectIds(arr);
  };

  const parseNumber = (val) => {
    const float_val = !isNaN(parseFloat(val.replace(/,/g, '')))
      ? parseFloat(val.replace(/,/g, ''))
      : 0;
    return float_val;
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Add Project Saving</Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            {'Project Name'}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <Form.Control
              required
              onChange={(e) => handleEditProject('name', e.target.value)}
            />
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {'MTCO2e/Year '}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={2}
              fixedDecimalScale
              placeholder={'0.00'}
              onChange={(e) =>
                handleEditProject('value', parseNumber(e.target.value))
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {'Duration (Years) '}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={0}
              fixedDecimalScale
              placeholder={'0'}
              onChange={(e) =>
                handleEditProject('years', parseNumber(e.target.value))
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {'Offset (Years) '}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={0}
              fixedDecimalScale
              placeholder={'0'}
              onChange={(e) =>
                handleEditProject('offset_years', parseNumber(e.target.value))
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Project Type
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>

          <Form.Control
            as='select'
            required
            onChange={(e) =>
              handleEditProject('savings_type', parseInt(e.target.value))
            }
          >
            <option key={'opt-null'} value={'null'} hidden>
              -
            </option>
            {Object.keys(ghg_savings_projects).map((type) => (
              <option key={type} value={type}>
                {ghg_savings_projects[type]}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Project(s)</Form.Label>
          <Form.Control
            as='select'
            multiple
            disabled={defaultProject !== null}
            defaultValue={defaultProject !== null ? [defaultProject.id] : []}
            onChange={(e) => updateSet(e.target.selectedOptions)}
          >
            {sortBy(Object.values(projects), ['name'])
              .filter((project) => project.project_type === 'ghg')
              .map((item) => (
                <option key={`project-opt-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          submitDisabled={disabled}
          onSubmit={addProject}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

AddGHGProjectSavingsProjectModal.propTypes = {
  defaultProject: PropTypes.object,
};

AddGHGProjectSavingsProjectModal.defaultProps = {
  defaultProject: null,
};

export default AddGHGProjectSavingsProjectModal;
