import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

export default function ChartInfoClick(props) {
  const { onClick, title } = props;
  return (
    <div
      style={{
        cursor: 'pointer',
        display: 'inline-block',
        margin: '0em 0.35em',
      }}
      title={title}
      onClick={() => onClick()}
    >
      <FontAwesomeIcon
        style={{
          fontSize: '18px',
          color: 'var(--et_tab_grey)',
        }}
        icon={faInfoCircle}
      />
    </div>
  );
}
