import React, { useEffect, useState } from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import 'pretty-checkbox';

import EnergyTracerTable from '../../components/tables';

import BillDiscrepancyModal from '../../components/modals/Alerts/BillDiscrepancyModal';
import BillDiscrepancyBillsModal from '../../components/modals/Alerts/BillDiscrepancyBillsModal';
import { ApiServiceServerless } from '../../xhr_libs';
import MONTHSABBREVIATED from '../../helpers/monthsAbbreviated';
import CHARTCOLORS from '../../helpers/chartColors';
import FilterInput from '../../components/FilterInput';

const LEVEL_OPTS = {
  0: 'Show all',
  1: 'Low',
  2: 'Medium',
  3: 'High',
};

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
    textAlign: 'center',
  },
  headerStyle: {
    textAlign: 'center',
  },
  formLabelStyles: {
    fontSize: '0.9rem',
    marginBottom: '0',
  },
};

export default function BillDiscrepancyTable(props) {
  const { org_id, methods, withBuildingInfo, buildingInfo, showToast } = props;

  const [showConfidenceModal, setShowConfidenceModal] = useState(false);
  const [showBillModal, setShowBillModal] = useState(false);
  const [activeAlert, setActiveAlert] = useState({});
  const [alertFlag, setAlertFlag] = useState(0);
  const [rowBills, setRowBills] = useState([]);
  const [billFlag, setBillsFlag] = useState(0);

  const [localMethods, setLocalMethods] = useState([]);
  const [filteredMethods, setFilteredMethods] = useState([]);
  const [tableRefresh, setTableRefresh] = useState(0);

  const [buildingFilterValue, setBuildingFilterValue] = useState('');
  const [levelFilterValue, setLevelFiterValue] = useState(3);

  useEffect(() => {
    setLocalMethods([]);
    setFilteredMethods([]);
    if (withBuildingInfo) {
      setLocalMethods(
        methods.map((method) => ({
          ...method,
          building_name:
            method.building_id in buildingInfo
              ? buildingInfo[method.building_id]['name']
              : '',
        }))
      );
    } else {
      setLocalMethods([...methods]);
    }
  }, [org_id, methods, buildingInfo, withBuildingInfo]);

  useEffect(() => {
    let temp_methods = [...localMethods];
    setFilteredMethods(
      temp_methods.filter(
        (method) =>
          (method.confidence_level === LEVEL_OPTS[levelFilterValue] ||
            levelFilterValue === 0) &&
          ((method.building_name &&
            method.building_name
              .toLowerCase()
              .includes(buildingFilterValue.toLowerCase())) ||
            buildingFilterValue === '')
      )
    );

    setTableRefresh((prev) => prev + 1);
  }, [localMethods, levelFilterValue, buildingFilterValue]);

  const handleShowConfidenceModal = (row) => {
    let new_alert = methods.find(
      (method) => method.alert_key === row.alert_key
    );

    setActiveAlert(new_alert);
    setAlertFlag((prev) => prev + 1);
  };

  useEffect(() => {
    if (Object.keys(activeAlert).length > 0) {
      setShowConfidenceModal(true);
    }
  }, [activeAlert, alertFlag]);

  const handleShowBillsModal = (row) => {
    getAssociatedBills(row);
  };

  const getAssociatedBills = (row) => {
    ApiServiceServerless.post(
      `/alerts/discrepancy_bills/${org_id}`,
      {
        building: row.building_id,
        utility: row.utility,
        year: row.year,
        month: row.month,
      },
      { authorization_id: org_id }
    )
      .then((res) => {
        setRowBills(res.data);
        setBillsFlag((prev) => prev + 1);
      })
      .catch(() => {
        showToast('danger', 'Error', 'Failed to load bills');
      });
  };

  useEffect(() => {
    if (billFlag > 0) {
      setShowBillModal(true);
    }
  }, [billFlag]);

  const scoreFormatter = (row, key) => {
    let val = '';
    let row_style = {};
    if (key === 'confidence_percent') {
      val = Math.round(row[key]) + '%';
    } else val = row[key];
    if (row.confidence_level === 'Low') {
      row_style = { color: 'green', fontWeight: 'bold' };
    } else if (row.confidence_level === 'Medium') {
      row_style = { color: 'orange', fontWeight: 'bold' };
    } else if (row.confidence_level === 'High') {
      row_style = { color: 'red', fontWeight: 'bold' };
    }
    return <div style={row_style}>{val}</div>;
  };

  const basicTableColumns = [
    {
      dataField: 'key',
      text: 'Date',
      style: styles.column,
      headerStyle: styles.headerStyle,
      formatter: (cell, row) => (
        <>{MONTHSABBREVIATED[row.month - 1] + ' ' + row.year} </>
      ),
    },
  ];

  const confidenceTableColumns = [
    {
      dataField: 'confidence_level',
      text: 'Confidence Level',
      style: styles.column,
      headerStyle: styles.headerStyle,
      formatter: (cell, row) => scoreFormatter(row, 'confidence_level'),
    },
    {
      dataField: 'confidence_percent',
      text: 'Confidence Percent',
      style: styles.column,
      headerStyle: styles.headerStyle,
      formatter: (cell, row) => scoreFormatter(row, 'confidence_percent'),
    },
  ];

  const confidenceInfoColumns = [
    {
      dataField: 'confidence_score',
      text: 'Confidence Info',
      style: styles.column,
      headerStyle: styles.headerStyle,
      formatter: (cell, row) => (
        <div
          style={{
            cursor: 'pointer',
            display: 'inline-block',
          }}
          onClick={() => handleShowConfidenceModal(row)}
        >
          <FontAwesomeIcon
            style={{ fontSize: '18px', color: CHARTCOLORS[1] }}
            icon={faInfoCircle}
          />
        </div>
      ),
    },
    {
      dataField: 'alert_key',
      text: 'Associated Bills ',
      style: styles.column,
      headerStyle: styles.headerStyle,
      formatter: (cell, row) => (
        <div
          style={{
            cursor: 'pointer',
            display: 'inline-block',
          }}
          onClick={() => handleShowBillsModal(row)}
        >
          <FontAwesomeIcon
            style={{ fontSize: '18px', color: CHARTCOLORS[1] }}
            icon={faInfoCircle}
          />
        </div>
      ),
    },
  ];

  const buildingTableColumns = [
    {
      dataField: 'building_name',
      text: 'Building',
      style: styles.column,
      headerStyle: styles.headerStyle,
    },
  ];

  let tableColumns = [].concat(
    withBuildingInfo ? buildingTableColumns : [],
    basicTableColumns,
    confidenceTableColumns,
    confidenceInfoColumns
  );

  return (
    <>
      <h4 style={{ marginTop: '1.5em' }}>Bill Discrepencies </h4>
      <Row>
        {withBuildingInfo && (
          <FilterInput
            label='Filter Building'
            setFilterValue={setBuildingFilterValue}
            size={3}
          />
        )}
        <Form.Group as={Col} md={2}>
          <Form.Label style={styles.formLabelStyles}>
            Confidence Level
          </Form.Label>
          <Form.Control
            as='select'
            onChange={(e) => setLevelFiterValue(parseInt(e.target.value))}
            value={levelFilterValue}
          >
            {Object.keys(LEVEL_OPTS).map((key) => (
              <option key={key} value={key}>
                {LEVEL_OPTS[key]}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Row>

      <EnergyTracerTable
        data={filteredMethods}
        columns={tableColumns}
        keyField={'alert_key'}
        classes='et-table-filter-row'
        key={tableRefresh}
      />
      <BillDiscrepancyModal
        show={showConfidenceModal}
        onHide={() => setShowConfidenceModal(false)}
        alert={activeAlert}
      />
      <BillDiscrepancyBillsModal
        show={showBillModal}
        onHide={() => setShowBillModal(false)}
        bills={rowBills}
        org_id={org_id}
      />
    </>
  );
}

BillDiscrepancyTable.defaultProps = {
  withBuildingInfo: false,
  buildingInfo: {},
};
