import React, { useState } from 'react';
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';

import InvalidFeedback from '../components/forms/InvalidFeedback';
import LoginPageDecoration from '../components/LoginPageDecoration';
import { ApiServicePublicServerless } from '../xhr_libs';
import Loader from '../components/Loader';

import Helmet from 'react-helmet';
const pageTitle = 'Set Password';

const STATE_OPTS = Object.freeze(['normal', 'wait', 'success']);

const SuccessMessage = () => (
  <div style={{ marginTop: '25px' }}>
    <Alert variant='success'>
      Your new password has been set. <br />
      Please return to <Link to='/login'>login</Link>.<br />
      <small>You will be redirected automatically in 10s.</small>
    </Alert>
    <Link to='/login'>Back to Login</Link>
  </div>
);

const WaitMessage = () => (
  <div style={{ marginTop: '25px', height: '260px' }}>
    <Loader />
  </div>
);

function SetPassword(props) {
  const {
    history,
    match: { params },
    showToast,
  } = props;

  const [password, setPassword] = useState('');
  const [state, setState] = useState(STATE_OPTS[0]);
  const [disabled, setDisabled] = useState(false);

  const { register, errors, handleSubmit } = useForm();

  const onSubmit = () => {
    setState(STATE_OPTS[1]);
    setDisabled(true);
    ApiServicePublicServerless.post(
      `/password-reset/${params.id}/${params.token}`,
      {
        password,
      }
    )
      .then(() => {
        setDisabled(false);
        setState(STATE_OPTS[2]);
        showToast('success', 'Success', 'Password was reset');
        setTimeout(() => history.push('/login'), 10000);
      })
      .catch((err) => {
        setDisabled(false);
        setState(STATE_OPTS[0]);
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  return (
    <>
      <LoginPageDecoration />
      <Container
        style={{ marginTop: '2em', width: '450px' }}
        className='et-unauth-container'
      >
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Card style={{ border: 'none' }}>
          <Card.Body className={'login-cardbody'}>
            <h4 className={'mb-4'}>{pageTitle}</h4>
            {STATE_OPTS.indexOf(state) === 0 ? (
              <Form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                style={{ textAlign: 'left' }}
              >
                <Form.Group>
                  <Form.Label>
                    Password
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    type='password'
                    name='password1'
                    placeholder=''
                    isInvalid={errors.password1}
                    ref={register({ required: true, minLength: 8 })}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='password1'
                    message='Password must be at least 8 characters long'
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Repeat password</Form.Label>
                  <Form.Control
                    type='password'
                    name='password2'
                    placeholder=''
                    isInvalid={errors.password2}
                    ref={register({
                      required: true,
                      validate: { match: (value) => value === password },
                    })}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='password2'
                    message='Must match first password'
                  />
                </Form.Group>
                <hr style={{ width: '100%' }} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    style={{
                      marginTop: '5px',
                      marginBottom: '15px',
                      marginLeft: 'auto',
                    }}
                    type='submit'
                    disabled={disabled}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            ) : STATE_OPTS.indexOf(state) === 2 ? (
              <SuccessMessage />
            ) : (
              <WaitMessage />
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default withRouter(SetPassword);
