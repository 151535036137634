import React, { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';

import BuildingSelectModal from '../../components/modals/BuildingSelectModal';
import CHARTCOLORS from '../../helpers/chartColors';
import { ReportLookup } from './ReportHelpers';
import { ReportSubmit } from './ReportSubmit';
import ReportOptions from './ReportOptions/';
import ReportImgs from './ReportImgs';

const styles = {
  reportsAndDate: {
    textAlignLast: 'center',
    display: 'block',
  },
  reportTypeBaselineLabel: {
    display: 'flex',
  },
};

export default function ReportTab(props) {
  const {
    variant,
    showToast,
    userSelectedOrganization,
    organizationData,
    payload,
    updatePayload,
    isLoading,
    isGeneratingReport,
    setIsGeneratingReport,
    activeAnalysisLicense,
    activeGHGLicense,
  } = props;

  const [reportPrimaryType, setReportPrimaryType] = useState(
    variant === 'basic'
      ? 'excel'
      : variant === 'advanced'
      ? 'performance'
      : 'ghg_summary'
  );
  const [secondaryReportOptions, setSecondaryReportOptions] = useState([]);
  const [reportSecondaryType, setReportSecondaryType] = useState(null);

  useEffect(() => {}, [
    reportPrimaryType,
    secondaryReportOptions,
    reportSecondaryType,
  ]);

  useEffect(() => {
    const reports = ReportLookup.find(
      (report) => report.type === reportPrimaryType
    ).reportInfo;
    setSecondaryReportOptions(reports);
    setReportSecondaryType(reports[0].type);
  }, [reportPrimaryType]);

  const [disabled, setDisabled] = useState(false);

  const [performanceWarning, setPerformanceWarning] = useState(false);
  const [performanceDisabled, setPerformanceDisabled] = useState(false);

  const [trendWarning, setTrendWarning] = useState(false);
  const [feasibilityWarning, setFeasibilityWarning] = useState(false);

  // building setters and user affects
  const [showBuildingModal, setShowBuildingModal] = useState(false);
  const [buildingHide, setBuildingHide] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [buildingIds, setBuildingIds] = useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [nonSelectedBuildings, setNonSelectedBuildings] = useState([]);
  const [selectedString, setSelectedString] = useState('');

  useEffect(() => {
    if ('buildings' in organizationData) {
      setBuildings(organizationData['buildings']);
      const temp_building_ids = organizationData['buildings'].map(
        (building) => {
          return building.id;
        }
      );
      setBuildingIds(temp_building_ids);
      setSelectedBuildings(temp_building_ids);
    } else {
      setBuildings([]);
      setBuildingIds([]);
      setSelectedBuildings([]);
    }
    setNonSelectedBuildings([]);
  }, [organizationData]);

  useEffect(() => {
    updatePayload('ignore_building_ids', nonSelectedBuildings);
  }, [nonSelectedBuildings, updatePayload]);

  useEffect(() => {
    let temp_string = '';
    for (let i = 0; i < selectedBuildings.length; i++) {
      temp_string =
        temp_string +
        buildings.find((building) => building.id === selectedBuildings[i]).name;
      if (i !== selectedBuildings.length - 1) {
        temp_string = temp_string + ', ';
      }
    }
    setSelectedString(temp_string);
  }, [selectedBuildings, buildings]);

  useEffect(() => {
    const utility_length = payload['utility_types']
      ? payload['utility_types'].length
      : 1;
    const report_option_legnth = payload['report_types']
      ? payload['report_types'].length
      : 1;
    const buildings_length = selectedBuildings ? selectedBuildings.length : 1;
    if (utility_length * report_option_legnth * buildings_length > 30) {
      setTrendWarning(true);
    } else {
      setTrendWarning(false);
    }
  }, [payload, selectedBuildings, reportPrimaryType]);

  useEffect(() => {
    const building_charts =
      'report_options' in payload &&
      payload['report_options'].includes('building_charts');
    if (selectedBuildings.length >= 10 && building_charts) {
      setFeasibilityWarning(true);
    } else {
      setFeasibilityWarning(false);
    }
  }, [payload, selectedBuildings, reportPrimaryType]);

  return (
    <>
      {(variant === 'advanced' && !activeAnalysisLicense) ||
      (variant === 'ghg' && !activeGHGLicense) ? (
        <div>
          This organization does not have a valid license to access this
          feature. Please contact an administrator about licensing.
        </div>
      ) : (
        <Row>
          <Col lg={7} md={12}>
            <Form style={styles.reportsAndDate}>
              <Form.Group controlId={'reportType_' + variant}>
                <Form.Label style={styles.reportTypeBaselineLabel}>
                  Report Type
                </Form.Label>
                <Form.Control
                  as='select'
                  onChange={(e) => setReportPrimaryType(e.target.value)}
                  defaultValue={reportPrimaryType}
                  disabled={isLoading || isGeneratingReport}
                >
                  {ReportLookup.filter(
                    (reportType) => reportType.variant === variant
                  ).map((reportType) => (
                    <option
                      key={`report-opt-${reportType.type}`}
                      value={reportType.type}
                    >
                      {reportType.primaryName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {secondaryReportOptions.length > 1 && (
                <Form.Group controlId={'secondaryReportType_' + variant}>
                  <Form.Label
                    style={{
                      ...styles.reportTypeBaselineLabel,
                      marginBottom: '0.25em',
                    }}
                  >
                    Report Option
                  </Form.Label>
                  <Form.Row style={{ marginLeft: '0.5em' }}>
                    {secondaryReportOptions.map((reportType) => (
                      <div key={'secondary_report_type_' + reportType.type}>
                        <input
                          type='radio'
                          checked={reportSecondaryType === reportType.type}
                          onChange={() =>
                            setReportSecondaryType(reportType.type)
                          }
                          data-id='secondary_report_type'
                        />
                        <label
                          style={{
                            paddingRight: '1em',
                            paddingLeft: '0.3em',
                            marginBottom: '0',
                            paddingTop: '0.15em',
                          }}
                        >
                          {reportType.name}
                        </label>
                      </div>
                    ))}
                  </Form.Row>
                </Form.Group>
              )}
              {!buildingHide && (
                <Form.Group controlId='project.buildings'>
                  <Form.Label
                    style={{
                      ...styles.reportTypeBaselineLabel,
                    }}
                  >
                    Select Buildings
                  </Form.Label>
                  <Form.Control
                    required
                    value={
                      buildings.length === selectedBuildings.length
                        ? 'All Buildings'
                        : selectedString
                    }
                    disabled={true}
                    style={{ paddingRight: '5%' }}
                  />
                  <FontAwesomeIcon
                    style={{
                      fontSize: '20px',
                      marginLeft: '0.5em',
                      color: CHARTCOLORS[1],
                      cursor: 'pointer',
                      float: 'right',
                      marginTop: '-29px',
                      marginRight: '5px',
                    }}
                    icon={faEdit}
                    id={`report-building-select-popover`}
                    onClick={() => setShowBuildingModal(true)}
                  />
                </Form.Group>
              )}
            </Form>
            <ReportOptions
              variant={variant}
              reportPrimaryType={reportPrimaryType}
              reportSecondaryType={reportSecondaryType}
              organizationData={organizationData}
              updatePayload={updatePayload}
              setDisabled={setDisabled}
              setPerformanceWarning={setPerformanceWarning}
              setPerformanceDisabled={setPerformanceDisabled}
              activeGHGLicense={activeGHGLicense}
              selectedBuildings={selectedBuildings}
              setBuildingHide={setBuildingHide}
              isLoading={isLoading}
            />
            <ReportSubmit
              organization_id={userSelectedOrganization.id}
              organization_name={userSelectedOrganization.name}
              payload={payload}
              reportPrimaryType={reportPrimaryType}
              reportSecondaryType={reportSecondaryType}
              reportDisabled={disabled}
              isLoading={isLoading}
              performanceDisabled={performanceDisabled}
              performanceWarning={performanceWarning}
              trendWarning={trendWarning}
              feasibilityWarning={feasibilityWarning}
              showToast={showToast}
              isGeneratingReport={isGeneratingReport}
              setIsGeneratingReport={setIsGeneratingReport}
            />
          </Col>
          <Col lg={5} md={0}>
            <ReportImgs reportSecondaryType={reportSecondaryType} />
          </Col>
        </Row>
      )}
      <BuildingSelectModal
        show={showBuildingModal}
        onHide={() => setShowBuildingModal(false)}
        buildings={buildings}
        buildingIds={buildingIds}
        selectedBuildings={selectedBuildings}
        setSelectedBuildings={setSelectedBuildings}
        setNonSelectedBuildings={setNonSelectedBuildings}
      />
    </>
  );
}
