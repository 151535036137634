import React, { useState, useEffect } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../xhr_libs';
import BillDiscrepancyTable from './BillDiscrepancyTable';
import Loader from '../../components/Loader';
import BuildingSelectModal from '../../components/modals/BuildingSelectModal';
import CHARTCOLORS from '../../helpers/chartColors';
import MonthSelect from '../../components/MonthSelect';

export default function BillDiscrepancyAlerts(props) {
  const {
    org_id,
    buildingLookup,
    utilityTypes,
    years,
    isLoading,
    setIsLoading,
    showToast,
  } = props;

  const [methods, setMethods] = useState([]);

  const [utilityType, setUtilityType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [showBuildingModal, setShowBuildingModal] = useState(false);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [availableBuildings, setAvailableBuildings] = useState([]);

  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [defaultEndDate, setDefaultEndDate] = useState(null);

  const getAlerts = () => {
    setIsLoading(true);
    ApiServiceServerless.post(
      `/alerts/discrepancy/${org_id}`,
      {
        start_date: startDate,
        end_date: endDate,
        utility: utilityType,
        building_ids: selectedBuildings,
      },
      {
        authorization_id: org_id,
      }
    )
      .then((res) => {
        setMethods(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setUtilityType(null);
    setSelectedBuildings([]);
    setAvailableBuildings([]);
    setMethods([]);
  }, [org_id]);

  useEffect(() => {
    let bids = Object.keys(buildingLookup).map((bid) => {
      return parseInt(bid);
    });

    setAvailableBuildings(bids);
    setSelectedBuildings(bids);
  }, [buildingLookup]);

  useEffect(() => {
    const today = dayjs.utc(new Date());
    setMinDate(dayjs.utc(new Date(years[0] - 1, 0, 1)));
    const max_year = Math.max(years[years.length - 1], today.year());
    setMaxDate(dayjs.utc(new Date(max_year, 11, 31)));

    setStartDate(dayjs.utc(new Date(today.year(), 0, 1)));
    setEndDate(today);

    setDefaultStartDate(dayjs.utc(new Date(today.year(), 0, 1)));
    setDefaultEndDate(today);
  }, [years]);

  useEffect(() => {
    let bids = Object.keys(buildingLookup).map((bid) => {
      return parseInt(bid);
    });

    setAvailableBuildings(bids);
    setSelectedBuildings(bids);
  }, [buildingLookup]);

  return (
    <>
      <Form style={{ marginBottom: '1em' }}>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Utility Type:</Form.Label>
              <Form.Control
                as='select'
                size='sm'
                onChange={(e) => setUtilityType(e.target.value)}
                value={utilityType ? utilityType : ''}
              >
                <option key={`alert-utility-select`} value={'null'} hidden>
                  {'Select a Utility'}
                </option>
                {utilityTypes.length === 0 && (
                  <option key={`alert-utility-null`} value={'null'} disabled>
                    {'No available types'}
                  </option>
                )}
                {utilityTypes.map((utility) => (
                  <option key={`alert-utility-${utility}`} value={utility}>
                    {utility.charAt(0).toUpperCase() +
                      utility.substr(1).toLowerCase()}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <MonthSelect
              label='Start Date'
              default_date={defaultStartDate}
              min_date={minDate}
              max_date={maxDate}
              setNewDate={setStartDate}
              showPicker={true}
            />
          </Col>
          <Col>
            <MonthSelect
              label='End Date'
              default_date={defaultEndDate}
              min_date={minDate}
              max_date={maxDate}
              setNewDate={setEndDate}
              showPicker={true}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Button
            variant='primary'
            onClick={() => getAlerts()}
            disabled={
              !(utilityType !== null && startDate !== null && endDate !== null)
            }
          >
            Get Alerts
          </Button>
          <div style={{ marginLeft: '2em', paddingTop: '0.25em' }}>
            Select Buildings{' '}
            <FontAwesomeIcon
              style={{
                fontSize: '20px',
                marginLeft: '0.5em',
                color: CHARTCOLORS[1],
                cursor: 'pointer',
              }}
              icon={faEdit}
              id={`alerts-building-select-popover`}
              onClick={() => setShowBuildingModal(true)}
            />
          </div>
        </Form.Row>
      </Form>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <BillDiscrepancyTable
            org_id={org_id}
            methods={methods}
            withBuildingInfo={true}
            buildingInfo={buildingLookup}
            showToast={showToast}
          />
        </>
      )}
      <BuildingSelectModal
        show={showBuildingModal}
        onHide={() => setShowBuildingModal(false)}
        buildings={{
          ...Object.values(buildingLookup),
        }}
        buildingIds={availableBuildings}
        selectedBuildings={selectedBuildings}
        setSelectedBuildings={setSelectedBuildings}
        setNonSelectedBuildings={() => {}}
      />
    </>
  );
}
