import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import EditEntityActionButtons from '../../../components/forms/EditEntityActionButtons';

const report_options = [
  'cumulative_savings',
  'savings_charts',
  'yearly_savings',
  'monthly_savings',
  'ytd_savings',
  'resource_charts',
];
const report_options_lookup = {
  cumulative_savings: 'Cumulative Savings',
  savings_charts: 'Savings Charts',
  yearly_savings: 'Yearly Savings Table',
  monthly_savings: 'Monthly Savings Table',
  ytd_savings: 'YTD Building Savings Table',
  resource_charts: 'Resource Charts',
};

export default function ProjectReportOptions(props) {
  const {
    localProject,
    saveProject,
    clearChanges,
    showToast,
    actionsDisabled,
    onFieldChange,
    updateAccessAnalysis,
    isSaving,
  } = props;

  const [localReportOptions, setLocalReportOptions] = useState([]);

  useEffect(() => {
    setLocalReportOptions(localProject['report_options']);
  }, [localProject]);

  const handleChange = useCallback(
    (option, value) => {
      let temp_report_options = [...localReportOptions];
      if (value === '1') {
        const index = temp_report_options.indexOf(option);
        if (index > -1) {
          temp_report_options.splice(index, 1);
        }
      } else {
        temp_report_options.push(option);
      }
      setLocalReportOptions(temp_report_options);
      onFieldChange('report_options', temp_report_options);
    },
    [localReportOptions, onFieldChange]
  );

  return (
    <>
      <br></br>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Row>
            <Form noValidate onSubmit={saveProject}>
              <h4> Performance PDF Report Chart Toggles</h4>
              <Row>
                {report_options.map((option) => (
                  <Col md={6} key={option}>
                    <Form.Group key={option}>
                      <Form.Label>{report_options_lookup[option]}</Form.Label>
                      <Form.Control
                        as='select'
                        onChange={(e) => handleChange(option, e.target.value)}
                        value={localReportOptions.includes(option) ? 0 : 1}
                      >
                        <option key={option + '_off'} value={0}>
                          Off
                        </option>
                        <option key={option + 'on'} value={1}>
                          On
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                ))}
              </Row>
              <h4> Performance PDF Report Options</h4>
              <Row>
                <Col md={6}>
                  <Form.Group key={'cumulative_chart_implementation_off'}>
                    <Form.Label>
                      Implementation Period in Cumulative Chart
                    </Form.Label>
                    <Form.Control
                      as='select'
                      onChange={(e) =>
                        handleChange(
                          'cumulative_chart_implementation_off',
                          e.target.value
                        )
                      }
                      value={
                        localReportOptions.includes(
                          'cumulative_chart_implementation_off'
                        )
                          ? 0
                          : 1
                      }
                    >
                      <option key={'On'} value={1}>
                        On
                      </option>
                      <option key={'off'} value={0}>
                        Off
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group key={'cumulative_chart_offset'}>
                    <Form.Label>Cumulative Chart Offset</Form.Label>
                    <Form.Control
                      as='select'
                      onChange={(e) =>
                        handleChange('cumulative_chart_offset', e.target.value)
                      }
                      value={
                        localReportOptions.includes('cumulative_chart_offset')
                          ? 0
                          : 1
                      }
                    >
                      <option key={'off'} value={1}>
                        Off
                      </option>
                      <option key={'on'} value={0}>
                        On
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group key={'resource_chart_type'}>
                    <Form.Label>Resource Chart Type</Form.Label>
                    <Form.Control
                      as='select'
                      onChange={(e) =>
                        handleChange('resource_chart_type', e.target.value)
                      }
                      value={
                        localReportOptions.includes('resource_chart_type')
                          ? 0
                          : 1
                      }
                    >
                      <option key={'on'} value={1}>
                        Yearly
                      </option>
                      <option key={'off'} value={0}>
                        Cumulative
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <h4>Performance Excel Report Options</h4>
              <Row>
                <Col md={6}>
                  <Form.Group key={'excel_cumulative'}>
                    <Form.Label>Excel Cumulative</Form.Label>
                    <Form.Control
                      as='select'
                      onChange={(e) =>
                        handleChange('excel_cumulative', e.target.value)
                      }
                      value={
                        localReportOptions.includes('excel_cumulative') ? 0 : 1
                      }
                    >
                      <option key={'on'} value={1}>
                        Yearly
                      </option>
                      <option key={'off'} value={0}>
                        Cumulative
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>

              <EditEntityActionButtons
                onSubmit={saveProject}
                onCancel={clearChanges}
                submitDisabled={actionsDisabled}
                cancelDisabled={actionsDisabled}
                submitShow={updateAccessAnalysis}
                deleteShow={false}
                showToast={showToast}
                isSubmitting={isSaving}
              />
            </Form>
          </Row>
        </Col>
      </Row>
    </>
  );
}
