import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import CustomGHGCollections from './CustomGHGCollections';

import CustomGHGConversions from './CustomGHGConversions';

export default function GHGConversionsMain(props) {
  const [deferredProps, setDeferredProps] = useState({ ...props });
  useEffect(() => {
    if (props.open) setDeferredProps({ ...props });
  }, [props]);
  const {
    showToast,
    organization,
    createAccess,
    updateAccess,
    deleteAccess,
    GHGConversions,
    GHGCollections,
    addToList,
    updateList,
    removeFromList,
  } = deferredProps;

  const [activeTab, setActiveTab] = useState('ghg-collections');

  return (
    <Tabs
      id='ghg-analysis-tabs'
      activeKey={activeTab}
      onSelect={(e) => setActiveTab(e)}
      className='et-second-tabs'
    >
      <Tab eventKey='ghg-collections' title={'Collections'}>
        <CustomGHGCollections
          showToast={showToast}
          organization={organization}
          createAccess={createAccess}
          updateAccess={updateAccess}
          deleteAccess={deleteAccess}
          GHGConversions={GHGConversions}
          GHGCollections={GHGCollections}
          addToList={addToList}
          updateList={updateList}
          removeFromList={removeFromList}
        />
      </Tab>
      <Tab eventKey='ghg-conversions' title={'GHG Conversions'}>
        <CustomGHGConversions
          showToast={showToast}
          organization={organization}
          createAccess={createAccess}
          updateAccess={updateAccess}
          deleteAccess={deleteAccess}
          GHGConversions={GHGConversions}
          GHGCollections={GHGCollections}
          addToList={addToList}
          updateList={updateList}
          removeFromList={removeFromList}
        />
      </Tab>
    </Tabs>
  );
}
