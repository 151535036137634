import React, { useEffect, useState } from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { sortBy } from 'lodash';

import { ApiServiceServerless } from '../../../../../xhr_libs';
import AddEntityActionButtons from '../../../../forms/AddEntityActionButtons';
import consumptionUnits from '../../../../../helpers/consumptionUnits';
import BillDateSelect from '../../../../bills/BillFormInputs/BillDateSelect';

export default function EditOrganizationRateModal(props) {
  const {
    show,
    onHide,
    organizationId,
    updateList,
    showToast,
    existingRate,
    rateCollections,
  } = props;

  const [availableConsumptionUnits, setAvailableConsumptionUnits] = useState(
    consumptionUnits['electric']
  );
  const [disabled, setDisabled] = useState(false);
  const [rateData, setRateData] = useState({
    id: null,
    name: '',
    utility_type: 'electric',
    value: null,
    unit: 'kwh',
    flat_value: 0,
    demand_rate: 0,
    year: null,
    month: null,
  });
  const [collectionIds, setCollectionIds] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (existingRate.utility_type) {
      setAvailableConsumptionUnits(consumptionUnits[existingRate.utility_type]);
      setRateData({
        id: existingRate.id,
        name: existingRate.name,
        utility_type: existingRate.utility_type,
        value: existingRate.value,
        unit: existingRate.unit,
        flat_value: existingRate.flat_value,
        demand_rate: existingRate.demand_rate,
        year: existingRate.year,
        month: existingRate.month,
      });
      setCollectionIds(existingRate.collection_ids);
    }
  }, [existingRate]);

  const parseNumber = (val) => {
    const float_val = !isNaN(parseFloat(val.replace(/,/g, '')))
      ? parseFloat(val.replace(/,/g, ''))
      : 0;
    return float_val;
  };

  const updateSet = (values) => {
    let arr = Array.prototype.slice.call(values);
    arr = arr.map((item) => item.value);
    setCollectionIds(arr);
  };

  useEffect(() => {
    setDisabled(
      rateData['value'] === null ||
        rateData['year'] === null ||
        rateData['month'] === null
    );
  }, [rateData]);

  const editRate = () => {
    setIsLoading(true);
    ApiServiceServerless.put(
      `/custom_rates/${rateData.id}`,
      {
        ...rateData,
        collection_ids: collectionIds,
      },
      { authorization_id: organizationId }
    )
      .then((res) => {
        showToast('success', 'Success', 'Rate successfully added');
        updateList(res.data[0], 'rates');
        res.data[1].forEach((collection) => {
          updateList(collection, 'rate_collections');
        });
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton> Edit Custom Rate</Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            {'Name'}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <Form.Control
              required
              placeholder=''
              defaultValue={rateData.name}
              onChange={(e) =>
                setRateData({
                  ...rateData,
                  name: e.target.value,
                })
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Year</Form.Label>
            <BillDateSelect
              type='year'
              value={rateData.year ? rateData.year.toString() : ''}
              onChange={(e) =>
                setRateData({
                  ...rateData,
                  year: isNaN(parseInt(e.target.value))
                    ? null
                    : parseInt(e.target.value),
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Month</Form.Label>
            <BillDateSelect
              type='month'
              value={rateData.month ? (rateData.month - 1).toString() : ''}
              onChange={(e) =>
                setRateData({
                  ...rateData,
                  month: isNaN(parseInt(e.target.value))
                    ? null
                    : parseInt(e.target.value) + 1,
                })
              }
            />
          </Form.Group>
        </Form.Row>
        <Form.Row></Form.Row>
        <Form.Group controlId='orgRate.utilityType'>
          <Form.Label>
            Utility type
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Control as='select' required disabled>
            <option
              key={`type-opt-${rateData.utility_type}`}
              value={rateData.utility_type}
            >
              {rateData.utility_type.charAt(0).toUpperCase() +
                rateData.utility_type.substr(1).toLowerCase()}
            </option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {'Rate ($/unit)'}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={3}
              fixedDecimalScale
              placeholder={'0.000'}
              defaultValue={rateData.value}
              onChange={(e) =>
                setRateData({
                  ...rateData,
                  value: parseNumber(e.target.value),
                })
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Group controlId='orgRate.unit'>
          <Form.Label>Unit</Form.Label>
          <Form.Control
            as='select'
            required
            onChange={(e) =>
              setRateData({
                ...rateData,
                unit: e.target.value,
              })
            }
            value={rateData.unit}
          >
            {availableConsumptionUnits.map((unit) => (
              <option key={`unit-opt-${unit}`} value={unit}>
                {unit}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>{'Flat Rate ($)'}</Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={3}
              fixedDecimalScale
              placeholder={'0.000'}
              defaultValue={rateData.flat_value}
              onChange={(e) =>
                setRateData({
                  ...rateData,
                  flat_value: parseNumber(e.target.value),
                })
              }
            />
          </Form.Row>
        </Form.Group>
        {rateData.utility_type === 'electric' && (
          <Form.Group>
            <Form.Label>{'Demand Rate ($)'}</Form.Label>
            <Form.Row>
              <NumberFormat
                customInput={Form.Control}
                thousandSeparator
                isNumericString
                decimalScale={3}
                fixedDecimalScale
                placeholder={'0.000'}
                defaultValue={rateData.demand_rate}
                onChange={(e) =>
                  setRateData({
                    ...rateData,
                    demand_rate: parseNumber(e.target.value),
                  })
                }
              />
            </Form.Row>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Collection(s)</Form.Label>
          <Form.Control
            as='select'
            multiple
            defaultValue={collectionIds}
            onChange={(e) => updateSet(e.target.selectedOptions)}
          >
            {sortBy(
              rateCollections.filter(
                (collection) =>
                  collection.utility_type === rateData.utility_type
              ),
              ['name']
            ).map((item) => (
              <option key={`collection-opt-${item.id}`} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          onSubmit={editRate}
          submitDisabled={disabled}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
