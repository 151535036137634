const OrganizationsFromPortfolios = (organizations, portfolio_id) => {
  if (parseInt(portfolio_id) !== -1) {
    let new_orgs = [];
    organizations.forEach(function (org) {
      if (Object.keys(org.portfolios).includes(portfolio_id.toString())) {
        new_orgs.push(org);
      }
    });
    return new_orgs;
  } else {
    return organizations;
  }
};

export default OrganizationsFromPortfolios;
