import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

export default function SmallerLoader(props) {
  const { text } = props;

  return (
    <>
      {text} &nbsp;
      <FontAwesomeIcon className='fa-spin' icon={faSyncAlt} />
    </>
  );
}
