import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Form, InputGroup, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faDownload, faEllipsis } from '@fortawesome/pro-solid-svg-icons';

//Components
import BillNumberInput from './BillFormInputs/BillNumberInput';
import BillDateSelect from './BillFormInputs/BillDateSelect';
import DefaultBillNames from './BillNames';
import { checkCanPauseAutomation, checkCanSplitBill } from './helpers';
import PopoverTrigger from '../PopoverTrigger';
import SmallerLoader from '../../components/SmallerLoader';

import BillAdvancedOptions from './BillAdvancedOptions';

const formCol = {
  textAlign: 'left',
  marginRight: '20px',
  fontSize: 14,
  padding: '0px',
};

const checkRow = {
  textAlign: 'left',
  marginRight: '20px',
  fontSize: 14,
  padding: '0px',
  marginBottom: '0px',
};

const warningStyle = {
  backgroundColor: 'rgba(255,255,0,0.25)',
};

const buttonStyle = {
  fontSize: '22px',
};

const buttonContainerStyle = {
  cursor: 'pointer',
  pointerEvents: 'auto',
};

const decimalScale = 2;

const Bill = React.memo((props) => {
  const {
    account,
    data,
    allBills,
    deleteBill,
    resetBillFlag,
    saveBill,
    handlePauseAutomation,
    handleSplitProviderBill,
    validateBill,
    isTotal,
    addToSaveBills,
    updateAccess,
    deleteAccess,
    removeFromSaveBills,
    hasSavedMultiple,
    isSaving,
    hideBottomBorder,
  } = props;

  const get_diff = (val1, val2) => {
    const diff = Math.abs(val1 - val2) / (Math.abs(val1 + val2) / 2);

    if (diff > 0.1 && val1 && val2) {
      return warningStyle;
    }
    return {};
  };

  const [bill, setBill] = useState({ id: null });
  const [billNames, setBillNames] = useState({});
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [formResetTrigger, setFormResetTrigger] = useState(0);

  const [totalCost, setTotalCost] = useState(0);
  const [totalConsumption, setTotalConsumption] = useState(0);
  const [rate, setRate] = useState(0);

  const [oldBill, setOldBill] = useState({});

  const [cellStyles, setCellStyles] = useState({});

  useEffect(() => {
    if (!checkTooManySubAccounts(account)) {
      const timeOutId = setTimeout(
        () =>
          setCellStyles({
            cost1: get_diff(oldBill['cost1_value'], bill['cost1_value']),
            cost2: get_diff(oldBill['cost2_value'], bill['cost2_value']),
            cost3: get_diff(oldBill['cost3_value'], bill['cost3_value']),
            consumption1: get_diff(
              oldBill['consumption1_value'],
              bill['consumption1_value']
            ),
            consumption2: get_diff(
              oldBill['consumption2_value'],
              bill['consumption2_value']
            ),
            demand1: get_diff(oldBill['demand1_value'], bill['demand1_value']),
            demand2: get_diff(oldBill['demand2_value'], bill['demand2_value']),
          }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [account, oldBill, bill]);

  useEffect(() => {
    setHasBeenEdited(false);
  }, [hasSavedMultiple]);

  useEffect(() => {
    if (!checkTooManySubAccounts(account)) {
      setOldBill(
        findClosestBill(
          {
            provider_meter_id: bill.provider_meter_id,
            read_date: bill.read_date,
          },
          allBills
        )
      );
    }
  }, [account, allBills, bill.read_date, bill.provider_meter_id]);

  const checkTooManySubAccounts = (account) => {
    if (account && account.sub_accounts && account.sub_accounts.length > 10) {
      return true;
    }
    return false;
  };

  const resetDateInputs = () => setFormResetTrigger((prev) => prev + 1);

  const setStateFromData = useCallback(
    (userInvoked) => {
      if (bill.id && !userInvoked) return;
      setBill({
        id: data.id,
        bill_type: data.bill_type,
        is_estimate: data.is_estimate,
        bill_date: data.bill_date
          ? dayjs.utc(data.bill_date).format('YYYY-MM-DD')
          : '',
        read_date: data.read_date
          ? dayjs.utc(data.read_date).format('YYYY-MM-DD')
          : '',
        start_date: data.start_date
          ? dayjs.utc(data.start_date).format('YYYY-MM-DD')
          : '',
        cost1_value: data.cost1_value,
        cost2_value: data.cost2_value,
        cost3_value: data.cost3_value,
        consumption1_value: data.consumption1_value,
        consumption2_value: data.consumption2_value,
        demand1_value: data.demand1_value,
        demand2_value: data.demand2_value,
        cost1_unit: data.cost1_unit,
        cost2_unit: data.cost2_unit,
        cost3_unit: data.cost3_unit,
        consumption1_unit: data.consumption1_unit,
        consumption2_unit: data.consumption2_unit,
        demand1_unit: data.demand1_unit,
        demand2_unit: data.demand2_unit,
        pdf: data.pdf,
        provider_meter_id: data.provider_meter_id,
        provider_bill_id: data.provider_bill_id,
        provider_bill_type: data.provider_bill_type,
        pause_automation: data.pause_automation,
        provider_split_bill_parent: data.provider_split_bill_parent,
        provider_split_bill_value: data.provider_split_bill_value,
      });
      let bill_names = { ...DefaultBillNames[data.bill_type] };
      if (account && account.utility_template) {
        bill_names = createBillNames(bill_names, account.utility_template);
        setBillNames(bill_names);
      } else {
        setBillNames(bill_names);
      }
      resetDateInputs();
    },
    [data, bill.id, account]
  );

  useEffect(() => {
    setStateFromData(true);
  }, [resetBillFlag, setStateFromData]);

  const createBillNames = (bill_names, utility_template) => {
    if (utility_template['cost1_name']) {
      bill_names['cost1'] = utility_template['cost1_name'];
    }
    if (utility_template['cost2_name']) {
      bill_names['cost2'] = utility_template['cost2_name'];
    }
    if (utility_template['cost3_name']) {
      bill_names['cost3'] = utility_template['cost3_name'];
    }
    return bill_names;
  };

  useEffect(() => {
    const totalCost =
      (bill.cost1_value || 0) +
      (bill.cost2_value || 0) +
      (bill.cost3_value || 0);
    setTotalCost(totalCost);
    const totalConsumption =
      (bill.consumption1_value || 0) + (bill.consumption2_value || 0);
    setTotalConsumption(totalConsumption);
    let rate = 0;
    if (totalConsumption !== 0) rate = totalCost / totalConsumption;
    setRate(rate);
  }, [
    bill.cost1_value,
    bill.cost2_value,
    bill.cost3_value,
    bill.consumption1_value,
    bill.consumption2_value,
  ]);

  const handleChangeNumber = (val, key) => {
    const float_val = !isNaN(parseFloat(val.replace(/,/g, '')))
      ? parseFloat(val.replace(/,/g, ''))
      : 0;
    const editedBill = { ...bill, [key]: float_val };
    setBill(editedBill);
    addToSaveBills(editedBill);
    setHasBeenEdited(true);
  };

  const handleChangeBillDate = (key, val) => {
    if (!isNaN(val) && val) {
      const date =
        key === 'year'
          ? dayjs.utc(bill.bill_date).year(val).format('YYYY-MM-DD')
          : dayjs.utc(bill.bill_date).month(val).format('YYYY-MM-DD');
      const editedBill = { ...bill, bill_date: date };
      setBill(editedBill);
      addToSaveBills(editedBill);
      setHasBeenEdited(true);
    }
  };

  const handleChangeDate = (key, val) => {
    if (dayjs(val).isValid()) {
      let editedBill = {};
      if (key === 'read_date') {
        editedBill = {
          ...bill,
          [key]: val,
          bill_date: dayjs(new Date(val)),
        };
      } else {
        editedBill = { ...bill, [key]: val };
      }
      if (Object.keys(editedBill).length) {
        setBill(editedBill);
        addToSaveBills(editedBill);
      }
      setHasBeenEdited(true);
    }
  };

  const handleChangeCheck = (key) => {
    const editedBill = { ...bill, [key]: !bill[key] };
    setBill(editedBill);
    addToSaveBills(editedBill);
    setHasBeenEdited(true);
  };

  const handleSaveBill = (e, exit = false) => {
    e.preventDefault();
    if (validateBill(bill)) {
      if (exit) {
        saveBill(bill, exit);
      } else {
        saveBill(bill);
      }
      setHasBeenEdited(false);
    }
  };

  useEffect(() => {
    setStateFromData();
  }, [setStateFromData]);

  return (
    <Container>
      <Form key={`${data.id}-${formResetTrigger}`}>
        <Row style={{ marginTop: '30px' }}>
          <Col style={formCol}>
            {!isTotal && (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Start Date</Form.Label>
                  <InputGroup size='sm'>
                    <Form.Control
                      name='start_date'
                      aria-label='startDate'
                      type='date'
                      disabled={!updateAccess}
                      defaultValue={
                        dayjs.utc(bill.start_date).isValid()
                          ? bill.start_date
                          : ''
                      }
                      onChange={(e) =>
                        handleChangeDate('start_date', e.target.value)
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
            )}
            {!isTotal && (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Read Date</Form.Label>
                  <InputGroup size='sm'>
                    <Form.Control
                      name='read_date'
                      aria-label='readDate'
                      type='date'
                      disabled={!updateAccess}
                      defaultValue={
                        dayjs.utc(bill.read_date).isValid()
                          ? bill.read_date
                          : ''
                      }
                      onChange={(e) =>
                        handleChangeDate('read_date', e.target.value)
                      }
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
            )}
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>Utility Year</Form.Label>
                <InputGroup size='sm'>
                  <BillDateSelect
                    type='year'
                    value={
                      dayjs.utc(bill.bill_date).isValid()
                        ? dayjs.utc(bill.bill_date).year()
                        : ''
                    }
                    disabled={isTotal || !updateAccess}
                    onChange={(e) =>
                      handleChangeBillDate('year', e.target.value)
                    }
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Utility Month</Form.Label>
                <InputGroup size='sm'>
                  <BillDateSelect
                    type='month'
                    value={
                      dayjs.utc(bill.bill_date).isValid()
                        ? dayjs.utc(bill.bill_date).month()
                        : ''
                    }
                    disabled={isTotal || !updateAccess}
                    onChange={(e) =>
                      handleChangeBillDate('month', e.target.value)
                    }
                  />
                </InputGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              {!isTotal && bill.bill_type !== 'production' && (
                <Form.Group style={checkRow} controlId='editBill.isEstimate'>
                  <Form.Check inline className='ml-3 align-text-bottom'>
                    <Form.Check.Input
                      disabled={!updateAccess}
                      checked={bill.is_estimate}
                      onChange={() => handleChangeCheck('is_estimate')}
                    />
                  </Form.Check>
                  <Form.Label>Is Estimate</Form.Label>
                </Form.Group>
              )}
            </Form.Row>
          </Col>
          {('cost1' in billNames ||
            'cost2' in billNames ||
            'cost3' in billNames) && (
            <Col style={formCol}>
              {'cost2' in billNames && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{billNames['cost2']}</Form.Label>
                    <InputGroup size='sm'>
                      <InputGroup.Prepend>
                        <InputGroup.Text id='cost2_unit'>
                          {bill.cost2_unit}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <BillNumberInput
                        style={cellStyles['cost2']}
                        customInput={Form.Control}
                        name='cost2_value'
                        aria-label='cost2_value'
                        value={bill.cost2_value}
                        old_value={oldBill.cost2_value}
                        disabled={isTotal || !updateAccess}
                        onChange={(e) =>
                          handleChangeNumber(e.target.value, 'cost2_value')
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              )}
              {'cost1' in billNames && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{billNames['cost1']} </Form.Label>
                    <InputGroup size='sm'>
                      <InputGroup.Prepend>
                        <InputGroup.Text id='cost1_unit'>
                          {bill.cost1_unit}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <BillNumberInput
                        style={cellStyles['cost1']}
                        customInput={Form.Control}
                        name='cost1_value'
                        aria-label='cost1_value'
                        value={bill.cost1_value}
                        old_value={oldBill.cost1_value}
                        disabled={isTotal || !updateAccess}
                        onChange={(e) =>
                          handleChangeNumber(e.target.value, 'cost1_value')
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              )}
              {'cost3' in billNames && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{billNames['cost3']}</Form.Label>
                    <InputGroup size='sm'>
                      <InputGroup.Prepend>
                        <InputGroup.Text id='cost3_unit'>
                          {bill.cost3_unit}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <BillNumberInput
                        customInput={Form.Control}
                        style={cellStyles['cost3']}
                        name='cost3_value'
                        aria-label='cost3_value'
                        value={bill.cost3_value}
                        old_value={oldBill.cost3_value}
                        disabled={isTotal || !updateAccess}
                        onChange={(e) =>
                          handleChangeNumber(e.target.value, 'cost3_value')
                        }
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              )}

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Total</Form.Label>
                  <InputGroup size='sm'>
                    <InputGroup.Prepend>
                      <InputGroup.Text id='inputGroup-total-cost'>
                        $
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <BillNumberInput
                      customInput={Form.Control}
                      aria-label='total-cost'
                      value={totalCost}
                      disabled
                    />
                  </InputGroup>
                </Form.Group>
              </Form.Row>
            </Col>
          )}
          <Col style={formCol}>
            {'consumption1' in billNames && (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>{billNames['consumption1']}</Form.Label>
                  <InputGroup size='sm'>
                    <BillNumberInput
                      style={cellStyles['consumption1']}
                      customInput={Form.Control}
                      name='consumption1_value'
                      aria-label='consumption1'
                      decimalScale={decimalScale}
                      value={bill.consumption1_value}
                      old_value={oldBill.consumption1_value}
                      disabled={isTotal || !updateAccess}
                      onChange={(e) =>
                        handleChangeNumber(e.target.value, 'consumption1_value')
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id='consumption1_unit'>
                        {bill.consumption1_unit}
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
            )}
            {'consumption2' in billNames && (
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>{billNames['consumption2']}</Form.Label>
                  <InputGroup size='sm'>
                    <BillNumberInput
                      style={cellStyles['consumption2']}
                      customInput={Form.Control}
                      name='consumption2_value'
                      aria-label='consumption2'
                      decimalScale={decimalScale}
                      value={bill.consumption2_value}
                      old_value={oldBill.consumption2_value}
                      disabled={isTotal || !updateAccess}
                      onChange={(e) =>
                        handleChangeNumber(e.target.value, 'consumption2_value')
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id='consumption2_unit'>
                        {bill.consumption2_unit}
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form.Row>
            )}
            {'cost1' in billNames && 'consumption1' in billNames && (
              <>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Blended Rate</Form.Label>
                    <InputGroup size='sm'>
                      <BillNumberInput
                        customInput={Form.Control}
                        aria-label='blendedRate'
                        decimalScale={3}
                        placeholder='-'
                        value={rate}
                        disabled
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id='inputGroup-total-rate'>
                          $/{bill.consumption1_unit}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Total</Form.Label>
                    <InputGroup size='sm'>
                      <BillNumberInput
                        customInput={Form.Control}
                        aria-label='totalEnergy'
                        decimalScale={decimalScale}
                        value={totalConsumption}
                        disabled
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id='inputGroup-total-energy'>
                          {bill.consumption1_unit}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              </>
            )}
          </Col>
          {('demand1' in billNames || 'demand2' in billNames) && (
            <Col style={formCol}>
              {'demand1' in billNames && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{billNames['demand1']}</Form.Label>
                    <InputGroup size='sm'>
                      <BillNumberInput
                        style={cellStyles['demand1']}
                        customInput={Form.Control}
                        name='demand1'
                        aria-label='demand1'
                        decimalScale={decimalScale}
                        value={bill.demand1_value}
                        old_value={oldBill.demand1_value}
                        disabled={isTotal || !updateAccess}
                        onChange={(e) =>
                          handleChangeNumber(e.target.value, 'demand1_value')
                        }
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id='demand1_unit'>
                          {bill.demand1_unit}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              )}
              {'demand2' in billNames && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>{billNames['demand2']}</Form.Label>
                    <InputGroup size='sm'>
                      <BillNumberInput
                        style={cellStyles['demand2']}
                        customInput={Form.Control}
                        name='demand2'
                        aria-label='demand2'
                        decimalScale={decimalScale}
                        value={bill.demand2_value}
                        old_value={oldBill.demand2_value}
                        disabled={isTotal || !updateAccess}
                        onChange={(e) =>
                          handleChangeNumber(e.target.value, 'demand2_value')
                        }
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id='demand2_unit'>
                          {bill.demand2_unit}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              )}
            </Col>
          )}
        </Row>

        <Row style={{ justifyContent: 'flex-end' }}>
          <Col>
            <Row>
              {!isTotal &&
                bill.id &&
                checkCanPauseAutomation(bill || checkCanSplitBill(bill)) && (
                  <Row style={{ paddingLeft: '1em' }}>
                    <Button
                      id={`bill-${bill.id}-popover-button`}
                      style={buttonContainerStyle}
                      variant={'secondary'}
                    >
                      <PopoverTrigger
                        rootClose={true}
                        popoverTitle={'Advanced Options'}
                        popoverContent={
                          <BillAdvancedOptions
                            bill={bill}
                            isSaving={isSaving}
                            handlePauseAutomation={handlePauseAutomation}
                            handleSplitProviderBill={handleSplitProviderBill}
                          />
                        }
                        trigger='click'
                        placement='bottom'
                      >
                        <div title='Advanced Options'>
                          <FontAwesomeIcon
                            style={buttonStyle}
                            icon={faEllipsis}
                            id={'report-date-popover'}
                          />
                        </div>
                      </PopoverTrigger>
                    </Button>
                    <div style={{ paddingTop: '6px', paddingLeft: '5px' }}>
                      {isSaving && <SmallerLoader />}
                    </div>
                  </Row>
                )}
            </Row>
          </Col>
          <Col>
            <Row style={{ justifyContent: 'flex-end' }}>
              <div style={{ marginRight: '20px' }}>
                {bill.pdf && (
                  <a
                    href={bill.pdf}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginRight: '30px' }}
                    title='Download Bill'
                  >
                    <FontAwesomeIcon
                      style={{
                        fontSize: '28px',
                        lineHeight: 'inherit',
                        position: 'absolute',
                      }}
                      icon={faDownload}
                    />
                  </a>
                )}
                {!isTotal && (
                  <>
                    {bill.id && deleteAccess && (
                      <Button
                        variant='danger'
                        size='sm'
                        style={{ marginLeft: '10px' }}
                        disabled={isSaving}
                        onClick={() => deleteBill(bill.id)}
                        title='Delete Bill'
                      >
                        <FontAwesomeIcon
                          style={{
                            fontSize: '21px',
                            lineHeight: 'inherit',
                            paddingTop: '2px',
                          }}
                          icon={faTrashAlt}
                        />
                      </Button>
                    )}

                    {updateAccess && bill.id && (
                      <Button
                        disabled={!hasBeenEdited || isSaving}
                        onClick={() => {
                          removeFromSaveBills(bill.id);
                          setStateFromData(true);
                          setHasBeenEdited(false);
                        }}
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                      >
                        Clear Changes
                      </Button>
                    )}
                    {updateAccess && !bill.id && (
                      <Button
                        style={{ marginRight: '1em' }}
                        disabled={(!hasBeenEdited && bill.id) || isSaving}
                        onClick={(e) => handleSaveBill(e, true)}
                      >
                        Save and Exit
                      </Button>
                    )}
                    {updateAccess && (
                      <Button
                        disabled={(!hasBeenEdited && bill.id) || isSaving}
                        onClick={(e) => handleSaveBill(e)}
                      >
                        Save Bill
                      </Button>
                    )}
                  </>
                )}
              </div>
            </Row>
          </Col>
        </Row>

        {!hideBottomBorder && <hr />}
      </Form>
    </Container>
  );
});

export default Bill;

const findClosestBill = (bill, allBills) => {
  const max_index = allBills.length;

  let closest_bill = {};
  let prev_diff = 365;
  for (let i = 0; i < max_index; i++) {
    if (bill.provider_meter_id === allBills[i].provider_meter_id) {
      const day_diff =
        dayjs(bill.read_date).diff(allBills[i].read_date, 'day') - 365;

      if (day_diff > 60) {
        break;
      }

      if (Math.abs(day_diff) < 30 && Math.abs(day_diff) < prev_diff) {
        closest_bill = allBills[i];
        prev_diff = Math.abs(day_diff);
      }
    }
  }
  return closest_bill;
};
