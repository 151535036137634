import React, { useEffect, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { debounce } from 'lodash';
import Helmet from 'react-helmet';

import { ApiServicePublicServerless } from '../xhr_libs';
import Loader from '../components/Loader';
import LoginPageDecoration from '../components/LoginPageDecoration';
import ArcadiaIFrame from '../components/UtilityImportSettings/ArcadiaIFrame';

const PAGE_TITLE = 'Share Cedential';

const ErrorMessage = ({ requesterEmail }) => (
  <div
    style={{ display: 'flex', justifyContent: 'center', marginTop: '10rem' }}
  >
    <Alert variant='warning' style={{ width: '40%' }}>
      Your link has expired.
      <br />
      {`You can contact ${requesterEmail} for a new link.`}
    </Alert>
  </div>
);

function ShareCredential(props) {
  const {
    match: {
      params: { token, requesterEmail, requesteeEmail, provider_type },
    },
  } = props;

  const [iFrameParams, setIFrameParams] = useState({ orgId: null, key: null });
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showLoader, setShowLoader] = useState(true);

  // hide content to prevent initial flash of iframe error message
  useEffect(() => {
    if (showLoader && iFrameParams.orgId && iFrameParams.key)
      debounce(() => setShowLoader(false), 1500)();
  }, [showLoader, iFrameParams.orgId, iFrameParams.key]);

  useEffect(() => {
    if (token && requesterEmail && requesteeEmail) {
      const payload = {
        token: token,
        requester_email: requesterEmail,
        requestee_email: requesteeEmail,
        provider_type: provider_type,
      };
      ApiServicePublicServerless.post('/providers/share-request-get', payload)
        .then(({ data }) =>
          setIFrameParams({
            orgId: data.organization_id,
            key: data.iframe_key,
          })
        )
        .catch(() => setShowErrorMsg(true));
    }
  }, [token, requesterEmail, requesteeEmail, provider_type]);

  return (
    <>
      <LoginPageDecoration />
      {showErrorMsg ? (
        <ErrorMessage requesterEmail={requesterEmail} />
      ) : (
        <Container
          style={{ padding: '0px', marginTop: '2em' }}
          className='et-unauth-container'
        >
          <Helmet>
            <title>{PAGE_TITLE}</title>
          </Helmet>
          {showLoader && <Loader />}
          {!showLoader && (
            <>
              {parseInt(provider_type) === 1 ||
              parseInt(provider_type) === 2 ? (
                <ArcadiaIFrame
                  iframe_key={iFrameParams.key}
                  organizationId={iFrameParams.orgId}
                  provider_type={provider_type}
                />
              ) : (
                <></>
              )}
            </>
          )}
        </Container>
      )}
    </>
  );
}

export default ShareCredential;
