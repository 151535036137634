import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faToggleOff,
  faToggleOn,
  faEye,
  faEyeSlash,
  faArrowUp,
  faArrowDown,
  faChartLineUp,
  faChartMixed,
} from '@fortawesome/pro-solid-svg-icons';

const variant_lookup = {
  0: { 0: faToggleOff, 1: faToggleOn },
  1: { 0: faArrowDown, 1: faArrowUp },
  2: { 0: faEye, 1: faEyeSlash },
  3: { 0: faChartMixed, 1: faChartLineUp },
};

function ChartToggle(props) {
  const { onClick, state, title, variant } = props;
  return (
    <FontAwesomeIcon
      style={{
        fontSize: '20px',
        margin: '0 0.35em',
        verticalAlign: 'text-bottom',
        cursor: 'pointer',
        color: 'var(--et_tab_grey)',
      }}
      icon={variant_lookup[variant][state]}
      title={title}
      onClick={() => onClick()}
    />
  );
}

ChartToggle.defaultProps = {
  variant: 0,
};

export default ChartToggle;
