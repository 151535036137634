import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

export default function PortfolioNav(props) {
  const { selectedPage, setSelectedPage, portfolioInfo, setPortfolioInfo } =
    props;

  return (
    <div style={{ margin: '0.5em 0em 0em 0.5em' }}>
      <div style={{ fontSize: '0.8em', marginBottom: '0.5em' }}>Navigation</div>
      <Breadcrumb className='et-breadcrumb'>
        <Breadcrumb.Item
          onClick={() => {
            setSelectedPage('portfolio-table');
            setPortfolioInfo({ id: null });
          }}
          active={'portfolio-table' === selectedPage}
        >
          {'Portfolios'}
        </Breadcrumb.Item>

        {portfolioInfo.id && parseInt(portfolioInfo.id) !== -1 && (
          <Breadcrumb.Item active={'edit-portfolio' === selectedPage}>
            {portfolioInfo.name}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
    </div>
  );
}
