import numeral from 'numeral';
import React from 'react';

import PopoverTrigger from '../../PopoverTrigger';

export function slashListFormatter(rowCallback) {
  const formatter = function (_cell, row) {
    return rowCallback(row).join(' / ');
  };

  return formatter;
}

export function fmtBoolYesNo(enrolled) {
  return enrolled === true ? 'Yes' : 'No';
}

export function fmtBoolExists(enrolled) {
  return enrolled ? 'Yes' : 'No';
}

export function fmtUSD(money) {
  return numeral(money).format('$0,0.00');
}

export const fmtLongString = (str) =>
  str.length < 26 ? (
    str
  ) : (
    <PopoverTrigger
      popoverContent={str}
      placement={'top'}
      trigger={['hover', 'focus']}
    >
      <span>{`${str.substring(0, 22)}...`}</span>
    </PopoverTrigger>
  );
