import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

export default function ChartInfo(props) {
  const { href, title } = props;
  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      href={href}
      title={title}
      style={{ margin: '0 0.35em' }}
    >
      <FontAwesomeIcon
        style={{ fontSize: '18px', color: 'var(--et_tab_grey)' }}
        icon={faInfoCircle}
      />
    </a>
  );
}
