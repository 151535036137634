import React, { useContext, useEffect, useState } from 'react';
import { faCog, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Navbar, Nav } from 'react-bootstrap';

import { Link } from 'react-router-dom';

import etLogo from '../../img/et-logo-new-cropped.svg';
import PopoverTrigger from '../../components/PopoverTrigger';
import SettingsPopover from './SettingsPopover';
import AppContext from '../../contexts/app-context';

const styles = {
  buttonStyle: {
    fontSize: '25px',
  },
  buttonContainerStyle: {
    display: 'inline-block',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
};

function BrandBar({ maxWidth }) {
  const { disableOrganizationSelect, updatingProfile } = useContext(AppContext);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(disableOrganizationSelect || updatingProfile);
  }, [disableOrganizationSelect, updatingProfile]);

  return (
    <Navbar
      bg='light'
      expand='md'
      collapseOnSelect
      style={{ padding: '0 1em' }}
    >
      <Container style={{ padding: 0, maxWidth: maxWidth }}>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />

        <Navbar.Brand
          className='mr-auto'
          to='/'
          as={Link}
          data-id='brand_home'
          style={{ padding: 0 }}
        >
          <img
            src={etLogo}
            alt='blockLogo'
            style={{
              height: '55px',
              paddingBottom: '5px',
              paddingLeft: '10px',
            }}
          />
        </Navbar.Brand>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            <Nav.Link
              active={false}
              onClick={() =>
                window.open(
                  'https://support.veregy.com/section/101-energytracer',
                  '_blank'
                )
              }
              data-id='nav_help'
            >
              <FontAwesomeIcon
                style={styles.buttonStyle}
                icon={faQuestionCircle}
              />
            </Nav.Link>
            <Nav.Link>
              <div
                id='settings-popover-button'
                style={styles.buttonContainerStyle}
              >
                <PopoverTrigger
                  rootClose={true}
                  popoverContent={<SettingsPopover />}
                  trigger={disabled ? 'none' : 'click'}
                  placement='bottom'
                  padding='0'
                >
                  <div>
                    <FontAwesomeIcon
                      style={styles.buttonStyle}
                      icon={faCog}
                      id={'settings-popover'}
                    />
                  </div>
                </PopoverTrigger>
              </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BrandBar;
