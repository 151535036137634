import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import InvalidFeedback from '../components/forms/InvalidFeedback';
import LoginPageDecoration from '../components/LoginPageDecoration';
import { ApiServicePublicServerless } from '../xhr_libs';

import Helmet from 'react-helmet';
const pageTitle = 'Update Your Cedentials';

const ErrorMessage = ({ requesterEmail }) => (
  <div
    style={{ display: 'flex', justifyContent: 'center', marginTop: '10rem' }}
  >
    <Alert variant='warning' style={{ width: '40%' }}>
      Your link has expired.
      <br />
      {`You can contact ${requesterEmail} for a new link.`}
    </Alert>
  </div>
);

const SuccessMessage = () => (
  <div
    style={{ display: 'flex', justifyContent: 'center', marginTop: '10rem' }}
  >
    <Alert variant='success' style={{ width: '40%' }}>
      Your credentials were updated successfully. You can safely close this
      window.
    </Alert>
  </div>
);

function UpdateCredential(props) {
  const {
    match: {
      params: { token, requesterEmail, requesteeEmail, provider_type },
    },
    showToast,
  } = props;

  const [credentialParams, setCredentialParams] = useState({
    credentialId: null,
    orgId: null,
  });

  const [password, setPassword] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  const { register, errors, handleSubmit } = useForm();

  useEffect(() => {
    if (token && requesterEmail && requesteeEmail) {
      const payload = {
        token: token,
        requester_email: requesterEmail,
        requestee_email: requesteeEmail,
        provider_type: provider_type,
      };
      ApiServicePublicServerless.post('/providers/share-update-get', payload)
        .then(({ data }) =>
          setCredentialParams({
            credentialId: data.credential_id,
            orgId: data.organization_id,
          })
        )
        .catch(() => setShowErrorMsg(true));
    }
  }, [token, requesterEmail, requesteeEmail, provider_type]);

  const onSubmit = (data) => {
    const params = {
      org_id: credentialParams.orgId,
      credential_id: credentialParams.credentialId,
      new_username: data.username,
      new_password: data.password1,
    };
    let url = '';
    if (parseInt(provider_type) === 1) {
      url = 'urjanet/credentials/update/public';
    }
    if (parseInt(provider_type) === 2) {
      url = 'plug/credentials/update/public';
    }
    ApiServicePublicServerless.post(url, params)
      .then(() => {
        showToast(
          'success',
          'Success',
          'Utility provider credentials have been updated'
        );
        setShowSuccessMsg(true);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  return (
    <>
      <LoginPageDecoration />
      {showErrorMsg ? (
        <ErrorMessage requesterEmail={requesterEmail} />
      ) : showSuccessMsg ? (
        <SuccessMessage />
      ) : (
        <Container
          style={{ marginTop: '2em', width: '450px' }}
          className='et-unauth-container'
        >
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>
          <Card style={{ border: 'none' }}>
            <Card.Body className={'login-cardbody'}>
              <h4 className={'mb-4'}>{pageTitle}</h4>

              <Form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                style={{ textAlign: 'left' }}
              >
                <Form.Group>
                  <Form.Label>
                    Username
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    name='username'
                    placeholder='Enter username'
                    isInvalid={errors.username}
                    ref={register({ required: true })}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='username'
                    message='Field is required'
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Password
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    type='password'
                    name='password1'
                    placeholder=''
                    isInvalid={errors.password1}
                    ref={register({ required: true })}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='password1'
                    message='Field is required'
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Repeat Password
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    type='password'
                    name='password2'
                    placeholder=''
                    isInvalid={errors.password2}
                    ref={register({
                      required: true,
                      validate: { match: (value) => value === password },
                    })}
                  />
                  <InvalidFeedback
                    errors={errors}
                    name='password2'
                    message='Must match first password'
                  />
                </Form.Group>
                <hr />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    type='submit'
                    variant='primary'
                    style={{ width: '100%', marginBottom: '-4px' }}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      )}
    </>
  );
}

export default UpdateCredential;
