import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Loader from '../../../components/Loader';

export default function BillsLoader() {
  return (
    <Row>
      <Col
        style={{
          paddingBottom: '8vh',
          paddingTop: '8vh',
          paddingLeft: '49%',
        }}
      >
        <Loader />
      </Col>
    </Row>
  );
}
