import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import PortfolioTable from './PortfolioTable';

import Helmet from 'react-helmet';
import EditPortfolio from './EditPortfolio';
import { DEFAULT_PORTFOLIO_VALUES } from './helpers';
import PortfolioNav from './PortfolioNav';

const pageTitle = 'Manage Portfolios';

export default function Portfolios(props) {
  const { showToast } = props;
  const [selectedPage, setSelectedPage] = useState('portfolio-table');

  const [portfolioInfo, setPortfolioInfo] = useState({ id: null });
  const [portfolio, setPortfolio] = useState(DEFAULT_PORTFOLIO_VALUES);

  const [refreshFlag, setRefreshFlag] = useState(0);

  useEffect(() => {
    setPortfolioInfo({ ...portfolio });
  }, [portfolio]);

  return (
    <Container className='et-container'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <PortfolioNav
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        portfolioInfo={portfolioInfo}
        setPortfolioInfo={setPortfolioInfo}
      />
      <Row style={{ padding: '1em' }}>
        <Col>
          <div
            style={{
              display: selectedPage === 'portfolio-table' ? '' : 'none',
            }}
          >
            <PortfolioTable
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              setPortfolioInfo={setPortfolioInfo}
              refreshFlag={refreshFlag}
            />
          </div>
          {portfolioInfo.id && parseInt(portfolioInfo.id) !== -1 && (
            <div
              style={{
                display: selectedPage === 'edit-portfolio' ? '' : 'none',
              }}
            >
              <EditPortfolio
                setSelectedPage={setSelectedPage}
                portfolioInfo={portfolioInfo}
                portfolio={portfolio}
                setPortfolio={setPortfolio}
                setRefreshFlag={setRefreshFlag}
                showToast={showToast}
              />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
