import React, { useEffect, useState, useContext, useCallback } from 'react';
import { sortBy } from 'lodash';
import Select from 'react-select';

import AppContext from '../contexts/app-context';
import OrganizationsFromPortfolios from '../helpers/OrganizationsFromPortfolios';

function OrganizationSelect() {
  const {
    setActiveOrganization,
    userSelectedOrganization,
    userOrganizations,
    userSelectedPortfolio,
    disableOrganizationSelect,
    updatingProfile,
  } = useContext(AppContext);

  const [localOrgs, setLocalOrgs] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);

  // change available orgs on portfolio changes
  useEffect(() => {
    const new_orgs = OrganizationsFromPortfolios(
      userOrganizations,
      userSelectedPortfolio.id
    );
    setSelectOptions([]);
    if (new_orgs && new_orgs.length > 0) {
      setLocalOrgs(sortBy(new_orgs, ['name']));
    } else {
      setLocalOrgs(null);
    }
  }, [userOrganizations, userSelectedPortfolio.id]);

  const handleSetActiveOrganization = useCallback(
    (new_organization, current_organization_id) => {
      if (new_organization.id !== current_organization_id) {
        setActiveOrganization(new_organization);
      }
    },
    [setActiveOrganization]
  );

  const isOrgInPortfolio = (orgId, organizations) =>
    organizations.some((org) => org.id === orgId);

  //change selected org on local orgs changes
  useEffect(() => {
    if (localOrgs && localOrgs.length > 0) {
      let organization = {};
      !userSelectedOrganization.id ||
      !isOrgInPortfolio(userSelectedOrganization.id, localOrgs)
        ? (organization = {
            id: parseInt(localOrgs[0]['id']),
            name: localOrgs[0]['name'],
          })
        : (organization = {
            id: parseInt(userSelectedOrganization.id),
            name: userSelectedOrganization.name,
          });
      handleSetActiveOrganization(organization, userSelectedOrganization.id);
    }
    if (localOrgs && localOrgs.length === 0) {
      handleSetActiveOrganization(
        { id: null, name: '' },
        userSelectedOrganization.id
      );
    }
  }, [
    userSelectedOrganization.id,
    userSelectedOrganization.name,
    localOrgs,
    handleSetActiveOrganization,
  ]);

  useEffect(() => {
    let temp_select = [];
    if (localOrgs) {
      localOrgs.forEach((option) => {
        temp_select.push({
          value: option.id,
          label: option.name,
        });
      });
    }
    setSelectOptions(temp_select);
  }, [localOrgs]);

  return (
    <>
      {selectOptions.length === 1 && (
        <b style={{ paddingRight: '2em', color: 'white' }}>
          {userSelectedOrganization.name}
        </b>
      )}
      {selectOptions.length > 1 && (
        <div>
          <div
            style={{
              marginRight: '.5rem',
              color: 'white',
              display: 'inline-block',
            }}
          >
            Organization:
          </div>
          <div style={{ display: 'inline-block', width: '250px' }}>
            <Select
              defaultValue={{
                value: userSelectedOrganization.id,
                label: userSelectedOrganization.name,
              }}
              onChange={(option) => {
                handleSetActiveOrganization(
                  { id: option.value, name: option.label },
                  userSelectedOrganization.id
                );
              }}
              options={selectOptions}
              isDisabled={disableOrganizationSelect || updatingProfile}
              classNamePrefix='et-react-select'
            />
          </div>
        </div>
      )}
    </>
  );
}

export default OrganizationSelect;
