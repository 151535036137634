import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ApiServicePublicServerless } from '../xhr_libs';

const REACT_APP_ENV = process.env.REACT_APP_ENV;

export const ReCaptcha = forwardRef((_props, ref) => (
  <ReCAPTCHA
    ref={ref}
    size='invisible'
    sitekey='6LetGDMaAAAAANsUm1FK4mYZUThBOdS32Q6vvHgx'
  />
));

const getToken = (recaptcha) => {
  if (recaptcha.getValue()) {
    recaptcha.reset();
  }
  return recaptcha.executeAsync();
};

export const withReCaptcha = async (callback, ref) => {
  if (REACT_APP_ENV === 'dev') return callback();
  const token = await getToken(ref.current);
  ApiServicePublicServerless.post('/recaptcha', { recaptcha_key: token }).then(
    ({ data }) => {
      data ? callback() : console.error('Failed reCAPTCHA verification.');
    }
  );
};

export default ReCaptcha;
