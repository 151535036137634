export const UTILITY_TYPES = {
  electric: 'Electric',
  gas: 'Gas',
  water: 'Water',
  propane: 'Propane',
};

export const EMISSION_SCOPES = {
  electric: 'indirect',
  gas: 'direct',
  propane: 'direct',
};

export const addAccountTypes = [
  'electric',
  'gas',
  'propane',
  'water',
  'production',
];

export const UTILITY_TYPES_OPTS = {
  0: 'Show all',
  1: 'Electric',
  2: 'Gas',
  3: 'Propane',
  4: 'Water',
  5: 'Production',
};
