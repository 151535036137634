import React from 'react';
import { Row, Form } from 'react-bootstrap';
import GHGReportUnitPicker from '../../../components/reports/GHGReportUnitPicker';

import ReportMonthRange from '../../../components/reports/ReportMonthRange';

const styles = {
  form: {
    textAlignLast: 'center',
    display: 'block',
  },
};

export default function CarbonTranscriptReport(props) {
  const { organizationData, updatePayload } = props;

  return (
    <>
      <Row>
        <ReportMonthRange
          major_label={'Date Range'}
          label='End Month'
          default_date={organizationData['offset_max_date']}
          min_date={organizationData['transcript_min_date']}
          max_date={organizationData['max_bill_date']}
          payload_key={'end'}
          updatePayload={updatePayload}
          showPicker={true}
        />
      </Row>
      <Form style={styles.form}>
        <GHGReportUnitPicker updatePayload={updatePayload} />
      </Form>
    </>
  );
}
