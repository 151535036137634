import React from 'react';

const LoginPageDecoration = () => (
  <>
    <div className='triangle-bottom-left-bg' />
    <div className='triangle-bottom-left' />
    <div
      aria-live='polite'
      aria-atomic='true'
      style={{
        position: 'relative',
        minHeight: '50px',
      }}
    />
    <div id='login-spacer'></div>
  </>
);

export default LoginPageDecoration;
