import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMeter, faSyncAlt } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import {
  BuildingTypes,
  BUILDING_BENCHMARK_OPTIONS,
} from '../../../helpers/BuildingTypes';

import { useOrganizationRoles } from '../../../helpers/checkRoles';
import EditBuildingModal from '../../../components/modals/BuildingModals/EditBuildingModal';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import BuildingAccountsTable from './BuildingAccountsTable';
import ResourceDetails from '../../../components/ResourceDetails';
import AddUtilityAccountModal from '../../../components/modals/UtilityAccountModals/AddUtilityAccountModal';
import ResourceActionButtons from '../ResourceActionButton';
import ResourceNav from '../ResourceNav';

const styles = {
  button: { marginRight: '0.5em', marginLeft: '0.5em' },
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
};

export default function Building(props) {
  const {
    userSelectedOrganization,
    userSelectedOrganizationDetails,
    selectedResource,
    setSelectedResource,
    buildingInfo,
    setBuildingInfo,
    building,
    setBuilding,
    utilityAccountInfo,
    setUtilityAccountInfo,
    resetBuilding,
    resetUtilityAccount,
    OBAUpdate,
    handleUpdateTables,
    setIsLoading,
    isSaving,
    setIsSaving,
    isDeleting,
    setIsDeleting,
    showToast,
  } = props;

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showUtilityModal, setShowUtilityModal] = useState(false);

  const [lastOBAUpdate, setLastOBAUpdate] = useState(0);
  const [firstUpdate, setFirstUpdate] = useState(false);
  const [manualRefresh, setManualRefresh] = useState(0);

  const updateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'building',
    'update'
  );

  const deleteAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'building',
    'delete'
  );

  const accountCreateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'utility_account',
    'create'
  );

  const accountUpdateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'utility_account',
    'update'
  );

  const disableProviderAccounts = (building) => {
    ApiServiceServerless.post(
      `/providers/disable/building/${buildingInfo.id}`,
      {},
      {
        authorization_id: buildingInfo.id,
        alternate_authorization_id: building.organization_id,
      }
    )
      .then(() => {
        deleteBuilding();
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        setIsDeleting(false);
        return false;
      });
  };

  const deleteBuilding = () => {
    ApiServiceServerless.delete(`buildings/${buildingInfo.id}`, {
      authorization_id: buildingInfo.id,
    })
      .then(() => {
        setSelectedResource('organization');
        resetBuilding();
        handleUpdateTables(0);
        showToast(
          'success',
          'Success',
          `Building "${building.name}" was deleted.`
        );
        setIsDeleting(false);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        setIsDeleting(false);
        throw error;
      });
  };

  const onDeleteClick = () => {
    let msg = `Are you sure you want to delete ${building.name}?  This cannot be undone.`;

    const has_provider_account = building['has_provider_account'];
    if (has_provider_account) {
      msg +=
        ' Additionally, any Automated Billing accounts associated with this building will be disabled';
    }

    const beginBuildingDelete = () => {
      setIsDeleting(true);
      if (has_provider_account) {
        disableProviderAccounts(building);
      } else {
        deleteBuilding();
      }
    };
    withConfirm(msg, beginBuildingDelete);
  };

  const getBuilding = useCallback(() => {
    setIsLoading(true);
    ApiServiceServerless.get(`buildings/${buildingInfo.id}`, {
      authorization_id: buildingInfo.id,
    })
      .then((res) => {
        setBuilding({
          ...res.data,
        });
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [showToast, buildingInfo.id, setBuilding, setIsLoading]);

  useEffect(() => {
    getBuilding();
    setFirstUpdate(true);
  }, [getBuilding]);

  const handleSetNewAccount = (data) => {
    setBuilding((prev) => ({ ...prev, accounts: [...prev.accounts, data] }));
    handleUpdateTables(0);
  };

  const handleSetBuilding = (data) => {
    setBuilding((prev) => ({
      ...data,
      organization: prev.organization,
      accounts: prev.accounts,
      siblings: prev.siblings,
    }));
  };

  useEffect(() => {
    if (
      firstUpdate &&
      OBAUpdate !== lastOBAUpdate &&
      selectedResource === 'building'
    ) {
      getBuilding();
      setLastOBAUpdate(OBAUpdate);
    }
    if (!firstUpdate) {
      setLastOBAUpdate(OBAUpdate);
    }
  }, [OBAUpdate, lastOBAUpdate, selectedResource, firstUpdate, getBuilding]);

  useEffect(() => {
    if (manualRefresh > 0) {
      getBuilding();
      setManualRefresh(0);
    }
  }, [manualRefresh, getBuilding]);

  return (
    <div style={{ display: selectedResource === 'building' ? '' : 'none' }}>
      {building.id && (
        <>
          <Row style={{ marginBottom: '1em' }}>
            <Col>
              <ResourceNav
                selectedResource={selectedResource}
                setSelectedResource={setSelectedResource}
                organizationInfo={userSelectedOrganization}
                buildingInfo={buildingInfo}
                utilityAccountInfo={utilityAccountInfo}
                resetBuilding={resetBuilding}
                resetUtilityAccount={resetUtilityAccount}
              />
            </Col>
            <Col md={8}>
              <Row>
                <Col md={2}>
                  <ResourceDetails
                    header={'Address'}
                    value={building.address}
                    variant={'address'}
                  />
                </Col>
                <Col md={2}>
                  <ResourceDetails
                    header={'Building Type'}
                    value={
                      building.building_type
                        ? BuildingTypes[building.building_type]
                        : null
                    }
                  />
                </Col>
                <Col md={2}>
                  <ResourceDetails
                    header={'Utility Usage Profile'}
                    value={
                      building.benchmark_type
                        ? BUILDING_BENCHMARK_OPTIONS[building.benchmark_type]
                        : null
                    }
                  />
                </Col>
                <Col md={2}>
                  <ResourceDetails
                    header={'Area'}
                    value={
                      building.not_physical ? null : building.square_footage
                    }
                    variant={'number'}
                    suffix={'ft²'}
                  />
                </Col>
                <ResourceActionButtons
                  resource_name='Building'
                  updateAccess={updateAccess}
                  deleteAccess={deleteAccess}
                  showEditModal={() => setShowEditModal(true)}
                  handleDelete={() => onDeleteClick(true)}
                  disabled={isSaving || isDeleting}
                />
              </Row>
            </Col>
          </Row>
          <div
            style={{
              width: '100%',
              textAlign: 'right',
              marginBottom: '1em',
              marginTop: '4em',
              position: 'absolute',
              right: '20px',
            }}
          >
            <FontAwesomeIcon
              style={styles.iconStyle}
              icon={faSyncAlt}
              title={'Refresh Building'}
              onClick={() => setManualRefresh(1)}
            />
            {accountCreateAccess && (
              <FontAwesomeIcon
                style={styles.iconStyle}
                icon={faMeter}
                title={'Add Utility Account'}
                onClick={() => setShowUtilityModal(true)}
              />
            )}
          </div>

          <BuildingAccountsTable
            building={building}
            updateAccess={accountUpdateAccess}
            setBuilding={setBuilding}
            setSelectedResource={setSelectedResource}
            setUtilityAccountInfo={setUtilityAccountInfo}
            OBAUpdate={OBAUpdate}
            handleUpdateTables={handleUpdateTables}
            showToast={showToast}
          />

          <div style={{ marginBottom: '20px' }} />
        </>
      )}
      <ConfirmationModal {...confirmationModalProps} />
      <EditBuildingModal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        building_id={buildingInfo.id}
        building={building}
        organization_name={building.organization.name}
        handleSetBuilding={handleSetBuilding}
        handleUpdateTables={handleUpdateTables}
        isLoading={false}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        showToast={showToast}
      />
      <AddUtilityAccountModal
        organization={building.organization}
        buildings={building.siblings}
        defaultBuildings={[building.id]}
        setDefaultBuildings={() => {}}
        show={showUtilityModal}
        onHide={() => {
          setShowUtilityModal(false);
        }}
        openAccountModal={() => setShowUtilityModal(true)}
        handleSetAccount={handleSetNewAccount}
        buildingInfo={buildingInfo}
        setSelectedResource={setSelectedResource}
        setBuildingInfo={setBuildingInfo}
        setUtilityAccountInfo={setUtilityAccountInfo}
        showToast={showToast}
      />
    </div>
  );
}
