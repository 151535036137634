import React, { useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';

import {
  getUserOrganizationPreference,
  setUserOrganizationPreference,
} from '../helpers/user-preferences';

const styles = {
  formLabelStyles: {
    fontSize: '0.9rem',
    marginBottom: '0',
  },
};

function FilterSelect(props) {
  const {
    label,
    options,
    setFilterValue,
    size,
    organization_id,
    preference_key,
    empty_preference,
  } = props;

  const [localValue, setLocalValue] = useState(
    preference_key
      ? getUserOrganizationPreference(preference_key, organization_id)
      : ''
  );

  useEffect(() => {
    setFilterValue(localValue);
  }, [localValue, setFilterValue, preference_key]);

  const handleSetLocalValue = (value) => {
    setLocalValue(value);
    if (preference_key) {
      if (value === empty_preference) {
        setUserOrganizationPreference(
          preference_key,
          organization_id,
          null,
          true
        );
      } else {
        setUserOrganizationPreference(
          preference_key,
          organization_id,
          value,
          false
        );
      }
    }
  };

  return (
    <Form.Group as={Col} md={size}>
      <Form.Label style={styles.formLabelStyles}>{label}</Form.Label>
      <Form.Control
        as='select'
        onChange={(e) => handleSetLocalValue(e.target.value)}
        value={localValue}
      >
        {Object.keys(options).map((key) => (
          <option key={key} value={key}>
            {options[key]}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}

FilterSelect.defaultProps = {
  organization_id: null,
  preference_key: null,
  empty_preference: null,
};

export default FilterSelect;
