import React, { useCallback, useEffect, useState } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import Helmet from 'react-helmet';

import { useTabUrl } from '../../helpers/tracked';
import { ApiServiceServerless } from '../../xhr_libs';
import ResourceAlerts from './ResourceAlerts';
import BillDiscrepancyAlerts from './BillDiscrepancyAlerts';
import BillIntegrityDetails from './BillIntegrityDetails';

const pageTitle = 'Alerts';
export default function Alerts(props) {
  const {
    userSelectedOrganization,
    userSelectedOrganizationDetails,
    showToast,
  } = props;

  const [activeAlertsLicense, setActiveAlertsLicense] = useState(false);

  useEffect(() => {
    let details = { ...userSelectedOrganizationDetails };
    if (details.id) {
      setActiveAlertsLicense(
        details && details.licenses && details.licenses.alerts
      );
    }
  }, [userSelectedOrganizationDetails]);

  const [activeTab, setActiveTab] = useTabUrl('data-coverage');
  const [activeTabList, setActiveTabList] = useState([]);

  const addNewActiveTab = useCallback(
    (tab) => {
      if (tab !== activeTab) {
        setActiveTab(tab);
      }
      if (!activeTabList.includes(tab)) {
        let temp_keys = [...activeTabList];
        temp_keys.push(tab);
        setActiveTabList(temp_keys);
      }
    },
    [activeTab, activeTabList, setActiveTab]
  );

  useEffect(() => {
    addNewActiveTab(activeTab);
  }, [activeTab, addNewActiveTab]);

  const [buildingLookup, setBuildingLookup] = useState({});
  const [accountLookup, setAccountLookup] = useState({});
  const [utilityTypes, setUtilityTypes] = useState([]);
  const [years, setYears] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getAlertDetails = useCallback(
    (id) => {
      setBuildingLookup({});
      setAccountLookup({});
      setUtilityTypes([]);
      setYears([]);

      if (id) {
        setIsLoading(true);
        ApiServiceServerless.get(`/alerts/info/${id}`, {
          authorization_id: id,
        })
          .then((res) => {
            setBuildingLookup(res.data.building_lookup);
            const ids = Object.keys(res.data.building_lookup);
            let int_ids = [];
            ids.forEach((id) => {
              int_ids.push(parseInt(id));
            });
            setAccountLookup(res.data.account_lookup);
            setUtilityTypes(res.data.utility_types);
            let years = res.data.years;
            setYears(years);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      }
    },
    [setIsLoading]
  );

  useEffect(() => {
    getAlertDetails(userSelectedOrganization.id);
  }, [userSelectedOrganization.id, getAlertDetails]);

  return (
    <Container className='et-container et-main-tab-content'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {!activeAlertsLicense && (
        <div style={{ color: 'red' }}>
          This organization does not have a valid license to access this
          feature.Please contact an administrator about licensing.
        </div>
      )}
      {activeAlertsLicense && (
        <Tabs
          id='tools-tabs'
          activeKey={activeTab}
          onSelect={addNewActiveTab}
          className='et-main-tabs'
        >
          <Tab eventKey='data-coverage' title={'Data Coverage'}>
            {activeTabList.includes('data-coverage') && (
              <BillIntegrityDetails
                org_id={userSelectedOrganization.id}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                buildingLookup={buildingLookup}
                accountLookup={accountLookup}
                utilityTypes={utilityTypes}
                years={years}
              />
            )}
          </Tab>
          <Tab eventKey='bill-discrepancy' title={'Bill Discrepancy Alerts'}>
            {activeTabList.includes('bill-discrepancy') && (
              <BillDiscrepancyAlerts
                org_id={userSelectedOrganization.id}
                showToast={showToast}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                buildingLookup={buildingLookup}
                accountLookup={accountLookup}
                utilityTypes={utilityTypes}
                years={years}
              />
            )}
          </Tab>

          <Tab
            eventKey='resource-integrity'
            title={'Resource Integrity Alerts'}
          >
            {activeTabList.includes('resource-integrity') && (
              <ResourceAlerts org_id={userSelectedOrganization.id} />
            )}
          </Tab>
        </Tabs>
      )}
    </Container>
  );
}
