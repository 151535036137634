const APP_ID = 'energytracer';

const WEBCLIENT_ID = process.env.REACT_APP_USER_POOL_WEBCLIENT_ID;
const REFRESH_TOKEN = 'RefreshToken';
const ID_TOKEN = 'IdToken';

const getTokens = () => {
  return {
    refreshToken: localStorage.getItem(
      `${APP_ID}.${WEBCLIENT_ID}.${REFRESH_TOKEN}`
    ),
    idToken: localStorage.getItem(`${APP_ID}.${WEBCLIENT_ID}.${ID_TOKEN}`),
  };
};

const setTokens = (payload) => {
  for (const token of [ID_TOKEN, REFRESH_TOKEN]) {
    if (!(token in payload)) {
      continue;
    }
    localStorage.setItem(`${APP_ID}.${WEBCLIENT_ID}.${token}`, payload[token]);
  }
  window.dispatchEvent(new Event('energytracer_oauth'));
};

const removeTokens = () => {
  for (const token of [ID_TOKEN, REFRESH_TOKEN]) {
    localStorage.removeItem(`${APP_ID}.${WEBCLIENT_ID}.${token}`);
  }
  window.dispatchEvent(new Event('energytracer_oauth'));
};

export { setTokens, getTokens, removeTokens };
