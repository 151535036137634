export const REGRESSORS = {
  temperature: 'Temperature (Avg.)',
  temperature_median: 'Temperature (Median)',
  wind_speed: 'Wind Speed (Avg.)',
  wind_speed_median: 'Wind Speed (Median)',
  wind_degree: 'Wind Degree (Avg.)',
  wind_degree_median: 'Wind Degree (Median)',
  precip: 'Precipitation (Avg.)',
  precip_median: 'Precipitation (Median)',
  humidity: 'Humidity (Avg.)',
  humidity_median: 'Humidity (Median)',
  pressure: 'Pressure (Avg.)',
  pressure_median: 'Pressure (Median)',
  cloudcover: 'Cloud Cover (Avg.)',
  cloudcover_median: 'Cloud Cover (Median)',
  heatindex: 'Heat Index (Avg.)',
  heatindex_median: 'Heat Index (Median)',
  dewpoint: 'Dewpoint (Avg.)',
  dewpoint_median: 'Dewpoint (Median)',
  windchill: 'Wind Chill (Avg.)',
  windchill_median: 'Wind Chill (Median)',
  windgust: 'Wind Gust (Avg.)',
  windgust_median: 'Wind Gust (Median)',
  feelslike: 'Feels Like Temp. (Avg.)',
  feelslike_median: 'Feels Like Temp. (Median)',
  uv_index: 'UV Index (Avg.)',
  uv_index_median: 'UV Index (Median)',
};

export default REGRESSORS;

export const REGRESSORS_UNITS = {
  temperature: '°F',
  temperature_median: '°F',
  wind_speed: 'mph',
  wind_speed_median: 'mph',
  wind_degree: '',
  wind_degree_median: '',
  precip: 'in',
  precip_median: 'in',
  humidity: '',
  humidity_median: '',
  pressure: 'mbar',
  pressure_median: 'mbar',
  cloudcover: '',
  cloudcover_median: '',
  heatindex: '',
  heatindex_median: '',
  dewpoint: '°F',
  dewpoint_median: '°F',
  windchill: '°F',
  windchill_median: '°F',
  windgust: 'mph',
  windgust_median: 'mph',
  feelslike: '°F',
  feelslike_median: '°F',
  uv_index: '',
  uv_index_median: '',
};

export const IGNORE_REGRESORS = [
  'years',
  'months',
  'energy',
  'production_names',
  'days',
];

export const NORMAL_CHANGE_POINTS_REGRESSORS = [
  'temperature',
  'temperature_median',
  'dewpoint',
  'dewpoint_median',
  'feelslike',
  'feelslike_median',
];

export const NORMAL_CHANGE_POINTS_MODELS = ['3pc', '3ph', '5p'];

export const UTILITY_TYPE_UNITS = {
  consumption: { electric: 'kwh', gas: 'ccf' },
  demand: { electric: 'kw' },
};

export const MODEL_LENGTH_LOOKUP = {
  linear: 1,
  '3pc': 2,
  '3ph': 2,
  '5p': 4,
  '': 0,
};

export const MODEL_NAMES = {
  linear: ['Slope'],
  '3pc': ['Slope', 'Change Point'],
  '3ph': ['Slope', 'Change Point'],
  '5p': ['Slope', 'Change Point', 'Slope', 'Change Point'],
  '': [''],
};

export const MODEL_PREFIXES = {
  '3pc': ['Cooling', 'Cooling'],
  '3ph': ['Heating', 'Heating'],
  '5p': ['Cooling', 'Cooling', 'Heating', 'Heating'],
};

export const regressionPiecesToName = (pieces, productionNames) => {
  let names = ['Indepent'];
  let model = '';
  let regressor = '';
  let temp_names = [];
  let prefixes = [];
  pieces.forEach((piece) => {
    model = piece['model'];
    regressor = piece['regressor'];
    temp_names = [...MODEL_NAMES[model]];
    prefixes = [];
    //get prefixes
    if (
      NORMAL_CHANGE_POINTS_REGRESSORS.includes(regressor) &&
      NORMAL_CHANGE_POINTS_MODELS.includes(model)
    ) {
      prefixes = [...MODEL_PREFIXES[model]];
    } else if (regressor && regressor.includes('production')) {
      temp_names.forEach((name) => {
        if (
          productionNames &&
          Object.keys(productionNames).includes(regressor)
        ) {
          prefixes.push(productionNames[regressor]);
        } else {
          prefixes.push(regressor);
        }
      });
    } else {
      temp_names.forEach((name) => {
        prefixes.push(REGRESSORS[regressor]);
      });
    }
    //append prefixes
    prefixes.forEach((pre, idx) => {
      temp_names[idx] = pre + ' ' + temp_names[idx];
    });
    names = [...names, ...temp_names];
  });
  return names;
};
