import React from 'react';
import { Form } from 'react-bootstrap';
import { sortBy } from 'lodash';

import InvalidFeedback from '../../forms/InvalidFeedback';
import { UTILITY_TYPES } from '../../../helpers/utility-types';

function CreateNewAccountOpts(props) {
  const { buildings, errors, register } = props;

  return (
    <>
      <Form.Group controlId='urjanet.providerForm.utilityType'>
        <Form.Label>
          Utility Type
          <span className='text-warning font-weight-bold pl-1'>*</span>
        </Form.Label>
        <Form.Control
          as='select'
          name='account_type'
          isInvalid={errors.types}
          ref={register({ required: true })}
        >
          {Object.keys(UTILITY_TYPES).map((type) => (
            <option key={`type-opt-${type}`} value={type}>
              {UTILITY_TYPES[type]}
            </option>
          ))}
        </Form.Control>
        <InvalidFeedback
          errors={errors}
          name='account_type'
          message='A utility type is required.'
        />
      </Form.Group>
      <Form.Group controlId='urjanet.providerForm.buildingsSelect'>
        <Form.Label>Select Building(s)</Form.Label>
        <Form.Control
          as='select'
          name='buildings'
          multiple
          isInvalid={errors.buildings}
          ref={register({ required: true })}
        >
          {sortBy(buildings, ['name']).map((building) => (
            <option key={`building-opt-${building.id}`} value={building.id}>
              {building.name}
            </option>
          ))}
        </Form.Control>
        <InvalidFeedback
          errors={errors}
          name='buildings'
          message='Please select one or more buildings.'
        />
      </Form.Group>
    </>
  );
}

export default CreateNewAccountOpts;
