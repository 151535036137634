import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal, Form, Col } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { sortBy } from 'lodash';

import { ApiServiceServerless } from '../../../../../xhr_libs';
import AddEntityActionButtons from '../../../../forms/AddEntityActionButtons';
import consumptionUnits from '../../../../../helpers/consumptionUnits';
import BillDateSelect from '../../../../bills/BillFormInputs/BillDateSelect';

const utilityTypes = ['electric', 'gas', 'propane', 'water'];

const default_rate = {
  name: '',
  utility_type: 'electric',
  value: null,
  unit: 'kwh',
  flat_value: 0,
  demand_rate: 0,
  year: null,
  month: null,
};

function AddOrganizationRateModal(props) {
  const {
    show,
    onHide,
    organizationId,
    addToList,
    updateList,
    showToast,
    rateCollections,
    defaultCollection,
  } = props;

  const [availableConsumptionUnits, setAvailableConsumptionUnits] = useState(
    []
  );
  const [disabled, setDisabled] = useState(false);
  const [rateData, setRateData] = useState(default_rate);
  const [collectionIds, setCollectionIds] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (defaultCollection !== null) {
      setCollectionIds([defaultCollection.id]);
      setRateData((prev) => ({
        ...prev,
        utility_type: defaultCollection.utility_type,
        unit: consumptionUnits[defaultCollection.utility_type][0],
      }));
      setAvailableConsumptionUnits(
        consumptionUnits[defaultCollection.utility_type]
      );
    } else {
      setCollectionIds([]);
      setRateData((prev) => ({
        ...prev,
        utility_type: 'electric',
        unit: consumptionUnits['electric'][0],
      }));
      setAvailableConsumptionUnits(consumptionUnits['electric']);
    }
  }, [defaultCollection, show]);

  const updateUtilityType = (value) => {
    setAvailableConsumptionUnits(consumptionUnits[value]);
    setRateData((prev) => ({
      ...prev,
      unit: consumptionUnits[value][0],
    }));
    setCollectionIds([]);
  };

  const parseNumber = (val) => {
    const float_val = !isNaN(parseFloat(val.replace(/,/g, '')))
      ? parseFloat(val.replace(/,/g, ''))
      : 0;
    return float_val;
  };

  const updateSet = (values) => {
    let arr = Array.prototype.slice.call(values);
    arr = arr.map((item) => item.value);
    setCollectionIds(arr);
  };

  useEffect(() => {
    setDisabled(
      rateData['value'] === null ||
        rateData['name'] === null ||
        rateData['year'] === null ||
        rateData['month'] === null
    );
  }, [rateData]);

  const addRate = () => {
    setIsLoading(true);
    ApiServiceServerless.post(
      `/custom_rates`,
      {
        ...rateData,
        organization_id: organizationId,
        collection_ids: collectionIds,
      },
      { authorization_id: organizationId }
    )
      .then((res) => {
        showToast('success', 'Success', 'Rate successfully added');
        addToList(res.data[0], 'rates');
        res.data[1].forEach((collection) => {
          updateList(collection, 'rate_collections');
        });
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        handleOnHide();
        setIsLoading(false);
      });
  };

  const handleOnHide = () => {
    setRateData(default_rate);
    setCollectionIds([]);
    onHide();
  };

  return (
    <Modal show={show} onHide={handleOnHide}>
      <Modal.Header closeButton> Add a Custom Rate</Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            {'Name'}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <Form.Control
              required
              placeholder=''
              onChange={(e) =>
                setRateData({
                  ...rateData,
                  name: e.target.value,
                })
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>Year</Form.Label>
            <BillDateSelect
              type='year'
              onChange={(e) =>
                setRateData({
                  ...rateData,
                  year: isNaN(parseInt(e.target.value))
                    ? null
                    : parseInt(e.target.value),
                })
              }
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Month</Form.Label>
            <BillDateSelect
              type='month'
              onChange={(e) =>
                setRateData({
                  ...rateData,
                  month: isNaN(parseInt(e.target.value))
                    ? null
                    : parseInt(e.target.value) + 1,
                })
              }
            />
          </Form.Group>
        </Form.Row>

        <Form.Group controlId='accountForm.utilityType'>
          <Form.Label>
            Utility type
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Control
            as='select'
            required
            disabled={defaultCollection ? true : false}
            defaultValue={
              defaultCollection ? defaultCollection.utility_type : 'electric'
            }
            onChange={(e) => {
              setRateData({
                ...rateData,
                utility_type: e.target.value,
              });
              updateUtilityType(e.target.value);
            }}
          >
            {utilityTypes.map((type) => (
              <option key={`type-opt-${type}`} value={type}>
                {type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {'Rate ($/unit)'}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={3}
              fixedDecimalScale
              placeholder={'0.000'}
              onChange={(e) =>
                setRateData({
                  ...rateData,
                  value: parseNumber(e.target.value),
                })
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Group controlId='orgRate.unit'>
          <Form.Label>Unit</Form.Label>
          <Form.Control
            as='select'
            required
            onChange={(e) =>
              setRateData({
                ...rateData,
                unit: e.target.value,
              })
            }
            value={rateData.unit}
          >
            {availableConsumptionUnits.map((unit) => (
              <option key={`unit-opt-${unit}`} value={unit}>
                {unit}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>{'Flat Rate ($)'}</Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={3}
              fixedDecimalScale
              placeholder={'0.000'}
              onChange={(e) =>
                setRateData({
                  ...rateData,
                  flat_value: parseNumber(e.target.value),
                })
              }
            />
          </Form.Row>
        </Form.Group>
        {rateData.utility_type === 'electric' && (
          <Form.Group>
            <Form.Label>{'Demand Rate ($/kw)'}</Form.Label>
            <Form.Row>
              <NumberFormat
                customInput={Form.Control}
                thousandSeparator
                isNumericString
                decimalScale={3}
                fixedDecimalScale
                placeholder={'0.000'}
                onChange={(e) =>
                  setRateData({
                    ...rateData,
                    demand_rate: parseNumber(e.target.value),
                  })
                }
              />
            </Form.Row>
          </Form.Group>
        )}
        {rateCollections.length > 0 && (
          <Form.Group>
            <Form.Label>Collection(s)</Form.Label>
            <Form.Control
              as='select'
              multiple
              disabled={defaultCollection !== null}
              defaultValue={
                defaultCollection !== null ? [defaultCollection.id] : []
              }
              onChange={(e) => updateSet(e.target.selectedOptions)}
            >
              {sortBy(
                rateCollections.filter(
                  (collection) =>
                    collection.utility_type === rateData.utility_type
                ),
                ['name']
              ).map((item) => (
                <option key={`collection-opt-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={handleOnHide}
          onSubmit={addRate}
          submitDisabled={disabled}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

AddOrganizationRateModal.propTypes = {
  defaultCollection: PropTypes.object,
};

AddOrganizationRateModal.defaultProps = {
  defaultCollection: null,
};

export default AddOrganizationRateModal;
