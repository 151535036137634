import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import { ApiServiceServerless } from '../../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../modals/ConfirmationModal';

import EnergyTracerTable from '../../../tables';
import ViewGHGConversionModal from '../../../modals/ViewGHGConversionModal';
import AddConversionCollectionModal from '../../../modals/AddConversionCollectionModal';
import AddCustomGHGModal from '../../../modals/AddCustomGHGModal';
import EditCustomGHGCollectionModal from '../../../modals/EditCustomGHGCollectionModal';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

export default function CustomGHGCollection(props) {
  const {
    showToast,
    organization,
    updateAccess,
    deleteAccess,
    GHGConversions,
    collection,
    addToList,
    updateList,
    removeFromList,
    resetCollection,
  } = props;

  const [tableData, setTableData] = useState([]);

  const [showAdd, setShowAdd] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [showView, setShowView] = useState(false);
  const [viewRow, setViewRow] = useState(null);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleSetView = (row) => {
    setViewRow(row);
    setShowView(true);
  };

  const handleHideView = () => {
    setViewRow(null);
    setShowView(false);
  };

  useEffect(() => {
    let temp_data = [];
    if (GHGConversions.length > 0) {
      GHGConversions.forEach((conversion) => {
        if (collection.conversion_ids.includes(conversion.id)) {
          temp_data.push(conversion);
        }
      });
      setTableData(temp_data);
    } else {
      setTableData([]);
    }
  }, [collection, GHGConversions]);

  const handleDelete = () => {
    const msg = `Are you sure you want to delete this Collection? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteCustomGHGCollections();
    });
  };

  const deleteCustomGHGCollections = () => {
    setIsDeleting(true);
    ApiServiceServerless.delete(
      `/custom_ghg_conversions/collection/${parseInt(collection.id)}`,
      { authorization_id: organization.id }
    )
      .then(() => {
        showToast('success', 'Success', 'Collection Deleted');
        removeFromList(collection.id, 'ghg_collections');
        resetCollection();
        setIsDeleting(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        setIsDeleting(false);
        throw err;
      });
  };

  const handleDeleteCollectionConversions = (id) => {
    const msg = `Are you sure you want to delete this Conversion from this Collection? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteCustomGHGCollectionConversions(id);
    });
  };

  const deleteCustomGHGCollectionConversions = (id) => {
    ApiServiceServerless.delete(
      `/custom_ghg_conversions/collection_conversion/${collection.id}/${id}`,
      { authorization_id: organization.id }
    )
      .then((res) => {
        showToast('success', 'Success', 'Collection Deleted');
        updateList(res.data[0], 'ghg_collections');
        updateList(res.data[1], 'ghg_conversions');
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'GHG Conversion',
      style: styles.column,
      formatter: (cell, row) => (
        <>
          <div
            className='et-link'
            style={{ display: 'inline-block' }}
            onClick={() => {
              handleSetView(row);
            }}
          >
            {row.name}{' '}
          </div>
        </>
      ),
    },
    { dataField: 'year', text: 'Year', style: styles.column },
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell) => (
        <>
          {deleteAccess && (
            <Button
              className='btn btn-danger'
              style={{ maxWidth: '33px', maxHeight: '31px' }}
              onClick={() => {
                handleDeleteCollectionConversions(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                }}
                icon={faTrashAlt}
              />
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {collection && (
        <h4 style={{ marginTop: '1em' }}>
          {collection.name}{' '}
          {updateAccess && (
            <Button
              variant='edit'
              style={{ marginRight: '0.5em', marginLeft: '0.5em' }}
              onClick={() => setShowEditModal(true)}
              disabled={isDeleting}
            >
              Edit Collection
            </Button>
          )}
          {deleteAccess && (
            <Button
              variant='danger'
              style={{ marginRight: '0.5em', marginLeft: '0.5em' }}
              onClick={() => handleDelete()}
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete Collection'}
            </Button>
          )}
        </h4>
      )}

      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <div style={{ marginTop: '1em' }}>
            {updateAccess && (
              <Button
                style={{
                  marginBottom: '1em',
                  float: 'right',
                  marginLeft: '1em',
                }}
                onClick={() => setShowAddNew(true)}
              >
                Add New Conversion
              </Button>
            )}
            {updateAccess && (
              <Button
                style={{ marginBottom: '1em', float: 'right' }}
                onClick={() => setShowAdd(true)}
              >
                Add to Collection
              </Button>
            )}
            <EnergyTracerTable
              data={tableData}
              columns={tableColumns}
              keyField={'id'}
            />
          </div>
        </Col>
      </Row>

      <ViewGHGConversionModal
        show={showView}
        onHide={() => handleHideView()}
        rows={viewRow ? [viewRow] : []}
      />
      <AddConversionCollectionModal
        show={showAdd}
        onHide={() => setShowAdd(false)}
        collection={collection}
        organization_id={organization.id}
        GHGConversions={GHGConversions}
        updateList={updateList}
        showToast={showToast}
      />
      <AddCustomGHGModal
        show={showAddNew}
        onHide={() => {
          setShowAddNew(false);
        }}
        showToast={showToast}
        organization_id={organization.id}
        addToList={addToList}
        updateList={updateList}
        GHGCollections={[collection]}
        defaultCollection={collection}
      />
      <EditCustomGHGCollectionModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        showToast={showToast}
        organization_id={organization.id}
        collection={collection}
        updateList={updateList}
      />

      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
}
