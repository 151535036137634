import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import MathJax from 'react-mathjax';

import MONTHSABBREVIATED from '../../../helpers/monthsAbbreviated';
import { regressionPiecesToName } from '../regressors';
import EnergyTracerTable from '../../tables';

const tableCols = [
  {
    dataField: 'name',
    text: 'Name',
    formatter: htmlFormatter((row) => []),
  },
  { dataField: 'value', text: 'Value' },
  {
    dataField: 'desc',
    text: 'Description',
  },
];

export default function RegressionInfoTable(props) {
  const {
    coefficients,
    equation,
    stats,
    chartData,
    regressionPieces,
    productionNames,
  } = props;

  const [tableRows, setTableRows] = useState([]);

  const [statRows, setStatRows] = useState([]);
  const [statCols, setStatCols] = useState([]);

  useEffect(() => {
    let table_rows = [];
    let regression_names = regressionPiecesToName(
      regressionPieces,
      productionNames
    );

    let temp_obj = {};
    coefficients.forEach((val, idx) => {
      temp_obj = {
        name: 'x' + idx,
        value: val.toFixed(2).toLocaleString(),
        desc: regression_names[idx],
        idx: idx,
      };
      table_rows.push(temp_obj);
    });

    setTableRows(table_rows);
  }, [regressionPieces, productionNames, coefficients, chartData]);

  useEffect(() => {
    if (stats.RMSE) {
      let stat_cols = [
        { dataField: 'RMSE', text: 'RMSE' },
        { dataField: 'CVRMSE', text: 'CVRMSE' },
        { dataField: 'R2', text: 'R2' },
      ];
      let stat_row = [
        {
          RMSE: parseFloat(stats.RMSE).toFixed(2).toLocaleString(),
          CVRMSE: parseFloat(stats.CVRMSE).toFixed(3).toLocaleString(),
          R2: parseFloat(stats.R2).toFixed(3).toLocaleString(),
        },
      ];

      if (
        Object.keys(stats).includes('outlier') &&
        chartData &&
        Object.keys(chartData).includes('months') &&
        chartData['months'][stats['outlier']]
      ) {
        stat_row[0]['outlier'] =
          MONTHSABBREVIATED[chartData['months'][stats['outlier']]] +
          ' ' +
          chartData['years'][stats['outlier']];
        stat_cols.push({ dataField: 'outlier', text: 'Outlier' });
      }
      setStatCols(stat_cols);
      setStatRows(stat_row);
    } else {
      setStatCols([]);
      setStatRows([]);
    }
  }, [stats, chartData]);

  return (
    <Row>
      <Col>
        <Row>
          <div style={{ width: '100%' }}>
            <MathJax.Provider input='ascii'>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <MathJax.Node inline formula={equation} />
              </div>
            </MathJax.Provider>
          </div>
        </Row>
        {coefficients.length > 0 && (
          <Row>
            <Col>
              <EnergyTracerTable
                keyField='idx'
                data={tableRows}
                columns={tableCols}
              />
            </Col>
          </Row>
        )}
        {statCols.length > 0 && (
          <Row>
            <Col>
              <EnergyTracerTable
                keyField='RMSE'
                data={statRows}
                columns={statCols}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export function htmlFormatter() {
  const formatter = function (_cell, row) {
    return (
      <>
        {_cell.length === 2 ? (
          <div>
            {_cell.slice(0, 1)}
            <sub>{_cell.slice(1, 2)}</sub>
          </div>
        ) : (
          _cell
        )}
      </>
    );
  };
  return formatter;
}
