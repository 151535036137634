import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Helmet from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import LoginPageDecoration from '../components/LoginPageDecoration';
import InvalidFeedback from '../components/forms/InvalidFeedback';
import ReCaptcha, { withReCaptcha } from '../components/ReCaptcha';
import { ApiServicePublicServerless } from '../xhr_libs';

const PAGE_TITLE = 'Activate Account';

function Activate({ location, history, showToast }) {
  const [userId, setUserId] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userToken, setUserToken] = useState();
  const [sumbitting, setSubmitting] = useState(false);

  const { register, errors, handleSubmit } = useForm();
  const recaptchaRef = useRef();

  const onSubmit = () => {
    setSubmitting(true);
    withReCaptcha(() => {
      ApiServicePublicServerless.get(`/activate/${userId}/${userToken}`)
        .then((res) => {
          history.push(res.data);
        })
        .catch((err) => {
          setSubmitting(false);
          showToast('danger', 'Error', err);
          throw err;
        });
    }, recaptchaRef);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const user_email = params.get('user_email');
    const user_id = params.get('user_id');
    const user_token = params.get('token');
    setUserId(user_id);
    setUserEmail(user_email);
    setUserToken(user_token);
  }, [location.search]);

  return (
    <>
      <ReCaptcha ref={recaptchaRef} />
      <LoginPageDecoration />
      <Container
        style={{ marginTop: '2em', width: '450px' }}
        className='et-unauth-container'
      >
        <Helmet>
          <title>{PAGE_TITLE}</title>
        </Helmet>
        <Card style={{ border: 'none' }}>
          <Card.Body className={'login-cardbody'}>
            <h4 className={'mb-4'}>{PAGE_TITLE}</h4>

            <Form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              style={{ textAlign: 'left' }}
            >
              <Form.Group>
                <Form.Label>
                  Email
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  placeholder=''
                  isInvalid={errors.email}
                  defaultValue={userEmail}
                  disabled={userEmail}
                  ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                />
                <InvalidFeedback
                  errors={errors}
                  name='email'
                  message='Please provide a valid email address'
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  User Access Token
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <Form.Control
                  placeholder=''
                  name='userToken'
                  defaultValue={userToken}
                  disabled={userToken}
                  isInvalid={errors.userToken}
                  ref={register({ required: true })}
                />
                <InvalidFeedback
                  errors={errors}
                  name='userToken'
                  message='Field is required'
                />
              </Form.Group>

              <hr />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  className={'btn-success'}
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    marginBottom: '15px',
                  }}
                  type='submit'
                  disabled={sumbitting}
                >
                  {sumbitting ? (
                    <>
                      {'Loading... '}
                      <FontAwesomeIcon
                        className='fa-spin'
                        icon={faSyncAlt}
                      />{' '}
                    </>
                  ) : (
                    ' Activate Account'
                  )}
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default withRouter(Activate);
