import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

export default function ResourceNav(props) {
  const {
    selectedResource,
    setSelectedResource,
    organizationInfo,
    buildingInfo,
    utilityAccountInfo,
    resetBuilding,
    resetUtilityAccount,
  } = props;

  return (
    <div style={{}}>
      <div style={{ fontSize: '0.8em', marginBottom: '0.5em' }}>Navigation</div>
      <Breadcrumb className='et-breadcrumb'>
        {organizationInfo.id && (
          <Breadcrumb.Item
            onClick={() => {
              setSelectedResource('organization');
              resetBuilding();
            }}
            active={'organization' === selectedResource}
          >
            {organizationInfo.name}{' '}
          </Breadcrumb.Item>
        )}
        {buildingInfo.id && (
          <Breadcrumb.Item
            onClick={() => {
              setSelectedResource('building');
              resetUtilityAccount();
            }}
            active={'building' === selectedResource}
          >
            {buildingInfo.name}
          </Breadcrumb.Item>
        )}
        {utilityAccountInfo.id && (
          <Breadcrumb.Item active={'utilityAccount' === selectedResource}>
            {utilityAccountInfo.name}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
    </div>
  );
}
