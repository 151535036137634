import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Row, Col, Accordion } from 'react-bootstrap';
import { isEqual } from 'lodash';

import AccordionCard from '../../components/AccordionCard';
import Bill from './Bill';
import BillsLoader from '../../pages/Resources/UtilityAccount/BillsLoader';
import SmallerLoader from '../SmallerLoader';

const classes = {
  container: { margin: '0px', padding: '0px' },
};

function BillList(props) {
  const { account, allBills, isLoadingBills } = props;

  const [billYears, setBillYears] = useState([]);
  const [billsGrouped, setBillsGrouped] = useState({});

  const [activeKey, setActiveKey] = useState(null);
  const [activeKeyList, setActiveKeyList] = useState([]);
  const [showLoader, setShowLoader] = useState(null);
  const [loadYears, setLoadYears] = useState(false); //this is to allow the accordion to open and avoid the user trying to double click

  const addNewActiveKey = (key) => {
    setActiveKey(key);
    if (!activeKeyList.includes(key)) {
      handleSetShowLoader(key);
      let temp_keys = [...activeKeyList];
      temp_keys.push(key);
      setTimeout(() => {
        setActiveKeyList(temp_keys);
      }, 100);
    }
  };

  const handleSetShowLoader = (key) => {
    setShowLoader(key);
    setTimeout(() => {
      setShowLoader(null);
    }, 500);
  };

  useEffect(() => {
    setBillsGrouped(groupByYear(allBills));
  }, [allBills]);

  useEffect(() => {
    const newYears = getYears(allBills);
    if (!isEqual(billYears, newYears)) {
      setBillYears(newYears);
      if (!checkTooManySubAccounts(account)) {
        setTimeout(() => {
          setLoadYears(true);
          setActiveKey(newYears[0]);
          setActiveKeyList([newYears[0]]);
          setTimeout(() => {
            setLoadYears(false);
          }, 800);
        }, 200);
      }
    }
  }, [account, allBills, billYears]);

  const getYears = (bills) => {
    const years = bills.map((bill) =>
      dayjs.utc(bill.bill_date).year().toString()
    );
    return [...new Set(years)].sort((a, b) => parseInt(b) - parseInt(a));
  };

  const groupByYear = (bills) => {
    let datesSplit = {};
    bills.map((data) =>
      datesSplit.hasOwnProperty(dayjs.utc(data.bill_date).year())
        ? datesSplit[dayjs.utc(data.bill_date).year()].push(data)
        : (datesSplit[dayjs.utc(data.bill_date).year()] = new Array(data))
    );
    return datesSplit;
  };

  const checkTooManySubAccounts = (account) => {
    if (account && account.sub_accounts && account.sub_accounts.length > 10) {
      return true;
    }
    return false;
  };

  return (
    <>
      {isLoadingBills ? (
        <BillsLoader />
      ) : (
        <div style={classes.container}>
          <Row>
            <Col style={{ margin: '0px', padding: '0px' }}>
              {billYears.length === 0 && (
                <div style={{ textAlign: 'center' }}>No data to display</div>
              )}
              <Accordion
                activeKey={activeKey}
                onSelect={(e) => addNewActiveKey(e)}
                style={{ pointerEvents: loadYears ? 'none' : 'all' }}
              >
                {billYears.map((year) => (
                  <AccordionCard
                    eventKey={year}
                    title={
                      year === showLoader ? (
                        <>
                          {year} <SmallerLoader />
                        </>
                      ) : (
                        year
                      )
                    }
                    key={year}
                  >
                    {activeKeyList.includes(year) && (
                      <>
                        {billsGrouped[year].map((bill) => (
                          <div
                            key={`bill-${bill.id}`}
                            style={{ paddingLeft: '20px' }}
                          >
                            <Bill {...props} data={bill} />
                          </div>
                        ))}
                      </>
                    )}
                  </AccordionCard>
                ))}
              </Accordion>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

export default React.memo(BillList);
