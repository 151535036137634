import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { ApiServiceServerless } from '../../../xhr_libs';
import Bill from '../../../components/bills/Bill';
import EmptyBill from '../../../components/bills/EmptyBill';

import CsvUploadContainer from './CsvUploadContainer';

const useEmptyBill = (type, defaultConsumptionUnit, allBills) => {
  const createEmptyBill = useCallback(
    () =>
      EmptyBill(
        type,
        defaultConsumptionUnit,
        typeof allBills !== 'undefined' && typeof allBills[0] !== 'undefined'
          ? allBills[0].read_date
          : null
      ),
    [type, defaultConsumptionUnit, allBills]
  );

  const [emptyBill, setEmptyBill] = useState(createEmptyBill());

  useEffect(() => {
    setEmptyBill(createEmptyBill());
  }, [createEmptyBill]);

  return emptyBill;
};

export default function AddBills(props) {
  const {
    type,
    defaultConsumptionUnit,
    account,
    allBills,
    isLoadingAccount,
    setIsSaving,
    showToast,
    handleRefreshBills,
    handleAddNewBill,
    validateBill,
    setSelectedResource,
    resetBuilding,
    handleNewMaxDate,
  } = props;

  const emptyBill = useEmptyBill(type, defaultConsumptionUnit, allBills);

  const saveNewBill = (data, exit) => {
    setIsSaving(true);
    let send_data = { ...data };
    delete send_data.provider_bill_type;
    delete send_data.provider_bill_id;
    ApiServiceServerless.post(
      `/bills`,
      { ...send_data, account_id: account.id },
      { authorization_id: account.id }
    )
      .then((res) => {
        handleAddNewBill(res.data);
        handleNewMaxDate({
          account_id: account.id,
          new_max_date: res.data.read_date,
        });
        const message = 'Bill has been created.';
        showToast('success', 'Success', message);
        setIsSaving(false);
        if (exit) {
          setSelectedResource('organization');
          resetBuilding();
        }
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        setIsSaving(false);
        throw error;
      });
  };
  return (
    <>
      <Row>
        <Col md={9}>
          <div
            style={{
              border: '1px solid rgba(0, 0, 0, 0.1)',
              padding: '0 0 1em 1em',
            }}
          >
            {emptyBill && (
              <Bill
                data={emptyBill}
                account={account}
                allBills={allBills}
                saveBill={saveNewBill}
                validateBill={validateBill}
                isTotal={false}
                updateAccess={true}
                addToSaveBills={() => {}}
                hideBottomBorder={true}
              />
            )}
          </div>
        </Col>
        <Col md={3}>
          <CsvUploadContainer
            isLoadingAccount={isLoadingAccount}
            account={account}
            showToast={showToast}
            handleRefreshBills={handleRefreshBills}
          />

          <br></br>
        </Col>
      </Row>
    </>
  );
}
