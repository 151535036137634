import React, { useEffect, useState } from 'react';

const ENV = process.env.REACT_APP_ENV;

export default function ArcadiaIFrame(props) {
  const { organizationId, iframe_key, provider_type } = props;

  const [iframeURL, setURL] = useState(null);

  useEffect(() => {
    let url = null;

    if (parseInt(provider_type) === 1) {
      url = `https://connect.urjanet.com/${iframe_key}?correlationId=${organizationId}&primaryColor=%23195E88&useCase=UDS`;
    }
    if (parseInt(provider_type) === 2) {
      url = `https://arc-connect.arcadia.com/${iframe_key}?correlationId=${organizationId}&primaryColor=%23195E88`;
      if (!['prod', 'staging'].includes(ENV)) {
        url = url + '&apiMode=sandbox';
      }
    }

    setURL(url);
  }, [organizationId, provider_type, iframe_key]);

  return (
    <>
      {organizationId && iframe_key && iframeURL ? (
        <iframe
          style={{ width: '100%', border: 'none' }}
          title='utilityAccountLoginInfo'
          src={iframeURL}
          width='780x'
          height='625px'
        />
      ) : (
        <></>
      )}
    </>
  );
}
