import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, withRouter, useLocation, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import veregyStar from '../img/veregy-star.svg';

import LoginPageDecoration from '../components/LoginPageDecoration';
import ReCaptcha, { withReCaptcha } from '../components/ReCaptcha';
import { ApiServicePublicServerless } from '../xhr_libs';
import { setAuthToken } from '../auth';
import { getTokens, setTokens, removeTokens } from '../auth/orchestrate';

import Helmet from 'react-helmet';
import SmallerLoader from '../components/SmallerLoader';
const pageTitle = 'Sign In';

const styles = {
  formContentStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  visible: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.5s linear',
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s 0.5s, opacity 0.5s linear',
  },
};

const fontSize1 = '14px';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const CLIENT_ID = process.env.REACT_APP_USER_POOL_WEBCLIENT_ID;

function Login(props) {
  const { showToast } = props;

  const history = useHistory();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const paramRefreshToken = searchParams.get('refreshToken');

  const [isLoading, setIsLoading] = useState(false);

  const { register, errors, handleSubmit } = useForm();
  const recaptchaRef = useRef();

  const [OAuthRefreshToken, setOAuthRefreshToken] = useState(null);
  const [OAuthEmail, setOAuthEmail] = useState(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const refreshOAuthToken = useCallback(() => {
    const tokens = getTokens();
    setOAuthRefreshToken(tokens.refreshToken);
    if (tokens.idToken) {
      const email = jwtDecode(tokens.idToken).email;
      setOAuthEmail(email);
    } else {
      setOAuthEmail(null);
    }
  }, []);

  /* set token on mount */
  useEffect(() => {
    refreshOAuthToken();
  }, [refreshOAuthToken]);

  /* listen for token update */
  window.addEventListener('energytracer_oauth', refreshOAuthToken);

  const onSubmit = (data) => {
    setIsLoading(true);
    withReCaptcha(() => {
      ApiServicePublicServerless.post('/auth', {
        username: data.username,
        password: data.password,
      })
        .then((response) => {
          setAuthToken(response);
          return response;
        })
        .catch((error) => {
          setIsLoading(false);
          showToast('danger', 'Error', error);
          throw error;
        });
    }, recaptchaRef);
  };

  const OAuthSubmit = useCallback(
    (refreshToken) => {
      setIsLoading(true);
      withReCaptcha(() => {
        ApiServicePublicServerless.post('/auth/oauth', {
          refreshToken: refreshToken,
        })
          .then((response) => {
            setTokens(response.data.oauth_tokens);
            setAuthToken(response);
            return response;
          })
          .catch((error) => {
            removeTokens(); // if fails refresh token is probably bad so just log out
            setIsLoading(false);
            showToast(
              'danger',
              'Error',
              'Current session expired. Please login again.'
            );
            throw error;
          });
      }, recaptchaRef);
    },
    [showToast]
  );

  /* look for query params from oauth redirect */
  useEffect(() => {
    if (paramRefreshToken) {
      setTokens({ RefreshToken: paramRefreshToken });
      OAuthSubmit(paramRefreshToken);
    }
  }, [paramRefreshToken, OAuthSubmit]);

  return (
    <>
      <ReCaptcha ref={recaptchaRef} />
      <LoginPageDecoration />
      <Container
        style={{ marginTop: '2em', width: '450px' }}
        className='et-unauth-container'
      >
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Card style={{ border: 'none' }}>
          <Card.Body className={'login-cardbody'}>
            {!OAuthRefreshToken && !paramRefreshToken && (
              <>
                <h4 style={{ marginBottom: '0.25em !important' }}>
                  {pageTitle}
                </h4>
                <div
                  style={{
                    marginBottom: '0.5em',
                    fontSize: fontSize1,
                    paddingLeft: '0.25em',
                  }}
                >
                  Stay informed about your energy consumption
                </div>

                <Form
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ textAlign: 'left' }}
                >
                  <Form.Group style={{ marginBottom: 0 }}>
                    <Form.Label
                      style={{ fontSize: '0.9rem', marginBottom: '0' }}
                    >
                      <div
                        style={email !== '' ? styles.visible : styles.hidden}
                      >
                        Email
                      </div>
                    </Form.Label>
                    <Form.Control
                      type='email'
                      name='username'
                      placeholder='Email'
                      isInvalid={errors.username}
                      ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group style={{ marginBottom: '0.5em' }}>
                    <Form.Label
                      style={{ fontSize: '0.9rem', marginBottom: '0' }}
                    >
                      <div
                        style={password !== '' ? styles.visible : styles.hidden}
                      >
                        Password
                      </div>
                    </Form.Label>
                    <Form.Control
                      type='password'
                      name='password'
                      placeholder='Password'
                      isInvalid={errors.password}
                      ref={register({ required: true, minLength: 8 })}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <div style={styles.formContentStyle}>
                    <Button
                      style={{
                        width: '100%',
                        marginTop: '5px',
                        marginBottom: '15px',
                      }}
                      disabled={isLoading}
                      type='submit'
                    >
                      {!isLoading && 'Sign in'}
                      {isLoading && <SmallerLoader text={'Signing in'} />}
                    </Button>
                  </div>
                </Form>
                <div style={styles.formContentStyle}>
                  <Link to='/forgotPassword' style={{ fontSize: fontSize1 }}>
                    Forgot password?
                  </Link>
                </div>

                <div
                  className='et-separator'
                  style={{
                    fontSize: fontSize1,
                    margin: '1em 0em',
                    color: 'var(--et_border-grey)',
                    opacity: '0.75',
                  }}
                >
                  Or
                </div>
                <div style={styles.formContentStyle}>
                  <Button
                    style={{
                      width: '100%',
                      marginTop: '5px',
                      fontSize: fontSize1,
                      backgroundColor: 'white',
                      borderColor: 'var(--et_blue)',
                    }}
                    variant='light'
                    disabled={isLoading}
                    onClick={() => {
                      window.location.href = `${AUTH_URL}login?client_id=${CLIENT_ID}`;
                    }}
                  >
                    <img
                      src={veregyStar}
                      alt='blockLogo'
                      style={{
                        height: '20px',
                      }}
                    />{' '}
                    Sign in with Orchestrate Hub
                  </Button>
                </div>
              </>
            )}
            {(OAuthRefreshToken || paramRefreshToken) && (
              <>
                {!isLoading && (
                  <>
                    <h4 style={{ marginBottom: '0.25em !important' }}>
                      Are you still there?
                    </h4>
                    <div
                      style={{
                        marginBottom: '0.5em',
                        fontSize: fontSize1,
                        paddingLeft: '0.25em',
                      }}
                    >
                      You've been logged out due to inactivity
                    </div>
                  </>
                )}
                <Button
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    marginBottom: '15px',
                  }}
                  disabled={isLoading}
                  onClick={() => OAuthSubmit(OAuthRefreshToken)}
                >
                  {!isLoading && "I'm still here"}
                  {isLoading && <SmallerLoader text={'Loading profile'} />}
                </Button>
                {!isLoading && (
                  <div style={{ fontSize: fontSize1 }}>
                    Last logged in as {OAuthEmail}{' '}
                    <div
                      style={{ display: 'inline-block' }}
                      className='et-link'
                      onClick={() => {
                        removeTokens();
                        history.push('login');
                      }}
                    >
                      {' '}
                      Leave
                    </div>
                  </div>
                )}
              </>
            )}
          </Card.Body>
        </Card>
        {!OAuthRefreshToken && !paramRefreshToken && (
          <div style={styles.formContentStyle}>
            <div style={{ marginTop: '0.5em', fontSize: fontSize1 }}>
              New to EnergyTracer?{' '}
              <div
                className='et-link'
                style={{ display: 'inline-block' }}
                onClick={() => history.push('register')}
              >
                Sign Up
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
}

export default withRouter(Login);
