import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import EditEntityActionButtons from '../forms/EditEntityActionButtons';
import { ApiServiceServerless } from '../../xhr_libs';

export default function AddProjectMeasureModal(props) {
  const {
    show,
    onHide,
    project_type,
    project_id,
    organization_id,
    measures,
    updateList,
    showToast,
  } = props;

  const [measureId, setMeasureId] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const clearForm = () => {
    setMeasureId('');
  };

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();
    ApiServiceServerless.post(
      `/${
        project_type === 'ghg' ? 'ghg' : 'performance'
      }_projects/project_measure/${parseInt(project_id)}/${measureId}`,
      {},
      { authorization_id: organization_id }
    )
      .then((res) => {
        updateList(
          res.data,
          (project_type === 'ghg' ? 'ghg' : 'performance') + '_measures'
        );
        onHide();
        clearForm();
        setIsSaving(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        onHide();
        setIsSaving(false);
        throw err;
      });
  };

  const handleCancel = () => {
    onHide();
    clearForm();
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          {'Add Project Saving'}
        </Modal.Header>
        <Form
          noValidate
          onSubmit={handleSubmit}
          style={{
            textAlign: 'left',
          }}
        >
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>Project Saving </Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) => setMeasureId(parseInt(e.target.value))}
                  >
                    <option key={'null-val'} value={''} hidden></option>
                    {measures.filter(
                      (measure) => !measure.project_ids.includes(project_id)
                    ).length === 0 && (
                      <option key={'null-null'} value={''} disabled>
                        No Existing Measures
                      </option>
                    )}
                    {measures
                      .filter(
                        (measure) => !measure.project_ids.includes(project_id)
                      )
                      .map((measure) => (
                        <option key={measure.id} value={measure.id}>
                          {project_type === 'ghg'
                            ? measure.name
                            : measure.measure}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <EditEntityActionButtons
              onCancel={handleCancel}
              submitDisabled={measureId === ''}
              submitShow={true}
              isSubmitting={isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
