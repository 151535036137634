import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import CsvUpload from './Csv/CsvUpload';
import CsvMultiUpload from './CsvMulti/CsvMultiUpload';
import CsvMonthlyUpload from './CsvMonthly/CsvMonthlyUpload';

export default function CsvUploadContainer(props) {
  const {
    isLoadingAccount,
    account,
    showToast,
    handleRefreshBills,
  } = props;

  const [uploadType, setUploadType] = useState('single');

  return (
    <div
      style={{
        textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        padding: '0.5em 0.5em 0em 0.5em',
      }}
    >
      <h5 style={{ marginBottom: '1em' }}>Bulk Import</h5>
      {!isLoadingAccount && (
        <>
          <Form.Group controlId='newOrg.type'>
            <Form.Control
              as='select'
              onChange={(e) => setUploadType(e.target.value)}
              value={uploadType}
            >
              <option key={'single'} value={'single'}>
                Single Account
              </option>
              <option key={'multi'} value={'multi'}>
                Multiple Accounts
              </option>
              <option key={'monthly'} value={'monthly'}>
                Monthly Format
              </option>
            </Form.Control>
          </Form.Group>

          {uploadType === 'single' && (
            <CsvUpload
              account={account}
              showToast={showToast}
              handleRefreshBills={handleRefreshBills}
            />
          )}
          {uploadType === 'multi' && (
            <CsvMultiUpload
              account={account}
              showToast={showToast}
              handleRefreshBills={handleRefreshBills}
            />
          )}
          {uploadType === 'monthly' && (
            <CsvMonthlyUpload
              account={account}
              showToast={showToast}
              handleRefreshBills={handleRefreshBills}
            />
          )}
        </>
      )}
    </div>
  );
}
