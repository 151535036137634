import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import SmallerLoader from '../SmallerLoader';
export default function EditEntityActionButtons(props) {
  return (
    <div style={{ width: '100%' }}>
      {props.onDelete && props.deleteShow && (
        <Button
          data-id='btn_delete'
          type='button'
          variant='danger'
          className='float-left'
          onClick={props.onDelete}
          disabled={
            props.deleteDisabled || props.isSubmitting || props.isDeleting
          }
        >
          {!props.isDeleting && <>{props.deleteText}</>}
          {props.isDeleting && <SmallerLoader text={props.deletingText} />}
        </Button>
      )}
      {props.submitShow && (
        <>
          <Button
            data-id='btn_save'
            type='submit'
            variant='primary'
            className='float-right ml-3'
            onSubmit={props.onSubmit}
            disabled={
              props.submitDisabled || props.isSubmitting || props.isDeleting
            }
          >
            {!props.isSubmitting && <>{props.submitText}</>}
            {props.isSubmitting && (
              <SmallerLoader text={props.submittingText} />
            )}
          </Button>
          {!props.hideCancel && (
            <Button
              data-id='btn_cancel'
              type='button'
              variant='outline-danger'
              className='float-right'
              onClick={props.onCancel}
              disabled={props.cancelDisabled || props.isDeleting}
            >
              Cancel
            </Button>
          )}
        </>
      )}
    </div>
  );
}

EditEntityActionButtons.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  submitShow: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  deleteDisabled: PropTypes.bool,
  deleteShow: PropTypes.bool,
  deleteText: PropTypes.string,
  deletingText: PropTypes.string,
  hideCancel: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  submitText: PropTypes.string,
  submittingText: PropTypes.string,
};

EditEntityActionButtons.defaultProps = {
  deleteShow: true,
  submitShow: true,
  hideCancel: false,
  deleteText: 'Delete',
  deletingText: 'Deleting....',
  submitText: 'Save',
  submittingText: 'Saving...',
  isSubmitting: false,
  isDeleting: false,
};
