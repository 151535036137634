import React, { useEffect, useState } from 'react';
import { Row, Form, Col } from 'react-bootstrap';

import {
  DateOptions,
  getReportTypes,
  ReportTypesLookup,
  UtilityTypesLookup,
} from '../ReportHelpers';
import ReportMonthRange from '../../../components/reports/ReportMonthRange';
import ReportCheckBoxPicker from '../../../components/reports/ReportCheckboxPicker';
import ReportUnits from '../ReportUnits';
import GHGReportUnitPicker from '../../../components/reports/GHGReportUnitPicker';
import ReportDropdownObject from '../../../components/reports/ReportDropdownObject';

const styles = {
  form: {
    textAlignLast: 'center',
    display: 'block',
  },
  label: {
    display: 'flex',
  },
};

export default function TranscriptReport(props) {
  const { organizationData, updatePayload, activeGHGLicense } = props;

  const [localBaselineYear, setLocalBaselineYear] = useState();
  const [localBaseline2Year, setLocalBaseline2Year] = useState();

  const [localReportTypes, setLocalReportTypes] = useState([]);

  useEffect(() => {
    const temp_baseline_year = organizationData['trend_years'][0];
    setLocalBaselineYear(temp_baseline_year);
    setLocalBaseline2Year('none');
    updatePayload('baseline_year', temp_baseline_year);
    updatePayload('baseline2_year', 'none');
  }, [organizationData, updatePayload]);

  return (
    <>
      <Row>
        <ReportMonthRange
          major_label={'Date Range'}
          label='Start Month'
          default_date={organizationData['default_start_date']}
          min_date={organizationData['min_date']}
          max_date={organizationData['max_date']}
          payload_key={'start'}
          updatePayload={updatePayload}
          showPicker={true}
        />
      </Row>
      <Row>
        <Col md={6}>
          <Form style={styles.form}>
            <Form.Group controlId={'trendBaslineYear'}>
              <Form.Label style={styles.label}>Baseline Year</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => {
                  updatePayload('baseline_year', e.target.value);
                  setLocalBaselineYear(e.target.value);
                }}
                value={localBaselineYear}
              >
                {organizationData['trend_years'].map((year) => (
                  <option key={`report-opt-${year}`} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
        <Col md={6}>
          <Form style={styles.form}>
            <Form.Group controlId={'trendBasline2Year'}>
              <Form.Label style={styles.label}>Second Baseline Year</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => {
                  updatePayload('baseline2_year', e.target.value);
                  setLocalBaseline2Year(e.target.value);
                }}
                value={localBaseline2Year}
              >
                <option key={`report-opt-none`} value={'none'}>
                  {'None'}
                </option>
                {organizationData['trend_years'].map((year) => (
                  <option key={`report-opt-${year}`} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReportCheckBoxPicker
            label={'Utility Types'}
            checkboxOptions={organizationData['utility_types']}
            checkboxLookups={UtilityTypesLookup}
            defaults={organizationData['utility_types']}
            requireds={[]}
            payload_key={'utility_types'}
            updatePayload={updatePayload}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ReportCheckBoxPicker
            label={'Report Types'}
            checkboxOptions={getReportTypes(activeGHGLicense)}
            checkboxLookups={ReportTypesLookup}
            defaults={getReportTypes(activeGHGLicense)}
            requireds={[]}
            payload_key={'report_types'}
            updatePayload={updatePayload}
            updateLocal={setLocalReportTypes}
          />
        </Col>
      </Row>
      <ReportDropdownObject
        updatePayload={updatePayload}
        payload_key={'date_option'}
        values={DateOptions}
        defaultValue={1}
        label={'Date Option'}
        key_option={'index'}
      />
      <ReportUnits
        organizationData={organizationData}
        updatePayload={updatePayload}
      />
      {localReportTypes.includes('ghg') && (
        <GHGReportUnitPicker
          payload_key={'ghg_unit'}
          updatePayload={updatePayload}
        />
      )}
    </>
  );
}
