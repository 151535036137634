import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import GHGProjectSavingsProjects from './GHGProjectSavingsProjects';
import OperationalSavings from './OperationalSavings';

export default function SavingMeasures(props) {
  const [deferredProps, setDeferredProps] = useState({ ...props });
  useEffect(() => {
    if (props.open) setDeferredProps({ ...props });
  }, [props]);
  const {
    organizationId,
    projects,
    performanceMeasures,
    GHGMeasures,
    addToList,
    updateList,
    removeFromList,
    showToast,
    createAccessAnalysis,
    updateAccessAnalysis,
    deleteAccessAnalysis,
    createAccessGHG,
    updateAccessGHG,
    deleteAccessGHG,
  } = deferredProps;

  const [activeTab, setActiveTab] = useState('operational_savings');

  return (
    <Tabs
      id='measures-analysis-tabs'
      activeKey={activeTab}
      onSelect={(e) => setActiveTab(e)}
      className='et-second-tabs'
    >
      <Tab eventKey='operational_savings' title={'Operational Savings'}>
        <OperationalSavings
          organizationId={organizationId}
          projects={projects}
          performanceMeasures={performanceMeasures}
          addToList={addToList}
          updateList={updateList}
          removeFromList={removeFromList}
          showToast={showToast}
          createAccess={createAccessAnalysis}
          updateAccess={updateAccessAnalysis}
          deleteAccess={deleteAccessAnalysis}
        />
      </Tab>
      <Tab eventKey='ghg-savings' title={'GHG Savings Projects'}>
        <GHGProjectSavingsProjects
          organizationId={organizationId}
          projects={projects}
          GHGMeasures={GHGMeasures}
          addToList={addToList}
          updateList={updateList}
          removeFromList={removeFromList}
          showToast={showToast}
          createAccess={createAccessGHG}
          updateAccess={updateAccessGHG}
          deleteAccess={deleteAccessGHG}
        />
      </Tab>
    </Tabs>
  );
}
