import { get } from 'lodash';

const OBJECT_NAME = 'energytracer_user_preferences';

const getUserPreferencesObject = () => {
  if (!localStorage.getItem(OBJECT_NAME))
    localStorage.setItem(OBJECT_NAME, JSON.stringify({}));
  return JSON.parse(localStorage.getItem(OBJECT_NAME));
};

const setUserPreferencesObject = (prefObj) =>
  localStorage.setItem(OBJECT_NAME, JSON.stringify(prefObj));

export const getUserPreference = (key) => {
  const prefsObject = getUserPreferencesObject();
  switch (key) {
    case 'aggregate_meters':
      return parseInt(get(prefsObject, key, 0));
    default:
      throw Error(`Invalid user preference key: ${key}`);
  }
};

export const setUserPreference = (key, value) => {
  const prefsObject = getUserPreferencesObject();
  switch (key) {
    case 'aggregate_meters':
      setUserPreferencesObject({ ...prefsObject, [key]: parseInt(value) });
      break;
    default:
      throw Error(`Invalid user preference key: ${key}`);
  }
};

const getOrgKey = (key, org_id) => {
  return key + '_o_' + org_id;
};

export const getUserOrganizationPreference = (key, org_id) => {
  const prefsObject = getUserPreferencesObject();
  return get(prefsObject, getOrgKey(key, org_id), '');
};

export const setUserOrganizationPreference = (
  key,
  org_id,
  value,
  clear_on_empty = true
) => {
  let prefsObject = getUserPreferencesObject();
  if (
    clear_on_empty &&
    (value === '' || value === null || value === undefined)
  ) {
    let local_obj = { ...prefsObject };
    delete local_obj[getOrgKey(key, org_id)];
    setUserPreferencesObject({ ...local_obj });
  } else {
    setUserPreferencesObject({
      ...prefsObject,
      [getOrgKey(key, org_id)]: value,
    });
  }
};

/** KEYS
 * bnf = building name filter
 * anf = account number filter
 * atf = account type filter
 * lsa = last selected account
 * dbs = dashboard building select
 *
 */
