import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import CustomRateCollections from './CustomRateCollections';

import EditCustomRates from './EditCustomRates';

export default function CustomRates(props) {
  const [deferredProps, setDeferredProps] = useState({ ...props });
  useEffect(() => {
    if (props.open) setDeferredProps({ ...props });
  }, [props]);
  const {
    organizationId,
    rates,
    rateCollections,
    addToList,
    updateList,
    removeFromList,
    showToast,
    createAccess,
    updateAccess,
    deleteAccess,
  } = deferredProps;
  const [activeTab, setActiveTab] = useState('custom-rate-collections');

  return (
    <Tabs
      id='custom-rate-tabs'
      activeKey={activeTab}
      onSelect={(e) => setActiveTab(e)}
      className='et-second-tabs'
    >
      <Tab eventKey='custom-rate-collections' title='Collections'>
        <CustomRateCollections
          showToast={showToast}
          organizationId={organizationId}
          createAccess={createAccess}
          updateAccess={updateAccess}
          deleteAccess={deleteAccess}
          rates={rates}
          rateCollections={rateCollections}
          addToList={addToList}
          updateList={updateList}
          removeFromList={removeFromList}
        />
      </Tab>
      <Tab eventKey='edit-custom-rates' title='Rates'>
        <EditCustomRates
          organizationId={organizationId}
          rates={rates}
          rateCollections={rateCollections}
          addToList={addToList}
          updateList={updateList}
          removeFromList={removeFromList}
          showToast={showToast}
          createAccess={createAccess}
          updateAccess={updateAccess}
          deleteAccess={deleteAccess}
        />
      </Tab>
    </Tabs>
  );
}
