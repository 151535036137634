import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import Loader from './Loader';

import Header from '../components/Header';
import Footer from '../components/Footer';

import { validToken } from '../auth';
import { AppConsumer } from '../contexts/app-context';
import HomeBar from '../pages/Home/HomeBar';

const route_background_color_lookup = {
  0: '#E0E0E0',
  1: '#FFFFFF',
  2: '#F5F5F5',
};

function AppRoute(props) {
  const {
    authenticated,
    noTokenRoute,
    logout,
    organizationSelectRoute,
    routeBackgroundColor,
    component: Component,
    path,
    ...rest
  } = props;

  const location = useLocation();

  //logic to store page navigation from links
  const [redirectPath, setRedirectPath] = useState('');
  useEffect(() => {
    if (path && !noTokenRoute && path !== '/logout') {
      //use location instead of path because path does not maintain ids or hashes
      setRedirectPath(location.pathname + location.hash);
    }
  }, [path, noTokenRoute, location]);

  useEffect(() => {
    const route_background_color =
      route_background_color_lookup[routeBackgroundColor];
    document.body.style.backgroundColor = route_background_color;
  }, [routeBackgroundColor]);

  const checkUserData = (appState, redirectPath) => {
    if (
      appState.userFirstName === null ||
      appState.userLastName === null ||
      appState.userFirstName === '' ||
      appState.userLastName === ''
    ) {
      return '/profile#user-settings';
    }
    return redirectPath ? redirectPath : '/dashboard';
  };

  return (
    <>
      <AppConsumer>
        {({ ...appState }) => {
          const passthruRoute = (
            <React.Fragment>
              {validToken() ? (
                <Header
                  {...appState}
                  organizationSelectRoute={organizationSelectRoute}
                />
              ) : (
                <></>
              )}

              <Route
                {...rest}
                render={(routeProps) => (
                  <>
                    {!validToken() ? <HomeBar {...routeProps} /> : <></>}
                    <Component {...routeProps} {...appState} />
                  </>
                )}
              />
              {validToken() && <Footer />}
            </React.Fragment>
          );
          //do not change order. TODO document why order is need
          //TLDR logout take prio, then login, then unauth, then expired, then make sure state isnt loading, finally pass authed routes
          if (logout) return passthruRoute;
          if (validToken() && noTokenRoute)
            return <Redirect to={checkUserData(appState, redirectPath)} />;
          if (!authenticated) return passthruRoute;
          if (!validToken()) return <Redirect to='/logout' />;
          if (!appState.userId === null) {
            return <Loader />;
          }
          return passthruRoute;
        }}
      </AppConsumer>
    </>
  );
}

AppRoute.propTypes = {
  authenticated: PropTypes.bool,
  noTokenRoute: PropTypes.bool,
  logout: PropTypes.bool,
  organizationSelectRoute: PropTypes.bool,
};

AppRoute.defaultProps = {
  authenticated: false,
  noTokenRoute: false,
  logout: false,
  organizationSelectRoute: false,
  routeBackgroundColor: 0,
};

export default AppRoute;
