import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

export default function CloneCustomRateCollectionModal(props) {
  const {
    show,
    onHide,
    showToast,
    organization_id,
    rateCollections,
    setActiveCollectionId,
    addToList,
    updateList,
  } = props;

  const [collectionName, setCollectionName] = useState('');
  const [cloneCollectionId, setCloneCollectionId] = useState('');

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (collectionName && cloneCollectionId) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [collectionName, cloneCollectionId]);

  const cloneCollection = (e) => {
    e.preventDefault();
    setIsLoading(true);
    ApiServiceServerless.post(
      `/custom_rates/collection/clone/${cloneCollectionId}`,
      {
        name: collectionName,
        org_id: organization_id,
      },
      { authorization_id: organization_id }
    )
      .then((res) => {
        addToList(res.data[0], 'rate_collections');
        setActiveCollectionId(res.data[0].id);
        res.data[1].forEach((data) => {
          updateList(data, 'rates');
        });
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Clone Collection</Modal.Header>
      <Form onSubmit={cloneCollection}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {'Collection Name'}
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Row>
              <Form.Control
                required
                placeholder=''
                onChange={(e) => setCollectionName(e.target.value)}
              />
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Collection To Clone
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>

            <Form.Control
              as='select'
              required
              onChange={(e) => {
                setCloneCollectionId(parseInt(e.target.value));
              }}
            >
              <option key={'opt-null'} value={''} hidden>
                -
              </option>
              {rateCollections.length === 0 && (
                <option key={'null-null'} value={''} disabled>
                  No existing collections
                </option>
              )}
              {rateCollections.map((collection) => (
                <option key={'opt-' + collection.id} value={collection.id}>
                  {collection.name + ' (' + collection.utility_type + ')'}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={onHide}
            submitDisabled={disabled}
            isSubmitting={isLoading}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
