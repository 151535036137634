import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import AddCustomRateCollectionModal from '../../../modals/AddCustomRateCollectionModal';
import CloneCustomRateCollectionModal from '../../../modals/CloneCustomRateCollectionModal';
import CustomRateCollection from './CustomRateCollection';

export default function CustomRateCollections(props) {
  const {
    organizationId,
    showToast,
    createAccess,
    updateAccess,
    deleteAccess,
    rates,
    rateCollections,
    addToList,
    updateList,
    removeFromList,
  } = props;

  const [showAddModal, setShowAddModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);

  const [activeCollectionId, setActiveCollectionId] = useState(null);
  const [activeCollection, setActiveCollection] = useState(null);

  const resetCollection = () => {
    setActiveCollectionId(null);
  };

  useEffect(() => {
    if (activeCollectionId !== null) {
      setActiveCollection(
        rateCollections.find(
          (collection) => collection.id === activeCollectionId
        )
      );
    } else {
      setActiveCollection(null);
    }
  }, [rateCollections, activeCollectionId]);

  return (
    <>
      <Row style={{ margin: 0 }}>
        <Col style={{ display: 'inline-flex' }}>
          <Row>
            <Form inline style={{ marginBottom: '0.5em' }}>
              <Form.Label
                style={{
                  display: 'block',
                  marginRight: '0.5em',
                  marginLeft: '0.5em',
                }}
              >
                Rate Collection:
              </Form.Label>
              <Form.Control
                style={{ float: 'right' }}
                as='select'
                size='sm'
                value={activeCollectionId ? activeCollectionId : ''}
                onChange={(e) => {
                  let cid = e.target.value;
                  setActiveCollectionId(parseInt(cid));
                }}
              >
                <option hidden value={'null'} key={'null-opt'}>
                  Select a Collection
                </option>
                {rateCollections.length === 0 && (
                  <option disabled value={'null'} key={'null-null'}>
                    No Collections
                  </option>
                )}
                {rateCollections.map((collection) => (
                  <option
                    key={`collection-opt-${collection.id}`}
                    value={collection.id}
                  >
                    {collection.name + ' (' + collection.utility_type + ')'}
                  </option>
                ))}
              </Form.Control>
            </Form>
          </Row>
          <Row style={{ marginLeft: '20px' }}>
            {createAccess && (
              <Button
                onClick={() => {
                  setShowAddModal(true);
                }}
                style={{ marginBottom: '1em', float: 'right' }}
              >
                Add Rate Collection
              </Button>
            )}
            {createAccess && (
              <Button
                onClick={() => {
                  setShowCloneModal(true);
                }}
                style={{
                  marginBottom: '1em',
                  marginLeft: '1em',
                  float: 'right',
                }}
              >
                Clone Rate Collection
              </Button>
            )}
          </Row>
        </Col>
      </Row>

      {activeCollectionId !== null && activeCollection && (
        <CustomRateCollection
          showToast={showToast}
          organizationId={organizationId}
          updateAccess={updateAccess}
          deleteAccess={deleteAccess}
          rates={rates}
          collection={activeCollection}
          addToList={addToList}
          updateList={updateList}
          removeFromList={removeFromList}
          resetCollection={resetCollection}
        />
      )}
      <AddCustomRateCollectionModal
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
        }}
        showToast={showToast}
        organization_id={organizationId}
        addToList={addToList}
        setActiveCollectionId={setActiveCollectionId}
      />
      <CloneCustomRateCollectionModal
        show={showCloneModal}
        onHide={() => {
          setShowCloneModal(false);
        }}
        showToast={showToast}
        organization_id={organizationId}
        rateCollections={rateCollections}
        setActiveCollectionId={setActiveCollectionId}
        addToList={addToList}
        updateList={updateList}
      />
    </>
  );
}
