import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function VersionRefreshModal(props) {
  const { show, onHide } = props;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Version Update</Modal.Header>
      <Modal.Body>
        <p>
          A new version of EnergyTracer has been released. Please refresh your
          browser to apply the changes
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
