import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

import CreateNewAccountOpts from './CreateNewAccountOpts';
import ConfirmationModal, { useConfirm } from '../ConfirmationModal';
import { fmtBoolYesNo } from '../../tables/formatters';

const TRUEFALSE = [true, false];

const DISABLE_PULL_HISTORY_MSG =
  'It is strongly recommended that historical bills are retrieved for accounts upon enrollment as it is not easy to do afterwards. Are you sure you want to proceed?';

function NotEnrolledForm(props) {
  const {
    availableLocalUtilityAccounts,
    buildings,
    errors,
    register,
    setValue,
    activeLicense,
  } = props;

  const [showNewAccountOpts, setShowNewAccountOpts] = useState(false);
  const [confirmationModalProps, withConfirm] = useConfirm();

  const confirmDisableHistory = (value) => {
    if (!value)
      withConfirm(DISABLE_PULL_HISTORY_MSG, null, () =>
        setValue('shouldPullHistory', value)
      );
  };

  return (
    <>
      <ConfirmationModal
        {...confirmationModalProps}
        confirmText='Confirm'
        submitVariant={'success'}
      />
      <Form.Group controlId='urjanet.providerForm.shouldPullHistorySelect'>
        <Form.Label>Pull Bill History for This Account?</Form.Label>
        <Form.Control
          as='select'
          name='shouldPullHistory'
          defaultValue={activeLicense}
          disabled={!activeLicense}
          ref={register}
          onChange={(e) => confirmDisableHistory(e.target.value === 'true')}
        >
          {TRUEFALSE.map((opt) => (
            <option key={`enroll-opt-${opt}`} value={opt}>
              {fmtBoolYesNo(opt)}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId='urjanet.providerForm.enrollAutoBilling'>
        <Form.Label>Enable Automatic Bill Imports</Form.Label>
        <Form.Control
          as='select'
          name='enrollAutoBilling'
          defaultValue={activeLicense}
          disabled={!activeLicense}
          ref={register}
        >
          {TRUEFALSE.map((opt) => (
            <option key={`enroll-opt-${opt}`} value={opt}>
              {fmtBoolYesNo(opt)}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId='urjanet.providerForm.linkedAccountSelect'>
        <Form.Label>EnergyTracer Account to Apply Bills To</Form.Label>
        <Form.Control
          as='select'
          name='linkAccount'
          isInvalid={errors.linkAccount}
          disabled={!activeLicense}
          ref={register({ required: true })}
          onChange={(e) => setShowNewAccountOpts(e.target.value === 'new')}
        >
          <option key={'link-opt-null'} value={''} hidden>
            -
          </option>
          {availableLocalUtilityAccounts.map((acct) => (
            <option key={`link-opt-${acct.id}`} value={acct.id}>
              {acct.account_number}
            </option>
          ))}
          <option key={`link-opt-new`} value={'new'}>
            Create a New Account
          </option>
        </Form.Control>
      </Form.Group>
      {showNewAccountOpts && (
        <CreateNewAccountOpts
          buildings={buildings}
          errors={errors}
          register={register}
        />
      )}
    </>
  );
}

export default NotEnrolledForm;
