import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faPlusSquare } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import EnergyTracerTable from '../../../components/tables';
import ViewModelModal from '../../../components/modals/ViewModelModal';
import AddProjectRegressionModal from '../../../components/modals/AddProjectRegressionModal';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

export default function ProjectRegressions(props) {
  const {
    organization_id,
    project_id,
    buildingsLookup,
    selectedBuildings,
    utility_types,
    existingModels,
    updateList,
    showToast,
    updateAccessAnalysis,
    deleteAccessAnalysis,
  } = props;

  const [tableData, setTableData] = useState([]);

  const [showAdd, setShowAdd] = useState(false);
  const [addBuildingId, setAddBuildingId] = useState(null);
  const [addUtilityType, setAddUtilityType] = useState(null);

  const [showView, setShowView] = useState(false);
  const [viewRow, setViewRow] = useState(null);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const handleSetAdd = (building, utility) => {
    setAddBuildingId(building);
    setAddUtilityType(utility);
    setShowAdd(true);
  };

  const handleHideAdd = () => {
    setAddBuildingId(null);
    setAddUtilityType(null);
    setShowAdd(false);
  };

  const handleSetView = (row) => {
    setViewRow(row);
    setShowView(true);
  };

  const handleHideView = () => {
    setViewRow(null);
    setShowView(false);
  };

  useEffect(() => {
    let temp_data = [];
    if (Object.keys(buildingsLookup).length > 0) {
      selectedBuildings.forEach((building) => {
        let temp_obj = {};
        temp_obj['building'] = building;
        temp_obj['building_name'] = buildingsLookup[building]['name'];
        utility_types.forEach((utility) => {
          temp_obj[utility] = null;
          let model = existingModels.filter(
            (model) =>
              model.project_ids.includes(project_id) &&
              model.building_id === parseInt(building) &&
              model.utility_type === utility &&
              model.regression_type === 'consumption'
          );
          if (model.length !== 0) {
            temp_obj[utility] = {
              ...model[0],
              building_name: buildingsLookup[building]['name'],
            };
          }
        });
        temp_data.push(temp_obj);
      });
      setTableData(sortBy(temp_data, ['building_name']));
    } else {
      setTableData([]);
    }
  }, [
    project_id,
    buildingsLookup,
    selectedBuildings,
    utility_types,
    existingModels,
  ]);

  const handleDelete = (row, project_id, organization_id) => {
    const msg = `Are you sure you want to delete this Project Regression? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteProjectRegression(row, project_id, organization_id);
    });
  };

  const deleteProjectRegression = (row, project_id, organization_id) => {
    ApiServiceServerless.delete(
      `/performance_projects/project_regression/${parseInt(project_id)}/${
        row.id
      }`,
      { authorization_id: organization_id }
    )
      .then((res) => {
        updateList(res.data, 'models');
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  const buildingColumn = [
    { dataField: 'building_name', text: 'Building', style: styles.column },
  ];

  let tableColumns = [].concat(
    buildingColumn,
    utility_types.includes('electric')
      ? utilityColumn(
          'electric',
          project_id,
          organization_id,
          handleDelete,
          handleSetAdd,
          handleSetView,
          updateAccessAnalysis,
          deleteAccessAnalysis
        )
      : [],
    utility_types.includes('gas')
      ? utilityColumn(
          'gas',
          project_id,
          organization_id,
          handleDelete,
          handleSetAdd,
          handleSetView,
          updateAccessAnalysis,
          deleteAccessAnalysis
        )
      : []
  );

  return (
    <>
      {tableData.length > 0 && (
        <EnergyTracerTable
          data={tableData}
          columns={tableColumns}
          keyField={'building'}
        />
      )}
      <ConfirmationModal {...confirmationModalProps} />
      <ViewModelModal
        show={showView}
        onHide={() => handleHideView()}
        row={viewRow}
      />
      <AddProjectRegressionModal
        show={showAdd}
        onHide={() => handleHideAdd()}
        building_id={addBuildingId}
        utility={addUtilityType}
        project_id={project_id}
        organization_id={organization_id}
        existingModels={existingModels}
        updateList={updateList}
        showToast={showToast}
      />
    </>
  );
}

function utilityColumn(
  utility,
  project_id,
  organization_id,
  handleDelete,
  handleSetAdd,
  handleSetView,
  updateAccess,
  deleteAccess
) {
  return [
    {
      dataField: utility,
      text: utility.charAt(0).toUpperCase() + utility.substr(1).toLowerCase(),
      style: styles.actionColumn,
      formatter: (cell, row) => (
        <>
          {row[utility] && row[utility] !== null ? (
            <ExistingModelDisplay
              row={row[utility]}
              project_id={project_id}
              organization_id={organization_id}
              handleDelete={handleDelete}
              handleSetView={handleSetView}
              deleteAccess={deleteAccess}
            />
          ) : (
            <AddModalDisplay
              utility={utility}
              building={row.building}
              handleSetAdd={handleSetAdd}
              updateAccess={updateAccess}
            />
          )}
        </>
      ),
    },
  ];
}

function ExistingModelDisplay(props) {
  const {
    row,
    project_id,
    organization_id,
    handleDelete,
    handleSetView,
    deleteAccess,
  } = props;

  return (
    <>
      <div
        className='et-link'
        style={{ display: 'inline-block' }}
        onClick={() => {
          handleSetView(row);
        }}
      >
        {row.name}{' '}
      </div>
      {deleteAccess && (
        <Button
          variant='outline-danger'
          style={{
            padding: '0px',
            borderWidth: '0px',
            borderRadius: '50%',
            height: '28px',
            width: '28px',
            marginLeft: '20px',
          }}
        >
          <FontAwesomeIcon
            style={{ fontSize: '18px', lineHeight: 'inherit' }}
            icon={faTimesCircle}
            onClick={() => handleDelete(row, project_id, organization_id)}
          />
        </Button>
      )}
    </>
  );
}

function AddModalDisplay(props) {
  const { building, utility, handleSetAdd, updateAccess } = props;

  return (
    <>
      {updateAccess ? (
        <Button
          variant='link'
          style={{ width: '100%', margin: '0px', padding: '0px' }}
          onClick={() => handleSetAdd(building, utility)}
        >
          <div style={{ textAlign: 'left', margin: 'auto' }}>
            <FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: '23px' }} />
          </div>
        </Button>
      ) : (
        <>No Model</>
      )}
    </>
  );
}
