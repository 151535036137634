import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { isEqual } from 'lodash';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';

import EditEntityActionButtons from '../../../components/forms/EditEntityActionButtons';
import { ApiServiceServerless } from '../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import ProjectMonthRange from './ProjectMonthRange';
import BuildingSelectModal from '../../../components/modals/BuildingSelectModal';
import CHARTCOLORS from '../../../helpers/chartColors';
import ProjectMeasures from './ProjectMeasures';
import ProjectGHGConversions from './ProjectGHGConversions';

const DEFAULT_PROJECT = {
  id: '',
  org_id: '',
  name: '',
  end_date: '',
  cc_name: '',
  cc_email: '',
  cc_phone: '',
  vc_name: '',
  vc_email: '',
  vc_phone: '',
  ignore_building_ids: '',
  baseline_goal_year: '',
  baseline_goal_percent: '',
  savings: [],
  default_project: false,
};

export default function GHGProject(props) {
  const {
    showToast,
    organization,
    buildings,
    buildingIds,
    buildingsLookup,
    GHGCollections,
    GHGConversions,
    measures,
    addToList,
    updateList,
    project,
    resetProject,
    removeProject,
    setProjects,
    updateAccessGHG,
    deleteAccessGHG,
  } = props;

  const [activeTab, setActiveTab] = useState('project-info');

  const [localProject, setLocalProject] = useState(DEFAULT_PROJECT);
  const [editProject, setEditProject] = useState(DEFAULT_PROJECT);
  const [actionsDisabled, setActionsDisabled] = useState(true);

  const [showBuildingModal, setShowBuildingModal] = useState(false);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [nonSelectedBuildings, setNonSelectedBuildings] = useState([]);
  const [selectedString, setSelectedString] = useState('');
  const [localProjectBuildings, setLocalProjectBuildings] = useState([]);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (project) {
      setLocalProject({ ...project });
      setEditProject({ ...project });
    }
  }, [project]);

  useEffect(() => {
    setActionsDisabled(isEqual(localProject, editProject));
  }, [localProject, editProject]);

  const clearChanges = () => {
    setEditProject({ ...localProject });
  };

  const onFieldChange = useCallback((fieldName, value) => {
    setEditProject((ep) => {
      ep[fieldName] = value;
      return { ...ep };
    });
  }, []);

  useEffect(() => {
    if (
      buildings.length > 0 &&
      localProject &&
      localProject.ignore_building_ids
    ) {
      const temp_ids = buildings.map((building) => {
        return building.id;
      });
      let temp_selected = [];
      let temp_non_selected = [];
      temp_ids.forEach((id) => {
        if (localProject.ignore_building_ids.includes(id)) {
          temp_non_selected.push(id);
        } else {
          temp_selected.push(id);
        }
      });

      setSelectedBuildings(temp_selected);
      setNonSelectedBuildings(temp_non_selected);
      setLocalProjectBuildings(temp_selected);
    } else {
      setSelectedBuildings([]);
      setNonSelectedBuildings([]);
      setLocalProjectBuildings([]);
    }
  }, [buildings, localProject]);

  useEffect(() => {
    onFieldChange('ignore_building_ids', nonSelectedBuildings);
  }, [nonSelectedBuildings, onFieldChange]);

  useEffect(() => {
    let temp_string = '';
    for (let i = 0; i < selectedBuildings.length; i++) {
      temp_string =
        temp_string +
        buildings.find((building) => building.id === selectedBuildings[i]).name;
      if (i !== selectedBuildings.length - 1) {
        temp_string = temp_string + ', ';
      }
    }
    setSelectedString(temp_string);
  }, [selectedBuildings, buildings]);

  const handleRefreshData = (data, setActive) => {
    if (setActive) {
      setLocalProject(data);
      setEditProject(data);
    }
    setProjects((prev) =>
      prev.map((project) => {
        if (
          !(
            project['id'] === data['id'] &&
            project['project_type'] === data['project_type']
          )
        )
          return project;
        return data;
      })
    );
  };

  const handleDelete = () => {
    const msg = `Are you sure you want to delete this Project? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteProject();
    });
  };

  const deleteProject = () => {
    setIsDeleting(true);
    ApiServiceServerless.delete(`/ghg_projects/${parseInt(localProject.id)}`, {
      authorization_id: organization.id,
    })
      .then(() => {
        removeProject(localProject.id, localProject.project_type);
        resetProject();
        setIsDeleting(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        setIsDeleting(false);
        throw err;
      });
  };

  const saveProject = (e) => {
    e.preventDefault();
    setIsSaving(true);
    ApiServiceServerless.put(
      `/ghg_projects/${parseInt(localProject.id)}`,
      editProject,
      { authorization_id: organization.id }
    )
      .then((res) => {
        if (res.data.length > 1) {
          handleRefreshData(res.data[0], true);
          handleRefreshData(res.data[1], false);
        } else {
          handleRefreshData(res.data[0], true);
        }
        setLocalProjectBuildings(selectedBuildings);
        showToast('success', 'Success', 'Project Saved');
        setIsSaving(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        clearChanges();
        setIsSaving(false);
        throw err;
      });
  };

  return (
    <>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col sm={12}>
          <Tabs
            id='ghg-project-tabs'
            activeKey={activeTab}
            onSelect={setActiveTab}
            className='et-second-tabs'
          >
            <Tab eventKey='project-info' title='Project Info'>
              <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  <Form noValidate onSubmit={saveProject}>
                    <Form.Group controlId='project.name'>
                      <Form.Label>
                        Project Name
                        <span className='text-warning font-weight-bold pl-1'>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        value={editProject.name || ''}
                        disabled={!updateAccessGHG}
                        onChange={(e) => onFieldChange('name', e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId='project.defaultProject'>
                      <Form.Label>Set as default project?</Form.Label>
                      <Form.Control
                        as='select'
                        onChange={(e) =>
                          onFieldChange('default_project', e.target.value)
                        }
                        value={editProject.default_project || ''}
                      >
                        <option key={'false'} value={false}>
                          No
                        </option>
                        <option key={'true'} value={true}>
                          Yes
                        </option>
                      </Form.Control>
                    </Form.Group>
                    <h4>Customer Contact</h4>
                    <Form.Group controlId='project.cc_name'>
                      <Form.Label>
                        Contact Name
                        <span className='text-warning font-weight-bold pl-1'>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        value={editProject.cc_name || ''}
                        disabled={!updateAccessGHG}
                        onChange={(e) =>
                          onFieldChange('cc_name', e.target.value)
                        }
                        autoComplete='new-password'
                      />
                    </Form.Group>
                    <Form.Group controlId='project.cc_email'>
                      <Form.Label>
                        Contact Email
                        <span className='text-warning font-weight-bold pl-1'>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        value={editProject.cc_email || ''}
                        disabled={!updateAccessGHG}
                        onChange={(e) =>
                          onFieldChange('cc_email', e.target.value)
                        }
                        placeholder={'example@email.com'}
                        autoComplete='new-password'
                      />
                    </Form.Group>
                    <Form.Group controlId='project.cc_phone'>
                      <Form.Label>Contact Phone</Form.Label>
                      <Form.Control
                        as={NumberFormat}
                        required
                        value={editProject.cc_phone || ''}
                        disabled={!updateAccessGHG}
                        onChange={(e) =>
                          onFieldChange('cc_phone', e.target.value)
                        }
                        format='###-###-####'
                        placeholder={'555-555-5555'}
                        autoComplete='new-password'
                      />
                    </Form.Group>
                    <h4> Project Contact</h4>
                    <Form.Group controlId='project.vc_name'>
                      <Form.Label>
                        Contact Name
                        <span className='text-warning font-weight-bold pl-1'>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        value={editProject.vc_name || ''}
                        disabled={!updateAccessGHG}
                        onChange={(e) =>
                          onFieldChange('vc_name', e.target.value)
                        }
                        autoComplete='new-password'
                      />
                    </Form.Group>
                    <Form.Group controlId='project.vc_email'>
                      <Form.Label>
                        Contact Email
                        <span className='text-warning font-weight-bold pl-1'>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        value={editProject.vc_email || ''}
                        disabled={!updateAccessGHG}
                        onChange={(e) =>
                          onFieldChange('vc_email', e.target.value)
                        }
                        placeholder={'example@email.com'}
                        autoComplete='new-password'
                      />
                    </Form.Group>
                    <Form.Group controlId='project.vc_phone'>
                      <Form.Label>Contact Phone</Form.Label>
                      <Form.Control
                        as={NumberFormat}
                        required
                        value={editProject.vc_phone || ''}
                        disabled={!updateAccessGHG}
                        onChange={(e) =>
                          onFieldChange('vc_phone', e.target.value)
                        }
                        format='###-###-####'
                        placeholder={'555-555-5555'}
                        autoComplete='new-password'
                      />
                    </Form.Group>
                    <EditEntityActionButtons
                      onSubmit={saveProject}
                      onCancel={clearChanges}
                      onDelete={handleDelete}
                      submitDisabled={actionsDisabled}
                      cancelDisabled={actionsDisabled}
                      submitShow={updateAccessGHG}
                      deleteShow={deleteAccessGHG}
                      isSubmitting={isSaving}
                      isDeleting={isDeleting}
                    />
                  </Form>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey='baseline-data' title='Baseline Data'>
              <Row>
                <Col md={2}></Col>
                <Col md={8}>
                  <Form noValidate onSubmit={saveProject}>
                    <Form.Group controlId='project.buildings'>
                      <Form.Label>
                        Select Buildings
                        <span className='text-warning font-weight-bold pl-1'>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        required
                        value={
                          buildings.length === selectedBuildings.length
                            ? 'All Buildings'
                            : selectedString
                        }
                        disabled={true}
                        style={{ paddingRight: '5%' }}
                      />
                      <FontAwesomeIcon
                        style={{
                          fontSize: '20px',
                          marginLeft: '0.5em',
                          color: CHARTCOLORS[1],
                          cursor: 'pointer',
                          float: 'right',
                          marginTop: '-29px',
                          marginRight: '5px',
                        }}
                        icon={faEdit}
                        id={`report-building-select-popover`}
                        onClick={() => setShowBuildingModal(true)}
                      />
                    </Form.Group>
                    <ProjectMonthRange
                      major_label={'Baseline Period'}
                      label='End Month'
                      default_date={
                        editProject.end_date
                          ? dayjs(editProject.end_date).format('YYYY-MM-DD')
                          : null
                      }
                      min_date={dayjs(new Date(2000, 0, 1)).format(
                        'YYYY-MM-DD'
                      )}
                      max_date={dayjs(
                        new Date(new Date().getFullYear() + 1, 0, 1)
                      ).format('YYYY-MM-DD')}
                      payload_key={'end_date'}
                      updatePayload={onFieldChange}
                      showPicker={true}
                    />

                    <Form.Group controlId='project.baseline_goal_percent'>
                      <Form.Label>
                        Yearly Reduction Goal (%)
                        <span className='text-warning font-weight-bold pl-1'>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        as={NumberFormat}
                        required
                        value={editProject.baseline_goal_percent || ''}
                        disabled={!updateAccessGHG}
                        onChange={(e) =>
                          onFieldChange('baseline_goal_percent', e.target.value)
                        }
                        decimalScale='2'
                        autoComplete='new-password'
                      />
                    </Form.Group>
                    <Form.Group controlId='project.baseline_goal_year'>
                      <Form.Label>
                        Goal Duration (years)
                        <span className='text-warning font-weight-bold pl-1'>
                          *
                        </span>
                      </Form.Label>
                      <Form.Control
                        as={NumberFormat}
                        required
                        value={editProject.baseline_goal_year || ''}
                        disabled={!updateAccessGHG}
                        onChange={(e) =>
                          onFieldChange('baseline_goal_year', e.target.value)
                        }
                        decimalScale='0'
                        autoComplete='new-password'
                      />
                    </Form.Group>
                    <EditEntityActionButtons
                      onSubmit={saveProject}
                      onCancel={clearChanges}
                      onDelete={handleDelete}
                      submitDisabled={actionsDisabled}
                      cancelDisabled={actionsDisabled}
                      submitShow={updateAccessGHG}
                      deleteShow={deleteAccessGHG}
                      isSubmitting={isSaving}
                      isDeleting={isDeleting}
                    />
                  </Form>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey='savings-projects' title='Savings Projects'>
              <ProjectMeasures
                organization_id={organization.id}
                project={localProject}
                measures={measures}
                updateList={updateList}
                addToList={addToList}
                showToast={showToast}
                updateAccess={updateAccessGHG}
                deleteAccess={deleteAccessGHG}
              />
            </Tab>
            <Tab eventKey='project-ghg-conversions' title={'GHG Conversions'}>
              <ProjectGHGConversions
                organization_id={organization.id}
                project_id={localProject['id']}
                buildingsLookup={buildingsLookup}
                selectedBuildings={localProjectBuildings}
                GHGConversions={GHGConversions}
                GHGCollections={GHGCollections}
                updateList={updateList}
                showToast={showToast}
                updateAccessGHG={updateAccessGHG}
                deleteAccessGHG={deleteAccessGHG}
              />
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <BuildingSelectModal
        show={showBuildingModal}
        onHide={() => setShowBuildingModal(false)}
        buildings={buildings}
        buildingIds={buildingIds}
        selectedBuildings={selectedBuildings}
        setSelectedBuildings={setSelectedBuildings}
        setNonSelectedBuildings={setNonSelectedBuildings}
      />
      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
}
