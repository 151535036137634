import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faPlusSquare } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import EnergyTracerTable from '../../../components/tables';
import AddProjectGHGCollectionModal from '../../../components/modals/AddProjectGHGCollectionModal';
import ViewGHGConversionModal from '../../../components/modals/ViewGHGConversionModal';
import AddMultiProjectGHGCollectionModal from '../../../components/modals/AddMultiProjectGHGCollectionModal';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

export default function ProjectGHGConversions(props) {
  const {
    organization_id,
    project_id,
    buildingsLookup,
    selectedBuildings,
    GHGConversions,
    GHGCollections,
    updateList,
    showToast,
    updateAccessGHG,
    deleteAccessGHG,
  } = props;

  const [tableData, setTableData] = useState([]);

  const [showAdd, setShowAdd] = useState(false);
  const [addBuildingId, setAddBuildingId] = useState(null);

  const [showView, setShowView] = useState(false);
  const [viewId, setViewId] = useState(null);

  const [showMultiAdd, setShowMultiAdd] = useState(false);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const handleSetAdd = (building) => {
    setAddBuildingId(building);
    setShowAdd(true);
  };

  const handleHideAdd = () => {
    setAddBuildingId(null);
    setShowAdd(false);
  };

  const handleSetView = (collection_id) => {
    setViewId(collection_id);
    setShowView(true);
  };

  const handleHideView = () => {
    setViewId(null);
    setShowView(false);
  };

  useEffect(() => {
    let temp_data = [];
    if (Object.keys(buildingsLookup).length > 0) {
      selectedBuildings.forEach((building) => {
        let temp_obj = {};
        temp_obj['building'] = building;
        temp_obj['building_name'] = buildingsLookup[building]['name'];
        temp_obj['collection'] = null;

        let collection = GHGCollections.filter(
          (collection) =>
            collection.ghg_project_buildings &&
            Object.keys(collection.ghg_project_buildings).includes(
              project_id.toString()
            ) &&
            collection.ghg_project_buildings[project_id].includes(building)
        );
        if (collection.length !== 0) {
          temp_obj['collection'] = {
            ...collection[0],
          };
        }
        temp_data.push(temp_obj);
      });
      setTableData(sortBy(temp_data, ['building_name']));
    } else {
      setTableData([]);
    }
  }, [project_id, buildingsLookup, selectedBuildings, GHGCollections]);

  const handleDelete = (row, project_id, organization_id) => {
    const msg = `Are you sure you want to delete this Project GHG Collection? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteProjectGHGCollection(row, project_id, organization_id);
    });
  };

  const deleteProjectGHGCollection = (row, project_id, organization_id) => {
    ApiServiceServerless.delete(
      `/ghg_projects/project_ghg_collection/${parseInt(project_id)}/${
        row.collection.id
      }/${row.building}`,
      { authorization_id: organization_id }
    )
      .then((res) => {
        updateList(res.data, 'ghg_collections');
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  const tableColumns = [
    { dataField: 'building_name', text: 'Building', style: styles.column },
    {
      dataField: 'collection',
      text: 'GHG Collection',
      style: styles.actionColumn,
      formatter: (cell, row) => (
        <>
          {row['collection'] !== null ? (
            <>
              <div
                className='et-link'
                style={{ display: 'inline-block' }}
                onClick={() => {
                  handleSetView(row.collection.id);
                }}
              >
                {row.collection.name}{' '}
              </div>
              {deleteAccessGHG && (
                <Button
                  variant='outline-danger'
                  style={{
                    padding: '0px',
                    borderWidth: '0px',
                    borderRadius: '50%',
                    height: '28px',
                    width: '28px',
                    marginLeft: '20px',
                  }}
                >
                  <FontAwesomeIcon
                    style={{ fontSize: '18px', lineHeight: 'inherit' }}
                    icon={faTimesCircle}
                    onClick={() =>
                      handleDelete(row, project_id, organization_id)
                    }
                  />
                </Button>
              )}
            </>
          ) : (
            <AddModalDisplay
              building={row.building}
              handleSetAdd={handleSetAdd}
              updateAccess={updateAccessGHG}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {updateAccessGHG && (
        <Button
          style={{ marginBottom: '1em', float: 'right', marginLeft: '1em' }}
          onClick={() => setShowMultiAdd(true)}
        >
          Multi GHG Set
        </Button>
      )}

      {tableData.length > 0 && (
        <EnergyTracerTable
          data={tableData}
          columns={tableColumns}
          keyField={'building'}
        />
      )}
      <ConfirmationModal {...confirmationModalProps} />
      <AddProjectGHGCollectionModal
        type={'ghg'}
        show={showAdd}
        onHide={() => handleHideAdd()}
        building_id={addBuildingId}
        project_id={project_id}
        organization_id={organization_id}
        GHGCollections={GHGCollections}
        updateList={updateList}
        showToast={showToast}
      />
      <ViewGHGConversionModal
        show={showView}
        onHide={() => handleHideView()}
        rows={sortBy(
          GHGConversions.filter((conversion) =>
            conversion.collection_ids.includes(parseInt(viewId))
          ),
          ['year']
        )}
      />
      <AddMultiProjectGHGCollectionModal
        type={'ghg'}
        show={showMultiAdd}
        onHide={() => setShowMultiAdd(false)}
        buildingsLookup={buildingsLookup}
        project_id={project_id}
        organization_id={organization_id}
        GHGCollections={GHGCollections}
        updateList={updateList}
        showToast={showToast}
      />
    </>
  );
}

function AddModalDisplay(props) {
  const { building, handleSetAdd, updateAccess } = props;

  return (
    <>
      {updateAccess ? (
        <Button
          variant='link'
          style={{ width: '100%', margin: '0px', padding: '0px' }}
          onClick={() => handleSetAdd(building)}
        >
          <div style={{ textAlign: 'left', margin: 'auto' }}>
            <FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: '23px' }} />
          </div>
        </Button>
      ) : (
        <>No Rate</>
      )}
    </>
  );
}
