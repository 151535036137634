import React, { useEffect, useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';

import EnergyTracerMultiLineChart from '../../components/EnergyTracerMultiLineChart';
import CheckResources from './CheckResources';
import {
  BarChart,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip,
  Bar,
  Legend,
} from 'recharts';
import CHARTCOLORS from '../../helpers/chartColors';
import formatValue from '../../helpers/chartFormatter';
import consumptionUnits from '../../helpers/consumptionUnits';
import DashboardBaselineDateSelectContainer from './DashboardBaselineDateSelectContainer';
import {
  ghg_units,
  short_ghg_units,
  ghg_conversions,
} from '../../helpers/GHGUnits';
import GraphActionContainer from '../../components/chartElements/GraphActionContainer';
import ChartDownload from '../../components/chartElements/Actions/ChartDownload';
import ChartToggle from '../../components/chartElements/Actions/ChartToggle';

const row = {
  marginRight: '40px',
  paddingLeft: '1em',
  marginTop: '1em',
};

const styles = {
  inputStyle: {
    width: '200px',
    textAlign: 'center',
    marginRight: '10px',
  },
  unit_form: { display: 'inline-block', marginLeft: '1em' },
};

const unit_utilities = ['gas', 'propane', 'water'];
const ghg_utilities = ['electric', 'gas', 'propane'];

export default function HistoricalData(props) {
  const [deferredProps, setDeferredProps] = useState({ ...props });
  useEffect(() => {
    if (props.open) setDeferredProps({ ...props });
  }, [props]);
  const {
    dashboardData,
    organization,
    endDate,
    dateOption,
    activeIds,
    activeKeys,
    activeGHGLicense,
  } = deferredProps;

  const [utilitySelected, setUtilitySelected] = useState('none');
  const [activeUtilities, setActiveUtilities] = useState([]);
  const [unitSelected, setUnitSelected] = useState('');

  const [activeGHGUnit, setActiveGHGUnit] = useState('lbs');
  const [GHGUnitFactor, setGHGUnitFactor] = useState(1);

  const [costData, setCostData] = useState([]);
  const [usageData, setUsageData] = useState([]);
  const [demandData, setDemandData] = useState([]);
  const [GHGData, setGHGData] = useState([]);
  const [convertedGHGData, setConvertedGHGData] = useState([]);
  const [costBarData, setCostBarData] = useState([]);
  const [costBarKeys, setCostBarKeys] = useState([]);

  const [costSummary, setCostSummary] = useState(true);
  const [usageSummary, setUsageSummary] = useState(true);
  const [demandSummary, setDemandSummary] = useState(true);
  const [GHGSummary, setGHGSummary] = useState(true);

  const [chartDownloadHeaders, setChartDownloadHeaders] = useState([]);
  const [chartDownloadKeys, setChartDownloadKeys] = useState([]);
  const [chartBarDownloadHeaders, setChartBarDownloadHeaders] = useState([]);
  const [chartBarDownloadKeys, setChartBarDownloadKeys] = useState([]);

  const [baselineData, setBaselineData] = useState({});

  useEffect(() => {
    setGHGUnitFactor(1 / ghg_conversions[activeGHGUnit]);
  }, [activeGHGUnit]);

  useEffect(() => {
    let altered_ghg_data = [];
    GHGData.forEach((month) => {
      let temp_month = {};
      Object.keys(month).forEach((key) => {
        if (['name', 'month'].includes(key)) {
          temp_month[key] = month[key];
        } else {
          temp_month[key] = month[key] * GHGUnitFactor;
        }
      });
      altered_ghg_data.push(temp_month);
    });
    setConvertedGHGData(altered_ghg_data);
  }, [GHGData, GHGUnitFactor]);

  const convertToChartForm = useCallback(
    (
      dashboardData,
      baselineData,
      utilitySelected,
      unitSelected,
      chartType,
      activeIds,
      activeKeys
    ) => {
      const hasBaseline = Object.keys(baselineData).length > 0;
      let data = dashboardData['month_array'].map((month) => {
        return {
          name: month,
          month: month,
        };
      });
      let factor = 1;
      if (
        unit_utilities.includes(utilitySelected) &&
        chartType === 'usage' &&
        Object.keys(dashboardData['conversions']).includes(utilitySelected) &&
        Object.keys(dashboardData['conversions'][utilitySelected]).includes(
          unitSelected
        )
      ) {
        factor = dashboardData['conversions'][utilitySelected][unitSelected];
      }

      data.forEach((entry, index) => {
        activeIds.forEach((id, index2) => {
          let key = activeKeys[index2];

          entry[key] =
            dashboardData['building_usage'][id] &&
            dashboardData['building_usage'][id][utilitySelected]
              ? dashboardData['building_usage'][id][utilitySelected][chartType][
                  index
                ] * factor
              : 0;
          if (hasBaseline) {
            entry[key + ' Baseline'] =
              baselineData['building_usage'][id] &&
              baselineData['building_usage'][id][utilitySelected]
                ? baselineData['building_usage'][id][utilitySelected][
                    chartType
                  ][index] * factor
                : 0;
          }
        });
      });
      return data;
    },
    []
  );

  const convertBarCosts = useCallback(
    (dashboardData, utilitySelected, activeIds) => {
      let cost_bar_keys = [];
      let local_ids = [...activeIds];

      let data = dashboardData['month_array'].map((month) => {
        return {
          name: month,
          month: month,
        };
      });
      data.forEach((entry, index) => {
        local_ids.forEach((id) => {
          let costs = {};
          if (
            dashboardData['building_usage'][id] &&
            dashboardData['building_usage'][id][utilitySelected] &&
            dashboardData['building_usage'][id][utilitySelected]['costs']
          ) {
            costs =
              dashboardData['building_usage'][id][utilitySelected]['costs'];
          }
          Object.keys(costs).forEach((cost) => {
            if (!cost_bar_keys.includes(cost) && costs[cost][index] !== 0) {
              cost_bar_keys.push(cost);
            }
            if (entry[cost]) {
              entry[cost] += costs[cost][index];
            } else {
              entry[cost] = costs[cost][index];
            }
          });
        });
      });
      setCostBarKeys(cost_bar_keys);
      return data;
    },
    []
  );

  const setOrgData = useCallback(() => {
    if (Object.keys(dashboardData).length) {
      setActiveUtilities(Object.keys(dashboardData['organization_usage']));
      const utility = Object.keys(dashboardData['organization_usage']).includes(
        utilitySelected
      )
        ? utilitySelected
        : Object.keys(dashboardData['organization_usage'])[0];

      setUtilitySelected(utility);
      setCostData(
        convertToChartForm(
          dashboardData,
          baselineData,
          utility,
          unitSelected,
          'cost',
          activeIds,
          activeKeys
        )
      );
      setUsageData(
        convertToChartForm(
          dashboardData,
          baselineData,
          utility,
          unitSelected,
          'usage',
          activeIds,
          activeKeys
        )
      );
      setDemandData(
        convertToChartForm(
          dashboardData,
          baselineData,
          utility,
          unitSelected,
          'demand',
          activeIds,
          activeKeys
        )
      );

      setGHGData(
        convertToChartForm(
          dashboardData,
          baselineData,
          utility,
          unitSelected,
          'ghg',
          activeIds,
          activeKeys
        )
      );
      setCostBarData(convertBarCosts(dashboardData, utility, activeIds));
    } else {
      setActiveUtilities([]);
      setCostData([]);
      setUsageData([]);
      setDemandData([]);
      setGHGData([]);
      setConvertedGHGData([]);
    }
  }, [
    dashboardData,
    baselineData,
    convertToChartForm,
    convertBarCosts,
    utilitySelected,
    activeIds,
    activeKeys,
    unitSelected,
  ]);

  useEffect(() => {
    if (unit_utilities.includes(utilitySelected)) {
      setUnitSelected(consumptionUnits[utilitySelected][0]);
    } else if (utilitySelected === 'electric') {
      setUnitSelected('kwh');
    } else {
      setUnitSelected('');
    }
  }, [utilitySelected]);

  useEffect(() => {
    setOrgData();
  }, [setOrgData]);

  useEffect(() => {
    let headers = ['Date'];
    let keys = ['name'];
    activeKeys.forEach((key) => {
      headers.push(key);
      keys.push(key);
      if (Object.keys(baselineData).length > 0) {
        headers.push(key + ' Baseline');
        keys.push(key + ' Baseline');
      }
    });
    setChartDownloadHeaders(headers);
    setChartDownloadKeys(keys);
  }, [activeKeys, baselineData]);

  useEffect(() => {
    let headers = ['Date'];
    let keys = ['name'];
    costBarKeys.forEach((key) => {
      headers.push(key);
      keys.push(key);
    });
    setChartBarDownloadHeaders(headers);
    setChartBarDownloadKeys(keys);
  }, [costBarKeys]);

  return (
    <div>
      <CheckResources
        organization={organization}
        dashboardData={dashboardData}
      />
      <div>
        <div style={{ display: 'inline-block' }}>
          <Form inline>
            <Form.Group>
              <Form.Label style={{ display: 'block', marginRight: '5px' }}>
                Utility:
              </Form.Label>
              <Form.Control
                style={styles.inputStyle}
                as='select'
                size='sm'
                onChange={(e) => {
                  setUtilitySelected(e.target.value);
                }}
                value={utilitySelected}
                data-id='db_org_utility'
              >
                {activeUtilities.map((utility) => (
                  <option
                    key={`util-tab-${utility.toLowerCase()}`}
                    value={utility.toLowerCase()}
                  >
                    {utility.charAt(0).toUpperCase() +
                      utility.substr(1).toLowerCase()}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {unit_utilities.includes(utilitySelected) && (
              <Form.Group>
                <Form.Label style={{ display: 'block', marginRight: '5px' }}>
                  Unit:
                </Form.Label>
                <Form.Control
                  style={styles.inputStyle}
                  as='select'
                  size='sm'
                  onChange={(e) => {
                    setUnitSelected(e.target.value);
                  }}
                  value={unitSelected}
                  data-id='db_org_unit'
                >
                  {consumptionUnits[utilitySelected].map((unit) => (
                    <option key={`unit-tab-${unit}`} value={unit}>
                      {unit}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
          </Form>
        </div>
      </div>
      <div style={{ display: 'inline-block', margin: '1em 0em' }}>
        <DashboardBaselineDateSelectContainer
          orgId={organization.id}
          endDate={endDate}
          dateOption={dateOption}
          setBaselineData={setBaselineData}
        />
      </div>
      <h5>{'Total Cost ($)'} </h5>
      <div style={{ ...row, marginBottom: '4em' }}>
        <EnergyTracerMultiLineChart
          data={!costData ? [] : costData}
          unit={'$'}
          keys={activeKeys}
          baseline={Object.keys(baselineData).length > 0}
          showSummary={costSummary}
        />
        <GraphActionContainer>
          <ChartToggle
            onClick={() => {
              setCostSummary((prev) => !prev);
            }}
            state={costSummary ? 1 : 0}
            title={'Toggle Selection Total'}
            variant={3}
          />
          <ChartDownload
            organization_id={organization.id}
            data={costData}
            headers={chartDownloadHeaders}
            keys={chartDownloadKeys}
            chart_type={'line'}
          />
        </GraphActionContainer>
      </div>
      {costBarKeys.length > 1 && (
        <>
          <br />
          <br></br> <h5>{'Cost Breakdown ($)'}</h5>
          <div style={{ ...row, marginBottom: '4em' }}>
            <ResponsiveContainer width={'100%'} height={300} debounce={300}>
              <BarChart data={costBarData}>
                <YAxis
                  type='number'
                  tickFormatter={(tick) => {
                    return tick.toLocaleString();
                  }}
                />
                <XAxis type='category' dataKey='name' tick={false}>
                  {' '}
                </XAxis>
                <Tooltip />
                <Legend
                  wrapperStyle={{ bottom: '0px', position: 'relative' }}
                />
                {costBarKeys.map((cost, idx) => (
                  <Bar
                    dataKey={cost}
                    fill={CHARTCOLORS[idx]}
                    formatter={(value) => formatValue(value, '$')}
                    stackId='a'
                    key={cost + '_' + idx}
                  ></Bar>
                ))}
              </BarChart>
            </ResponsiveContainer>
            <GraphActionContainer customStyle={{ marginTop: '1em' }}>
              <ChartDownload
                organization_id={organization.id}
                data={costBarData}
                headers={chartBarDownloadHeaders}
                keys={chartBarDownloadKeys}
                chart_type={'bar'}
              />
            </GraphActionContainer>
          </div>
        </>
      )}
      <br />
      <br></br>
      <h5>{'Total Consumption (' + unitSelected + ')'} </h5>
      <div style={{ ...row, marginBottom: '6em' }}>
        <EnergyTracerMultiLineChart
          data={!usageData ? [] : usageData}
          unit={unitSelected}
          keys={activeKeys}
          baseline={Object.keys(baselineData).length > 0}
          showSummary={usageSummary}
        />
        <GraphActionContainer>
          <ChartToggle
            onClick={() => {
              setUsageSummary((prev) => !prev);
            }}
            state={usageSummary ? 1 : 0}
            title={'Toggle Selection Total'}
            variant={3}
          />
          <ChartDownload
            organization_id={organization.id}
            data={usageData}
            headers={chartDownloadHeaders}
            keys={chartDownloadKeys}
            chart_type={'line'}
          />
        </GraphActionContainer>
      </div>{' '}
      {utilitySelected === 'electric' && (
        <>
          <h5>{'Total Demand (kW)'}</h5>
          <div style={{ ...row, marginBottom: '6em' }}>
            <EnergyTracerMultiLineChart
              data={!demandData ? [] : demandData}
              unit={'kW'}
              keys={activeKeys}
              baseline={Object.keys(baselineData).length > 0}
              showSummary={demandSummary}
            />
            <GraphActionContainer>
              <ChartToggle
                onClick={() => {
                  setDemandSummary((prev) => !prev);
                }}
                state={demandSummary ? 1 : 0}
                title={'Toggle Selection Total'}
                variant={3}
              />
              <ChartDownload
                organization_id={organization.id}
                data={demandData}
                headers={chartDownloadHeaders}
                keys={chartDownloadKeys}
                chart_type={'line'}
              />
            </GraphActionContainer>
          </div>
        </>
      )}
      {ghg_utilities.includes(utilitySelected) && activeGHGLicense && (
        <>
          <h5>
            {'Total Emissions'}

            <Form.Group controlId={'ghg-unit-select'} style={styles.unit_form}>
              <Form.Control
                as='select'
                onChange={(e) => setActiveGHGUnit(e.target.value)}
                defaultValue={activeGHGUnit}
              >
                {Object.keys(ghg_units).map((unit) => (
                  <option key={`unit-opt-${unit}`} value={unit}>
                    {ghg_units[unit]}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </h5>
          <div style={{ ...row, marginBottom: '6em' }}>
            <EnergyTracerMultiLineChart
              data={!convertedGHGData ? [] : convertedGHGData}
              unit={short_ghg_units[activeGHGUnit]}
              keys={activeKeys}
              baseline={Object.keys(baselineData).length > 0}
              showSummary={GHGSummary}
            />
            <GraphActionContainer>
              <ChartToggle
                onClick={() => {
                  setGHGSummary((prev) => !prev);
                }}
                state={GHGSummary ? 1 : 0}
                title={'Toggle Selection Total'}
                variant={3}
              />
              <ChartDownload
                organization_id={organization.id}
                data={convertedGHGData}
                headers={chartDownloadHeaders}
                keys={chartDownloadKeys}
                chart_type={'line'}
              />
            </GraphActionContainer>
          </div>
        </>
      )}
    </div>
  );
}
