import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import dayjs from 'dayjs';
import { ApiServiceServerless } from '../../../../xhr_libs';

const styles = {
  duplicateMessage: {
    textAlign: 'center',
    marginTop: '20px',
  },
  dateList: {
    margin: '0 16px',
    color: '#DB2B39',
  },
};

export default function DuplicateCsvMonthlyModal(props) {
  const {
    show,
    onHide,
    account,
    file,
    duplicateDates,
    handleSuccessToast,
    showToast,
    resetUpload,
    setIsLoading,
  } = props;

  const [overwriteDuplicates, setOverwriteDuplicates] = useState(true);
  const [localDuplicates, setLocalDuplicates] = useState({});
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    let temp = {};
    Object.keys(duplicateDates).forEach((acc) => {
      if (duplicateDates[acc].length > 0) {
        temp[acc] = duplicateDates[acc];
      }
    });
    setLocalDuplicates(temp);
  }, [duplicateDates]);

  const uploadFileWithUpdates = (e) => {
    setDisabled(true);
    e.preventDefault();
    ApiServiceServerless.post(
      `/bill_upload_monthly/${account.organization.id}/${account.account_type}/${overwriteDuplicates}`,
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        authorization_id: account.organization.id,
      }
    )
      .then((resp) => {
        handleSuccessToast(resp);
        onHide();
        setDisabled(false);
      })
      .catch((error) => {
        setDisabled(false);
        showToast('danger', 'Error', error);
        resetUpload();
        onHide();
        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const dateList = (duplicates) => {
    return duplicates.map((date, j) =>
      j < duplicates.length - 1
        ? dayjs.utc(date).format('M/D/YYYY') + ', '
        : dayjs.utc(date).format('M/D/YYYY')
    );
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>Duplicate Uploads</Modal.Header>
      <Form onSubmit={uploadFileWithUpdates}>
        <Modal.Body>
          <Row style={styles.duplicateMessage}>
            <Col>
              Bills have already been uploaded for the following Read Dates:
            </Col>
          </Row>

          <hr />

          <Tabs
            id='duplicate_bill_tabs'
            defaultActiveKey={Object.keys(localDuplicates)[0]}
            className='et-second-tabs'
          >
            {Object.keys(localDuplicates).map((acc) => (
              <Tab eventKey={acc} title={acc} key={acc}>
                <Row>
                  <Col>
                    <p style={styles.dateList}>
                      {dateList(localDuplicates[acc])}
                    </p>
                  </Col>
                </Row>
              </Tab>
            ))}
          </Tabs>

          <hr />
          <Row style={styles.duplicateMessage}>
            <Col>Would you like to overwrite them?</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Form.Group className='d-flex justify-content-end'>
            <Button
              type='submit'
              variant='outline-primary'
              className='mx-3'
              onClick={() => {
                setOverwriteDuplicates(false);
                setIsLoading(false);
              }}
              disabled={disabled}
            >
              No
            </Button>
            <Button
              type='submit'
              variant='primary'
              onClick={() => setOverwriteDuplicates(true)}
              disabled={disabled}
            >
              Yes
            </Button>
          </Form.Group>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
