import React, { useRef, useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Helmet from 'react-helmet';

import LoginPageDecoration from '../components/LoginPageDecoration';
import ReCaptcha, { withReCaptcha } from '../components/ReCaptcha';
import InvalidFeedback from '../components/forms/InvalidFeedback';
import { ApiServicePublicServerless } from '../xhr_libs';

const PAGE_TITLE = 'Reset Password';

function ForgotPassword({ showToast }) {
  const { register, errors, handleSubmit } = useForm();
  const recaptchaRef = useRef();

  const [disabled, setDisabled] = useState(false);

  const onSubmit = (data) => {
    setDisabled(true);
    withReCaptcha(() => {
      ApiServicePublicServerless.post('/password-reset/request', {
        username: data.email,
      })
        .then((res) => {
          if (res.status === 200) {
            showToast(
              'success',
              'Success',
              'If the account exists you will receive a verification email'
            );
          }
          setDisabled(false);
        })
        .catch((error) => {
          setDisabled(false);
          showToast('danger', 'Error', error);
          throw error;
        });
    }, recaptchaRef);
  };

  return (
    <>
      <ReCaptcha ref={recaptchaRef} />
      <LoginPageDecoration />
      <Container
        style={{ marginTop: '2em', width: '450px' }}
        className='et-unauth-container'
      >
        <Helmet>
          <title>{PAGE_TITLE}</title>
        </Helmet>
        <Card style={{ border: 'none' }}>
          <Card.Body className={'login-cardbody'}>
            <h4 className={'mb-4'}>{PAGE_TITLE}</h4>
            <Form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              style={{ textAlign: 'left' }}
            >
              <Form.Group>
                <Form.Label>
                  Email
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  placeholder='Enter email'
                  isInvalid={errors.email}
                  ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                />
                <InvalidFeedback
                  errors={errors}
                  name='email'
                  message='Please provide a valid email address'
                />
              </Form.Group>
              <hr style={{ width: '100%' }} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    marginBottom: '15px',
                  }}
                  type='submit'
                  disabled={disabled}
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default ForgotPassword;
