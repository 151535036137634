import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Helmet from 'react-helmet';

import './App.css';
import './Mobile.css';
import './PDF.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/custom.scss';

import Routes from './routes';
import { AppProvider } from './contexts/app-context';
import Toasts from './components/toasts/Toasts';
import { ApiServicePublicServerless } from './xhr_libs';
import { version } from './auth';
import VersionRefreshModal from './components/modals/VersionRefreshModal';

function App() {
  const [rolesObject, setRolesObject] = useState({});

  const [appVersion, setAppVersion] = useState(null);
  const [appVersionRefresh, setAppVersionRefresh] = useState(false);

  const [showRefreshModal, setShowRefreshModal] = useState(false);

  const getRolesObject = useCallback(() => {
    ApiServicePublicServerless.get('app_details').then((res) => {
      setRolesObject(res.data.roles);
      setAppVersion(res.data.version);
      setAppVersionRefresh(res.data.version_refresh);
    });
  }, []);

  useEffect(() => {
    getRolesObject();
  }, [getRolesObject]);

  useEffect(() => {
    if (appVersion && version && appVersion !== version && appVersionRefresh) {
      setShowRefreshModal(true);
    }
  }, [appVersion, appVersionRefresh]);

  return (
    <div className='App' style={{ maxHeight: '100vh', overflowY: 'scroll' }}>
      <Helmet
        defaultTitle='EnergyTracer by Veregy'
        titleTemplate='%s | EnergyTracer by Veregy'
      ></Helmet>
      <AppProvider rolesObject={rolesObject}>
        <BrowserRouter>
          <Routes />
          <Toasts />
        </BrowserRouter>
      </AppProvider>
      <VersionRefreshModal
        show={showRefreshModal}
        onHide={() => {
          setShowRefreshModal(false);
        }}
      />
    </div>
  );
}

export default App;
