import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import { ApiServiceServerless, forceAuthRefresh } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

const formStyle = {
  textAlign: 'left',
};

const portfolioDataDefaults = {
  name: '',
};

export default function PortfolioModal(props) {
  const { show, onHide, setPortfolios, showToast } = props;

  const [portfolioData, setPortfolioData] = useState(portfolioDataDefaults);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRefreshData = (data) => {
    setPortfolios((prev) => [...prev, data]);
  };

  const savePortfolio = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      ApiServiceServerless.post(`/portfolios`, {
        name: portfolioData.name,
      })
        .then((res) => {
          handleRefreshData(res.data);
          setValidated(false);
          setPortfolioData(portfolioDataDefaults);
          showToast(
            'success',
            'Success',
            `New Portfolio ${portfolioData.name} was created.`
          );
          forceAuthRefresh();
        })
        .catch(() => {
          showToast('danger', 'Alert', 'Portfolio Creation Error');
        })
        .finally(() => {
          onHide();
          setValidated(false);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setValidated(true);
    }
  };

  const handleCancel = () => {
    onHide();
    setValidated(false);
    setPortfolioData(portfolioDataDefaults);
  };

  const saveDisabled = !portfolioData.name;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Create a Portfolio</Modal.Header>
      <Form
        noValidate
        validated={validated}
        onSubmit={savePortfolio}
        style={formStyle}
      >
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group controlId='newPortfolioForm.name'>
                <Form.Label>
                  Name
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <Form.Control
                  required
                  placeholder=''
                  onChange={(e) =>
                    setPortfolioData({
                      ...portfolioData,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={handleCancel}
            submitDisabled={saveDisabled}
            isSubmitting={isLoading}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
