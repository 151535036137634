import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import CreateNewAccountOpts from './CreateNewAccountOpts';
import { fmtBoolYesNo } from '../../tables/formatters';

const TRUEFALSE = [true, false];

function EnrolledForm(props) {
  const {
    availableLocalUtilityAccounts,
    modalData,
    buildings,
    errors,
    register,
    activeLicense,
  } = props;

  const [showNewAccountOpts, setShowNewAccountOpts] = useState(false);

  return (
    <>
      <Form.Group controlId='urjanet.providerForm.shouldPullHistorySelect'>
        <Form.Label>Pull Bill History for This Account?</Form.Label>
        <Form.Control
          as='select'
          name='shouldPullHistory'
          defaultValue={modalData.pullHistory}
          disabled={!activeLicense}
          ref={register}
        >
          {TRUEFALSE.map((opt) => (
            <option key={`enroll-opt-${opt}`} value={opt}>
              {fmtBoolYesNo(opt)}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId='urjanet.formGridSubscribe'>
        <Form.Label>Enable Automatic Bill Imports</Form.Label>
        <Form.Control
          as='select'
          name='enrollAutoBilling'
          defaultValue={modalData.subscribed}
          disabled={!activeLicense}
          ref={register}
        >
          {TRUEFALSE.map((opt) => (
            <option key={`enroll-opt-${opt}`} value={opt}>
              {fmtBoolYesNo(opt)}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId='urjanet.formAccountLink'>
        <Form.Label>Linked EnergyTracer Account</Form.Label>
        <Form.Row>
          <Col>
            <Form.Control
              as='select'
              name='linkAccount'
              defaultValue={modalData.newAccountId}
              disabled={true}
              isInvalid={errors.linkAccount}
              ref={register({ required: true })}
              onChange={(e) => setShowNewAccountOpts(e.target.value === 'new')}
            >
              <option key={'link-opt-null'} value={''} hidden>
                -
              </option>
              {availableLocalUtilityAccounts.map((acct) => (
                <option key={`link-opt-${acct.id}`} value={acct.id}>
                  {acct.account_number}
                </option>
              ))}
              <option key={`link-opt-new`} value={'new'}>
                Create a New Account
              </option>
            </Form.Control>
          </Col>
        </Form.Row>
      </Form.Group>
      {showNewAccountOpts && (
        <CreateNewAccountOpts
          buildings={buildings}
          errors={errors}
          register={register}
        />
      )}
    </>
  );
}

export default EnrolledForm;
