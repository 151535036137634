import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

import { sortBy } from 'lodash';
import dayjs from 'dayjs';

import Bill from './Bill';
import MONTHS from '../../helpers/months';
import YEARS from '../../helpers/years';

export default function BillFilter(props) {
  const { allBills } = props;

  const [filteredBills, setFilteredBills] = useState([]);
  const [filterType, setFilterType] = useState('read_date');
  const [filterMonth, setFilterMonth] = useState('null');
  const [filterYear, setFilterYear] = useState('null');

  const filterBills = (allBills, filterType, filterMonth, filterYear) => {
    let localBills = [...allBills];
    localBills = localBills.filter(
      (bill) =>
        dayjs.utc(bill[filterType]).month() === parseInt(filterMonth) &&
        dayjs.utc(bill[filterType]).year() === parseInt(filterYear)
    );
    localBills = sortBy(localBills, ['provider_meter_name']);
    setFilteredBills(localBills);
  };

  useEffect(() => {
    if (filterMonth !== 'null' && filterYear !== 'null') {
      filterBills(allBills, filterType, filterMonth, filterYear);
    } else {
      setFilteredBills([]);
    }
  }, [allBills, filterType, filterMonth, filterYear]);

  const hanldeChangeFilterType = (val) => {
    setFilterMonth('null');
    setFilterYear('null');
    setFilterType(val);
  };

  return (
    <>
      <Row style={{ marginBottom: '2em' }}>
        <Col>
          <Form.Group as={Col}>
            <Form.Label>Filter Type</Form.Label>
            <Form.Control
              data-id='org_settings_date'
              as='select'
              onChange={(e) => hanldeChangeFilterType(e.target.value)}
            >
              <option key={`bill_date`} value={'bill_date'}>
                Bill Date
              </option>
              <option key={`read_date`} value={'read_date'}>
                Read Date
              </option>
              <option key={`start_date`} value={'start_date'}>
                Start Date
              </option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Col}>
            <Form.Label>Filter Year</Form.Label>
            <Form.Control
              data-id='filter_year'
              as='select'
              value={filterYear}
              onChange={(e) => setFilterYear(e.target.value)}
            >
              <option key={`opt-null`} value={'null'}>
                Select Year
              </option>
              {YEARS.map((data, index) => (
                <option key={`opt-${index}`} value={data}>
                  {data}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Filter Month</Form.Label>
            <Form.Control
              data-id='filter_month'
              as='select'
              value={filterMonth}
              onChange={(e) => setFilterMonth(e.target.value)}
            >
              <option key={`opt-null`} value={'null'}>
                Select Month
              </option>
              {MONTHS.map((data, index) => (
                <option key={`opt-${index}`} value={index}>
                  {data}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      {filteredBills.map((bill) => (
        <div key={`bill-${bill.id}`} style={{ paddingLeft: '20px' }}>
          <h4>
            Sub-Account:{' '}
            {bill.provider_meter_name ? bill.provider_meter_name : 'Manual'}
          </h4>
          <Bill {...props} data={bill} />
        </div>
      ))}
    </>
  );
}
