import React from 'react';
import { Button, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPause, faPlay, faSplit } from '@fortawesome/pro-solid-svg-icons';

import { checkCanPauseAutomation, checkCanSplitBill } from './helpers';

export default function BillAdvancedOptions(props) {
  const { bill, isSaving, handlePauseAutomation, handleSplitProviderBill } =
    props;

  return (
    <Row>
      {checkCanPauseAutomation(bill) && (
        <div
          title={
            bill.provider_split_bill_value === true ||
            bill.provider_split_bill_parent !== null
              ? 'Feature disabled due to bill split. Contact an administrator for help.'
              : bill.pause_automation
              ? 'Resume Automation'
              : 'Pause Automation'
          }
        >
          <Button
            size='sm'
            style={{ marginLeft: '10px' }}
            disabled={
              isSaving ||
              bill.provider_split_bill_value === true ||
              bill.provider_split_bill_parent !== null
            }
            onClick={() => handlePauseAutomation(bill)}
          >
            <FontAwesomeIcon
              style={{
                fontSize: '21px',
                lineHeight: 'inherit',
                paddingTop: '2px',
              }}
              icon={bill.pause_automation ? faPlay : faPause}
            />
          </Button>
        </div>
      )}
      {checkCanSplitBill(bill) && (
        <div
          title={
            bill.provider_split_bill_parent === null
              ? 'Split Bill'
              : 'Bill cannot be split further'
          }
        >
          <Button
            size='sm'
            style={{ marginLeft: '10px' }}
            disabled={isSaving || bill.provider_split_bill_parent !== null}
            onClick={() => handleSplitProviderBill(bill.provider_bill_id)}
          >
            <FontAwesomeIcon
              style={{
                fontSize: '21px',
                lineHeight: 'inherit',
                paddingTop: '2px',
              }}
              icon={faSplit}
            />
          </Button>
        </div>
      )}
    </Row>
  );
}
