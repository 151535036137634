import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { ApiServiceServerless } from '../../../xhr_libs';
import AddEntityActionButtons from '../../forms/AddEntityActionButtons';
import EnrolledForm from './EnrolledForm';
import NotEnrolledForm from './NotEnrolledForm';
import consumptionUnits from '../../../helpers/consumptionUnits';
import {
  handleSetAccountKey,
  handleEnrollAccount,
  handleUpdateAccount,
  handleSetNewAccount,
} from '../../../components/UtilityImportSettings/helpers/provider';

const formStyle = {
  textAlign: 'left',
};

function UrjanetLinkAccountModal(props) {
  const {
    localUtilityAccounts,
    modalData,
    onHide,
    organization,
    buildings,
    provider_type,
    setProviderAccounts,
    setLocalUtilityAccounts,
    handleUpdateTables,
    show,
    showToast,
    activeLicense,
  } = props;

  const [linkedAccountInfo, setLinkedAccountInfo] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [jointUtilityAccounts, setJointUtilityAccounts] = useState([]);
  const [accountKey, setAccountKey] = useState('');

  const { errors, formState, register, handleSubmit, setValue } = useForm();

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    handleSetAccountKey(provider_type, setAccountKey);
  }, [provider_type]);

  useEffect(() => {
    const localAccount = localUtilityAccounts.find(
      (acc) => acc[accountKey] === modalData.id
    );

    if (localAccount) {
      setLinkedAccountInfo(localAccount);
    }
  }, [localUtilityAccounts, modalData, accountKey, show]);

  useEffect(() => {
    let jointAccounts = [];
    localUtilityAccounts.forEach((acc) => jointAccounts.push(acc));

    if (
      linkedAccountInfo !== '' &&
      !localUtilityAccounts.find((acc) => acc.id === linkedAccountInfo.id)
    ) {
      jointAccounts.push(linkedAccountInfo);
    }
    setJointUtilityAccounts(jointAccounts);
  }, [localUtilityAccounts, linkedAccountInfo]);

  useEffect(() => {
    linkedAccountInfo.id
      ? setValue('linkAccount', linkedAccountInfo.id)
      : setValue('linkAccount', '');
  }, [jointUtilityAccounts, linkedAccountInfo, setValue]);

  useEffect(() => {
    setSubmitDisabled(false);
  }, [show]);

  const onSubmit = (data) => {
    setIsSaving(true);
    setSubmitDisabled(true);

    maybeCreateNewUtilityAccount(data).then((newAccount) => {
      if (newAccount) {
        if (newAccount.id) {
          handleSetNewAccount(
            provider_type,
            newAccount,
            setLocalUtilityAccounts
          );
          handleUpdateTables(1);
        }
        modalData.enrolled
          ? updateAccount(data, newAccount.id)
          : enrollAccount(data, newAccount.id);
      } else {
        onHide();
      }
    });
  };

  const maybeCreateNewUtilityAccount = async (data) => {
    if (data.linkAccount !== 'new') return { id: null };
    const account_type = data.account_type;
    const payload = {
      building_ids: data.buildings,
      account: {
        account_number: modalData.accountNumber,
        account_type: account_type,
        utility_provider: modalData.utilityProvider,
        exclude_from_reports: false,
        default_consumption_unit: consumptionUnits[account_type][0],
      },
    };
    const response = await ApiServiceServerless.post(
      '/utility_accounts',
      payload,
      { authorization_id: organization.id }
    ).catch(() => {
      setIsSaving(false);
      showToast('danger', 'Error', 'Error creating new account.');
    });
    return response && response.data ? response.data : null;
  };

  const enrollAccount = (data, newAccountId) => {
    handleEnrollAccount(
      provider_type,
      modalData,
      organization.id,
      data,
      newAccountId,
      setProviderAccounts,
      setLocalUtilityAccounts,
      setSubmitDisabled,
      showToast,
      onHide,
      setIsSaving
    );
  };

  const updateAccount = (data, newAccountId) => {
    handleUpdateAccount(
      provider_type,
      modalData,
      organization.id,
      data,
      newAccountId,
      setProviderAccounts,
      setLocalUtilityAccounts,
      setSubmitDisabled,
      showToast,
      onHide,
      setIsSaving
    );
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>Account Import Settings</Modal.Header>
      <Form style={formStyle} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group controlId='urjanet.formGridNumber'>
                <Form.Label>Imported Account Number</Form.Label>
                <Form.Control
                  name='accountNumber'
                  defaultValue={modalData.accountNumber}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group controlId='urjanet.formGridUtility'>
                <Form.Label>Utility Provider</Form.Label>
                <Form.Control
                  name='utilityProvider'
                  defaultValue={modalData.utilityProvider}
                  disabled={true}
                />
              </Form.Group>
              {modalData.localAccount ? (
                <EnrolledForm
                  availableLocalUtilityAccounts={jointUtilityAccounts.filter(
                    (acct) =>
                      !acct[accountKey] || acct[accountKey] === modalData.id
                  )}
                  modalData={modalData}
                  buildings={buildings}
                  errors={errors}
                  register={register}
                  activeLicense={activeLicense}
                />
              ) : (
                <NotEnrolledForm
                  availableLocalUtilityAccounts={jointUtilityAccounts.filter(
                    (acct) => !acct[accountKey]
                  )}
                  buildings={buildings}
                  errors={errors}
                  register={register}
                  setValue={setValue}
                  activeLicense={activeLicense}
                />
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={onHide}
            submitDisabled={
              !formState.isDirty || submitDisabled || !activeLicense
            }
            isSubmitting={isSaving}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default UrjanetLinkAccountModal;
