import PropTypes from 'prop-types';
import React from 'react';
import { ErrorMessage } from '@hookform/error-message';

const InvalidFeedback = (props) => {
  const { errors, name, message } = props;
  return (
    <div
      style={{
        height: '15px',
        width: '100%',
        paddingTop: '.25rem',
        fontSize: '80%',
      }}
    >
      <ErrorMessage
        errors={errors}
        name={name}
        render={() => <span className='text-danger'>{message}</span>}
      />
    </div>
  );
};

export default InvalidFeedback;

InvalidFeedback.propTypes = {
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
