import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';
import { addAccountTypes } from '../../helpers/utility-types';
import consumptionUnits from '../../helpers/consumptionUnits';

export default function AddUtilityAccountMaskModal(props) {
  const {
    show,
    onHide,
    account,
    setAccount,
    buildings,
    sub_account,
    refreshAddMask,
    showToast,
    defaultType,
  } = props;

  const [maskedAccountId, setMaskedAccountId] = useState(null);
  const [showNewAccountOpts, setShowNewAccountOpts] = useState(false);

  const [newAccountType, setNewAccountType] = useState(defaultType);
  const [newAccountBuildings, setNewAccountBuildings] = useState([]);

  const [disabled, setDisabled] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setShowNewAccountOpts(false);
  }, [show]);

  useEffect(() => {
    if (showNewAccountOpts) {
      if (newAccountBuildings.length === 0) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    } else {
      if (maskedAccountId) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [showNewAccountOpts, maskedAccountId, newAccountBuildings]);

  const handleAddMask = () => {
    setIsSaving(true);
    if (showNewAccountOpts) {
      createNewAccount();
    } else {
      addMask(maskedAccountId);
    }
  };

  const createNewAccount = () => {
    let building_ids = Array.prototype.slice.call(newAccountBuildings);
    building_ids = building_ids.map((item) => item.value);
    const payload = {
      building_ids: building_ids,
      account: {
        account_number: sub_account.name,
        utility_provider: account.utility_provider,
        exclude_from_reports: account.exclude_from_reports,
        account_type: newAccountType,
        default_consumption_unit: consumptionUnits[newAccountType][0],
      },
    };

    ApiServiceServerless.post('/utility_accounts', payload, {
      authorization_id: account.organization.id,
    })
      .then((res) => {
        setAccount((prev) => {
          let new_acc = { ...prev };
          new_acc.available_masks[res.data.id] = res.data;
          return new_acc;
        });
        addMask(res.data.id);
      })
      .catch(() => {
        showToast('danger', 'Error', 'Error creating mask');
        setDisabled(false);
        onHide();
        setIsSaving(false);
      });
  };

  const addMask = (masked_account_id) => {
    ApiServiceServerless.post(
      `utility_accounts/utility_account_masks/${account.id}/${sub_account.id}/${masked_account_id}`,
      {},
      { authorization_id: account.id }
    )
      .then((res) => {
        refreshAddMask(res.data);
        setMaskedAccountId(null);
      })
      .catch(() => {
        showToast('danger', 'Error', 'Error creating mask');
        setDisabled(false);
      })
      .finally(() => {
        onHide();
        setIsSaving(false);
      });
  };

  const handleChange = (value) => {
    if (value === 'new') {
      setMaskedAccountId(null);
      setShowNewAccountOpts(true);
    } else {
      setMaskedAccountId(value);
      setShowNewAccountOpts(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Mask Sub Account</Modal.Header>
      <Modal.Body>
        <Form.Control
          as='select'
          required
          onChange={(e) => handleChange(e.target.value)}
        >
          <option key={'opt-null'} value={''} hidden>
            -
          </option>
          <option key={`link-opt-new`} value={'new'}>
            Create a New Account
          </option>
          {sortBy(
            Object.values(account.available_masks).filter(
              (acc) => acc.is_available && acc.id !== account.id
            ),
            ['account_number']
          ).map((row) => (
            <option key={'opt-ghg-' + row.id} value={row.id}>
              {row.account_number}
            </option>
          ))}
        </Form.Control>
        {showNewAccountOpts && (
          <>
            <Form.Group controlId='accountMask.buildingsSelect'>
              <Form.Label>Select Building(s)</Form.Label>
              <Form.Control
                as='select'
                name='buildings'
                multiple
                onChange={(e) =>
                  setNewAccountBuildings(e.target.selectedOptions)
                }
              >
                {sortBy(buildings, ['name']).map((building) => (
                  <option
                    key={`building-opt-${building.id}`}
                    value={building.id}
                  >
                    {building.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='accountMask.utilityType'>
              <Form.Label>
                Utility type
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                as='select'
                required
                value={newAccountType}
                onChange={(e) => setNewAccountType(e.target.value)}
              >
                {addAccountTypes.map((type) => (
                  <option key={`type-opt-${type}`} value={type}>
                    {type.charAt(0).toUpperCase() +
                      type.substr(1).toLowerCase()}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          submitDisabled={disabled || isSaving}
          onSubmit={() => handleAddMask()}
          isSubmitting={isSaving}
        />
      </Modal.Footer>
    </Modal>
  );
}
