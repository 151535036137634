import PropTypes from 'prop-types';
import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

import ArrowAccordionToggle from './ArrowAccordionToggle';

function AccordionCard({ children, eventKey, title }) {
  return (
    <Card>
      <ArrowAccordionToggle eventKey={eventKey}>{title}</ArrowAccordionToggle>
      <Accordion.Collapse eventKey={eventKey}>
        <>{children}</>
      </Accordion.Collapse>
    </Card>
  );
}

AccordionCard.propTypes = {
  eventKey: PropTypes.string.isRequired,
};

export default AccordionCard;
