import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

import ReportDropdownArray from '../../components/reports/ReportDropdownArray';
import consumptionUnits from '../../helpers/consumptionUnits';

export default function ReportUnits(props) {
  const { organizationData, updatePayload, excludeWater } = props;

  return (
    <Row>
      {organizationData['utility_types'].includes('gas') && (
        <Col>
          <ReportDropdownArray
            updatePayload={updatePayload}
            payload_key='gas_unit'
            values={consumptionUnits['gas']}
            defaultValue={0}
            label={'Gas Unit'}
          ></ReportDropdownArray>
        </Col>
      )}
      {organizationData['utility_types'].includes('propane') && (
        <Col>
          <ReportDropdownArray
            updatePayload={updatePayload}
            payload_key='propane_unit'
            values={consumptionUnits['propane']}
            defaultValue={0}
            label={'Propane Unit'}
          ></ReportDropdownArray>
        </Col>
      )}
      {organizationData['utility_types'].includes('water') && !excludeWater && (
        <Col>
          <ReportDropdownArray
            updatePayload={updatePayload}
            payload_key='water_unit'
            values={consumptionUnits['water']}
            defaultValue={0}
            label={'Water Unit'}
          ></ReportDropdownArray>
        </Col>
      )}
    </Row>
  );
}

ReportUnits.propTypes = {
  organizationData: PropTypes.object,
  updatePayload: PropTypes.func,
  excludeWater: PropTypes.bool,
};

ReportUnits.defaultProps = {
  excludeWater: false,
};
