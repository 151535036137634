import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import { sortBy, isEqual } from 'lodash';

import { ApiServiceServerless } from '../../../xhr_libs';
import EditEntityActionButtons from '../../forms/EditEntityActionButtons';
import consumptionUnits from '../../../helpers/consumptionUnits';
import EditUtilityAccountDates from './EditUtilityAccountDates';

const formStyle = {
  textAlign: 'left',
};

const utilityTypes = ['electric', 'gas', 'propane', 'water', 'production'];

const accountDataDefaults = {
  account_number: '',
  account_type: 'electric',
  default_consumption_unit: 'kwh',
  utility_provider: '',
  reference_id: '',
  building_ids: [],
  exclude_from_reports: false,
  first_bill_date: null,
  last_bill_date: null,
};

const PAYLOAD_KEYS = [
  'account_number',
  'utility_provider',
  'reference_id',
  'exclude_from_reports',
  'default_consumption_unit',
  'first_bill_date',
  'last_bill_date',
];

export default function EditUtilityAccountModal(props) {
  const {
    show,
    onHide,
    accountDetails,
    organization,
    buildings,
    defaultBuildings,
    handleSetAccount,
    handleUpdateTables,
    showToast,
  } = props;

  const [account, setAccount] = useState(accountDataDefaults);
  const [validated, setValidated] = useState(false);
  const [availableConsumptionUnits, setAvailableConsumptionUnits] = useState(
    consumptionUnits['electric']
  );

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setAccount({ ...accountDetails });
    if (accountDetails.account_type) {
      setAvailableConsumptionUnits(
        consumptionUnits[accountDetails.account_type]
      );
    }
  }, [accountDetails, show]);

  useEffect(() => {
    if (defaultBuildings && defaultBuildings.length > 0) {
      setAccount((prevAccount) => ({
        ...prevAccount,
        building_ids: defaultBuildings,
      }));
    }
  }, [defaultBuildings]);

  const updateAccountNumber = (e) => {
    const { value } = e.target;
    setAccount((prevAccount) => ({
      ...prevAccount,
      account_number: value,
    }));
  };

  const updateExcludeFromReport = () => {
    setAccount({
      ...account,
      exclude_from_reports: !account.exclude_from_reports,
    });
  };

  const updateUtilityProvider = (e) => {
    const { value } = e.target;
    setAccount((prevAccount) => ({
      ...prevAccount,
      utility_provider: value,
    }));
  };

  const updateReferenceID = (e) => {
    const { value } = e.target;
    setAccount((prevAccount) => ({
      ...prevAccount,
      reference_id: value,
    }));
  };

  const updateUtilityType = (e) => {
    const value = e.target.value;
    setAccount((prevAccount) => ({
      ...prevAccount,
      account_type: value,
      default_consumption_unit: consumptionUnits[value][0],
    }));

    setAvailableConsumptionUnits(consumptionUnits[value]);
  };

  const updateDefaultConsumptionUnit = (e) => {
    const value = e.target.value;
    setAccount((prevAccount) => ({
      ...prevAccount,
      default_consumption_unit: value,
    }));
  };

  const updateAccountDate = (val, key) => {
    setAccount((prevAccount) => ({
      ...prevAccount,
      [key]: val,
    }));
  };

  const updateSet = (values, property) => {
    const accountData = { ...account };
    const arr = Array.prototype.slice.call(values);
    accountData[property] = arr.map((item) => item.value);
    setAccount(accountData);
  };

  const formatPayload = (account, accountDetails, handleUpdateTables) => {
    let payload = { account: {} };

    PAYLOAD_KEYS.forEach((key) => {
      if (account[key] !== accountDetails[key]) {
        payload.account[key] = account[key];
      }
    });
    if (account.building_ids !== accountDetails.building_ids) {
      payload.building_ids = account.building_ids;
    }

    const local_account_keys = Object.keys(payload.account);
    const local_payload_keys = Object.keys(payload);
    //special checks
    if (
      local_account_keys.includes('exclude_from_reports') &&
      account.account_type === 'production'
    ) {
      delete payload.exclude_from_reports;
    }
    //table update checks
    if (
      local_account_keys.includes('account_number') ||
      local_payload_keys.includes('building_ids')
    ) {
      handleUpdateTables(0);
    }

    return payload;
  };

  const saveAccount = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      setIsSaving(true);
      const payload = formatPayload(
        account,
        accountDetails,
        handleUpdateTables
      );
      ApiServiceServerless.post(`/utility_accounts/${account.id}`, payload, {
        authorization_id: account.id,
      })
        .then((res) => {
          setValidated(false);
          handleSetAccount(res.data);
          clearAccountData();
          showToast('success_button', 'Success', `Account Updated.`);
        })
        .catch(() => {
          showToast(
            'danger',
            'Alert',
            'An error occurred while saving the Account'
          );
        })
        .finally(() => {
          onHide();
          setIsSaving(false);
        });
    }
    setValidated(true);
  };

  const clearAccountData = () => {
    setAccount(accountDataDefaults);
    setAvailableConsumptionUnits(consumptionUnits['electric']);
  };

  const actionsDisabled = isEqual(accountDetails, account);
  const saveDisabled =
    (account.building_ids && account.building_ids.length === 0) ||
    !account.account_number;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Create a Utility Account</Modal.Header>
      <Form
        noValidate
        validated={validated}
        onSubmit={saveAccount}
        style={formStyle}
      >
        <Modal.Body style={{ maxHeight: '75vh', overflowY: 'auto' }}>
          <Form.Group controlId='accountForm.OrgSelect'>
            <Form.Label>Organization</Form.Label>
            <Form.Control value={organization.name} disabled></Form.Control>
          </Form.Group>
          <Form.Group controlId='accountForm.BuildingSelect'>
            <Form.Label>
              Building(s)
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Control
              as='select'
              multiple
              required
              defaultValue={defaultBuildings}
              onChange={(e) =>
                updateSet(e.target.selectedOptions, 'building_ids')
              }
            >
              {sortBy(buildings, ['name']).map((item) => (
                <option key={`building-opt-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='accountForm.utilityType'>
            <Form.Label>
              Utility type
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Control
              as='select'
              required
              onChange={(e) => updateUtilityType(e)}
              value={account.account_type}
              disabled
            >
              {utilityTypes.map((type) => (
                <option key={`type-opt-${type}`} value={type}>
                  {type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId='accountForm.accountNumber'>
            <Form.Label>
              Account{' '}
              {account.account_type === 'production' ? 'name' : 'number'}
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Control
              placeholder=''
              required
              onChange={updateAccountNumber}
              value={account.account_number}
              maxLength={100}
            />
          </Form.Group>

          <Form.Group controlId='accountForm.referenceID'>
            <Form.Label>Reference ID</Form.Label>
            <Form.Control
              placeholder=''
              onChange={updateReferenceID}
              value={account.reference_id}
            />
          </Form.Group>

          <Form.Group controlId='accountForm.utilityProvider'>
            <Form.Label>Utility provider</Form.Label>
            <Form.Control
              placeholder=''
              onChange={updateUtilityProvider}
              value={account.utility_provider}
            />
          </Form.Group>

          {account.account_type !== 'production' && (
            <Form.Group controlId='accountForm.defaultConsumptionUnit'>
              <Form.Label>
                Default Unit
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                as='select'
                required
                onChange={(e) => updateDefaultConsumptionUnit(e)}
                value={account.default_consumption_unit}
              >
                {availableConsumptionUnits.map((unit) => (
                  <option key={`unit-opt-${unit}`} value={unit}>
                    {unit}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {account.account_type !== 'production' && (
            <Form.Group
              controlId='accountForm.reports'
              style={{ marginBottom: '0px' }}
            >
              <Form.Label>Exclude From Reports?</Form.Label>
              <Form.Check inline className='ml-3 align-text-bottom'>
                <Form.Check.Input
                  style={{ height: '1.1rem', width: '1.1rem' }}
                  checked={account.exclude_from_reports}
                  onChange={updateExcludeFromReport}
                />
              </Form.Check>
            </Form.Group>
          )}
          <EditUtilityAccountDates
            title={'First Bill Date'}
            date={account.first_bill_date}
            dateKey={'first_bill_date'}
            updateAccountDate={updateAccountDate}
          />
          <EditUtilityAccountDates
            title={'Last Bill Date'}
            date={account.last_bill_date}
            dateKey={'last_bill_date'}
            updateAccountDate={updateAccountDate}
          />
        </Modal.Body>
        <Modal.Footer>
          <EditEntityActionButtons
            onSubmit={saveAccount}
            submitDisabled={actionsDisabled || saveDisabled}
            onCancel={onHide}
            isSubmitting={isSaving}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

EditUtilityAccountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  organization: PropTypes.object,
  handleSetAccount: PropTypes.func,
};
