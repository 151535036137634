import React from 'react';
import { Modal } from 'react-bootstrap';

export default function GHGInfoModal(props) {
  const { show, onHide } = props;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Greenhouse Gas Information</Modal.Header>
      <Modal.Body>
        <p>
          {' '}
          Electric greenhouse gas emissions are based on geographic location
          using data provided by the U.S. EPA's{' '}
          <a
            href='https://www.epa.gov/egrid/power-profiler#/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Power Profiler.
          </a>
        </p>
        <br></br>
        <b>Note: </b>Greenhouse gas emissions are calculated using the most{' '}
        <a
          href='https://www.epa.gov/egrid/download-data'
          target='_blank'
          rel='noopener noreferrer'
        >
          {' '}
          recent available data{' '}
        </a>{' '}
        from the U.S. EPA. Emissions may change when newer data becomes
        available.{' '}
      </Modal.Body>
    </Modal>
  );
}
