// storage name / pretty display name

const OrganizationTypes = {
  school: 'Education K-12',
  college: 'College/University',
  library: 'Library System',
  commerical: 'Commerical/ Office',
  healthcare: 'Healthcare',
  healthcare_out: 'Healthcare Outpatient',
  municipality: 'Municipality',
  industrial: 'Industrial',
  other: 'Other',
};

export default OrganizationTypes;
