import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/pro-light-svg-icons';

import { ApiServiceServerless } from '../../../../../xhr_libs';
import EnergyTracerTable from '../../../../tables';
import MONTHSABBREVIATED from '../../../../../helpers/monthsAbbreviated';

import ConfirmationModal, {
  useConfirm,
} from '../../../../modals/ConfirmationModal';
import AddOrganizationRateModal from './AddOrganizationRateModal';
import EditOrganizationRateModal from './EditOrganizationRateModal';
import FilterInput from '../../../../FilterInput';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

const null_rate = {
  id: null,
  utility_type: null,
  value: null,
  unit: null,
  flat_value: 0,
  demand_rate: 0,
};

export default function EditCustomRates(props) {
  const {
    organizationId,
    rates,
    rateCollections,
    addToList,
    updateList,
    removeFromList,
    showToast,
    createAccess,
    updateAccess,
    deleteAccess,
  } = props;

  const [localRates, setLocalRates] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [utilityFilterValue, setUtilityFilterValue] = useState('');

  useEffect(() => {
    setLocalRates(rates);
  }, [rates]);

  useEffect(() => {
    let temp_data = [];

    localRates
      .filter(
        (rate) =>
          rate.utility_type
            .toLowerCase()
            .includes(utilityFilterValue.toLowerCase()) ||
          utilityFilterValue === ''
      )
      .forEach((rate) => {
        let temp_obj = { ...rate };
        temp_obj['collection_names'] = [];
        temp_obj['collection_ids'].forEach((collection_id) => {
          let collection = rateCollections.filter(
            (collection) => collection.id === collection_id
          );
          if (collection.length > 0) {
            temp_obj['collection_names'].push(collection[0].name);
          }
        });
        temp_data.push(temp_obj);
      });
    setTableData(sortBy(temp_data, ['year', 'month']));
  }, [localRates, rateCollections, utilityFilterValue]);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [editFlag, setEditFlag] = useState(0);
  const [editRate, setEditRate] = useState(null_rate);

  const tableColumns = [
    { dataField: 'name', text: 'Name', style: styles.column },
    {
      dataField: 'utility_type',
      text: 'Utility Type',
      style: styles.column,
    },
    { dataField: 'year', text: 'Year', style: styles.column },
    {
      dataField: 'month',
      text: 'Month',
      style: styles.column,
      formatter: (cell, row) => <>{MONTHSABBREVIATED[row.month - 1]}</>,
    },
    {
      dataField: 'value',
      text: 'Rate ($/unit)',
      style: styles.column,
      formatter: (cell) => cell.toLocaleString(),
    },
    { dataField: 'unit', text: 'Unit', style: styles.column },
    {
      dataField: 'flat_value',
      text: 'Flat Rate ($)',
      style: styles.column,
      formatter: (cell) => cell.toLocaleString(),
    },
    {
      dataField: 'demand_rate',
      text: 'Demand Rate ($/kw)',
      style: styles.column,
      formatter: (cell) => cell.toLocaleString(),
    },
    {
      dataField: 'collection_names',
      text: 'Collection List',
      style: styles.column,
      formatter: (cell, row) =>
        cell.length === 0 ? (
          <>No Collections</>
        ) : (
          <>
            {cell.map((name, idx) => (
              <div key={idx}>{name}</div>
            ))}
          </>
        ),
    },
  ];

  const actionColumns = [
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell) => (
        <>
          {updateAccess && (
            <Button
              onClick={() => handleShowEditModal(cell)}
              style={{
                marginRight: '1em',
                maxWidth: '33px',
                maxHeight: '31px',
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                  position: 'relative',
                  left: '-1px',
                }}
              />
            </Button>
          )}
          {deleteAccess && (
            <Button
              className='btn btn-danger'
              style={{ maxWidth: '33px', maxHeight: '31px' }}
              onClick={() => {
                handleRateDelete(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                }}
                icon={faTrashAlt}
              />
            </Button>
          )}
        </>
      ),
    },
  ];

  const handleRateDelete = (rate_id) => {
    const deleteRate = () => {
      ApiServiceServerless.delete(`custom_rates/${rate_id}`, {
        authorization_id: organizationId,
      })
        .then(() => {
          showToast('success', 'Success', 'Rate successfully deleted');
          removeFromList(rate_id, 'rates');
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          throw error;
        });
    };
    withConfirm('Are you sure you want to delete this rate?', deleteRate);
  };

  const handleShowEditModal = (rate_id) => {
    setEditFlag((prev) => prev + 1);
    setEditRate(localRates.find((rate) => rate.id === rate_id));
  };

  useEffect(() => {
    if (editRate.value !== null) {
      setShowEditModal(true);
    }
  }, [editRate, editFlag]);

  return (
    <>
      <Row>
        <FilterInput
          label='Filter Utility'
          setFilterValue={setUtilityFilterValue}
          size={3}
        />
        <Col md={9}>
          <div
            style={{
              marginTop: '1em',
              marginBottom: '1em',
              textAlign: 'right',
            }}
          >
            {createAccess && (
              <Button
                onClick={() => {
                  setShowAddModal(true);
                }}
                style={{ marginBottom: '1em', float: 'right' }}
              >
                Add Rate
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <EnergyTracerTable
        data={tableData}
        columns={[].concat(tableColumns, actionColumns)}
        keyField={'id'}
      />
      <AddOrganizationRateModal
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
        }}
        organizationId={organizationId}
        addToList={addToList}
        updateList={updateList}
        showToast={showToast}
        rateCollections={rateCollections}
      />
      <EditOrganizationRateModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        organizationId={organizationId}
        updateList={updateList}
        showToast={showToast}
        existingRate={editRate}
        rateCollections={rateCollections}
      />

      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
}
