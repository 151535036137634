import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { Checkbox } from 'pretty-checkbox-react';
import dayjs from 'dayjs';

import MONTHSABBREVIATED from '../../helpers/monthsAbbreviated';

const styles = {
  check: {
    fontSize: '1.25em',
    marginBottom: '0.5em',
  },
  checkLabel: {
    fontSize: '1rem',
    position: 'relative',
    top: '-0.25em',
  },
  row: {
    paddingLeft: '2em',
  },
};

export default function RegressionAdvancedDatesModal(props) {
  const {
    show,
    onHide,
    startDate,
    endDate,
    setIgnoreDateKeys,
    setLoadedIgnoreDateKeys,
    loadedIgnoreDateKeys,
    clearRegression,
  } = props;

  const [localSelected, setLocalSelected] = useState([]);
  const [localDates, setLocalDates] = useState([]);

  useEffect(() => {
    let dates = [];
    let selected = [];
    let cur_date = startDate;
    let month = '';
    let year = '';
    let key = '';
    let name = '';

    if (cur_date && endDate) {
      while (dayjs.utc(endDate).diff(dayjs.utc(cur_date)) >= 0) {
        month = cur_date.month();
        year = cur_date.year();
        key = year + '-' + (month + 1);
        name = MONTHSABBREVIATED[month] + ' ' + year;
        if (!loadedIgnoreDateKeys.includes(key)) {
          selected.push(key);
        }
        dates.push({ key: key, name: name });
        cur_date = cur_date.month(cur_date.month() + 1);
      }
    }

    setLocalDates(dates);
    setLocalSelected(selected);
  }, [startDate, endDate, loadedIgnoreDateKeys]);

  const updateLocalSelected = (key) => {
    let temp_selected = [...localSelected];

    const found = temp_selected.indexOf(key);

    if (found !== -1) {
      temp_selected.splice(found, 1);
    } else {
      temp_selected.push(key);
    }
    setLocalSelected(temp_selected);
  };

  const updateDates = () => {
    clearRegression();
    let tempNonSelectedDates = [];
    localDates.forEach((date) => {
      if (!localSelected.includes(date.key)) {
        tempNonSelectedDates.push(date.key);
      }
    });
    setLoadedIgnoreDateKeys(tempNonSelectedDates);
    setIgnoreDateKeys(tempNonSelectedDates);

    onHide();
  };

  const makeDateColumns = (dates) => {
    return dates.map((date) => (
      <Row key={date.key}>
        <Col md={1}>
          <Checkbox
            style={styles.check}
            color={'primary-o'}
            onChange={() => {
              updateLocalSelected(date.key);
            }}
            checked={localSelected.includes(date.key)}
            key={date.key}
          >
            {''}
          </Checkbox>
        </Col>
        <Col md={11}>
          <div style={styles.checkLabel}>{date.name}</div>
        </Col>
      </Row>
    ));
  };

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton style={{ height: '50px' }}>
        Advanced Date Select
      </Modal.Header>
      <Modal.Body>
        {' '}
        <Row style={styles.row}>
          <Col lg={6} xs={12}>
            {makeDateColumns(
              localDates.slice(0, Math.ceil(localDates.length / 2))
            )}
          </Col>
          <Col lg={6} xs={12}>
            {makeDateColumns(
              localDates.slice(
                Math.ceil(localDates.length / 2, localDates.length)
              )
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='float-right ml-3'
          type='button'
          variant='outline-danger'
          onClick={() => onHide()}
        >
          Cancel
        </Button>
        <Button
          className='float-right ml-3'
          onClick={() => updateDates()}
          disabled={localSelected.length === 0}
          title={localSelected.length === 0 ? 'One selection required.' : null}
        >
          {' '}
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
