import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';

import { ApiServiceServerless } from '../../../xhr_libs';
import UserManagement from '../../../components/UserManagement';
import PortfolioSettings from './PortfolioSettings';
import PortfolioOrganizations from './PortfolioOrganizations';
import {
  usePortfolioRoles,
  usePortfolioRolesWeight,
} from '../../../helpers/checkRoles';
import Loader from '../../../components/Loader';

function EditPortfolio(props) {
  const {
    setSelectedPage,
    portfolioInfo,
    portfolio,
    setPortfolio,
    showToast,
    setRefreshFlag,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [activeTab, setActiveTab] = useState('portfolio-settings');

  const updatePortfolioAccess = usePortfolioRoles(
    parseInt(portfolioInfo.id),
    'portfolio',
    'update'
  );
  const deletePortfolioAccess = usePortfolioRoles(
    parseInt(portfolioInfo.id),
    'portfolio',
    'delete'
  );

  const createPortfolioUserAccess = usePortfolioRoles(
    parseInt(portfolioInfo.id),
    'portfolio_user',
    'create'
  );
  const updatePortfolioUserAccess = usePortfolioRoles(
    parseInt(portfolioInfo.id),
    'portfolio_user',
    'update'
  );
  const deletePortfolioUserAccess = usePortfolioRoles(
    parseInt(portfolioInfo.id),
    'portfolio_user',
    'delete'
  );

  const portfolioRoleWeight = usePortfolioRolesWeight(
    parseInt(portfolioInfo.id)
  );

  const getPortfolio = useCallback(
    (id) => {
      setIsLoading(true);
      ApiServiceServerless.get(`/portfolios/${id}`, {
        authorization_id: id,
      })
        .then((res) => {
          setPortfolio(res.data);
        })
        .finally(() => setIsLoading(false));
    },
    [setPortfolio]
  );

  useEffect(() => {
    getPortfolio(portfolioInfo.id);
  }, [portfolioInfo.id, getPortfolio]);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && portfolio.id && (
        <Tabs
          id='portfolio-tabs'
          activeKey={activeTab}
          onSelect={setActiveTab}
          className='et-main-tabs'
        >
          <Tab eventKey='portfolio-settings' title='Portfolio Settings'>
            <Row style={{ marginTop: '16px' }}>
              <Col />
              <Col lg={8} sm={10}>
                <PortfolioSettings
                  setSelectedPage={setSelectedPage}
                  portfolio={portfolio}
                  showToast={showToast}
                  setPortfolio={setPortfolio}
                  setRefreshFlag={setRefreshFlag}
                  updateAccess={updatePortfolioAccess}
                  deleteAccess={deletePortfolioAccess}
                />
              </Col>
              <Col />
            </Row>
          </Tab>
          <Tab eventKey='organizations' title='Organizations'>
            <PortfolioOrganizations
              portfolio={portfolio}
              setPortfolio={setPortfolio}
              setRefreshFlag={setRefreshFlag}
              showToast={showToast}
              isLoading={isLoading}
              deleteAccess={deletePortfolioAccess}
            />
          </Tab>
          <Tab eventKey='user-management' title='User Management'>
            <UserManagement
              entityType={'portfolio'}
              entityId={parseInt(portfolio.id)}
              showToast={showToast}
              createAccess={createPortfolioUserAccess}
              updateAccess={updatePortfolioUserAccess}
              deleteAccess={deletePortfolioUserAccess}
              roleWeight={portfolioRoleWeight}
            />
          </Tab>
        </Tabs>
      )}
    </>
  );
}

export default EditPortfolio;
