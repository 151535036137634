import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { setUserPreference } from '../../helpers/user-preferences';

const styles = {
  label: {
    display: 'flex',
  },
};

function ReportDropdownObject(props) {
  const {
    updatePayload,
    payload_key,
    values,
    defaultValue,
    label,
    set_user_preference,
    user_preference_key,
  } = props;

  const [localValue, setLocalValue] = useState(defaultValue);

  useEffect(() => {
    if (payload_key) {
      updatePayload(payload_key, localValue);
    }
  }, [updatePayload, localValue, payload_key]);

  useEffect(() => {
    if (set_user_preference) {
      setUserPreference(user_preference_key, localValue);
    }
  }, [localValue, set_user_preference, user_preference_key]);

  return (
    <Form.Group controlId={label + '-val-select'}>
      <Form.Label style={styles.label}>{label}</Form.Label>
      <Form.Control
        as='select'
        onChange={(e) => setLocalValue(e.target.value)}
        defaultValue={localValue}
      >
        {Object.keys(values).map((val) => (
          <option key={`val-opt-${val}`} value={val}>
            {values[val]}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}

ReportDropdownObject.defaultProps = {
  set_user_preference: false,
  user_preference_key: '',
};

export default ReportDropdownObject;
