import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const styles = {
  button: {
    position: 'absolute',
    bottom: '1em',
    right: '2em',
  },
  alert: {
    padding: '1em',
    marginTop: '0.5em',
  },
};

export default function NavigationAlert(props) {
  const { mainText, buttonText, location } = props;

  const history = useHistory();

  return (
    <Alert variant='primary' style={styles.alert}>
      {mainText}
      <Button
        style={styles.button}
        variant='success'
        onClick={() => history.push(location)}
      >
        {buttonText}
      </Button>
    </Alert>
  );
}
