import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Button } from 'react-bootstrap';
import { sortBy } from 'lodash';

import AppContext from '../../../contexts/app-context';
import { ApiServiceServerless } from '../../../xhr_libs';
import AddEntityActionButtons from '../../forms/AddEntityActionButtons';
import consumptionUnits from '../../../helpers/consumptionUnits';
import { addAccountTypes } from '../../../helpers/utility-types';

const formStyle = {
  textAlign: 'left',
};

const accountDataDefaults = {
  accountNumber: '',
  utilityType: 'electric',
  defaultConsumptionUnit: 'kwh',
  utilityProvider: '',
  referenceID: '',
  buildingIds: new Set(),
  exclude_from_reports: false,
};

export default function AddUtilityAccountModal(props) {
  const {
    show,
    onHide,
    organization,
    buildings,
    defaultBuildings,
    setDefaultBuildings,
    handleSetAccount,
    buildingInfo,
    setSelectedResource,
    setBuildingInfo,
    setUtilityAccountInfo,
    showToast,
    openAccountModal,
  } = props;
  const { setToasts } = useContext(AppContext);

  const [account, setAccount] = useState(accountDataDefaults);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [availableConsumptionUnits, setAvailableConsumptionUnits] = useState(
    consumptionUnits['electric']
  );

  const navToNewAccount = (account) => {
    setToasts([]);
    if (buildingInfo.id === null) {
      setBuildingInfo({
        id: account.buildings[0].id,
        name: account.buildings[0].name,
      });
    }
    setUtilityAccountInfo({ id: account.id, name: account.account_number });
    setSelectedResource('utilityAccount');
  };

  const addAnotherAccount = (account) => {
    setToasts([]);
    setDefaultBuildings(account.building_ids);
    openAccountModal();
  };

  useEffect(() => {
    if (defaultBuildings && defaultBuildings.length > 0) {
      setAccount((prevAccount) => ({
        ...prevAccount,
        buildingIds: defaultBuildings,
      }));
    }
  }, [defaultBuildings]);

  const updateAccountNumber = (e) => {
    const { value } = e.target;
    setAccount((prevAccount) => ({
      ...prevAccount,
      accountNumber: value,
    }));
  };

  const updateExcludeFromReport = () => {
    setAccount({
      ...account,
      exclude_from_reports: !account.exclude_from_reports,
    });
  };

  const updateUtilityProvider = (e) => {
    const { value } = e.target;
    setAccount((prevAccount) => ({
      ...prevAccount,
      utilityProvider: value,
    }));
  };

  const updateReferenceID = (e) => {
    const { value } = e.target;
    setAccount((prevAccount) => ({
      ...prevAccount,
      referenceID: value,
    }));
  };

  const updateUtilityType = (e) => {
    const value = e.target.value;
    setAccount((prevAccount) => ({
      ...prevAccount,
      utilityType: value,
      defaultConsumptionUnit: consumptionUnits[value][0],
    }));

    setAvailableConsumptionUnits(consumptionUnits[value]);
  };

  const updateDefaultConsumptionUnit = (e) => {
    const value = e.target.value;
    setAccount((prevAccount) => ({
      ...prevAccount,
      defaultConsumptionUnit: value,
    }));
  };

  const updateSet = (values, property) => {
    const accountData = { ...account };
    const arr = Array.prototype.slice.call(values);
    accountData[property] = arr.map((item) => item.value);
    setAccount(accountData);
  };

  const saveAccount = (event) => {
    event.preventDefault();
    setIsLoading(true);
    const form = event.currentTarget;
    if (form.checkValidity()) {
      const payload = {
        building_ids: Array.from(account.buildingIds),
        account: {
          account_number: account.accountNumber,
          utility_provider: account.utilityProvider,
          reference_id: account.referenceID,
          exclude_from_reports:
            account.utilityType === 'production'
              ? true
              : account.exclude_from_reports,
          account_type: account.utilityType,
          default_consumption_unit: account.defaultConsumptionUnit,
        },
      };

      ApiServiceServerless.post('/utility_accounts', payload, {
        authorization_id: organization.id,
      })
        .then((res) => {
          setValidated(false);
          handleSetAccount(res.data);
          clearAccountData();
          showToast(
            'success_button',
            'Success',
            <div>
              {`New Account "${account.accountNumber}" was created.`}
              <br></br>
              <Button
                variant='edit'
                onClick={() => {
                  navToNewAccount(res.data);
                }}
              >
                {'View Account Details'}
              </Button>
              <Button
                variant='edit'
                onClick={() => {
                  addAnotherAccount(res.data);
                }}
              >
                {'Add Another Account'}
              </Button>
            </div>
          );
        })
        .catch((error) => {
          showToast(
            'danger',
            'Alert',
            'An error occurred while saving the Account'
          );
          throw error;
        })
        .finally(() => {
          onHide();
          setValidated(false);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setValidated(true);
    }
  };

  const clearAccountData = () => {
    setAccount(accountDataDefaults);
    setAvailableConsumptionUnits(consumptionUnits['electric']);
  };

  const handleCancel = () => {
    onHide();
    setValidated(false);
    clearAccountData();
  };

  const saveDisabled =
    account.buildingIds.length === 0 || !account.accountNumber;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Create a Utility Account</Modal.Header>

      <Form
        noValidate
        validated={validated}
        onSubmit={saveAccount}
        style={formStyle}
      >
        <Modal.Body>
          <div>
            <Form.Group controlId='accountForm.OrgSelect'>
              <Form.Label>Organization</Form.Label>
              <Form.Control value={organization.name} disabled></Form.Control>
            </Form.Group>
            <Form.Group controlId='accountForm.BuildingSelect'>
              <Form.Label>
                Building(s)
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                as='select'
                multiple
                required
                defaultValue={defaultBuildings}
                onChange={(e) =>
                  updateSet(e.target.selectedOptions, 'buildingIds')
                }
              >
                {sortBy(buildings, ['name']).map((item) => (
                  <option key={`building-opt-${item.id}`} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='accountForm.utilityType'>
              <Form.Label>
                Utility type
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                as='select'
                required
                onChange={(e) => updateUtilityType(e)}
              >
                {addAccountTypes.map((type) => (
                  <option key={`type-opt-${type}`} value={type}>
                    {type.charAt(0).toUpperCase() +
                      type.substr(1).toLowerCase()}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='accountForm.accountNumber'>
              <Form.Label>
                Account{' '}
                {account.utilityType === 'production' ? 'name' : 'number'}
                <span className='text-warning font-weight-bold pl-1'>*</span>
              </Form.Label>
              <Form.Control
                placeholder=''
                required
                onChange={updateAccountNumber}
                maxLength={100}
              />
            </Form.Group>

            <Form.Group controlId='accountForm.referenceID'>
              <Form.Label>Reference ID</Form.Label>
              <Form.Control placeholder='' onChange={updateReferenceID} />
            </Form.Group>

            <Form.Group controlId='accountForm.utilityProvider'>
              <Form.Label>Utility provider</Form.Label>
              <Form.Control placeholder='' onChange={updateUtilityProvider} />
            </Form.Group>

            {account.utilityType !== 'production' && (
              <Form.Group controlId='accountForm.defaultConsumptionUnit'>
                <Form.Label>
                  Default Unit
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <Form.Control
                  as='select'
                  required
                  onChange={(e) => updateDefaultConsumptionUnit(e)}
                  value={account.defaultConsumptionUnit}
                >
                  {availableConsumptionUnits.map((unit) => (
                    <option key={`unit-opt-${unit}`} value={unit}>
                      {unit}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
            {account.utilityType !== 'production' && (
              <Form.Group controlId='accountForm.reports'>
                <Form.Label>Exclude From Reports?</Form.Label>
                <Form.Check inline className='ml-3 align-text-bottom'>
                  <Form.Check.Input
                    style={{ height: '1.1rem', width: '1.1rem' }}
                    checked={account.exclude_from_reports}
                    onChange={updateExcludeFromReport}
                  />
                </Form.Check>
              </Form.Group>
            )}
          </div>

          <AddEntityActionButtons
            submitDisabled={saveDisabled}
            onCancel={handleCancel}
            isSubmitting={isLoading}
          />
        </Modal.Body>
      </Form>
    </Modal>
  );
}

AddUtilityAccountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  organization: PropTypes.object,
  refreshTable: PropTypes.func,
};
