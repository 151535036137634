import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import EditEntityActionButtons from '../forms/EditEntityActionButtons';
import { ApiServiceServerless } from '../../xhr_libs';

export default function AddProjectGHGCollectionModal(props) {
  const {
    type,
    show,
    onHide,
    building_id,
    project_id,
    organization_id,
    GHGCollections,
    updateList,
    showToast,
  } = props;

  const [collectionId, setCollectionId] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const clearForm = () => {
    setCollectionId('');
  };

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();
    ApiServiceServerless.post(
      `/${
        type === 'ghg' ? 'ghg' : 'performance'
      }_projects/project_ghg_collection/${parseInt(
        project_id
      )}/${collectionId}`,
      { building_ids: [building_id] },
      { authorization_id: organization_id }
    )
      .then((res) => {
        updateList(res.data[0], 'ghg_collections');
        onHide();
        clearForm();
        setIsSaving(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        onHide();
        setIsSaving(false);
        throw err;
      });
  };

  const handleCancel = () => {
    onHide();
    clearForm();
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          {'Add Project GHG Collection'}
        </Modal.Header>
        <Form
          noValidate
          onSubmit={handleSubmit}
          style={{
            textAlign: 'left',
          }}
        >
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>GHG Collection </Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) => setCollectionId(parseInt(e.target.value))}
                  >
                    <option key={'null-val'} value={''} hidden></option>
                    {GHGCollections.length === 0 && (
                      <option key={'null-null'} value={''} disabled>
                        No available collections
                      </option>
                    )}
                    {GHGCollections.map((collection) => (
                      <option key={collection.id} value={collection.id}>
                        {collection.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <EditEntityActionButtons
              onCancel={handleCancel}
              submitDisabled={collectionId === ''}
              submitShow={true}
              isSubmitting={isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
