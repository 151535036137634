import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import { ApiServiceServerless, forceAuthRefreshAsync } from '../../../xhr_libs';
import AddEntityActionButtons from '../../../components/forms/AddEntityActionButtons';
import {
  ROLES,
  ROLE_NAMES_UPPER,
  ROLE_WEIGHTS,
} from '../../../helpers/RolesData';

const formStyle = {
  textAlign: 'left',
};

export default function OrganizationPortfolioIdModal(props) {
  const {
    show,
    onHide,
    organizationId,
    setPortfolioOrganizations,
    portfolios,
    showToast,
    roleWeight,
  } = props;

  const [portfolioId, setPortfolioId] = useState('');
  const [role, setRole] = useState('user');

  const addPortfolio = (e) => {
    e.preventDefault();
    ApiServiceServerless.put(
      `/portfolios/portfolio_organizations/id/${organizationId}/${portfolioId}/${role}`,
      {},
      { authorization_id: organizationId }
    )
      .then(async (res) => {
        await forceAuthRefreshAsync();
        setPortfolioOrganizations((prev) => [...prev, res.data]);
        showToast(
          'success',
          'Success',
          `Your Organization was added to ${res.data.name}.`
        );
      })
      .catch((error) => {
        showToast('danger', 'Alert', error);
      })
      .finally(() => {
        onHide();
        setPortfolioId('');
      });
  };

  const handleCancel = () => {
    onHide();
    setPortfolioId('');
  };

  const saveDisabled = !portfolioId;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        Add your Organization to a Portfolio
      </Modal.Header>
      <Form style={formStyle}>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <Form.Group controlId='addPortfolioForm.portfolio'>
                <Form.Label>
                  Portfolio
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <Form.Control
                  as='select'
                  required
                  onChange={(e) => setPortfolioId(e.target.value)}
                >
                  <option key={'portfolio-opt-null'} value={''} hidden>
                    -
                  </option>
                  {portfolios.map((portfolio) => (
                    <option
                      key={`portfolio-opt-${portfolio.id}`}
                      value={portfolio.id}
                    >
                      {portfolio.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Form.Control
                  data-id='port-role'
                  as='select'
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                >
                  {ROLES.map(
                    (role) =>
                      roleWeight >= ROLE_WEIGHTS[role] && (
                        <option key={role} value={role}>
                          {ROLE_NAMES_UPPER[role]}
                        </option>
                      )
                  )}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={handleCancel}
            submitDisabled={saveDisabled}
            onSubmit={addPortfolio}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
