import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { isEqual } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { ApiServiceServerless, forceAuthRefreshAsync } from '../../../xhr_libs';
import AddressWrapper from '../../../helpers/AddressWrapper';
import EditEntityActionButtons from '../../../components/forms/EditEntityActionButtons';
import OrganizationTypes from '../../../helpers/OrganizationTypes';
import CHARTCOLORS from '../../../helpers/chartColors';

const DEFAULT_ORG_VALUES = {
  id: '',
  name: '',
  report_name: '',
  address: {},
  organization_type: '',
  reference_number: '',
  cbecs_survey_type: '',
};

const PAYLOAD_KEYS = [
  'name',
  'report_name',
  'address',
  'organization_type',
  'reference_number',
  'cbecs_survey_type',
];

const styles = {
  form: {
    textAlign: 'left',
    padding: '10px',
    marginTop: '5px',
  },
};

export default function EditOrganizationModal(props) {
  const {
    show,
    onHide,
    organization,
    setOrganization,
    isSaving,
    setIsSaving,
    showToast,
  } = props;

  const [editOrg, setEditOrg] = useState({ ...DEFAULT_ORG_VALUES });

  const [validated, setValidated] = useState(false);
  const [validAddress, setValidAddress] = useState(true);

  useEffect(() => {
    setEditOrg({ ...organization });
  }, [organization, show]);

  const formatPayload = (editOrg, organization) => {
    let payload = {};
    PAYLOAD_KEYS.forEach((key) => {
      if (editOrg[key] !== organization[key]) {
        payload[key] = editOrg[key];
      }
    });

    return payload;
  };

  const onSaveClick = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let payload = formatPayload(editOrg, organization);

    if (validAddress && form.checkValidity()) {
      setIsSaving(true);
      ApiServiceServerless.post(`organizations/${organization.id}`, payload, {
        authorization_id: organization.id,
      })
        .then(async (res) => {
          await forceAuthRefreshAsync();
          setOrganization(res.data);
          showToast('success', 'Success', 'Organization Saved');
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
        })
        .finally(() => {
          onHide();
          setIsSaving(false);
        });
    } else {
      setValidated(true);
    }
  };

  const handleCancel = () => {
    setEditOrg({ ...DEFAULT_ORG_VALUES });
    setValidated(false);
    onHide();
  };

  const onFieldChange = (fieldName, value) => {
    setEditOrg((org) => {
      org[fieldName] = value;
      return { ...org };
    });
  };

  const actionsDisabled = isEqual(organization, editOrg);
  const saveDisabled =
    !validAddress || !editOrg.name || Object.keys(editOrg.address).length === 0;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Edit Organization</Modal.Header>
      <Form
        noValidate
        validated={validated}
        onSubmit={onSaveClick}
        style={styles.form}
      >
        <Form.Group controlId='editOrgForm.name'>
          <Form.Label>
            Name
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Control
            required
            value={editOrg.name || ''}
            onChange={(e) => onFieldChange('name', e.target.value)}
            data-id='org_settings_name'
            maxLength={80}
          />
        </Form.Group>
        <Form.Group controlId='editOrgForm.report_name'>
          <Form.Label>Name for Reports</Form.Label>
          <Form.Control
            value={editOrg.report_name || ''}
            onChange={(e) => onFieldChange('report_name', e.target.value)}
            data-id='org_settings_report_name'
            maxLength={80}
          />
        </Form.Group>
        <AddressWrapper
          address={editOrg.address}
          onAddressSelect={(value) => onFieldChange('address', value)}
          setValidAddress={setValidAddress}
        />
        <Form.Group controlId='editOrg.type'>
          <Form.Label>Type </Form.Label>
          <Form.Control
            as='select'
            onChange={(e) => onFieldChange('organization_type', e.target.value)}
            value={editOrg.organization_type || ''}
          >
            <option key={'select-type'} value={''}></option>
            {Object.keys(OrganizationTypes).map((data, index) => (
              <option key={index} value={data}>
                {OrganizationTypes[data]}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId='editOrg.cbecs'>
          <Form.Label>
            Benchmarking Survey Year{' '}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://www.eia.gov/consumption/commercial/about.php'
            >
              <FontAwesomeIcon
                style={{ fontSize: '18px', color: CHARTCOLORS[1] }}
                icon={faInfoCircle}
              />
            </a>{' '}
          </Form.Label>
          <Form.Control
            as='select'
            onChange={(e) => onFieldChange('cbecs_survey_type', e.target.value)}
            value={editOrg.cbecs_survey_type || ''}
          >
            <option key={0} value={0}>
              {'2012 Survey'}
            </option>
            <option key={1} value={1}>
              {'2018 Survey'}
            </option>
          </Form.Control>
        </Form.Group>
        <EditEntityActionButtons
          submitDisabled={saveDisabled || actionsDisabled}
          onCancel={handleCancel}
          isSubmitting={isSaving}
        />
      </Form>
    </Modal>
  );
}
