import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import {
  DateOptions,
  getReportTypes,
  ReportTypesLookup,
  UtilityTypesLookup,
} from '../ReportHelpers';
import ReportMonthSelect from '../../../components/reports/ReportMonthSelect';
import ReportCheckBoxPicker from '../../../components/reports/ReportCheckboxPicker';
import ReportUnits from '../ReportUnits';
import GHGReportUnitPicker from '../../../components/reports/GHGReportUnitPicker';
import ReportDropdownObject from '../../../components/reports/ReportDropdownObject';

export default function UsageReport(props) {
  const { organizationData, updatePayload, activeGHGLicense } = props;

  const [localReportTypes, setLocalReportTypes] = useState([]);

  return (
    <>
      <Row>
        <ReportMonthSelect
          label='Start Month'
          default_date={organizationData['default_start_date']}
          min_date={organizationData['min_date']}
          max_date={organizationData['max_date']}
          payload_key={'start'}
          updatePayload={updatePayload}
          showPicker={true}
        />
        <ReportMonthSelect
          label='End Month'
          default_date={organizationData['default_end_date']}
          min_date={organizationData['min_date']}
          max_date={organizationData['max_date']}
          payload_key={'end'}
          updatePayload={updatePayload}
          showPicker={true}
        />
      </Row>
      <Row>
        <Col>
          <ReportCheckBoxPicker
            label={'Utility Types'}
            checkboxOptions={organizationData['utility_types']}
            checkboxLookups={UtilityTypesLookup}
            defaults={organizationData['utility_types']}
            requireds={[]}
            payload_key={'utility_types'}
            updatePayload={updatePayload}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ReportCheckBoxPicker
            label={'Report Types'}
            checkboxOptions={getReportTypes(activeGHGLicense)}
            checkboxLookups={ReportTypesLookup}
            defaults={getReportTypes(activeGHGLicense)}
            requireds={[]}
            payload_key={'report_types'}
            updatePayload={updatePayload}
            updateLocal={setLocalReportTypes}
          />
        </Col>
      </Row>
      <ReportDropdownObject
        updatePayload={updatePayload}
        payload_key={'date_option'}
        values={DateOptions}
        defaultValue={1}
        label={'Date Option'}
        key_option={'index'}
      />
      <ReportUnits
        organizationData={organizationData}
        updatePayload={updatePayload}
      />
      {localReportTypes.includes('ghg') && (
        <GHGReportUnitPicker
          payload_key={'ghg_unit'}
          updatePayload={updatePayload}
        />
      )}
    </>
  );
}
