const DefaultBillNames = {
  electric: {
    cost1: 'Generation Cost',
    cost2: 'Distribution Cost',
    cost3: 'Solar Cost',
    consumption1: 'Metered',
    consumption2: 'Solar',
    demand1: 'Metered',
    demand2: 'Billed',
  },
  gas: {
    cost1: 'Commodity Cost',
    cost2: 'Transmission Cost',
    consumption1: 'Usage',
  },
  water: {
    cost1: 'Total Cost',
    consumption1: 'Usage',
  },
  propane: {
    cost1: 'Total Cost',
    consumption1: 'Usage',
  },
  production: {
    consumption1: 'Value',
  },
};

export default DefaultBillNames;
