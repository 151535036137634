import React from 'react';
import { Modal } from 'react-bootstrap';
import { sortBy } from 'lodash';
import EnergyTracerTable from '../tables';
import MONTHSABBREVIATED from '../../helpers/monthsAbbreviated';

const styles = {
  column: {
    paddingTop: '11px',
  },
};

export default function ViewRateModal(props) {
  const { show, onHide, rows } = props;

  const tableColumns = [
    { dataField: 'name', text: 'Name', style: styles.column },
    { dataField: 'utility_type', text: 'Utility Type', style: styles.column },
    { dataField: 'year', text: 'Year', style: styles.column },
    {
      dataField: 'month',
      text: 'Month',
      style: styles.column,
      formatter: (cell, row) => <>{MONTHSABBREVIATED[row.month - 1]}</>,
    },
    {
      dataField: 'value',
      text: 'Rate ($/unit)',
      style: styles.column,
      formatter: (cell) => cell.toLocaleString(),
    },
    { dataField: 'unit', text: 'Unit', style: styles.column },
    {
      dataField: 'flat_value',
      text: 'Flat Rate ($)',
      style: styles.column,
      formatter: (cell) => cell.toLocaleString(),
    },
    {
      dataField: 'demand_rate',
      text: 'Demand Rate ($/kW)',
      style: styles.column,
      formatter: (cell) => cell.toLocaleString(),
    },
  ];

  return (
    <Modal show={show} onHide={onHide} size='lg'>
      <Modal.Header closeButton>View Rate</Modal.Header>
      <Modal.Body>
        <div style={{ padding: '0.5em' }}>
          <EnergyTracerTable
            data={sortBy(rows, ['year', 'month'])}
            columns={tableColumns}
            keyField='id'
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
