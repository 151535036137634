import React from 'react';
import { PropagateLoader } from 'react-spinners';
import CHARTCOLORS from '../helpers/chartColors';

const Loader = (props) => (
  <div className='loader' {...props}>
    <PropagateLoader className='loader' color={CHARTCOLORS[1]} />
  </div>
);

export default Loader;
