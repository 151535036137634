import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Form } from 'react-bootstrap';

import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import { ApiServiceServerless, forceAuthRefresh } from '../../../xhr_libs';
import EnergyTracerTable from '../../../components/tables';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import AppContext from '../../../contexts/app-context';
import { portfolioOrganizationLink } from '../helpers';

const styles = {
  column: {
    padding: '22px 15px 0px 15px',
  },
  actionColumn: {
    padding: '16px 15px 0px 15px',
  },
};

function PortfolioOrganizations(props) {
  const { portfolio, setPortfolio, showToast, deleteAccess, setRefreshFlag } =
    props;

  const { userSelectedPortfolio, setActivePortfolio, setActiveOrganization } =
    useContext(AppContext);

  const history = useHistory();

  const [confirmationModalProps, withConfirm] = useConfirm();

  const organizationColumns = [
    {
      dataField: 'name',
      text: 'Name',
      style: styles.column,
      formatExtraData: {
        userSelectedPortfolio: userSelectedPortfolio,
      },
      formatter: (cell, row, rowIndex, formatExtraData) =>
        portfolioOrganizationLink(
          portfolio,
          row,
          formatExtraData,
          setActiveOrganization,
          setActivePortfolio,
          withConfirm,
          history
        ),
    },
    {
      dataField: 'portfolio_role',
      text: 'Role',
      style: styles.actionColumn,
      formatter: (cell, row) => (
        <Form.Group>
          <Form.Control as='select' value={cell} disabled>
            <option key={'org-role-key-' + row.id} value={cell}>
              {cell}
            </option>
          </Form.Control>
        </Form.Group>
      ),
    },
    deleteAccess
      ? {
          dataField: 'id',
          text: 'Actions',
          style: styles.actionColumn,
          formatter: (cell) => (
            <button
              className='btn btn-danger'
              onClick={() => {
                handleDeletePortfolioOrganization(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '21px',
                  lineHeight: 'inherit',
                  paddingTop: '2px',
                }}
                icon={faTrashAlt}
              />
            </button>
          ),
        }
      : {},
  ];

  const handleDeletePortfolioOrganization = (org_id) => {
    const deletePortfolioOrganization = () => {
      ApiServiceServerless.delete(
        `portfolios/portfolio_organizations/portfolio/${portfolio.id}/${org_id}`,
        { authorization_id: portfolio.id }
      )
        .then((res) => {
          setPortfolio(res.data);
          forceAuthRefresh();
          setRefreshFlag((prev) => prev + 1);
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          throw error;
        });
    };
    withConfirm(
      'Are you sure you want to remove this organization from this portfolio?',
      deletePortfolioOrganization
    );
  };

  return (
    <>
      {portfolio.organizations.length === 0 ? (
        <div>This Portfolio has no Organizations yet.</div>
      ) : (
        <EnergyTracerTable
          data={sortBy(portfolio.organizations, ['name'])}
          columns={organizationColumns}
          keyField='id'
        />
      )}
      <>
        <ConfirmationModal
          {...confirmationModalProps}
          confirmText='Confirm'
          submitVariant={'success'}
        />
      </>
    </>
  );
}

export default PortfolioOrganizations;
