import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

import AddProjectModal from '../../components/modals/AddProjectModal';
import GHGProject from '../../pages/Projects/GHGProject';
import PerformanceProject from '../../pages/Projects/PerformanceProject';
import CloneProjectModal from '../modals/CloneProjectModal';

export default function Projects(props) {
  const [deferredProps, setDeferredProps] = useState({ ...props });
  useEffect(() => {
    if (props.open) setDeferredProps({ ...props });
  }, [props]);
  const {
    organization,
    projects,
    setProjects,
    buildings,
    buildingIds,
    buildingsLookup,
    availableUtilities,
    existingModels,
    rates,
    rateCollections,
    performanceMeasures,
    GHGMeasures,
    GHGConversions,
    GHGCollections,
    addToList,
    updateList,
    showToast,
    createAccessAnalysis,
    updateAccessAnalysis,
    deleteAccessAnalysis,
    createAccessGHG,
    updateAccessGHG,
    deleteAccessGHG,
    activeGHGLicense,
    setDefaultPerformanceProject,
  } = deferredProps;

  const [showAddModal, setShowAddModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);

  const [activeProjectId, setActiveProjectId] = useState(null);
  const [activeProjectType, setActiveProjectType] = useState(null);
  const [activeProject, setActiveProject] = useState(null);

  const resetProject = () => {
    setActiveProject(null);
    setActiveProjectId(null);
    setActiveProjectType(null);
  };

  useEffect(() => {
    resetProject();
  }, [organization]);

  useEffect(() => {
    if (activeProjectId !== null && activeProjectType !== null) {
      let project = projects.find(
        (project) =>
          project.id === activeProjectId &&
          project.project_type === activeProjectType
      );
      setActiveProject(project ? project : null);
    } else {
      setActiveProject(null);
    }
  }, [projects, activeProjectId, activeProjectType]);

  useEffect(() => {
    setDefaultPerformanceProject(
      projects.find(
        (project) =>
          project.default_project === true &&
          project.project_type === 'performance'
      )
    );
  }, [projects, setDefaultPerformanceProject]);

  const removeProject = useCallback(
    (id, project_type) => {
      setProjects(
        projects.filter(
          (project) =>
            !(project.id === id && project.project_type === project_type)
        )
      );
    },
    [projects, setProjects]
  );

  return (
    <>
      <Row>
        <Col style={{ display: 'inline-flex' }}>
          <Row>
            <Form inline style={{ marginBottom: '0.5em' }}>
              <Form.Label
                style={{
                  display: 'block',
                  marginRight: '0.5em',
                  marginLeft: '0.5em',
                }}
              >
                Project:
              </Form.Label>
              <Form.Control
                style={{ float: 'right' }}
                as='select'
                size='sm'
                value={
                  activeProjectId
                    ? activeProjectId + '_' + activeProjectType
                    : ''
                }
                onChange={(e) => {
                  let pid = e.target.value.split('_')[0];
                  let p_type = e.target.value.split('_')[1];
                  setActiveProjectId(parseInt(pid));
                  setActiveProjectType(p_type);
                  setActiveProject(null);
                }}
              >
                <option hidden value={'null'} key={'null-opt'}>
                  Select a project
                </option>
                {projects.length === 0 && (
                  <option disabled value={'dis'} key={'null-dis'}>
                    No Projects
                  </option>
                )}
                {projects.map((project) => (
                  <option
                    key={`project-opt-${project.id}-${project.project_type}`}
                    value={project.id + '_' + project.project_type}
                  >
                    {project.name + ' ('}
                    {project.project_type === 'ghg' ? 'GHG' : 'Performance'}
                    {')'}
                  </option>
                ))}
              </Form.Control>
            </Form>
          </Row>
          <Row style={{ marginLeft: '20px' }}>
            {(createAccessAnalysis || createAccessGHG) && (
              <Button
                onClick={() => {
                  setShowAddModal(true);
                }}
                style={{
                  marginBottom: '1em',
                  marginLeft: '1em',
                }}
              >
                Add Project
              </Button>
            )}
            {(createAccessAnalysis || createAccessGHG) &&
              projects.length > 0 && (
                <Button
                  onClick={() => {
                    setShowCloneModal(true);
                  }}
                  style={{ marginBottom: '1em', marginLeft: '1em' }}
                >
                  Clone Project
                </Button>
              )}
          </Row>
        </Col>
      </Row>
      {activeProject && (
        <h4 style={{ marginTop: '1em' }}>
          {activeProject.name + ' ('}
          {activeProject.project_type === 'ghg' ? 'GHG' : 'Performance'}
          {')'}
        </h4>
      )}

      {activeProjectId !== null &&
        activeProjectType === 'ghg' &&
        activeProject !== null && (
          <GHGProject
            showToast={showToast}
            organization={organization}
            buildings={buildings}
            buildingIds={buildingIds}
            buildingsLookup={buildingsLookup}
            measures={GHGMeasures}
            GHGConversions={GHGConversions}
            GHGCollections={GHGCollections}
            addToList={addToList}
            updateList={updateList}
            project={activeProject}
            setProjects={setProjects}
            resetProject={() => resetProject()}
            removeProject={removeProject}
            updateAccessGHG={updateAccessGHG}
            deleteAccessGHG={deleteAccessGHG}
          />
        )}
      {activeProjectId !== null &&
        activeProjectType === 'performance' &&
        activeProject !== null && (
          <PerformanceProject
            showToast={showToast}
            organization={organization}
            buildings={buildings}
            buildingIds={buildingIds}
            buildingsLookup={buildingsLookup}
            availableUtilities={availableUtilities}
            existingModels={existingModels}
            rates={rates}
            rateCollections={rateCollections}
            measures={performanceMeasures}
            GHGConversions={GHGConversions}
            GHGCollections={GHGCollections}
            addToList={addToList}
            updateList={updateList}
            project={activeProject}
            setProjects={setProjects}
            resetProject={() => resetProject()}
            removeProject={removeProject}
            activeGHGLicense={activeGHGLicense}
            updateAccessAnalysis={updateAccessAnalysis}
            deleteAccessAnalysis={deleteAccessAnalysis}
          />
        )}
      <AddProjectModal
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
        }}
        showToast={showToast}
        organization_id={organization.id}
        setActiveProject={setActiveProject}
        setActiveProjectId={setActiveProjectId}
        setActiveProjectType={setActiveProjectType}
        setProjects={setProjects}
        createAccessAnalysis={createAccessAnalysis}
        createAccessGHG={createAccessGHG}
      />
      {projects.length > 0 && (
        <CloneProjectModal
          show={showCloneModal}
          onHide={() => {
            setShowCloneModal(false);
          }}
          showToast={showToast}
          organization_id={organization.id}
          projects={projects}
          updateList={updateList}
          setActiveProject={setActiveProject}
          setActiveProjectId={setActiveProjectId}
          setActiveProjectType={setActiveProjectType}
          setProjects={setProjects}
          createAccessAnalysis={createAccessAnalysis}
          createAccessGHG={createAccessGHG}
        />
      )}
    </>
  );
}
