import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faEdit } from '@fortawesome/pro-light-svg-icons';

import { ApiServiceServerless } from '../../../../xhr_libs';

import EnergyTracerTable from '../../../tables';
import AddOperationalSavingsMeasureModal from '../../../modals/OperationalSavingsModals/AddOperationalSavingsMeasureModal';
import ConfirmationModal, {
  useConfirm,
} from '../../../modals/ConfirmationModal';
import EditOperationalSavingsMeasureModal from '../../../modals/OperationalSavingsModals/EditOperationalSavingsMeasureModal';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

export default function OperationalSavings(props) {
  const {
    organizationId,
    projects,
    performanceMeasures,
    addToList,
    updateList,
    removeFromList,
    showToast,
    createAccess,
    updateAccess,
    deleteAccess,
  } = props;

  const [localMeasures, setLocalMeasures] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setLocalMeasures(performanceMeasures);
  }, [performanceMeasures]);

  useEffect(() => {
    let temp_data = [];
    localMeasures.forEach((measure) => {
      let temp_obj = { ...measure };
      temp_obj['project_names'] = [];
      temp_obj['project_ids'].forEach((project_id) => {
        let project = projects.filter(
          (project) =>
            project.id === parseInt(project_id) &&
            project.project_type === 'performance'
        );
        if (project.length > 0) {
          temp_obj['project_names'].push(project[0].name);
        }
      });
      temp_data.push(temp_obj);
    });
    setTableData(temp_data);
  }, [localMeasures, projects]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [confirmationModalProps, withConfirm] = useConfirm();

  const null_measure = {
    measure: null,
    value: null,
    years: null,
  };
  const [editFlag, setEditFlag] = useState(0);
  const [editMeasure, setEditMeasure] = useState(null_measure);

  const tableColumns = [
    { dataField: 'measure', text: 'Measure', style: styles.column },
    {
      dataField: 'value',
      text: '$/Year',
      style: styles.column,
      formatter: (cell) =>
        cell.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
    { dataField: 'years', text: 'Duration (yrs)', style: styles.column },
    {
      dataField: 'project_names',
      text: 'Project List',
      style: styles.column,
      formatter: (cell, row) =>
        cell.length === 0 ? (
          <>No Projects</>
        ) : (
          <>
            {cell.map((name, idx) => (
              <div key={idx}>{name}</div>
            ))}
          </>
        ),
    },
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell) => (
        <>
          {updateAccess && (
            <Button
              onClick={() => handleShowEditModal(cell)}
              style={{
                marginRight: '1em',
                maxWidth: '33px',
                maxHeight: '31px',
              }}
            >
              <FontAwesomeIcon
                icon={faEdit}
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                  position: 'relative',
                  left: '-1px',
                }}
              />
            </Button>
          )}
          {deleteAccess && (
            <Button
              className='btn btn-danger'
              style={{ maxWidth: '33px', maxHeight: '31px' }}
              onClick={() => {
                handleMeasureDelete(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                }}
                icon={faTrashAlt}
              />
            </Button>
          )}
        </>
      ),
    },
  ];

  const handleMeasureDelete = (measure_id) => {
    const deleteMeasure = () => {
      ApiServiceServerless.delete(`operational_savings/${measure_id}`, {
        authorization_id: organizationId,
      })
        .then(() => {
          showToast('success', 'Success', 'Measure successfully deleted');
          removeFromList(measure_id, 'performance_measures');
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          throw error;
        });
    };
    withConfirm('Are you sure you want to delete this measure?', deleteMeasure);
  };

  const handleShowEditModal = (measure_id) => {
    setEditFlag((prev) => prev + 1);
    setEditMeasure(localMeasures.find((measure) => measure.id === measure_id));
  };

  useEffect(() => {
    if (editMeasure.measure !== null) {
      setShowEditModal(true);
    }
  }, [editMeasure, editFlag]);

  return (
    <>
      {createAccess && (
        <Button
          onClick={() => {
            setShowAddModal(true);
          }}
          style={{ marginBottom: '1em', float: 'right' }}
        >
          Add Measure
        </Button>
      )}
      <EnergyTracerTable
        data={tableData}
        columns={tableColumns}
        keyField={'id'}
      />
      <AddOperationalSavingsMeasureModal
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
        }}
        organizationId={organizationId}
        projects={projects}
        addToList={addToList}
        showToast={showToast}
      />
      <EditOperationalSavingsMeasureModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        organizationId={organizationId}
        projects={projects}
        updateList={updateList}
        showToast={showToast}
        existingMeasure={editMeasure}
      />
      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
}
