import React, { useState } from 'react';
import { Row, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons';

import REGRESSORS from './regressors';
import RegressionOptionsModal from '../modals/RegressionOptionsModal';
import SmallerLoader from '../SmallerLoader';

const styles = {
  regressionPieceLabel: {
    marginLeft: '20px',
  },
  regressionPieceRow: {
    marginBottom: '16px',
  },
  rowId: {
    marginRight: '10px',
    marginTop: '4px',
  },
  regressionPieceInputStyle: {
    width: '200px',
    marginRight: '20px',
  },
  regressionPieceInputStyle2: {
    width: '200px',
    marginRight: '12px',
  },
  deleteButton: {
    padding: '0px',
    height: '30px',
    width: '30px',
    borderWidth: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    fontSize: '26px',
  },
  regressionPieceButtonRow: {
    justifyContent: 'center',
    marginLeft: '70px',
  },
  regressionPieceButton: {
    width: '300px',
    marginTop: '10px',
  },
};

const MODELS = {
  linear: 'Linear',
  '3pc': '3PC',
  '3ph': '3PH',
  '5p': '5P',
  //x2: 'Quadratic',
};

function RegressionPieces(props) {
  const {
    regressionPieces,
    disabled,
    onChange,
    onDelete,
    onAdd,
    runRegression,
    disableRun,
    filterRegressionPieces,
    regressionLoading,
    showToast,
    availableRegressors,
    productionNames,
    defaultRegressionOptions,
    loadedRegressionOptions,
    setRegressionOptions,
  } = props;

  const [showOptionsModal, setShowOptionsModal] = useState(false);

  const checkForDuplicates = () => {
    const regressionPiecesFiltered = filterRegressionPieces();
    const regressionPieceSet = new Set(
      regressionPiecesFiltered.map((piece) => {
        return piece.regressor.concat(piece.model);
      })
    );
    const duplicatesExist =
      regressionPieceSet.size !== regressionPiecesFiltered.length;
    if (duplicatesExist) {
      showToast('danger', 'Error', 'Cannot have duplicate regression pieces.');
    }
    return duplicatesExist;
  };

  const handleRunRegressionClick = () => {
    if (!checkForDuplicates()) {
      runRegression();
    }
  };

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Form.Label style={styles.regressionPieceLabel}>Regressor</Form.Label>
        <Form.Label
          style={{ ...styles.regressionPieceLabel, marginLeft: '146px' }}
        >
          Model
        </Form.Label>
      </Row>
      {regressionPieces.map((regressionPiece, index) => (
        <Row
          key={`regressionPiece-${regressionPiece.id}`}
          style={styles.regressionPieceRow}
        >
          <span style={styles.rowId}>{index + 1}</span>
          <Form.Control
            style={styles.regressionPieceInputStyle}
            as='select'
            size='sm'
            onChange={(e) =>
              onChange(regressionPiece.id, 'regressor', e.target.value)
            }
            value={regressionPiece.regressor}
            disabled={disabled}
          >
            <option key={'regressor-opt-null'} value={''} hidden>
              -
            </option>
            {availableRegressors.map((regressor) => (
              <option key={`regressor-opt-${regressor}`} value={regressor}>
                {regressor in REGRESSORS
                  ? REGRESSORS[regressor]
                  : productionNames && regressor in productionNames
                  ? productionNames[regressor]
                  : ''}
              </option>
            ))}
          </Form.Control>
          <Form.Control
            style={styles.regressionPieceInputStyle2}
            as='select'
            size='sm'
            onChange={(e) =>
              onChange(regressionPiece.id, 'model', e.target.value)
            }
            value={regressionPiece.model}
            disabled={disabled}
          >
            <option key={'model-opt-null'} value={''} hidden>
              -
            </option>
            {Object.keys(MODELS).map((model) => (
              <option key={`model-opt-${model}`} value={model}>
                {MODELS[model]}
              </option>
            ))}
          </Form.Control>
          <Button variant='outline-danger' style={styles.deleteButton}>
            <FontAwesomeIcon
              style={{ fontSize: '21px', lineHeight: 'inherit' }}
              icon={faMinusCircle}
              onClick={() => onDelete(regressionPiece.id)}
            />
          </Button>
        </Row>
      ))}

      <Row style={styles.regressionPieceButtonRow}>
        <Button
          variant='outline-primary'
          style={styles.regressionPieceButton}
          onClick={onAdd}
          disabled={
            regressionPieces.length >= 4 || regressionLoading || disabled
          }
        >
          Add Model Piece
        </Button>
      </Row>
      <Row style={styles.regressionPieceButtonRow}>
        <Button
          variant='outline-primary'
          style={styles.regressionPieceButton}
          onClick={() => {
            setShowOptionsModal(true);
          }}
          disabled={disabled || regressionLoading}
        >
          Regression Options
        </Button>
      </Row>
      <Row style={styles.regressionPieceButtonRow}>
        <Button
          variant='primary'
          style={styles.regressionPieceButton}
          onClick={handleRunRegressionClick}
          disabled={disabled || disableRun() || regressionLoading}
        >
          {!regressionLoading && 'Run Regression Analysis'}
          {regressionLoading && (
            <SmallerLoader text={'Running Regression Analysis'} />
          )}
        </Button>
      </Row>
      <RegressionOptionsModal
        show={showOptionsModal}
        onHide={() => setShowOptionsModal(false)}
        defaultRegressionOptions={defaultRegressionOptions}
        loadedRegressionOptions={loadedRegressionOptions}
        setRegressionOptions={setRegressionOptions}
        regressionPieces={regressionPieces}
      />
    </>
  );
}

export default RegressionPieces;
