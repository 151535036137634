import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import EditEntityActionButtons from '../forms/EditEntityActionButtons';
import { ApiServiceServerless } from '../../xhr_libs';

export default function AddProjectRegressionModal(props) {
  const {
    show,
    onHide,
    building_id,
    utility,
    project_id,
    organization_id,
    existingModels,
    updateList,
    showToast,
  } = props;

  const [regressionId, setRegressionId] = useState('');

  const [isSaving, setIsSaving] = useState(false);

  const clearForm = () => {
    setRegressionId('');
  };

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();
    ApiServiceServerless.post(
      `/performance_projects/project_regression/${parseInt(
        project_id
      )}/${regressionId}`,
      {
        building_id: building_id,
        utility_type: utility,
      },
      { authorization_id: organization_id }
    )
      .then((res) => {
        updateList(res.data, 'models');
        onHide();
        clearForm();
        setIsSaving(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        onHide();
        setIsSaving(false);
        throw err;
      });
  };

  const handleCancel = () => {
    onHide();
    clearForm();
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          {'Add Project Regression'}
        </Modal.Header>
        <Form
          noValidate
          onSubmit={handleSubmit}
          style={{
            textAlign: 'left',
          }}
        >
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group controlId='newOrg.type'>
                  <Form.Label>Model </Form.Label>
                  <Form.Control
                    as='select'
                    onChange={(e) => setRegressionId(parseInt(e.target.value))}
                  >
                    <option key={'null-val'} value={''} hidden></option>
                    {existingModels.filter(
                      (model) =>
                        model.building_id === parseInt(building_id) &&
                        model.utility_type === utility &&
                        model.regression_type === 'consumption'
                    ).length === 0 && (
                      <option key={'null-null'} value={''} disabled>
                        No available regressions
                      </option>
                    )}
                    {existingModels
                      .filter(
                        (model) =>
                          model.building_id === parseInt(building_id) &&
                          model.utility_type === utility &&
                          model.regression_type === 'consumption'
                      )
                      .map((model) => (
                        <option key={model.id} value={model.id}>
                          {model.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <EditEntityActionButtons
              onCancel={handleCancel}
              submitDisabled={regressionId === ''}
              submitShow={true}
              isSubmitting={isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
