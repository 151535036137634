import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';
import AddressWrapper from '../../helpers/AddressWrapper';

const formStyle = {
  textAlign: 'left',
};

const contactDataDefaults = {
  name: '',
  email: '',
  title: '',
  address: {},
  phone: '',
  admin: false,
  billing: false,
  facility: false,
  reports: false,
};

export default function OrganizationContactModal(props) {
  const { show, onHide, setOrganization, showToast, organizationId } = props;

  const [contactData, setContactData] = useState(contactDataDefaults);
  const [validated, setValidated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const saveContact = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      ApiServiceServerless.post(
        `/organizations/${organizationId}/contacts`,
        {
          organization_id: organizationId,
          name: contactData.name,
          email: contactData.email,
          title: contactData.title,
          address: contactData.address,
          phone: contactData.phone,
          admin: contactData.admin,
          billing: contactData.billing,
          facility: contactData.facility,
          reports: contactData.reports,
        },
        { authorization_id: organizationId }
      )
        .then((res) => {
          setOrganization((prev) => ({
            ...prev,
            contacts: [...prev.contacts, res.data],
          }));
          setValidated(false);
          setContactData(contactDataDefaults);
          showToast('success', 'Success', `New Contact was Added.`);
        })
        .catch(() => {
          showToast('danger', 'Alert', 'Contact Creation Error');
          setValidated(false);
        })
        .finally(() => {
          onHide();
          setIsLoading(false);
        });
    } else {
      setValidated(true);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    onHide();
    setValidated(false);
    setContactData(contactDataDefaults);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Add Contact </Modal.Header>
      <Form
        noValidate
        validated={validated}
        onSubmit={saveContact}
        style={formStyle}
      >
        <Modal.Body>
          <Form.Group controlId='newContactForm.contactName'>
            <Form.Label>
              Contact Name{' '}
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Control
              required
              onChange={(e) =>
                setContactData({
                  ...contactData,
                  name: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group controlId='newContactForm.contactTitle'>
            <Form.Label>Contact Title</Form.Label>
            <Form.Control
              onChange={(e) =>
                setContactData({
                  ...contactData,
                  title: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group controlId='newContactForm.contactEmail'>
            <Form.Label>
              Contact email{' '}
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Control
              required
              type='email'
              placeholder='email@example.com'
              onChange={(e) =>
                setContactData({
                  ...contactData,
                  email: e.target.value,
                })
              }
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId='newContactForm.contactPhone'>
            <Form.Label>Contact Phone Number</Form.Label>
            <Form.Control
              type='phone'
              onChange={(e) =>
                setContactData({
                  ...contactData,
                  phone: e.target.value,
                })
              }
            />
          </Form.Group>
          <AddressWrapper
            notRequired={true}
            address={contactData.address}
            onAddressSelect={(value) =>
              setContactData({
                ...contactData,
                address: value,
              })
            }
          />
          <Form.Group controlId='newContactForm.admin'>
            <Form.Label>Administrative Contact</Form.Label>
            <Form.Check inline className='ml-3 align-text-bottom'>
              <Form.Check.Input
                style={{ height: '1.1rem', width: '1.1rem' }}
                onChange={(e) =>
                  setContactData({
                    ...contactData,
                    admin: e.target.checked,
                  })
                }
              />
            </Form.Check>
          </Form.Group>
          <Form.Group controlId='newContactForm.billing'>
            <Form.Label>Billing Contact</Form.Label>
            <Form.Check inline className='ml-3 align-text-bottom'>
              <Form.Check.Input
                style={{ height: '1.1rem', width: '1.1rem' }}
                onChange={(e) =>
                  setContactData({
                    ...contactData,
                    billing: e.target.checked,
                  })
                }
              />
            </Form.Check>
          </Form.Group>
          <Form.Group controlId='newContactForm.facility'>
            <Form.Label>Facility Contact</Form.Label>
            <Form.Check inline className='ml-3 align-text-bottom'>
              <Form.Check.Input
                style={{ height: '1.1rem', width: '1.1rem' }}
                onChange={(e) =>
                  setContactData({
                    ...contactData,
                    facility: e.target.checked,
                  })
                }
              />
            </Form.Check>
          </Form.Group>
          <Form.Group controlId='newContactForm.reports'>
            <Form.Label>Send Reports</Form.Label>
            <Form.Check inline className='ml-3 align-text-bottom'>
              <Form.Check.Input
                style={{ height: '1.1rem', width: '1.1rem' }}
                onChange={(e) =>
                  setContactData({
                    ...contactData,
                    reports: e.target.checked,
                  })
                }
              />
            </Form.Check>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={handleCancel}
            submitDisabled={contactData.name === '' || contactData.email === ''}
            isSubmitting={isLoading}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
