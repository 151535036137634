import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { get } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons';

import InviteUserModal from '../modals/InviteUserModal';
import { ApiServiceServerless } from '../../xhr_libs';
import Loader from '../Loader';
import UserTable from './UserTable';
import ConfirmationModal, { useConfirm } from '../modals/ConfirmationModal';

const ENTITY_LOOKUP = {
  organization: {
    url: 'organizations',
    roles: 'organization_roles',
    id: 'organization_id',
  },
  portfolio: {
    url: 'portfolios',
    roles: 'portfolio_roles',
    id: 'portfolio_id',
  },
};

const DELETE_CONFIRM_TEXT = 'Are you sure you want to delete this user?';

const styles = {
  iconStyle: {
    fontSize: '21px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
};

function UserManagement(props) {
  const {
    entityType,
    entityId,
    showToast,
    createAccess,
    updateAccess,
    deleteAccess,
    roleWeight,
  } = props;

  const [users, setUsers] = useState([]);

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddingUser, setIsAddingUser] = useState(false);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const getUserRole = useCallback(
    (entityId, roles) =>
      roles.find(
        (role) => get(role, ENTITY_LOOKUP[entityType].id) === parseInt(entityId)
      )['role'],
    [entityType]
  );

  const getUserToken = useCallback(
    (entityId, roles) =>
      roles.find(
        (role) => get(role, ENTITY_LOOKUP[entityType].id) === parseInt(entityId)
      )['uuid'],
    [entityType]
  );

  const formatUser = useCallback(
    (user, entityId, entityType) => {
      return {
        id: user.id,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        role: getUserRole(entityId, get(user, ENTITY_LOOKUP[entityType].roles)),
        token:
          entityType === 'organization'
            ? getUserToken(entityId, get(user, ENTITY_LOOKUP[entityType].roles))
            : null,
      };
    },
    [getUserRole, getUserToken]
  );

  const getUsers = useCallback(() => {
    if (entityId) {
      ApiServiceServerless.get(
        `/${ENTITY_LOOKUP[entityType].url}/${entityId}/users`,
        {
          authorization_id: entityId,
        }
      )
        .then((res) => {
          setUsers(
            res.data.map((user) => formatUser(user, entityId, entityType))
          );
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          throw error;
        })
        .finally(() => setIsLoading(false));
    }
  }, [entityId, entityType, showToast, formatUser]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const handleDeleteUser = (userId) => {
    const deleteUser = () => {
      ApiServiceServerless.delete(
        `/${ENTITY_LOOKUP[entityType].url}/${entityId}/users/${userId}`,
        { authorization_id: entityId }
      )
        .then(() => {
          setUsers((prev) => prev.filter((user) => user.id !== userId));
          showToast('success', 'Success', 'User Deleted');
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
          throw error;
        });
    };
    withConfirm(DELETE_CONFIRM_TEXT, deleteUser);
  };

  const handleUpdateUser = (data, userId, entityId, entityType) => {
    setUsers((prev) =>
      prev.map((user) => {
        if (user.id !== userId) return user;
        return formatUser(data, entityId, entityType);
      })
    );
  };

  const handleResetUser = (oldRole, userId) => {
    setUsers((prev) =>
      prev.map((user) => {
        if (user.id !== userId) return user;
        return { ...user, role: oldRole };
      })
    );
  };

  const saveUserRoles = (userId, userRole, oldRole) => {
    ApiServiceServerless.post(
      `/${ENTITY_LOOKUP[entityType].url}/${entityId}/users/${userId}`,
      {
        role: userRole,
      },
      { authorization_id: entityId }
    )
      .then((res) => {
        handleUpdateUser(res.data, userId, entityId, entityType);
        showToast('success', 'Success', 'Updated User Role');
      })
      .catch((error) => {
        handleResetUser(oldRole, userId);
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  const addUserToken = (userId) => {
    ApiServiceServerless.post(
      `/organizations/${entityId}/users/${userId}/token`,
      {},
      { authorization_id: entityId }
    )
      .then((res) => {
        handleUpdateUser(res.data, userId, entityId, 'organization');
        showToast('success', 'Success', 'Token Added');
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  const deleteUserToken = (userId) => {
    ApiServiceServerless.delete(
      `/organizations/${entityId}/users/${userId}/token`,
      { authorization_id: entityId }
    )
      .then((res) => {
        handleUpdateUser(res.data, userId, entityId, 'organization');
        showToast('success', 'Success', 'Token Removed');
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  const handleInvite = (data) => {
    ApiServiceServerless.post(
      `/${ENTITY_LOOKUP[entityType].url}/${entityId}/users`,
      data,
      { authorization_id: entityId }
    )
      .then((res) => {
        setUsers((prev) => [
          ...prev,
          formatUser(res.data, entityId, entityType),
        ]);
        showToast(
          'success',
          'Success',
          'An invitation has been sent to the user'
        );
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setShowInviteModal(false);
        setIsAddingUser(false);
      });
  };

  return (
    <Card.Body style={{ minHeight: '55px' }}>
      <Row>
        <Col xs={12}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {createAccess && (
                <div
                  style={{
                    textAlign: 'right',
                    marginBottom: '1em',
                  }}
                >
                  <FontAwesomeIcon
                    style={styles.iconStyle}
                    icon={faUserPlus}
                    title={'Invite User'}
                    onClick={() => setShowInviteModal(true)}
                  />
                </div>
              )}
              <UserTable
                entityType={entityType}
                users={users}
                deleteUser={handleDeleteUser}
                saveUserRoles={saveUserRoles}
                setUsers={setUsers}
                addUserToken={addUserToken}
                deleteUserToken={deleteUserToken}
                updateAccess={updateAccess}
                deleteAccess={deleteAccess}
                roleWeight={roleWeight}
              />
            </>
          )}
        </Col>
      </Row>
      <ConfirmationModal {...confirmationModalProps} />
      <InviteUserModal
        show={showInviteModal}
        onSubmit={handleInvite}
        onHide={() => setShowInviteModal(false)}
        roleWeight={roleWeight}
        isAddingUser={isAddingUser}
        setIsAddingUser={setIsAddingUser}
      />
    </Card.Body>
  );
}

UserManagement.propTypes = {
  entityType: PropTypes.oneOf(['organization', 'portfolio']).isRequired,
  entityId: PropTypes.number,
  showToast: PropTypes.func.isRequired,
};

export default UserManagement;
