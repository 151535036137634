import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { setAuthToken } from '../auth';
import Loader from '../components/Loader';

class SetAuth extends React.Component {
  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');
    const claims = urlParams.get('user_claims');

    if (accessToken && claims) {
      setAuthToken({
        data: { access_token: accessToken, user_claims: JSON.parse(claims) },
      });
      this.props.history.push('/');
    }

    return <Loader />;
  }
}

SetAuth.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SetAuth);
