import React from 'react';
import NumberFormat from 'react-number-format';
import PopoverTrigger from '../../../components/PopoverTrigger';

export default function BillNumberInput(props) {
  const defaultDecimalScale = 2;
  const { old_value } = props;

  return (
    <>
      {old_value ? (
        <PopoverTrigger
          id='popover_cont'
          rootClose={true}
          popoverTitle={'Last Year'}
          popoverContent={old_value.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}
          trigger='click'
          placement='left'
        >
          <NumberFormat
            {...props}
            thousandSeparator
            isNumericString
            decimalScale={
              props.decimalScale ? props.decimalScale : defaultDecimalScale
            }
            fixedDecimalScale
            placeholder={'0.00'}
            autoComplete='off'
          />
        </PopoverTrigger>
      ) : (
        <NumberFormat
          {...props}
          thousandSeparator
          isNumericString
          decimalScale={
            props.decimalScale ? props.decimalScale : defaultDecimalScale
          }
          fixedDecimalScale
          placeholder={'0.00'}
          autoComplete='off'
        />
      )}
    </>
  );
}
