import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = {
  card: {
    height: '100%',
    minHeight: '10em',
  },
  row: {
    flexGrow: 1,
    margin: '1rem 1.25rem 0rem 1.25em',
    borderBottom: 'solid 5px var(--et_blue)',
    height: '100%',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '1em',
  },
  text1: {
    fontSize: '2.25em',
  },
  text2: {
    fontSize: '1.15em',
    width: '95%',
  },
  iconCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '3em',
    alignItems: 'flex-end',
  },
};

const formatGHGValue = (val) => {
  if (val > 1e8) {
    return (
      (val / 1e8).toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }) + ' B'
    );
  } else if (val > 1e6) {
    return (
      (val / 1e6).toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }) + ' M'
    );
  } else {
    return val.toLocaleString('en-US', {
      maximumFractionDigits: 2,
    });
  }
};

const WidgetInfo = ({ title, value, icon, iconColor }) => (
  <Row style={styles.row}>
    <Col lg={10} md={12} style={styles.col}>
      <Row style={styles.text1}>{value ? formatGHGValue(value) : 0}</Row>
      <Row style={styles.text2}>{title}</Row>
    </Col>
    <Col lg={2} md={0} style={styles.iconCol}>
      {icon && <FontAwesomeIcon style={{ color: iconColor }} icon={icon} />}
    </Col>
  </Row>
);

export default WidgetInfo;
