import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import AddEntityActionButtons from '../forms/AddEntityActionButtons';

export default function CloneProjectModal(props) {
  const {
    show,
    onHide,
    organization_id,
    projects,
    updateList,
    showToast,
    setActiveProject,
    setActiveProjectId,
    setActiveProjectType,
    setProjects,
    createAccessAnalysis,
    createAccessGHG,
  } = props;

  const [localProjects, setLocalProjects] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [cloneProjectId, setCloneProjectId] = useState('');
  const [cloneProjectType, setCloneProjectType] = useState('');

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let temp_projects = [];
    projects.forEach((project) => {
      if (project.project_type === 'ghg' && createAccessGHG) {
        temp_projects.push(project);
      }
      if (project.project_type === 'performance' && createAccessAnalysis) {
        temp_projects.push(project);
      }
    });
    setLocalProjects(temp_projects);
  }, [projects, createAccessGHG, createAccessAnalysis]);

  useEffect(() => {
    if (projectName && cloneProjectId && cloneProjectType) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [projectName, cloneProjectId, cloneProjectType]);

  const cloneProject = (e) => {
    e.preventDefault();
    setIsLoading(true);
    ApiServiceServerless.post(
      `/${cloneProjectType}_projects/clone/${cloneProjectId}`,
      {
        name: projectName,
        organization_id: organization_id,
      },
      { authorization_id: organization_id }
    )
      .then((res) => {
        let project = res.data[0];
        setProjects((prev) => [...prev, project]);
        setActiveProject(null);
        setActiveProjectId(project.id);
        setActiveProjectType(project.project_type);
        if (cloneProjectType === 'ghg') {
          res.data[1].forEach((data) => {
            updateList(data, 'ghg_measures');
          });
          res.data[2].forEach((data) => {
            updateList(data, 'ghg_collections');
          });
        } else {
          res.data[1].forEach((data) => {
            updateList(data, 'models');
          });
          res.data[2].forEach((data) => {
            updateList(data, 'rate_collections');
          });
          res.data[3].forEach((data) => {
            updateList(data, 'performance_measures');
          });
          res.data[4].forEach((data) => {
            updateList(data, 'ghg_collections');
          });
        }
        onHide();
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Clone Project</Modal.Header>
      <Form onSubmit={cloneProject}>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              {'Project Name'}
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Row>
              <Form.Control
                required
                placeholder=''
                onChange={(e) => setProjectName(e.target.value)}
              />
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              Project To Clone
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>

            <Form.Control
              as='select'
              required
              onChange={(e) => {
                setCloneProjectId(e.target.value.split('-')[0]);
                setCloneProjectType(e.target.value.split('-')[1]);
              }}
            >
              <option key={'opt-null'} value={''} hidden>
                -
              </option>
              {localProjects.map((project) => (
                <option
                  key={'opt-' + project.id + '-' + project.project_type}
                  value={project.id + '-' + project.project_type}
                >
                  {project.name + ' (' + project.project_type + ')'}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <AddEntityActionButtons
            onCancel={onHide}
            submitDisabled={disabled}
            isSubmitting={isLoading}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
