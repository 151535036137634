import React, { useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import {
  getUserOrganizationPreference,
  setUserOrganizationPreference,
} from '../helpers/user-preferences';

const styles = {
  visible: {
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 0.5s linear',
  },
  hidden: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s 0.5s, opacity 0.5s linear',
  },
  cancelStyle: {
    position: 'absolute',
    right: '25px',
    top: '33px',
    fontSize: '18px',
    lineHeight: 'inherit',
    color: 'red',
    cursor: 'pointer',
  },
};

function FilterInput(props) {
  const {
    label,
    setFilterValue,
    size,
    organization_id,
    preference_key,
    clear_on_empty,
  } = props;

  const [localValue, setLocalValue] = useState(
    preference_key
      ? getUserOrganizationPreference(preference_key, organization_id)
      : ''
  );

  useEffect(() => {
    setFilterValue(localValue);
  }, [localValue, setFilterValue, preference_key]);

  const handleSetLocalValue = (value) => {
    setLocalValue(value);
    if (preference_key) {
      setUserOrganizationPreference(
        preference_key,
        organization_id,
        value,
        clear_on_empty
      );
    }
  };

  return (
    <Form.Group as={Col} md={size}>
      <Form.Label style={{ fontSize: '0.9rem', marginBottom: '0' }}>
        <div style={localValue !== '' ? styles.visible : styles.hidden}>
          {label}
        </div>
      </Form.Label>
      <div>
        <Form.Control
          placeholder={label}
          value={localValue ? localValue : ''}
          onChange={(e) => handleSetLocalValue(e.target.value)}
          style={{
            display: 'inline',
          }}
        />
        {localValue && (
          <FontAwesomeIcon
            style={styles.cancelStyle}
            icon={faTimesCircle}
            onClick={() => handleSetLocalValue('')}
          />
        )}
      </div>
    </Form.Group>
  );
}

FilterInput.defaultProps = {
  organization_id: null,
  preference_key: null,
  clear_on_empty: true,
};

export default FilterInput;
