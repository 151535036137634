import React, { useContext } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';

import ExpansionArrow from './ExpansionArrow';

const ArrowAccordionToggle = ({ children, eventKey }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  return (
    <Accordion.Toggle as={Card.Header} eventKey={eventKey}>
      <div style={{ float: 'left' }}>{children}</div>
      <div style={{ float: 'right' }}>
        <ExpansionArrow expanded={isCurrentEventKey} />
      </div>
    </Accordion.Toggle>
  );
};

export default ArrowAccordionToggle;
