import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { sortBy, uniqBy } from 'lodash';

import EditCredential from './EditCredential';
import ExpansionArrow from '../../components/ExpansionArrow';
import Loader from '../../components/Loader';
import EnergyTracerTable from '../../components/tables';
import {
  getCredentialColumns,
  getAccountColumns,
  handleFilterAccountByCredential,
} from './helpers/provider';

function UtilityTable(props) {
  const {
    organizationId,
    allCredentials,
    pseudoCredentials,
    providerAccounts,
    provider_type,
    showSecondary,
    showHidden,
    handleOpenLinkModal,
    isLoading,
    setAllCredentials,
    showToast,
    updateAccess,
    activeLicense,
  } = props;

  const [CREDENTIAL_COLUMNS, setCredentialColumns] = useState([]);
  const [ACCOUNT_COLUMNS, setAccountColumns] = useState([]);

  useEffect(() => {
    getCredentialColumns(provider_type, setCredentialColumns);
  }, [setCredentialColumns, provider_type]);

  useEffect(() => {
    getAccountColumns(
      provider_type,
      setAccountColumns,
      handleOpenLinkModal,
      updateAccess
    );
  }, [provider_type, handleOpenLinkModal, updateAccess]);

  const filterAccountsByCredential = (credential, account, showSecondary) => {
    return handleFilterAccountByCredential(
      provider_type,
      credential,
      account,
      showSecondary
    );
  };

  const EXPAND_ROW = {
    renderer: (row) => (
      <div
        style={{
          boxShadow: 'inset 1px 2px 3px 0px #eeeeee',
          margin: '-5px',
          paddingTop: '16px',
          paddingRight: '16px',
        }}
      >
        <Row>
          {isLoading ? (
            <Col style={{ height: '40px', marginBottom: '25px' }}>
              <Loader />
            </Col>
          ) : (
            <>
              <Col xs={1} />
              <Col xs={11}>
                {updateAccess && activeLicense && !row.psuedo && (
                  <EditCredential
                    organizationId={organizationId}
                    credential={row}
                    shared={row.shared}
                    provider_type={provider_type}
                    setAllCredentials={setAllCredentials}
                    showToast={showToast}
                  />
                )}
                <EnergyTracerTable
                  style={{ margin: '0px', padding: '0px' }}
                  keyField='id'
                  data={sortBy(
                    uniqBy(
                      providerAccounts.filter(
                        (acct) =>
                          filterAccountsByCredential(
                            row,
                            acct,
                            showSecondary
                          ) &&
                          (showSecondary ||
                            acct.providerClassification === 'PRIMARY')
                      ),
                      'id'
                    ),
                    'accountNumber'
                  )}
                  columns={ACCOUNT_COLUMNS}
                  noDataIndication={
                    <div style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                      There are no available accounts or discovery is still
                      pending. Please note that discovery can take up to 48
                      hours.
                    </div>
                  }
                />
              </Col>
            </>
          )}
        </Row>
      </div>
    ),
    showExpandColumn: true,
    expandColumnPosition: 'right',
    expandHeaderColumnRenderer: ({ isAnyExpands: expanded }) => (
      <>
        <span style={{ marginRight: '10px' }}>Expand All</span>
        <span>
          <ExpansionArrow expanded={expanded} />
        </span>
      </>
    ),
    expandColumnRenderer: ({ expanded }) => (
      <>
        <span style={{ marginRight: '10px' }}>Expand</span>
        <span>
          <ExpansionArrow expanded={expanded} />
        </span>
      </>
    ),
  };

  return (
    <>
      {CREDENTIAL_COLUMNS.length > 0 && (
        <EnergyTracerTable
          headerClasses='bg-primary text-light'
          keyField='id'
          data={allCredentials
            .concat(pseudoCredentials)
            .filter((cred) => showHidden || !cred.hidden)}
          columns={CREDENTIAL_COLUMNS}
          expandRow={EXPAND_ROW}
          noDataIndication={
            <div style={{ paddingTop: '30px', paddingBottom: '30px' }}>
              You have not connected any utility providers to this organization.
            </div>
          }
        />
      )}
    </>
  );
}

export default UtilityTable;
