import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { Checkbox } from 'pretty-checkbox-react';

import 'pretty-checkbox';

const styles = {
  col: {
    paddingLeft: '0',
  },
  row: {
    marginLeft: '0.5em',
  },
  check: {
    fontSize: '1.25em',
    marginBottom: '0.5em',
  },
  checkLabel: {
    fontSize: '1rem',
    position: 'relative',
    top: '-0.25em',
  },
};
export default function ReportCheckBoxPicker(props) {
  const {
    label,
    checkboxOptions,
    checkboxLookups,
    defaults,
    requireds,
    payload_key,
    updatePayload,
    updateLocal,
  } = props;

  const [localChecks, setLocalChecks] = useState([]);

  const changeCheck = (check) => {
    let new_checks = [...localChecks];
    if (new_checks.includes(check)) {
      new_checks = new_checks.filter(function (item) {
        return item !== check;
      });
    } else {
      new_checks.push(check);
    }
    setLocalChecks(new_checks);
    updateLocal(new_checks);
    setValues(new_checks);
  };

  const setValues = useCallback(
    (values) => {
      if (payload_key !== 'none') {
        updatePayload(payload_key, values);
      }
    },
    [payload_key, updatePayload]
  );

  useEffect(() => {
    setLocalChecks([...defaults]);
    updateLocal([...defaults]);
    setValues([...defaults]);
  }, [setValues, defaults, updateLocal]);

  return (
    <Form.Group as={Col} style={styles.col}>
      <Form.Label>{label}</Form.Label>
      <Form.Row style={styles.row}>
        {checkboxOptions.map((option) => (
          <Checkbox
            style={styles.check}
            color={'primary-o'}
            onChange={() => {
              changeCheck(option);
            }}
            disabled={requireds.includes(option)}
            checked={localChecks.includes(option)}
            key={checkboxLookups[option]}
          >
            <div>
              <div style={styles.checkLabel}> {checkboxLookups[option]}</div>
            </div>
          </Checkbox>
        ))}
      </Form.Row>
    </Form.Group>
  );
}

ReportCheckBoxPicker.propTypes = {
  label: PropTypes.string,
  checkboxOptions: PropTypes.array,
  checkboxLookups: PropTypes.object,
  defaults: PropTypes.array,
  requireds: PropTypes.array,
  payload_key: PropTypes.string,
  updatePayload: PropTypes.func,
  updateLocal: PropTypes.func,
};

ReportCheckBoxPicker.defaultProps = {
  updateLocal: () => {},
  updatePayload: () => {},
  payload_key: 'none',
  requireds: [],
};
