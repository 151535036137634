import React, { useCallback, useEffect, useState } from 'react';

import { ApiServiceServerless } from '../../xhr_libs';
import EnergyTracerTable from '../../components/tables';

const styles = {
  column: {
    paddingTop: '10px',
  },
};

const cellFormatter = (val) => {
  return val === true ? (
    <div style={{ color: 'green' }}>Complete</div>
  ) : (
    <div style={{ color: 'red' }}>Missing</div>
  );
};

const orgColumns = [
  {
    dataField: 'name',
    text: 'Name',
    style: styles.column,
  },
  {
    dataField: 'org_type',
    text: 'Organization Type',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
];

const buildingColumns = [
  {
    dataField: 'name',
    text: 'Name',
    style: styles.column,
  },
  {
    dataField: 'building_type',
    text: 'Building Type',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'address',
    text: 'Address',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'utility_usage',
    text: 'Utility Use Profile',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
];

export default function ResourceAlerts(props) {
  const { org_id } = props;

  const [orgAlerts, setOrgAlerts] = useState([]);
  const [buildingAlerts, setBuildingAlerts] = useState([]);

  const getAlerts = useCallback((id) => {
    setOrgAlerts([]);
    setBuildingAlerts([]);
    if (id) {
      ApiServiceServerless.get(`/alerts/resource/${id}`, {
        authorization_id: id,
      }).then((res) => {
        setOrgAlerts(res.data.organization);
        setBuildingAlerts(res.data.building);
      });
    }
  }, []);

  useEffect(() => {
    getAlerts(org_id);
  }, [org_id, getAlerts]);

  return (
    <>
      <h4 style={{ marginTop: '1.5em' }}>Organization Integrity</h4>
      {orgAlerts.length > 0 ? (
        <>
          <EnergyTracerTable
            data={orgAlerts}
            columns={orgColumns}
            keyField={'id'}
          />
        </>
      ) : (
        <div>No Organization Integrity Alerts</div>
      )}
      <h4 style={{ marginTop: '1.5em' }}>Building Integrity</h4>
      {buildingAlerts.length > 0 ? (
        <>
          <EnergyTracerTable
            data={buildingAlerts}
            columns={buildingColumns}
            keyField={'id'}
          />
        </>
      ) : (
        <div>No Building Integrity Alerts</div>
      )}
    </>
  );
}
