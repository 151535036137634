import React, { useCallback, useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarEdit } from '@fortawesome/pro-solid-svg-icons';

import PopoverTrigger from './PopoverTrigger';
import ReportDatePicker from './reports/ReportDatePicker';
import CHARTCOLORS from '../helpers/chartColors';

const styles = {
  label: {
    display: 'flex',
  },
  inputStyle: {
    textAlign: 'center',
    marginRight: '10px',
  },
  buttonStyle: {
    fontSize: '22px',
    color: CHARTCOLORS[1],
  },
  buttonContainerStyle: {
    cursor: 'pointer',
    pointerEvents: 'auto',
    position: 'absolute',
    top: '57%',
    right: '4%',
  },
};

export default function MonthSelect(props) {
  const { label, default_date, min_date, max_date, setNewDate } = props;

  const [currentDate, setCurrentDate] = useState('');

  const setDate = useCallback(
    (date) => {
      const new_date = dayjs.utc(date);
      setCurrentDate(new_date);
      setNewDate(new_date);
    },
    [setNewDate]
  );

  useEffect(() => {
    setDate(default_date);
  }, [setDate, default_date]);

  return (
    <Form.Group as={Col}>
      <Form.Label style={styles.label}>{label}</Form.Label>
      <Form.Control
        style={styles.inputStyle}
        size='sm'
        disabled
        value={currentDate ? dayjs.utc(currentDate).format('MMMM YYYY') : ''}
      />
      <div id='report-popover-button' style={styles.buttonContainerStyle}>
        <PopoverTrigger
          rootClose={true}
          popoverTitle={label}
          popoverContent={
            <ReportDatePicker
              currentDate={currentDate}
              setDate={setDate}
              minDate={min_date}
              maxDate={max_date}
            />
          }
          trigger='click'
          placement='bottom'
        >
          <FontAwesomeIcon
            style={styles.buttonStyle}
            icon={faCalendarEdit}
            id={'report-date-popover'}
          />
        </PopoverTrigger>
      </div>
    </Form.Group>
  );
}
