import React, { useContext, useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import AppContext from '../../contexts/app-context';
import OrganizationSelect from '../OrganizationSelect';

function NavBar({ maxWidth, organizationSelectRoute }) {
  const { disableOrganizationSelect, updatingProfile } = useContext(AppContext);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setDisabled(disableOrganizationSelect || updatingProfile);
  }, [disableOrganizationSelect, updatingProfile]);

  return (
    <Navbar
      bg='primary'
      variant='dark'
      expand='md'
      collapseOnSelect
      style={{ boxShadow: '1px 1px 4px #9E9E9E', padding: '0.25em 1em' }}
    >
      <Container style={{ maxWidth: maxWidth }}>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <LinkContainer to='/dashboard' data-id='nav_db'>
              <Nav.Link active={false} disabled={disabled}>
                Dashboard
              </Nav.Link>
            </LinkContainer>
            <LinkContainer
              to='/resources'
              data-id='nav_resources'
              onClick={() =>
                window.dispatchEvent(new Event('energytracer_resource_click'))
              }
            >
              <Nav.Link active={false} disabled={disabled}>
                Resources
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to='/reports' data-id='nav_reports'>
              <Nav.Link active={false} disabled={disabled}>
                Reports
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to='/analysis' data-id='nav_analysis'>
              <Nav.Link active={false} disabled={disabled}>
                Analysis
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
        {organizationSelectRoute && <OrganizationSelect />}
      </Container>
    </Navbar>
  );
}

export default NavBar;
