import React, { useState } from 'react';
import { sortBy } from 'lodash';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import EnergyTracerTable from '../../../components/tables';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import AddProjectedSavingsModal from '../../../components/modals/AddProjectedSavingsModal';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

export default function ProjectedSavings(props) {
  const {
    organizationId,
    project,
    handleRefreshData,
    updateAccessAnalysis,
    showToast,
  } = props;

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [show, setShow] = useState(false);

  const handleDelete = (savings) => {
    const msg = `Are you sure you want to delete this Projected Savings? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteProjectSavings(savings);
    });
  };

  const deleteProjectSavings = (savings) => {
    ApiServiceServerless.delete(
      `/performance_projects/projected_savings/${parseInt(
        project.id
      )}/${savings}`,
      { authorization_id: organizationId }
    )
      .then((res) => {
        handleRefreshData(res.data);
        showToast('success', 'Success', 'Savings deleted');
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  const tableColumns = [
    { dataField: 'year', text: 'Performance Year', style: styles.column },
    {
      dataField: 'value',
      text: 'Value',
      style: styles.column,
      formatter: (cell) =>
        cell.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell) => (
        <>
          {updateAccessAnalysis && (
            <Button
              className='btn btn-danger'
              style={{ maxWidth: '33px', maxHeight: '31px' }}
              onClick={() => {
                handleDelete(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                }}
                icon={faTrashAlt}
              />
            </Button>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <h4 style={{ display: 'inline-block' }}>Projected Savings</h4>
      {updateAccessAnalysis && (
        <Button
          onClick={() => {
            setShow(true);
          }}
          style={{ marginBottom: '1em', float: 'right' }}
        >
          Add Projected Savings
        </Button>
      )}
      <EnergyTracerTable
        data={sortBy(project['projected_savings'], 'year')}
        columns={tableColumns}
        keyField={'id'}
      />
      <ConfirmationModal {...confirmationModalProps} />
      <AddProjectedSavingsModal
        show={show}
        onHide={() => setShow(false)}
        organizationId={organizationId}
        project={project}
        handleRefreshData={handleRefreshData}
        showToast={showToast}
      />
    </>
  );
}
