import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import EnergyTracerTable from '../../../components/tables';
import AddProjectMeasureModal from '../../../components/modals/AddProjectMeasureModal';
import AddOperationalSavingsMeasureModal from '../../../components/modals/OperationalSavingsModals/AddOperationalSavingsMeasureModal';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

export default function ProjectMeasures(props) {
  const {
    organization_id,
    project,
    measures,
    updateList,
    addToList,
    showToast,
    updateAccessAnalysis,
    deleteAccessAnalysis,
  } = props;

  const [showAdd, setShowAdd] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const handleDelete = (measure_id) => {
    const msg = `Are you sure you want to delete this Project Measure? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteProjectMeasure(measure_id);
    });
  };

  const deleteProjectMeasure = (measure_id) => {
    ApiServiceServerless.delete(
      `/performance_projects/project_measure/${parseInt(
        project.id
      )}/${measure_id}`,
      { authorization_id: organization_id }
    )
      .then((res) => {
        updateList(res.data, 'performance_measures');
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  const tableColumns = [
    { dataField: 'measure', text: 'Measure', style: styles.column },
    {
      dataField: 'value',
      text: '$/Year',
      style: styles.column,
      formatter: (cell) =>
        cell.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
    },
    { dataField: 'years', text: 'Duration (yrs)', style: styles.column },
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell) => (
        <>
          {deleteAccessAnalysis && (
            <Button
              className='btn btn-danger'
              style={{ maxWidth: '33px', maxHeight: '31px' }}
              onClick={() => {
                handleDelete(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                }}
                icon={faTrashAlt}
              />
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {updateAccessAnalysis && (
        <Button
          onClick={() => {
            setShowAddNew(true);
          }}
          style={{ marginBottom: '1em', float: 'right', marginLeft: '1em' }}
        >
          Add New Measure
        </Button>
      )}
      {updateAccessAnalysis && (
        <Button
          onClick={() => {
            setShowAdd(true);
          }}
          style={{ marginBottom: '1em', float: 'right' }}
        >
          Add Existing Measure
        </Button>
      )}

      <EnergyTracerTable
        data={measures.filter((measure) =>
          measure.project_ids.includes(project.id)
        )}
        columns={tableColumns}
        keyField={'id'}
      />

      <ConfirmationModal {...confirmationModalProps} />
      <AddProjectMeasureModal
        show={showAdd}
        onHide={() => setShowAdd(false)}
        project_type={'performance'}
        project_id={project.id}
        organization_id={organization_id}
        measures={measures}
        updateList={updateList}
        showToast={showToast}
      />
      <AddOperationalSavingsMeasureModal
        show={showAddNew}
        onHide={() => {
          setShowAddNew(false);
        }}
        organizationId={organization_id}
        defaultProject={project}
        projects={[project]}
        addToList={addToList}
        showToast={showToast}
      />
    </>
  );
}
