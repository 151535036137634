import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { ApiServiceServerless } from '../../xhr_libs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { GHGTypesLookup } from '../Reports/ReportHelpers';

import { ghg_units, short_ghg_units } from '../../helpers/GHGUnits';
import CHARTCOLORS from '../../helpers/chartColors';
import SmallerLoader from '../../components/SmallerLoader';

const styles = {
  list_group: { width: '25%', textAlign: 'center', fontSize: '1.1rem' },
};

const consumptionUnits = {
  electric: ['kWh', 'MWH'],
  gas: ['CCF', 'CF', 'mmBTU', 'Therms', 'Decatherms'],
  propane: ['gal', 'BTU', 'kBTU', 'mmBTU'],
};

const empty_conversion = {
  CO2: 0,
  N2O: 0,
  CH4: 0,
  CO2e: 0,
};
export default function GHGTool(props) {
  const { showToast, organizationId } = props;
  const [consumption, setConsumption] = useState(0);
  const [utility, setUtility] = useState('electric');
  const [unit, setUnit] = useState('kwh');
  const [zip, setZip] = useState(43215);
  const [year, setYear] = useState(new Date().getFullYear());
  const [emissionType, setEmissionType] = useState(false);
  const [activeUnit, setActiveUnit] = useState('lbs');

  const [availableConsumptionUnits, setAvailableConsumptionUnits] = useState(
    consumptionUnits['electric']
  );
  const [isLoading, setIsLoading] = useState(false);
  const [conversions, setConversions] = useState(empty_conversion);

  useEffect(() => {
    setAvailableConsumptionUnits(consumptionUnits[utility]);
    setUnit(consumptionUnits[utility][0]);
  }, [utility]);

  useEffect(() => {
    setConversions(empty_conversion);
  }, [consumption, utility, unit, zip, year, emissionType, activeUnit]);

  const parseNumber = (val) => {
    const float_val = !isNaN(parseFloat(val.replace(/,/g, '')))
      ? parseFloat(val.replace(/,/g, ''))
      : 0;
    return float_val;
  };

  const validateInput = () => {
    if (consumption === 0) {
      showToast('danger', 'Error', 'Please enter a consumption value.');
    } else if (utility === 'electric' && zip.toString().length !== 5) {
      showToast('danger', 'Error', 'Zip Code must be 5 digits.');
    } else if (utility === 'electric' && year.toString().length !== 4) {
      showToast('danger', 'Error', 'Year must be 4 digits.');
    } else {
      sumbitInputs();
    }
  };

  const sumbitInputs = () => {
    setIsLoading(true);
    setConversions(empty_conversion);
    ApiServiceServerless.post(
      '/tools/ghg_tool',
      {
        consumption: consumption,
        utility: utility,
        unit: unit,
        zip: zip,
        year: year,
        emissionType: emissionType,
        organization_id: organizationId,
        ghg_unit: activeUnit,
      },
      { authorization_id: organizationId }
    )
      .then((res) => {
        setConversions(res.data);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <Form.Group>
            <Form.Label>Utility Type</Form.Label>
            <Form.Row>
              <Form.Control
                as='select'
                onChange={(e) => setUtility(e.target.value)}
                defaultValue={utility}
              >
                {Object.keys(GHGTypesLookup).map((utilityType) => (
                  <option
                    key={`utility-opt-${utilityType}`}
                    value={utilityType}
                  >
                    {GHGTypesLookup[utilityType]}
                  </option>
                ))}
              </Form.Control>
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>Consumption Unit</Form.Label>
            <Form.Row>
              <Form.Control
                as='select'
                onChange={(e) => setUnit(e.target.value)}
                value={unit}
              >
                {availableConsumptionUnits.map((unit) => (
                  <option key={`unit-opt-${unit}`} value={unit}>
                    {unit}
                  </option>
                ))}
              </Form.Control>
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Label>{'Consumption Value '}</Form.Label>
            <Form.Row>
              <NumberFormat
                customInput={Form.Control}
                thousandSeparator
                isNumericString
                fixedDecimalScale
                placeholder={'0.00'}
                onChange={(e) => setConsumption(parseNumber(e.target.value))}
              />
            </Form.Row>
          </Form.Group>
          {utility === 'electric' ? (
            <>
              <Form.Group>
                <Form.Label>{'Zip Code '}</Form.Label>
                <Form.Row>
                  <NumberFormat
                    customInput={Form.Control}
                    isNumericString
                    decimalScale={0}
                    fixedDecimalScale
                    format={'#####'}
                    defaultValue={zip}
                    onChange={(e) => setZip(parseNumber(e.target.value))}
                  />
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Label>{'Year '}</Form.Label>
                <Form.Row>
                  <NumberFormat
                    customInput={Form.Control}
                    isNumericString
                    decimalScale={0}
                    fixedDecimalScale
                    format={'####'}
                    defaultValue={year}
                    onChange={(e) => setYear(parseNumber(e.target.value))}
                  />
                </Form.Row>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Emission Conversion Type{' '}
                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                    }}
                    onClick={() =>
                      window.open(
                        'https://support.veregy.com/article/252-greenhouse-gas-analysis',
                        '_blank'
                      )
                    }
                  >
                    <FontAwesomeIcon
                      style={{ fontSize: '18px', color: CHARTCOLORS[1] }}
                      icon={faInfoCircle}
                    />
                  </div>
                </Form.Label>
                <Form.Row>
                  <Form.Control
                    as='select'
                    onChange={(e) =>
                      setEmissionType(e.target.value === 'true' ? true : false)
                    }
                    defaultValue={emissionType}
                  >
                    <option key={`emission-type-normal`} value={false}>
                      Output Emissions Rate (Normal)
                    </option>
                    <option key={`emission-type-savings`} value={true}>
                      Non-Baseload Output Emissions Rate (Savings)
                    </option>
                  </Form.Control>
                </Form.Row>
              </Form.Group>
              <Form.Group
                controlId={'ghg-unit-select'}
                style={styles.unit_form}
              >
                <Form.Label>Unit</Form.Label>
                <Form.Control
                  as='select'
                  onChange={(e) => setActiveUnit(e.target.value)}
                  defaultValue={activeUnit}
                >
                  {Object.keys(ghg_units).map((unit) => (
                    <option key={`unit-opt-${unit}`} value={unit}>
                      {ghg_units[unit]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </>
          ) : (
            <></>
          )}

          <Button
            variant='primary'
            type='submit'
            disabled={isLoading}
            style={{ width: '100%' }}
            onClick={() => validateInput()}
          >
            {!isLoading && 'Convert'}
            {isLoading && <SmallerLoader text={'Converting...'} />}
          </Button>
        </Col>
        <Col md={2}></Col>
      </Row>
      <br></br>
      <ListGroup horizontal>
        <ListGroupItem style={styles.list_group}>
          <b>
            CO<sub>2</sub>
          </b>{' '}
          -{' '}
          {conversions['CO2'].toLocaleString('en-US', {
            maximumFractionDigits: 4,
          }) || 0}{' '}
          {short_ghg_units[activeUnit]}
        </ListGroupItem>
        <ListGroupItem style={styles.list_group}>
          <b>
            CH<sub>4</sub>
          </b>{' '}
          -{' '}
          {conversions['CH4'].toLocaleString('en-US', {
            maximumFractionDigits: 4,
          }) || 0}{' '}
          {short_ghg_units[activeUnit]}
        </ListGroupItem>
        <ListGroupItem style={styles.list_group}>
          <b>
            N<sub>2</sub>O
          </b>{' '}
          -{' '}
          {conversions['N2O'].toLocaleString('en-US', {
            maximumFractionDigits: 4,
          }) || 0}{' '}
          {short_ghg_units[activeUnit]}
        </ListGroupItem>
        <ListGroupItem style={styles.list_group}>
          <b>
            CO<sub>2</sub>e{' '}
          </b>
          -{' '}
          {conversions['CO2e'].toLocaleString('en-US', {
            maximumFractionDigits: 4,
          }) || 0}{' '}
          {short_ghg_units[activeUnit]}
        </ListGroupItem>
      </ListGroup>
    </>
  );
}
