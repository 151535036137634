import React from 'react';
import { Modal } from 'react-bootstrap';

import { version_acticle } from '../../auth';

export default function VersionModal(props) {
  const { show, onHide } = props;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Version Update</Modal.Header>
      <Modal.Body>
        <p>
          A new version of EnergyTracer has been released. Visit our{' '}
          <a href={version_acticle} target='_blank' rel='noopener noreferrer'>
            help center{''}
          </a>{' '}
          to checkout what's new.
        </p>
      </Modal.Body>
    </Modal>
  );
}
