import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';

import EnergyTracerTable from '../../../tables';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
};

export default function RegressionEventRegressionsTable(props) {
  const { regressionEventCollection, buildingsLookup, existingModels } = props;

  const [regressionTableData, setRegressionTableData] = useState([]);

  useEffect(() => {
    let temp_data = [];

    regressionEventCollection.regression_ids.forEach((reg_id) => {
      let temp_obj = { key: reg_id };
      let model = existingModels.find((model) => model.id === reg_id);
      if (model) {
        temp_obj['regression_name'] = model.name;
        temp_obj['building'] = buildingsLookup[model.building_id].name;
        temp_obj['utility'] =
          model.utility_type.charAt(0).toUpperCase() +
          model.utility_type.substr(1).toLowerCase();
        temp_data.push(temp_obj);
      }
    });
    temp_data = orderBy(temp_data, ['building']);
    setRegressionTableData(temp_data);
  }, [regressionEventCollection, buildingsLookup, existingModels]);

  const tableColumns = [
    {
      dataField: 'regression_name',
      text: 'Regression Name',
      style: styles.column,
    },

    {
      dataField: 'building',
      text: 'Building',
      style: styles.column,
    },

    {
      dataField: 'utility',
      text: 'Utility Type',
      style: styles.column,
    },
  ];

  return (
    <>
      {regressionTableData.length === 0 ? (
        <>No Regressions use this Collection</>
      ) : (
        <EnergyTracerTable
          data={regressionTableData}
          columns={tableColumns}
          keyField={'key'}
        />
      )}
    </>
  );
}
