import React, { useContext, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import AppContext from '../../contexts/app-context';
import { ApiServiceServerless } from '../../xhr_libs';
import InvalidFeedback from '../../components/forms/InvalidFeedback';

export default function UserResetPassword() {
  const { showToast } = useContext(AppContext);

  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(false);

  const { register, errors, handleSubmit, setValue } = useForm();

  const onSubmit = () => {
    setDisabled(true);
    ApiServiceServerless.post(`/password-reset`, {
      password: password,
    })
      .then(() => {
        showToast('success', 'Success', 'Password was reset');
        setValue('password1', '');
        setValue('password2', '');
        setDisabled(false);
      })
      .catch((err) => {
        setDisabled(false);
        showToast('danger', 'Error', err);
        setValue('password1', '');
        setValue('password2', '');
        throw err;
      });
  };

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ textAlign: 'left' }}
      >
        <Form.Group>
          <Form.Label>
            New password
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Control
            type='password'
            name='password1'
            placeholder=''
            isInvalid={errors.password1}
            ref={register({ required: true, minLength: 8 })}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Repeat new password
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Control
            type='password'
            name='password2'
            placeholder=''
            isInvalid={errors.password2}
            ref={register({
              required: true,
              validate: { match: (value) => value === password },
            })}
          />
          <InvalidFeedback
            errors={errors}
            name='password2'
            message='Must match first password'
          />
        </Form.Group>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            style={{
              marginTop: '5px',
              marginBottom: '15px',
              marginLeft: 'auto',
            }}
            type='submit'
            disabled={disabled}
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}
