import { report_img_lookup, report_img_styles } from './helpers';

export default function ReportImgs(props) {
  const { reportSecondaryType } = props;

  return (
    <div style={{ textAlign: 'center' }}>
      {Object.keys(report_img_lookup).includes(reportSecondaryType) ? (
        <div
          style={
            Object.keys(report_img_styles).includes(reportSecondaryType)
              ? report_img_styles[reportSecondaryType]
              : report_img_styles.default
          }
        >
          <div style={{ paddingBottom: '1em' }}>Report Preview </div>
          <img
            src={report_img_lookup[reportSecondaryType]}
            alt='Report Preview'
            style={{ height: '100%' }}
          />
        </div>
      ) : (
        <div style={{ ...report_img_styles.default }}>
          <div style={{ paddingBottom: '1em' }}>No preview available </div>
          <img
            src={report_img_lookup.default}
            alt='Report Preview'
            style={{ height: '100%' }}
          />
        </div>
      )}
    </div>
  );
}
