import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import {
  getReportTypes,
  ReportTypesLookup,
  UtilityTypesLookup,
} from '../ReportHelpers';
import ReportMonthRange from '../../../components/reports/ReportMonthRange';
import ReportCheckBoxPicker from '../../../components/reports/ReportCheckboxPicker';
import ReportUnits from '../ReportUnits';
import GHGReportUnitPicker from '../../../components/reports/GHGReportUnitPicker';

export default function TranscriptReport(props) {
  const { organizationData, updatePayload, activeGHGLicense } = props;

  const [requiredUtilities, setRequiredUtilities] = useState([]);

  const [localReportTypes, setLocalReportTypes] = useState([]);

  useEffect(() => {
    let local_utilities = [];
    organizationData['utility_types'].forEach((utility) => {
      if (utility === 'electric' || utility === 'gas') {
        local_utilities.push(utility);
      }
    });
    setRequiredUtilities(local_utilities);
  }, [organizationData]);

  return (
    <>
      <Row>
        <ReportMonthRange
          major_label={'Date Range'}
          label='End Month'
          default_date={organizationData['offset_max_date']}
          min_date={organizationData['transcript_min_date']}
          max_date={organizationData['max_bill_date']}
          payload_key={'end'}
          updatePayload={updatePayload}
          showPicker={true}
        />
      </Row>
      <Row>
        <Col>
          <ReportCheckBoxPicker
            label={'Utility Types'}
            checkboxOptions={organizationData['utility_types']}
            checkboxLookups={UtilityTypesLookup}
            defaults={organizationData['utility_types']}
            requireds={requiredUtilities}
            payload_key={'utility_types'}
            updatePayload={updatePayload}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ReportCheckBoxPicker
            label={'Report Types'}
            checkboxOptions={getReportTypes(activeGHGLicense)}
            checkboxLookups={ReportTypesLookup}
            defaults={getReportTypes(activeGHGLicense)}
            requireds={['usage']}
            payload_key={'report_types'}
            updatePayload={updatePayload}
            updateLocal={setLocalReportTypes}
          />
        </Col>
      </Row>
      <ReportUnits
        organizationData={organizationData}
        updatePayload={updatePayload}
      />
      {localReportTypes.includes('ghg') && (
        <GHGReportUnitPicker
          payload_key={'ghg_unit'}
          updatePayload={updatePayload}
        />
      )}
    </>
  );
}
