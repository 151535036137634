import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
const popover = (title, content, style) => {
  return (
    <Popover>
      {title && <Popover.Title as='h3'>{title}</Popover.Title>}
      <Popover.Content style={style}>{content}</Popover.Content>
    </Popover>
  );
};
function PopoverTrigger(props) {
  const {
    children,
    popoverContent,
    popoverTitle,
    trigger,
    placement,
    rootClose,
    padding,
  } = props;
  const style = {};
  if (padding) {
    style.padding = padding + 'px';
  }
  return (
    <OverlayTrigger
      rootClose={rootClose || false}
      trigger={trigger || 'hover'}
      placement={placement || 'right'}
      overlay={popover(popoverTitle, popoverContent, style)}
    >
      {children}
    </OverlayTrigger>
  );
}
PopoverTrigger.propTypes = {
  popoverTitle: PropTypes.string,
  popoverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placement: PropTypes.oneOf([
    'auto-start',
    'auto',
    'auto-end',
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'bottom-start',
    'left-end',
    'left',
    'left-start',
  ]),
};
export default PopoverTrigger;
