import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { orderBy } from 'lodash';

import ReportMonthRange from '../../../components/reports/ReportMonthRange';
import ReportCheckBoxPicker from '../../../components/reports/ReportCheckboxPicker';
import NavigationAlert from '../../../components/analysis/NavigationAlert';

const styles = {
  form: {
    textAlignLast: 'center',
    display: 'block',
  },
  label: {
    display: 'flex',
  },
};

const report_options = ['calendar_option', 'performance_option', 'lea_option'];
const default_report_options = ['calendar_option', 'lea_option'];
const report_options_names = {
  calendar_option: 'Performance Data Analysis',
  performance_option: 'Performance Model Analysis',
  lea_option: 'Lean Energy Analysis',
};

export default function AnalysisReport(props) {
  const { organizationData, updatePayload, setPerformanceDisabled } = props;

  const [localProjects, setLocalProjects] = useState({});
  const [performanceProjectId, setPerformanceProjectId] = useState(null);

  useEffect(() => {
    const temp_projects = orderBy(
      Object.values(organizationData['performance_projects']),
      ['default_project', 'id'],
      ['desc', 'asc']
    );
    setLocalProjects(organizationData['performance_projects']);
    if (temp_projects.length > 0) {
      setPerformanceProjectId(temp_projects[0]['id']);
      updatePayload('performance_project', temp_projects[0]['id']);
    } else {
      setPerformanceProjectId(null);
    }
  }, [organizationData, updatePayload]);

  useEffect(() => {
    let flag = false;
    if (Object.keys(localProjects).length === 0) {
      flag = true;
    }
    if (
      Object.keys(localProjects).length > 0 &&
      performanceProjectId !== null &&
      !localProjects[performanceProjectId]['project_complete']
    ) {
      flag = true;
    }
    setPerformanceDisabled(flag);
  }, [localProjects, performanceProjectId, setPerformanceDisabled]);

  return (
    <>
      {Object.keys(localProjects).length === 0 ? (
        <div style={{ marginBottom: '1em' }}>
          <NavigationAlert
            mainText={'Performance Project required for this report'}
            buttonText={'Create Project'}
            location={'/analysis'}
          />
        </div>
      ) : (
        <>
          <Form style={styles.form}>
            <Form.Group controlId={'performanceProject'}>
              <Form.Label style={styles.label}>Performance Project</Form.Label>
              <Form.Control
                as='select'
                onChange={(e) => {
                  updatePayload(
                    'performance_project',
                    parseInt(e.target.value)
                  );
                  setPerformanceProjectId(parseInt(e.target.value));
                }}
                value={performanceProjectId}
              >
                {orderBy(
                  Object.values(localProjects),
                  ['default_project', 'id'],
                  ['desc', 'asc']
                ).map((project) => (
                  <option key={`report-opt-${project.id}`} value={project.id}>
                    {project.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
          <>
            {performanceProjectId !== null &&
            !localProjects[performanceProjectId]['project_complete'] ? (
              <div style={{ marginBottom: '1em' }}>
                The selected project is not complete. Please fill out all fields
                to run this project
              </div>
            ) : (
              <>
                <Row>
                  <ReportMonthRange
                    major_label={'Performance Period'}
                    label='End Month'
                    default_date={organizationData['offset_max_date']}
                    min_date={organizationData['transcript_min_date']}
                    max_date={organizationData['max_bill_date']}
                    payload_key={'end'}
                    updatePayload={updatePayload}
                    showPicker={true}
                  />
                </Row>

                <Row>
                  <Col>
                    <ReportCheckBoxPicker
                      label={'Report Options'}
                      checkboxOptions={report_options}
                      checkboxLookups={report_options_names}
                      defaults={default_report_options}
                      requireds={[]}
                      payload_key={'report_options'}
                      updatePayload={updatePayload}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        </>
      )}
    </>
  );
}
