import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

const styles = {
  label: {
    display: 'flex',
  },
};

export default function ProjectUnitsDropdown(props) {
  const { updateUnits, unit_key, title, units, names, defaultUnit } = props;

  const [localUnit, setLocalUnit] = useState(defaultUnit);

  useEffect(() => {
    if (unit_key) {
      updateUnits(unit_key, localUnit);
    }
  }, [updateUnits, localUnit, unit_key]);

  return (
    <Form.Group controlId={unit_key + '-unit-select'}>
      <Form.Label style={styles.label}>{title} Unit</Form.Label>
      <Form.Control
        as='select'
        onChange={(e) => setLocalUnit(e.target.value)}
        defaultValue={localUnit}
      >
        {units.map((unit, idx) => (
          <option key={`unit-opt-${unit}`} value={unit}>
            {names[idx]}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
}
