import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import classNames from 'classnames';

function EnergyTracerTable(props) {
  const {
    keyField = 'id',
    wrapperStyle = 'et-table-wrapper',
    enableScroll,
  } = props;
  const [wrapperClasses, setWrapperClasses] = useState(classNames({}));

  useEffect(() => {
    let wrapper_classes = {};
    wrapper_classes[wrapperStyle] = enableScroll;
    setWrapperClasses(classNames(wrapper_classes));
  }, [wrapperStyle, enableScroll]);

  return (
    <BootstrapTable
      {...props}
      bootstrap4
      condensed
      hover
      bordered={false}
      wrapperClasses={wrapperClasses}
      headerWrapperClasses={classNames({
        'et-table-header': enableScroll,
      })}
      headerClasses='bg-light text-dark'
      keyField={keyField}
    />
  );
}

EnergyTracerTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  hover: PropTypes.bool,
  keyField: PropTypes.string,
  selectRow: PropTypes.object,
  disabled: PropTypes.bool,
  enableScroll: PropTypes.bool,
};

export default EnergyTracerTable;
