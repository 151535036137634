import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isEqual } from 'lodash';
import { Form, Alert, Row } from 'react-bootstrap';

import { ApiServiceServerless, forceAuthRefresh } from '../../xhr_libs';
import AppContext from '../../contexts/app-context';
import EditEntityActionButtons from '../../components/forms/EditEntityActionButtons';

class UserEditForm extends React.Component {
  state = {
    user: {
      email: '',
      firstName: '',
      lastName: '',
    },
    editUser: {
      email: '',
      firstName: '',
      lastName: '',
    },
    validated: false,
    isSaving: false,
  };

  static contextType = AppContext;
  showToast = this.context.showToast;

  componentDidMount() {
    this.setState({ isSaving: true });
    ApiServiceServerless.get('/profile')
      .then((result) => {
        const newState = {
          email: result.data.email,
          firstName: result.data.first_name || '',
          lastName: result.data.last_name || '',
        };
        this.setState({ user: { ...newState }, editUser: { ...newState } });
        this.setState({ isSaving: false });
      })
      .catch((error) => {
        this.showToast('danger', 'Error', error);
        this.setState({ isSaving: false });
        throw error;
      });
  }

  handleChange = (field, value) => {
    let newState = cloneDeep(this.state);
    newState['editUser'][field] = value;
    this.setState(newState);
  };

  handleReset = () => {
    this.setState({ editUser: cloneDeep(this.state.user), validated: false });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let payload = {
      first_name: this.state.editUser.firstName,
      last_name: this.state.editUser.lastName,
      email: this.state.editUser.email,
    };
    const form = event.currentTarget;
    if (form.checkValidity()) {
      ApiServiceServerless.post('/profile', payload)
        .then((result) => {
          const newState = {
            email: result.data.user.email || '',
            firstName: result.data.user.first_name || '',
            lastName: result.data.user.last_name || '',
          };
          this.setState({
            user: { ...newState },
            editUser: { ...newState },
            validated: false,
          });
          this.showToast('success', 'Success', 'Profile saved');
        })
        .catch((error) => {
          this.showToast('danger', 'Error', error);
          this.setState({ editUser: { ...this.state.user }, validated: false });
          throw error;
        })
        .finally(() => {
          forceAuthRefresh();
        });
    } else {
      this.setState({ validated: true });
    }
  };

  render() {
    const {
      editUser,
      editUser: { email, firstName, lastName },
      user,
      validated,
      isSaving,
    } = this.state;
    const containsDiff = !isEqual(user, editUser);
    const saveDisabled = !editUser.firstName || !editUser.email;
    return (
      <>
        {(user.firstName === null ||
          user.lastName === null ||
          user.firstName === '' ||
          user.lastName === '') && (
          <Row style={{ justifyContent: 'center' }}>
            <Alert style={{ width: '90%' }} variant='danger'>
              You have missing profile information, please complete all fields
              below.
            </Alert>
          </Row>
        )}
        <Form
          noValidate
          validated={validated}
          onSubmit={this.handleSubmit}
          style={{ textAlign: 'left' }}
        >
          <Form.Group controlId='formHorizontalEmail'>
            <Form.Label>
              Email<span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Control
              required
              type='email'
              value={email}
              onChange={(e) => this.handleChange('email', e.target.value)}
            />
            <Form.Control.Feedback type='invalid'>
              Please enter a valid email address.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId='formHorizontalFirstName'>
            <Form.Label>
              First name
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Control
              type='text'
              placeholder=''
              value={firstName}
              onChange={(e) => this.handleChange('firstName', e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId='formHorizontalLastName'>
            <Form.Label>
              Last name
              <span className='text-warning font-weight-bold pl-1'>*</span>
            </Form.Label>
            <Form.Control
              type='text'
              placeholder=''
              value={lastName}
              onChange={(e) => this.handleChange('lastName', e.target.value)}
            />
          </Form.Group>
          <EditEntityActionButtons
            onCancel={this.handleReset}
            submitDisabled={!containsDiff || saveDisabled}
            cancelDisabled={!containsDiff}
            isSubmitting={isSaving}
          />
        </Form>
      </>
    );
  }
}

UserEditForm.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
};

export default UserEditForm;
