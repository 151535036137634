import React, { useEffect, useState } from 'react';

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

import CHARTCOLORS from '../helpers/chartColors';
import formatValue from '../helpers/chartFormatter';

export default function ActivePieChart(props) {
  const { data, unit } = props;

  const [chartDebounce, setChartDebounce] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setChartDebounce((prev) => prev + 1);
    }, 0);
  }, [data]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [buildingLabelHeight, setBuildingLabelHeight] = useState(0);

  const labelRef = (element) => {
    if (element) {
      setBuildingLabelHeight(element.getBoundingClientRect().height);
    }
  };

  const onPieEnter = (_data, index) => {
    setActiveIndex(index);
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 7) * cos;
    const sy = cy + (outerRadius + 7) * sin;
    const mx = cx + (outerRadius + 16) * cos;
    const my = cy + (outerRadius + 16) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const str1 = formatValue(value, unit === '$' ? unit : '');
    const str2 = unit === '$' ? '' : unit;
    const fontsize = str1.length < 13 ? 20 : 18;

    return (
      <g>
        <g>
          <foreignObject
            x={cx - 75}
            y={cy - buildingLabelHeight / 2}
            width={150}
            height={120}
          >
            <div
              ref={labelRef}
              style={{
                fontSize: 20,
                lineHeight: 1.1,
                color: CHARTCOLORS[activeIndex % CHARTCOLORS.length],
              }}
            >
              {payload.name}
            </div>
          </foreignObject>
        </g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 4}
          outerRadius={outerRadius + 7}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          strokeWidth={2}
          fill='none'
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 6}
          y={ey}
          textAnchor={textAnchor}
          fill='#333'
          fontSize={fontsize}
        >
          {str1}
        </text>

        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey + 18}
          textAnchor={textAnchor}
          fill='#333'
          fontSize={fontsize}
        >
          {str2}
        </text>
      </g>
    );
  };

  return (
    <div style={{ width: '400px' }}>
      <ResponsiveContainer width={'100%'} height={325} debounce={chartDebounce}>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            dataKey='value'
            cx={200}
            cy={150}
            innerRadius={80}
            outerRadius={110}
            fill='#8884d8'
            onMouseEnter={onPieEnter}
          >
            {data.map((_entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={CHARTCOLORS[index % CHARTCOLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
