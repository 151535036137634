import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { sortBy } from 'lodash';
import dayjs from 'dayjs';

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  LineChart,
  Line,
  ResponsiveContainer,
} from 'recharts';

import { ApiServiceServerless } from '../../xhr_libs';
import AnalysisYears from '../../components/analysis/AnalysisYears';
import CHARTCOLORS from '../../helpers/chartColors';
import formatValue from '../../helpers/chartFormatter';
import consumptionUnits from '../../helpers/consumptionUnits';

import BillDiscrepancyTable from '../Alerts/BillDiscrepancyTable';

const styles = {
  inputStyle: {
    width: '240px',
  },
  colStyle: {
    maxWidth: '300px',
  },
};

export default function BillTool(props) {
  const {
    organizationId,
    showToast,
    activeAnalysisLicense,
    analysisLicenseId,
  } = props;

  const [buildings, setBuildings] = useState([]);
  const [utilityTypes, setUtilityTypes] = useState([]);

  const [building, setBuilding] = useState('');
  const [utilityType, setUtilityType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [billData, setBillData] = useState([]);

  const [methods, setMethods] = useState([]);

  const resetPage = () => {
    setBuilding('');
    setUtilityType('');
    setBuildings([]);
    setUtilityTypes([]);
    setBillData([]);

    setMethods([]);
  };

  useEffect(() => {
    resetPage();
    if (
      organizationId &&
      analysisLicenseId &&
      organizationId === analysisLicenseId &&
      activeAnalysisLicense
    ) {
      ApiServiceServerless.get(`/analysis/organization/${organizationId}`, {
        authorization_id: organizationId,
      }).then((res) => {
        setBuildings(res.data['buildings']);
        let utilities = res.data['utility_types'];
        utilities = utilities.filter((utility) => utility !== 'Production');
        utilities = utilities.map(
          (utility) =>
            utility.charAt(0).toUpperCase() + utility.substr(1).toLowerCase()
        );
        setUtilityTypes(utilities);
      });
    } else {
      setBuildings([]);
      setUtilityTypes([]);
    }
  }, [organizationId, activeAnalysisLicense, analysisLicenseId]);

  useEffect(() => {
    let endDate = dayjs.utc(new Date()).endOf('month');
    let startDate = endDate;
    startDate = startDate.month(startDate.month() - 11).startOf('month');

    setStartDate(startDate);
    setEndDate(endDate);
  }, [organizationId]);

  const getBillData = useCallback(
    (organizationId, buildingId, utility, startDate, endDate) => {
      ApiServiceServerless.post(
        `/tools/bill_tool`,
        {
          building_id: buildingId,
          utility: utility,
          start_date: dayjs.utc(startDate).format('YYYY-MM-DD'),
          end_date: dayjs.utc(endDate).format('YYYY-MM-DD'),
        },
        { authorization_id: organizationId }
      )
        .then((res) => {
          setBillData(res.data.output);
          setMethods(res.data.methods);
        })
        .catch((err) => {
          showToast('danger', 'Error', err);
          throw err;
        });
    },
    [showToast]
  );

  useEffect(() => {
    setBillData([]);
    setMethods([]);
    if (
      building &&
      utilityType &&
      dayjs(startDate).isValid() &&
      dayjs(endDate).isValid()
    ) {
      if (dayjs(startDate).diff(endDate) > 0) {
        showToast('danger', 'Error', 'Start Date must be before End Date');
      } else {
        getBillData(organizationId, building, utilityType, startDate, endDate);
      }
    }
  }, [
    organizationId,
    building,
    utilityType,
    startDate,
    endDate,
    showToast,
    getBillData,
  ]);

  return (
    <>
      <Row>
        <Col style={styles.colStyle}>
          <Form.Group>
            <Form.Control
              style={styles.inputStyle}
              as='select'
              onChange={(e) => {
                setUtilityType('');
                setBuilding(e.target.value);
              }}
              value={building}
            >
              <option key={'building-opt-select'} value={''} hidden>
                Select Building
              </option>
              <option
                key={'building-opt-null'}
                value={''}
                hidden={buildings.length}
                disabled
              >
                No Buildings
              </option>
              {sortBy(buildings, ['name']).map((building) => (
                <option key={`building-opt-${building.id}`} value={building.id}>
                  {building.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col style={styles.colStyle}>
          <Form.Group>
            <Form.Control
              style={styles.inputStyle}
              as='select'
              onChange={(e) => {
                setUtilityType(e.target.value);
              }}
              value={utilityType}
              disabled={!building}
            >
              <option key={'utilityType-opt-select'} value={''} hidden>
                Select Utility Type
              </option>
              <option
                key={'utilityType-opt-null'}
                value={''}
                hidden={utilityTypes.length}
                disabled
              >
                No Utility Accounts
              </option>
              {utilityTypes.map((utilityType) => (
                <option
                  key={`utilityType-opt-${utilityType.toLowerCase()}`}
                  value={utilityType.toLowerCase()}
                >
                  {utilityType}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col style={{ marginLeft: '40px', width: '300px' }}>
          <AnalysisYears
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            utilityType={utilityType}
            regressionType={utilityType}
            getBaselineData={() => {}}
            clearRegression={() => {}}
            title=' '
          />
        </Col>
      </Row>
      {billData.length > 0 && (
        <>
          <h4>
            {utilityType ? 'Cost Per ' + consumptionUnits[utilityType][0] : ''}
          </h4>
          <ResponsiveContainer
            width={'100%'}
            height={300}
            debounce={billData.length}
          >
            <LineChart data={billData} margin={{ right: 25 }}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='date' type='category' tick={{ fontSize: 16 }} />
              <YAxis
                type='number'
                tickFormatter={(tick) => formatValue(tick, '')}
              >
                <Label value={''} position='top' offset={10} />
              </YAxis>
              <Tooltip />

              <Line
                type='monotone'
                dataKey={'value'}
                stroke={CHARTCOLORS[0]}
                formatter={(value) =>
                  formatValue(
                    value,
                    utilityType ? '$/' + consumptionUnits[utilityType][0] : ''
                  )
                }
              />
            </LineChart>
          </ResponsiveContainer>

          <BillDiscrepancyTable
            org_id={organizationId}
            methods={methods}
            showToast={showToast}
          />
        </>
      )}
    </>
  );
}
