import React, { useState, useEffect } from 'react';

function Footer() {
  const [width, setWidth] = useState('100%');

  useEffect(() => {
    const handleResize = () => {
      const el = document.getElementById('energytracer-header');
      setWidth(el.offsetWidth);
    };

    const el = document.getElementById('energytracer-header');
    setWidth(el.offsetWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{ width: width }}
      className='et-footer d-flex fixed-bottom bg-primary text-white px-3'
    >
      <div
        className=' d-flex justify-content-between align-items-center w-100 m-auto '
        style={{ maxWidth: '1300px' }}
      >
        <small>
          EnergyTracer, developed by{' '}
          <a
            data-id='foot_orch'
            href='https://www.veregy.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='text-white'
          >
            <span className='underline'>Veregy</span>
          </a>
        </small>
        <small
          data-id='foot_help'
          style={{ cursor: 'pointer' }}
          className='text-white underline'
          onClick={() => {
            window.open(
              'https://support.veregy.com/conversation/new',
              '_blank'
            );
          }}
        >
          Contact us
        </small>
      </div>
    </div>
  );
}

export default Footer;
