// storage name / pretty display name

export const BuildingTypes = {
  education: 'Education',
  food_sales: 'Food Sales',
  food_services: 'Food Service',
  healthcare_in: 'Health Care (Inpatient)',
  healthcare_out: 'Health Care (Outpatient)',
  lodging: 'Lodging',
  mercantile: 'Mercantile (Retail Other Than Mall)',
  mercantile_enclosed: 'Mercantile (Enclosed and Strip Malls)',
  office: 'Office',
  public_assembly: 'Public Assembly',
  public_order: 'Public Order and Safety',
  religious: 'Religious Worship',
  service: 'Service',
  warehouse: 'Warehouse and Storage',
  other: 'Other',
  vacant: 'Vacant',
};

export const BUILDING_BENCHMARK_OPTIONS = {
  1: 'Electric/Mixed',
  2: 'Electric and Gas',
  3: 'Electric Only',
};

export default BuildingTypes;
