import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import GHGReportUnitPicker from '../../../components/reports/GHGReportUnitPicker';
import ReportUnits from '../ReportUnits';
import { orderBy } from 'lodash';

const styles = {
  form: {
    textAlignLast: 'center',
    display: 'block',
  },
  label: {
    display: 'flex',
  },
};

export default function GHGBaselineReport(props) {
  const { organizationData, updatePayload, with_units } = props;

  const [ghgProject, setGHGProject] = useState(0);

  useEffect(() => {
    if (
      Object.keys(organizationData).length !== 0 &&
      Object.keys(organizationData['ghg_projects']).length !== 0
    ) {
      const temp_projects = orderBy(
        Object.values(organizationData['ghg_projects']),
        ['default_project', 'id'],
        ['desc', 'asc']
      );
      const ghg_project = parseInt(temp_projects[0].id);
      setGHGProject(ghg_project);
      updatePayload('ghg_project', ghg_project);
    }
  }, [organizationData, updatePayload]);

  return Object.keys(organizationData).length === 0 ||
    Object.keys(organizationData['ghg_projects']).length === 0 ? (
    <div style={{ marginBottom: '1em' }}>
      Create a Greenhouse Gas Project to run this report.
    </div>
  ) : (
    <Form style={styles.form}>
      <Form.Group controlId={'ghgBaslineYear'}>
        <Form.Label style={styles.label}>Greenhouse Gas Project</Form.Label>
        <Form.Control
          as='select'
          onChange={(e) => {
            updatePayload('ghg_project', parseInt(e.target.value));
            setGHGProject(parseInt(e.target.value));
          }}
          value={ghgProject}
        >
          {orderBy(
            Object.values(organizationData['ghg_projects']),
            ['default_project', 'id'],
            ['desc', 'asc']
          ).map((project) => (
            <option key={`report-opt-${project.id}`} value={project.id}>
              {project.name}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      {with_units && (
        <ReportUnits
          organizationData={organizationData}
          updatePayload={updatePayload}
          excludeWater={true}
        />
      )}
      <GHGReportUnitPicker updatePayload={updatePayload} />
    </Form>
  );
}
