import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faPlusSquare } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import EnergyTracerTable from '../../../components/tables';
import ViewRateModal from '../../../components/modals/ViewRateModal';
import AddProjectRateCollectionModal from '../../../components/modals/AddProjectRateCollectionModal';
import AddMultiProjectRateCollectionModal from '../../../components/modals/AddMultiProjectRateCollectionModel';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

export default function ProjectRates(props) {
  const {
    organization_id,
    project_id,
    buildingsLookup,
    selectedBuildings,
    utility_types,
    rates,
    rateCollections,
    updateList,
    showToast,
    updateAccessAnalysis,
    deleteAccessAnalysis,
  } = props;

  const [tableData, setTableData] = useState([]);

  const [showAdd, setShowAdd] = useState(false);
  const [addBuildingId, setAddBuildingId] = useState(null);
  const [addUtility, setAddUtility] = useState(null);

  const [showView, setShowView] = useState(false);
  const [viewId, setViewId] = useState(null);

  const [showMultiAdd, setShowMultiAdd] = useState(false);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const handleSetAdd = (building, utility) => {
    setAddBuildingId(building);
    setAddUtility(utility);
    setShowAdd(true);
  };

  const handleHideAdd = () => {
    setAddBuildingId(null);
    setAddUtility(null);
    setShowAdd(false);
  };

  const handleSetView = (collection_id) => {
    setViewId(collection_id);
    setShowView(true);
  };

  const handleHideView = () => {
    setViewId(null);
    setShowView(false);
  };

  useEffect(() => {
    let temp_data = [];
    if (Object.keys(buildingsLookup).length > 0) {
      selectedBuildings.forEach((building) => {
        let temp_obj = {};
        temp_obj['building'] = building;
        temp_obj['building_name'] = buildingsLookup[building]['name'];
        utility_types.forEach((utility) => {
          temp_obj[utility] = null;
          let rate = rateCollections.filter(
            (rate) =>
              rate.project_buildings &&
              Object.keys(rate.project_buildings).includes(
                project_id.toString()
              ) &&
              rate.project_buildings[project_id].includes(building) &&
              rate.utility_type === utility
          );
          if (rate.length !== 0) {
            temp_obj[utility] = {
              ...rate[0],
              building_id: building,
              building_name: buildingsLookup[building]['name'],
            };
          }
        });
        temp_data.push(temp_obj);
      });
      setTableData(sortBy(temp_data, ['building_name']));
    } else {
      setTableData([]);
    }
  }, [
    project_id,
    buildingsLookup,
    selectedBuildings,
    utility_types,
    rateCollections,
  ]);

  const handleDelete = (row, project_id, organization_id) => {
    const msg = `Are you sure you want to delete this Project Rate? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteProjectRate(row, project_id, organization_id);
    });
  };

  const deleteProjectRate = (row, project_id, organization_id) => {
    ApiServiceServerless.delete(
      `/performance_projects/project_rate_collection/${parseInt(project_id)}/${
        row.id
      }/${row.building_id}/${row.utility_type}`,
      { authorization_id: organization_id }
    )
      .then((res) => {
        updateList(res.data, 'rate_collections');
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  const buildingColumn = [
    { dataField: 'building_name', text: 'Building', style: styles.column },
  ];

  let tableColumns = [].concat(
    buildingColumn,
    utility_types.includes('electric')
      ? utilityColumn(
          'electric',
          project_id,
          organization_id,
          handleDelete,
          handleSetAdd,
          handleSetView,
          updateAccessAnalysis,
          deleteAccessAnalysis
        )
      : [],
    utility_types.includes('gas')
      ? utilityColumn(
          'gas',
          project_id,
          organization_id,
          handleDelete,
          handleSetAdd,
          handleSetView,
          updateAccessAnalysis,
          deleteAccessAnalysis
        )
      : [],
    utility_types.includes('propane')
      ? utilityColumn(
          'propane',
          project_id,
          organization_id,
          handleDelete,
          handleSetAdd,
          handleSetView,
          updateAccessAnalysis,
          deleteAccessAnalysis
        )
      : [],
    utility_types.includes('water')
      ? utilityColumn(
          'water',
          project_id,
          organization_id,
          handleDelete,
          handleSetAdd,
          handleSetView,
          updateAccessAnalysis,
          deleteAccessAnalysis
        )
      : []
  );

  return (
    <>
      {updateAccessAnalysis && (
        <Button
          style={{ marginBottom: '1em', float: 'right', marginLeft: '1em' }}
          onClick={() => setShowMultiAdd(true)}
        >
          Multi Rate Set
        </Button>
      )}

      {tableData.length > 0 && (
        <EnergyTracerTable
          data={tableData}
          columns={tableColumns}
          keyField={'building'}
        />
      )}
      <ConfirmationModal {...confirmationModalProps} />
      <AddProjectRateCollectionModal
        show={showAdd}
        onHide={() => handleHideAdd()}
        building_id={addBuildingId}
        utility={addUtility}
        project_id={project_id}
        organization_id={organization_id}
        rateCollections={rateCollections}
        updateList={updateList}
        showToast={showToast}
      />
      <ViewRateModal
        show={showView}
        onHide={() => handleHideView()}
        rows={rates.filter((rate) =>
          rate.collection_ids.includes(parseInt(viewId))
        )}
      />
      <AddMultiProjectRateCollectionModal
        show={showMultiAdd}
        onHide={() => setShowMultiAdd(false)}
        buildingsLookup={buildingsLookup}
        project_id={project_id}
        organization_id={organization_id}
        rateCollections={rateCollections}
        updateList={updateList}
        showToast={showToast}
      />
    </>
  );
}

function utilityColumn(
  utility,
  project_id,
  organization_id,
  handleDelete,
  handleSetAdd,
  handleSetView,
  updateAccess,
  deleteAccess
) {
  return [
    {
      dataField: utility,
      text: utility.charAt(0).toUpperCase() + utility.substr(1).toLowerCase(),
      style: styles.actionColumn,
      formatter: (cell, row) => (
        <>
          {row[utility] && row[utility] !== null ? (
            <ExistingRateDisplay
              row={row[utility]}
              project_id={project_id}
              organization_id={organization_id}
              handleDelete={handleDelete}
              handleSetView={handleSetView}
              deleteAccess={deleteAccess}
            />
          ) : (
            <AddModalDisplay
              utility={utility}
              building={row.building}
              handleSetAdd={handleSetAdd}
              updateAccess={updateAccess}
            />
          )}
        </>
      ),
    },
  ];
}

function ExistingRateDisplay(props) {
  const {
    row,
    project_id,
    organization_id,
    handleDelete,
    handleSetView,
    deleteAccess,
  } = props;

  return (
    <>
      <div
        className='et-link'
        style={{ display: 'inline-block' }}
        onClick={() => {
          handleSetView(row.id);
        }}
      >
        {row.name}{' '}
      </div>
      {deleteAccess && (
        <Button
          variant='outline-danger'
          style={{
            padding: '0px',
            borderWidth: '0px',
            borderRadius: '50%',
            height: '28px',
            width: '28px',
            marginLeft: '20px',
          }}
        >
          <FontAwesomeIcon
            style={{ fontSize: '18px', lineHeight: 'inherit' }}
            icon={faTimesCircle}
            onClick={() => handleDelete(row, project_id, organization_id)}
          />
        </Button>
      )}
    </>
  );
}

function AddModalDisplay(props) {
  const { building, utility, handleSetAdd, updateAccess } = props;

  return (
    <>
      {updateAccess ? (
        <Button
          variant='link'
          style={{ width: '100%', margin: '0px', padding: '0px' }}
          onClick={() => handleSetAdd(building, utility)}
        >
          <div style={{ textAlign: 'left', margin: 'auto' }}>
            <FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: '23px' }} />
          </div>
        </Button>
      ) : (
        <>No Rate</>
      )}
    </>
  );
}
