import React, { useContext } from 'react';

import AppContext from '../../contexts/app-context';
import ToastMessage from './ToastMessage';

function Toasts() {
  const { toasts, setToasts } = useContext(AppContext);

  const removeToast = (id) =>
    setToasts(toasts.filter((toast) => toast.id !== id));

  return (
    <div
      style={{
        backgroundColor: '',
        position: 'fixed',
        marginRight: 10,
        marginTop: 125,
        top: 10,
        right: 10,
        zIndex: 1001,
      }}
    >
      {toasts.map((toast) => (
        <ToastMessage
          key={toast.id}
          variant={toast.variant}
          title={toast.title}
          onClose={() => removeToast(toast.id)}
        >
          {toast.children}
        </ToastMessage>
      ))}
    </div>
  );
}

export default Toasts;
