import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import {
  faCars,
  faHome,
  faFire,
  faTrees,
} from '@fortawesome/pro-solid-svg-icons';

import WidgetPieHover from '../../components/widgets/WidgetPieHover';
import WidgetActiveBar from '../../components/widgets/WidgetActiveBar';
import EnergyTracerMultiLineChart from '../../components/EnergyTracerMultiLineChart';
import WidgetInfo from '../../components/widgets/WidgetInfo';
import CHARTCOLORS from '../../helpers/chartColors';
import { EMISSION_SCOPES } from '../../helpers/utility-types';
import GHGInfoModal from '../../components/modals/GHGInfoModal';
import {
  ghg_units,
  short_ghg_units,
  ghg_conversions,
} from '../../helpers/GHGUnits';
import CheckResources from './CheckResources';
import DashboardBaselineDateSelectContainer from './DashboardBaselineDateSelectContainer';
import GraphActionContainer from '../../components/chartElements/GraphActionContainer';
import ChartInfo from '../../components/chartElements/Actions/ChartInfo';
import ChartInfoClick from '../../components/chartElements/Actions/ChartInfoClick';
import ChartDownload from '../../components/chartElements/Actions/ChartDownload';
import ChartToggle from '../../components/chartElements/Actions/ChartToggle';

const styles = {
  list_group: {
    textAlign: 'center',
    fontSize: '1.1rem',
    padding: '0.75em 0',
    border: 'solid 1px rgba(0,0,0,.125)',
  },
  unit_form: { display: 'inline-block', width: '225px' },
  unit_form_label: { marginRight: '0.5em', display: 'inline-block' },
  unit_form_control: { width: '125px', display: 'inline-block' },
  col_widget_style1: { minHeight: '100px' },
  col_widget_style2: { minHeight: '120px' },
};

const EMPTY_EMISSIONS = { CO2: 0, CH4: 0, N2O: 0, CO2e: 0 };
const EMPTY_EQUIVALENCIES = { cars: 0, homes: 0, coal: 0, acres: 0 };

function CarbonSummary(props) {
  const [deferredProps, setDeferredProps] = useState({ ...props });
  useEffect(() => {
    if (props.open) setDeferredProps({ ...props });
  }, [props]);
  const {
    dashboardData,
    organization,
    endDate,
    dateOption,
    activeIds,
    activeKeys,
    localBuildingIds,
    activeGHGLicense,
  } = deferredProps;

  const [ghgMonthData, setGHGMonthData] = useState([]);
  const [ghgUtilityData, setGHGUtilityData] = useState([]);
  const [ghgScopeData, setGHGScopeData] = useState([]);
  const [ghgBuildingData, setGHGBuildingData] = useState([]);
  const [ghgEmissionData, setGHGEmissionData] = useState({
    ...EMPTY_EMISSIONS,
  });
  const [ghgEquivalenciesData, setGHGEquivalenciesData] = useState({
    ...EMPTY_EQUIVALENCIES,
  });

  const [GHGSummary, setGHGSummary] = useState(true);

  const [baselineData, setBaselineData] = useState({});

  const [activeUnit, setActiveUnit] = useState('lbs');
  const [unitFactor, setUnitFactor] = useState(1);

  const [showGHGInfoModal, setShowGHGInfoModal] = useState(false);

  const [chartDownloadHeaders, setChartDownloadHeaders] = useState([]);
  const [chartDownloadKeys, setChartDownloadKeys] = useState([]);

  useEffect(() => {
    setUnitFactor(1 / ghg_conversions[activeUnit]);
  }, [activeUnit]);

  useEffect(() => {
    const hasBaseline = Object.keys(baselineData).length > 0;

    let headers = ['Date'];
    let keys = ['name'];
    activeKeys.forEach((key) => {
      headers.push(key);
      keys.push(key);
      if (Object.keys(baselineData).length > 0) {
        headers.push(key + ' Baseline');
        keys.push(key + ' Baseline');
      }
    });
    setChartDownloadHeaders(headers);
    setChartDownloadKeys(keys);
    let data = dashboardData['month_array'].map((month) => {
      return {
        name: month,
        month: month,
      };
    });
    data.forEach((entry, index) => {
      activeIds.forEach((id, index2) => {
        let key = activeKeys[index2];

        entry[key] =
          dashboardData['ghg_breakdown'][id] &&
          dashboardData['ghg_breakdown'][id]['monthly']
            ? dashboardData['ghg_breakdown'][id]['monthly'][index] * unitFactor
            : 0;
        if (hasBaseline) {
          entry[key + ' Baseline'] =
            baselineData['ghg_breakdown'][id] &&
            baselineData['ghg_breakdown'][id]['monthly']
              ? baselineData['ghg_breakdown'][id]['monthly'][index] * unitFactor
              : 0;
        }
      });
    });
    setGHGMonthData(data);
  }, [dashboardData, baselineData, unitFactor, activeIds, activeKeys]);

  useEffect(() => {
    let temp = [];
    let ghg = dashboardData['ghg_breakdown'];
    let temp_ids = [...activeIds];

    temp_ids.forEach((id) => {
      if (Object.keys(ghg).includes(id.toString())) {
        temp.push({
          name: dashboardData['building_info'][id]['name'],
          value: Math.round(ghg[id]['emissions']['CO2e'] * unitFactor),
        });
      }
    });
    setGHGBuildingData(temp);
  }, [dashboardData, unitFactor, activeIds, localBuildingIds]);

  useEffect(() => {
    const ghg = dashboardData['ghg_breakdown'];
    if (ghg && Object.keys(ghg).length > 0) {
      let utility = [];
      let scope = [];
      let emissions = { ...EMPTY_EMISSIONS };
      let equivalencies = { ...EMPTY_EQUIVALENCIES };

      let temp_ids = [...activeIds];

      let utilities = {};
      let scopes = {};
      temp_ids.forEach((id) => {
        if (id in ghg) {
          let keys = Object.keys(ghg[id]['utility']);
          keys.forEach((key) => {
            if (Object.keys(utilities).includes(key)) {
              utilities[key]['value'] += Math.round(
                ghg[id]['utility'][key] * unitFactor
              );
            } else {
              utilities[key] = {
                name: key.charAt(0).toUpperCase() + key.substr(1).toLowerCase(),
                value: Math.round(ghg[id]['utility'][key] * unitFactor),
              };
            }
          });

          Object.keys(ghg[id]['scope']).forEach((key) => {
            if (Object.keys(scopes).includes(key)) {
              scopes[key]['value'] += Math.round(
                ghg[id]['scope'][key] * unitFactor
              );
            } else {
              scopes[key] = {
                name: key.charAt(0).toUpperCase() + key.substr(1).toLowerCase(),
                value: Math.round(ghg[id]['scope'][key] * unitFactor),
              };
            }
          });

          Object.keys(ghg[id]['emissions']).forEach((key) => {
            emissions[key] += ghg[id]['emissions'][key] * unitFactor;
          });

          Object.keys(EMPTY_EQUIVALENCIES).forEach((key) => {
            equivalencies[key] += ghg[id]['equivalencies'][key];
          });
        }
      });
      Object.keys(utilities).forEach((key) => {
        if (EMISSION_SCOPES[key] === 'indirect') {
          utility.push(utilities[key]);
        } else {
          utility.unshift(utilities[key]);
        }
      });
      Object.keys(scopes).forEach((key) => {
        if (key === 'indirect') {
          scope.push(scopes[key]);
        } else {
          scope.unshift(scopes[key]);
        }
      });

      setGHGUtilityData(utility);
      setGHGScopeData(scope);
      setGHGEmissionData(emissions);
      setGHGEquivalenciesData(equivalencies);
    } else {
      setGHGUtilityData([]);
      setGHGScopeData([]);
      setGHGEmissionData({ ...EMPTY_EMISSIONS });
      setGHGEquivalenciesData({ ...EMPTY_EQUIVALENCIES });
    }
  }, [dashboardData, activeIds, unitFactor, localBuildingIds]);

  return !activeGHGLicense ? (
    <div>
      This organization does not have a valid license to access this feature.
      Please contact an administrator about licensing.
    </div>
  ) : (
    <>
      <CheckResources
        organization={organization}
        dashboardData={dashboardData}
      />

      <Row style={{ marginBottom: '-1em' }}>
        <Col md={12}>
          <Form.Group controlId={'ghg-unit-select'} style={styles.unit_form}>
            <Form.Label style={styles.unit_form_label}>
              Select Unit:{' '}
            </Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => setActiveUnit(e.target.value)}
              defaultValue={activeUnit}
              style={styles.unit_form_control}
            >
              {Object.keys(ghg_units).map((unit) => (
                <option key={`unit-opt-${unit}`} value={unit}>
                  {ghg_units[unit]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={3} style={styles.col_widget_style2}>
          <WidgetInfo
            value={ghgEquivalenciesData['cars']}
            title={'Passenger Cars Driven for One Year'}
            icon={faCars}
            iconColor={CHARTCOLORS[2]}
          />
        </Col>
        <Col md={3} style={styles.col_widget_style2}>
          <WidgetInfo
            value={ghgEquivalenciesData['homes']}
            title={'Homes Energy Use for One Year'}
            icon={faHome}
            iconColor={CHARTCOLORS[1]}
          />
        </Col>
        <Col md={3} style={styles.col_widget_style2}>
          <WidgetInfo
            value={ghgEquivalenciesData['coal']}
            title={'Railcars of Coal Burned'}
            icon={faFire}
            iconColor={CHARTCOLORS[3]}
          />
        </Col>
        <Col md={3} style={styles.col_widget_style2}>
          <WidgetInfo
            value={ghgEquivalenciesData['acres']}
            title={'Acres of U.S. Forests'}
            icon={faTrees}
            iconColor={CHARTCOLORS[0]}
          />
        </Col>
        <GraphActionContainer
          customStyle={{ marginTop: '2em' }}
        ></GraphActionContainer>
      </Row>

      <br></br>
      <Row>
        <Col md={3}>
          <WidgetPieHover
            title={
              <>Scope Emissions{' (' + short_ghg_units[activeUnit] + ')'}</>
            }
            dataseries={ghgScopeData}
            unit={short_ghg_units[activeUnit]}
            height={325}
          />
        </Col>
        <Col md={3}>
          <WidgetPieHover
            title={'Utility Emissions (' + short_ghg_units[activeUnit] + ')'}
            dataseries={ghgUtilityData}
            unit={short_ghg_units[activeUnit]}
            height={325}
          />
        </Col>

        <Col md={6}>
          <h5 style={{ textAlign: 'center', width: '100%' }}>
            {'Building Emissions (' + short_ghg_units[activeUnit] + ')'}
          </h5>
          <WidgetActiveBar
            organization_id={organization.id}
            dataseries={ghgBuildingData}
            unit={short_ghg_units[activeUnit]}
            headers={['Building Name', 'Emissions']}
            keys={['name', 'value']}
            height={325}
          />
        </Col>
        <GraphActionContainer>
          <ChartInfo
            href='https://www.epa.gov/climateleadership/scope-1-and-scope-2-inventory-guidance'
            title='Scope Information'
          />
          <ChartDownload
            organization_id={organization.id}
            data={ghgBuildingData}
            headers={['Building Name', 'Emissions']}
            keys={['name', 'value']}
            chart_type='bar'
            title={'Download Bar Chart Data'}
          />
        </GraphActionContainer>
      </Row>
      <br></br>
      <Row>
        <Col md={3} style={styles.col_widget_style1}>
          <WidgetInfo
            value={Math.round(ghgEmissionData['CO2'])}
            title={
              <>
                Total CO<sub style={{ bottom: '-1.3em' }}>2</sub>
              </>
            }
            icon={''}
          />
        </Col>
        <Col md={3} style={styles.col_widget_style1}>
          <WidgetInfo
            value={Math.round(ghgEmissionData['CH4'])}
            title={
              <>
                Total CH<sub style={{ bottom: '-1.3em' }}>4</sub>
              </>
            }
            icon={''}
          />
        </Col>
        <Col md={3} style={styles.col_widget_style1}>
          <WidgetInfo
            value={Math.round(ghgEmissionData['N2O'])}
            title={
              <>
                Total N<sub style={{ bottom: '-1.3em' }}>2</sub>O
              </>
            }
            icon={''}
          />
        </Col>
        <Col md={3} style={styles.col_widget_style1}>
          <WidgetInfo
            value={Math.round(ghgEmissionData['CO2e'])}
            title={
              <>
                Total CO<sub style={{ bottom: '-1.3em' }}>2</sub>e
              </>
            }
            icon={''}
          />
        </Col>
        <GraphActionContainer customStyle={{ marginTop: '2.5em' }}>
          <ChartInfoClick
            onClick={() => {
              setShowGHGInfoModal(true);
            }}
            title='Greenhouse Gas Information '
          />
        </GraphActionContainer>
      </Row>

      <br></br>
      <Row style={{ marginBottom: '2em' }}>
        <div style={{ marginBottom: '1em' }}>
          <h5 style={{ display: 'inline-block', paddingLeft: '2em' }}>
            {'Monthly Greenhouse Gas Emissions (' +
              short_ghg_units[activeUnit] +
              ')'}
          </h5>
          <div style={{ display: 'inline-block' }}>
            <DashboardBaselineDateSelectContainer
              orgId={organization.id}
              endDate={endDate}
              dateOption={dateOption}
              setBaselineData={setBaselineData}
              customStyle={{ marginLeft: '3em' }}
            />
          </div>
        </div>

        <div style={{ paddingLeft: '2em', width: '98%' }}>
          <EnergyTracerMultiLineChart
            data={!ghgMonthData ? [] : ghgMonthData}
            unit={short_ghg_units[activeUnit]}
            keys={activeKeys}
            baseline={Object.keys(baselineData).length > 0}
            showSummary={GHGSummary}
          />
        </div>
        <GraphActionContainer>
          <ChartToggle
            onClick={() => {
              setGHGSummary((prev) => !prev);
            }}
            state={GHGSummary ? 1 : 0}
            title={'Toggle Selection Total'}
            variant={3}
          />
          <ChartDownload
            organization_id={organization.id}
            data={ghgMonthData}
            headers={chartDownloadHeaders}
            keys={chartDownloadKeys}
            chart_type={'line'}
          />
        </GraphActionContainer>
      </Row>
      <GHGInfoModal
        show={showGHGInfoModal}
        onHide={() => setShowGHGInfoModal(false)}
      />
    </>
  );
}

export default CarbonSummary;
