import React, { useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { sortBy } from 'lodash';

import { ApiServiceServerless } from '../../../xhr_libs';
import AddEntityActionButtons from '../../forms/AddEntityActionButtons';

export default function EditOperationalSavingsMeasureModal(props) {
  const {
    show,
    onHide,
    organizationId,
    projects,
    updateList,
    showToast,
    existingMeasure,
  } = props;

  const [measureData, setMeasureData] = useState({
    measure: null,
    value: null,
    years: null,
  });
  const [projectIds, setProjectIds] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMeasureData({
      measure: existingMeasure.measure,
      value: existingMeasure.value,
      years: existingMeasure.years,
    });
    setProjectIds(existingMeasure.project_ids);
  }, [existingMeasure]);

  const parseNumber = (val) => {
    const float_val = !isNaN(parseFloat(val.replace(/,/g, '')))
      ? parseFloat(val.replace(/,/g, ''))
      : 0;
    return float_val;
  };

  const updateSet = (values) => {
    let arr = Array.prototype.slice.call(values);
    arr = arr.map((item) => item.value);
    setProjectIds(arr);
  };

  const saveMeasure = () => {
    setIsLoading(true);
    ApiServiceServerless.put(
      `/operational_savings/${existingMeasure.id}`,
      {
        org_id: organizationId,
        ...measureData,
        project_ids: projectIds,
      },
      { authorization_id: organizationId }
    )
      .then((res) => {
        showToast('success', 'Success', 'Measure successfully added');
        updateList(res.data, 'performance_measures');
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        onHide();
        setIsLoading(false);
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton> Edit Measure</Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            {'Measure'}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <Form.Control
              required
              defaultValue={measureData.measure}
              onChange={(e) =>
                setMeasureData({
                  ...measureData,
                  measure: e.target.value,
                })
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {'$/Year '}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={2}
              fixedDecimalScale
              placeholder={'0.00'}
              defaultValue={measureData.value}
              onChange={(e) =>
                setMeasureData({
                  ...measureData,
                  value: parseNumber(e.target.value),
                })
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {'Duration (Years) '}
            <span className='text-warning font-weight-bold pl-1'>*</span>
          </Form.Label>
          <Form.Row>
            <NumberFormat
              customInput={Form.Control}
              thousandSeparator
              isNumericString
              decimalScale={0}
              fixedDecimalScale
              placeholder={'0'}
              defaultValue={measureData.years}
              onChange={(e) =>
                setMeasureData({
                  ...measureData,
                  years: parseNumber(e.target.value),
                })
              }
            />
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Project(s)</Form.Label>
          <Form.Control
            as='select'
            multiple
            defaultValue={projectIds}
            onChange={(e) => updateSet(e.target.selectedOptions)}
          >
            {sortBy(Object.values(projects), ['name'])
              .filter((project) => project.project_type === 'performance')
              .map((item) => (
                <option key={`project-opt-${item.id}`} value={item.id}>
                  {item.name}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons
          onCancel={onHide}
          onSubmit={saveMeasure}
          isSubmitting={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}
